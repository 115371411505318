/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
} from './';

/**
 * incident type
 * @export
 * @interface ApiIncident
 */
export interface ApiIncident {
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof ApiIncident
     */
    incidentType: ApiAssistanceIncidentType;
}

export function ApiIncidentFromJSON(json: any): ApiIncident {
    return ApiIncidentFromJSONTyped(json, false);
}

export function ApiIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidentType': ApiAssistanceIncidentTypeFromJSON(json['incidentType']),
    };
}

export function ApiIncidentToJSON(value?: ApiIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidentType': ApiAssistanceIncidentTypeToJSON(value.incidentType),
    };
}

