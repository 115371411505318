import { useLocation } from 'react-router-dom';

export const getRelativeCurrentUrl = (): string => {
  return window.location.pathname;
};

export const getCurrentFlow = (): string | null => {
  const currentPath = getRelativeCurrentUrl();
  const flowRoute = currentPath.split('/');
  if (flowRoute && flowRoute.length > 0) {
    return flowRoute[1];
  } else return null;
};

export const getParamFromUrl = (key: string): string | null => {
  const queryParam = new URLSearchParams(useLocation().search);
  return queryParam.get(key);
};
