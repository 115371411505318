/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVehicleDetailsPreview
 */
export interface ApiVehicleDetailsPreview {
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetailsPreview
     */
    licensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetailsPreview
     */
    vinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetailsPreview
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetailsPreview
     */
    model?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetailsPreview
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetailsPreview
     */
    error?: string;
}

export function ApiVehicleDetailsPreviewFromJSON(json: any): ApiVehicleDetailsPreview {
    return ApiVehicleDetailsPreviewFromJSONTyped(json, false);
}

export function ApiVehicleDetailsPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVehicleDetailsPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'vinCode': !exists(json, 'vinCode') ? undefined : json['vinCode'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ApiVehicleDetailsPreviewToJSON(value?: ApiVehicleDetailsPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licensePlate': value.licensePlate,
        'vinCode': value.vinCode,
        'manufacturer': value.manufacturer,
        'model': value.model,
        'year': value.year,
        'error': value.error,
    };
}

