import { Dispatch } from 'redux';
import {
  AdditionalDetailsState,
  resetAdditionalDetails,
  saveImages,
  setAdditionalDetails,
} from './additionDetailsReducer';
import {
  ApiAssistanceRequestAdditionalDetails,
  assistanceApi,
  assistanceRequestFilesApi,
} from '../../../apis';
import * as Sentry from '@sentry/react';
import { NetworkError } from '../../../common/exception/NetworkError';

export const saveAdditionalDetails =
  (id: number, details: AdditionalDetailsState) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const additionalDetailsRequest: ApiAssistanceRequestAdditionalDetails = {
        email: details.email,
        description: details.description,
      };
      await assistanceApi.updateAssistanceRequestAdditionalInfo({
        id: id,
        apiAssistanceRequestAdditionalDetails: additionalDetailsRequest,
      });
      dispatch(setAdditionalDetails(details));
    } catch (e) {
      dispatch(resetAdditionalDetails());
      if (e instanceof NetworkError) throw e;
    }
  };

export const saveAdditionalImages =
  (id: number, details: AdditionalDetailsState) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await Promise.all(
        (details.images as Blob[]).map(async (image: Blob) => {
          await assistanceRequestFilesApi.uploadFile({
            id: id,
            file: image,
            smsIdentifier: 'MVP1', //TODO: update when available
          });
        })
      );
      dispatch(saveImages(details));
    } catch (e) {
      console.log(e);
      if (e instanceof NetworkError) throw e;
      Sentry.captureException(e);
      throw Error('errors.failedToUpload');
    }
  };
