/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiLanguage {
    English = 'ENGLISH',
    Estonian = 'ESTONIAN',
    Russian = 'RUSSIAN',
    Latvian = 'LATVIAN',
    Lithuanian = 'LITHUANIAN'
}

export function ApiLanguageFromJSON(json: any): ApiLanguage {
    return ApiLanguageFromJSONTyped(json, false);
}

export function ApiLanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiLanguage {
    return json as ApiLanguage;
}

export function ApiLanguageToJSON(value?: ApiLanguage | null): any {
    return value as any;
}

