/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAssistanceRequestTowTruckDriver,
    ApiAssistanceRequestTowTruckDriverFromJSON,
    ApiAssistanceRequestTowTruckDriverToJSON,
} from '../models';

export interface GetTowTruckDriverByAssistanceRequestRequest {
    assistanceRequestId: number;
}

/**
 * 
 */
export class TowTruckApi extends runtime.BaseAPI {

    /**
     * returns tow truck driver info for a roadside assistance request
     */
    async getTowTruckDriverByAssistanceRequestRaw(requestParameters: GetTowTruckDriverByAssistanceRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestTowTruckDriver>> {
        if (requestParameters.assistanceRequestId === null || requestParameters.assistanceRequestId === undefined) {
            throw new runtime.RequiredError('assistanceRequestId','Required parameter requestParameters.assistanceRequestId was null or undefined when calling getTowTruckDriverByAssistanceRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.assistanceRequestId !== undefined) {
            queryParameters['assistanceRequestId'] = requestParameters.assistanceRequestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tow-truck/accepted-driver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestTowTruckDriverFromJSON(jsonValue));
    }

    /**
     * returns tow truck driver info for a roadside assistance request
     */
    async getTowTruckDriverByAssistanceRequest(requestParameters: GetTowTruckDriverByAssistanceRequestRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestTowTruckDriver> {
        const response = await this.getTowTruckDriverByAssistanceRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
