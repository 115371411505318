import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import TermsEstonian from './TermsEstonian';
import PrivacyEstonian from './PrivacyEstonian';
import TermsEnglish from './TermsEnglish';
import PrivacyEnglish from './PrivacyEnglish';
import TermsLatvian from './TermsLatvian';
import PrivacyLatvian from './PrivacyLatvian';
import TermsRussian from './TermsRussian';
import PrivacyRussian from './PrivacyRussian';
import TermsLithuanian from './TermsLithuanian';
import PrivacyLithuanian from './PrivacyLithuanian';
import { Translate } from 'react-redux-i18n';
import { ApiLanguage } from '../../api';

export interface TermsAndPrivacyProps {
  color: string;
  onClose: () => void;
  language: ApiLanguage;
}

export const TermsAndPrivacy: React.FC<React.PropsWithChildren<TermsAndPrivacyProps>> = ({
  color,
  onClose,
  language,
}: TermsAndPrivacyProps) => {
  const getTermsComponent = () => {
    if (language == ApiLanguage.Latvian) {
      return <TermsLatvian />;
    } else if (language == ApiLanguage.Estonian) {
      return <TermsEstonian />;
    } else if (language == ApiLanguage.Russian) {
      return <TermsRussian />;
    } else if (language == ApiLanguage.Lithuanian) {
      return <TermsLithuanian />;
    } else {
      return <TermsEnglish />;
    }
  };

  const getPrivacyComponent = () => {
    if (language == ApiLanguage.Latvian) {
      return <PrivacyLatvian />;
    } else if (language == ApiLanguage.Estonian) {
      return <PrivacyEstonian />;
    } else if (language == ApiLanguage.Russian) {
      return <PrivacyRussian />;
    } else if (language == ApiLanguage.Lithuanian) {
      return <PrivacyLithuanian />;
    } else {
      return <PrivacyEnglish />;
    }
  };

  return (
    <Container className='container col-lg-12' id='terms-privacy' fluid>
      <Tabs defaultActiveKey='terms' id='terms-and-privacy' className='mb-3' fill justify>
        <Tab eventKey='terms' title={<Translate value={'terms.termsTitle'} />}>
          {getTermsComponent()}
        </Tab>
        <Tab eventKey='privacy' title={<Translate value={'terms.privacyTitle'} />}>
          {getPrivacyComponent()}
        </Tab>
      </Tabs>
      <div className='terms-button'>
        <Button style={{ backgroundColor: color, borderColor: color }} onClick={onClose} block>
          Ok
        </Button>
      </div>
    </Container>
  );
};

export default TermsAndPrivacy;
