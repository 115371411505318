import React from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import '../../../common/components/incidents/incident.css';
import { Translate } from 'react-redux-i18n';
import { VehicleDetailsState } from './vehicleDetailsReducer';
import './VehicleInfoBox.css';
import { MdCall } from 'react-icons/md';
import { getErrorMessageKey } from '../../../locale/locale';

export interface VehicleInfoBoxProps {
  vehicleDetails: VehicleDetailsState;
  loading: boolean;
  themeColor?: string;
  insurance?: boolean;
  directPhoneLine?: string;
}

export const VehicleInfoBox: React.FC<React.PropsWithChildren<VehicleInfoBoxProps>> = ({
  vehicleDetails,
  insurance,
  loading,
  themeColor,
  directPhoneLine,
}: VehicleInfoBoxProps) => {
  const callOperatorCard = () => {
    return (
      <Row className='veh-dtl-row veh-dtl-error'>
        <p className='veh-dtl-data'>
          <Translate value={'vehicleDetails.error'} />
          {directPhoneLine && (
            <a className='text-center' href={`tel:${directPhoneLine}`}>
              <Button
                block
                data-testid='callButton'
                className='w-auto text-uppercase call-button'
                style={{
                  background: themeColor,
                  borderColor: themeColor,
                }}
              >
                <MdCall className='mr-1' />
                <Translate value={'vehicleDetails.callOperator'} /> {directPhoneLine}
              </Button>
            </a>
          )}
        </p>
      </Row>
    );
  };
  return vehicleDetails.plateNumber != '' && !loading ? (
    <Container fluid className='veh-card'>
      <Row>
        <Col>
          <Row className='veh-dtl-row'>
            <p className='mr-1'>
              <Translate value={'vehicleDetails.mark'} />:
            </p>
            <p className='veh-dtl-data'>{vehicleDetails.mark}</p>
          </Row>
          <Row className='veh-dtl-row'>
            <p className='mr-1'>
              <Translate value={'vehicleDetails.model'} />:
            </p>
            <p className='veh-dtl-data'>{vehicleDetails.model}</p>
          </Row>
          {vehicleDetails.year && (
            <Row className='veh-dtl-row'>
              <p className='mr-1'>
                <Translate value={'vehicleDetails.year'} />:
              </p>
              <p className='veh-dtl-data'>{vehicleDetails.year}</p>
            </Row>
          )}
        </Col>
        <Col className='veh-icon' data-testid='bodytype' />
      </Row>
      {insurance !== undefined && !insurance && callOperatorCard()}
    </Container>
  ) : vehicleDetails.error != '' ? (
    <Container fluid className='veh-card'>
      <p className='veh-dtl-data' data-testid='vehDtlErr'>
        <Translate value={getErrorMessageKey(vehicleDetails.error)} />
      </p>
      {callOperatorCard()}
    </Container>
  ) : loading ? (
    <Container fluid className='veh-card'>
      <Row>
        <Spinner animation='border' style={{ display: 'block' }} data-testid='spinner' />
      </Row>
    </Container>
  ) : null;
};

export default VehicleInfoBox;
