/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiClaimIncidentType,
    ApiClaimIncidentTypeFromJSON,
    ApiClaimIncidentTypeFromJSONTyped,
    ApiClaimIncidentTypeToJSON,
} from './';

/**
 * claim incident type
 * @export
 * @interface ApiClaimIncident
 */
export interface ApiClaimIncident {
    /**
     * 
     * @type {ApiClaimIncidentType}
     * @memberof ApiClaimIncident
     */
    claimIncidentType: ApiClaimIncidentType;
}

export function ApiClaimIncidentFromJSON(json: any): ApiClaimIncident {
    return ApiClaimIncidentFromJSONTyped(json, false);
}

export function ApiClaimIncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimIncident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'claimIncidentType': ApiClaimIncidentTypeFromJSON(json['claimIncidentType']),
    };
}

export function ApiClaimIncidentToJSON(value?: ApiClaimIncident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimIncidentType': ApiClaimIncidentTypeToJSON(value.claimIncidentType),
    };
}

