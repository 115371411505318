/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPerson
 */
export interface ApiPerson {
    /**
     * 
     * @type {number}
     * @memberof ApiPerson
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPerson
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPerson
     */
    personalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPerson
     */
    phonePrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPerson
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPerson
     */
    email?: string;
}

export function ApiPersonFromJSON(json: any): ApiPerson {
    return ApiPersonFromJSONTyped(json, false);
}

export function ApiPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'personalCode': !exists(json, 'personalCode') ? undefined : json['personalCode'],
        'phonePrefix': !exists(json, 'phonePrefix') ? undefined : json['phonePrefix'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function ApiPersonToJSON(value?: ApiPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'personalCode': value.personalCode,
        'phonePrefix': value.phonePrefix,
        'phone': value.phone,
        'email': value.email,
    };
}

