/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiOtherPartyDetails
 */
export interface ApiOtherPartyDetails {
    /**
     * 
     * @type {boolean}
     * @memberof ApiOtherPartyDetails
     */
    otherPartiesInvolved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiOtherPartyDetails
     */
    otherPartiesInfo?: boolean;
}

export function ApiOtherPartyDetailsFromJSON(json: any): ApiOtherPartyDetails {
    return ApiOtherPartyDetailsFromJSONTyped(json, false);
}

export function ApiOtherPartyDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOtherPartyDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'otherPartiesInvolved': json['otherPartiesInvolved'],
        'otherPartiesInfo': !exists(json, 'otherPartiesInfo') ? undefined : json['otherPartiesInfo'],
    };
}

export function ApiOtherPartyDetailsToJSON(value?: ApiOtherPartyDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'otherPartiesInvolved': value.otherPartiesInvolved,
        'otherPartiesInfo': value.otherPartiesInfo,
    };
}

