import React, { lazy } from 'react';

import { default as AssistanceIncidentSelection } from '../pages/assistance-requests/incidents/IncidentSelection';
import VehicleDetails from '../pages/assistance-requests/vehicle-details/VehicleDetails';
import AdditionalDetails from '../pages/assistance-requests/additional-details/AdditionalDetails';
import AssistanceRequestLanding from '../pages/assistance-requests/landing/landing';
import { default as ClaimsIncidentSelection } from '../pages/claims/incidents/IncidentSelection';
import DamageLocation from '../pages/claims/damage-location/DamageLocation';
import { default as VehicleIncidentSelection } from '../pages/claims/vehicle-details/VehicleDetails';
import PhotosView from '../pages/claims/photos/PhotosView';
import NotifierInfo from '../pages/claims/notifier-info/NotifierInfo';
import AccidentDetails from '../pages/claims/accident-details/AccidentDetails';
import OtherParties from '../pages/claims/other-parties/OtherParties';
import ClaimReceived from '../pages/claims/claim-received/ClaimReceived';
import CommunicationPreference from '../pages/claims/communication-preferences/CommunicationPreference';
import { ApiClaimIncidentType } from '../api';
import { getRelativeCurrentUrl } from '../common/utils/URLUtils';
import InsuranceSelection from '../pages/claims/insurance-selection/InsuranceSelection';

const Map = lazy(() => import('../pages/assistance-requests/map/Map'));
const TrackDriverView = lazy(() => import('../pages/assistance-requests/track/TrackDriverView'));
const WorkshopSelectionView = lazy(
  () => import('../pages/claims/select-workshop/WorkshopSelection')
);

export interface RouteName {
  path: string;
  component: React.ComponentType<React.PropsWithChildren<unknown>>;
}

export const RoutePrefixes = {
  ASSISTANCE: 'assistance',
  CLAIMS: 'claims',
};

/*Order of the routes is important*/
export const AssistanceRoutes: RouteName[] = [
  {
    path: `/${RoutePrefixes.ASSISTANCE}/landing`,
    component: AssistanceRequestLanding,
  },
  {
    path: `/${RoutePrefixes.ASSISTANCE}/vehicle-details`,
    component: VehicleDetails,
  },
  {
    path: `/${RoutePrefixes.ASSISTANCE}/incidents`,
    component: AssistanceIncidentSelection,
  },
  {
    path: `/${RoutePrefixes.ASSISTANCE}/map`,
    component: Map,
  },
  {
    path: `/${RoutePrefixes.ASSISTANCE}/additional-details`,
    component: AdditionalDetails,
  },
  {
    path: `/${RoutePrefixes.ASSISTANCE}/track-driver`,
    component: TrackDriverView,
  },
];

/* Below are all available routes for the claim flow. Needed only for router population */
export const ClaimRoutes: RouteName[] = [
  {
    path: `/${RoutePrefixes.CLAIMS}/incidents`,
    component: ClaimsIncidentSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
    component: VehicleIncidentSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/damage-location`,
    component: DamageLocation,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/photo`,
    component: PhotosView,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
    component: NotifierInfo,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/other-parties`,
    component: OtherParties,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/accident-details`,
    component: AccidentDetails,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
    component: InsuranceSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/select-workshop`,
    component: WorkshopSelectionView,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
    component: CommunicationPreference,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/claim-received`,
    component: ClaimReceived,
  },
];

/*Order of the routes is important*/
export const ClaimRoutesForStandardFlow: RouteName[] = [
  {
    path: `/${RoutePrefixes.CLAIMS}/incidents`,
    component: ClaimsIncidentSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
    component: VehicleIncidentSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/damage-location`,
    component: DamageLocation,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/photo`,
    component: PhotosView,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
    component: NotifierInfo,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/other-parties`,
    component: OtherParties,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/accident-details`,
    component: AccidentDetails,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
    component: InsuranceSelection,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/select-workshop`,
    component: WorkshopSelectionView,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
    component: CommunicationPreference,
  },
  {
    path: `/${RoutePrefixes.CLAIMS}/claim-received`,
    component: ClaimReceived,
  },
];

export const getClaimRouteOrder = (incident?: ApiClaimIncidentType): number => {
  if (!incident) return 1;
  const currentRoute = getRelativeCurrentUrl();
  // @ts-ignore
  const routes = Object.entries(claimRoutesByIncidents).find(
    (item: any) => item[0] === incident
  )[1] as Array<any>;
  return routes.findIndex((route: RouteName) => route.path === currentRoute) + 1;
};

const claimRoutesByIncidents = {
  [ApiClaimIncidentType.WindshieldDamage]: [
    {
      path: `/${RoutePrefixes.CLAIMS}/incidents`,
      component: ClaimsIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
      component: VehicleIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/photo`,
      component: PhotosView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
      component: NotifierInfo,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/accident-details`,
      component: AccidentDetails,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
      component: InsuranceSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/select-workshop`,
      component: WorkshopSelectionView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
      component: CommunicationPreference,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/claim-received`,
      component: ClaimReceived,
    },
  ],
  [ApiClaimIncidentType.TrafficAccident]: ClaimRoutesForStandardFlow,
  [ApiClaimIncidentType.HitWildAnimal]: ClaimRoutesForStandardFlow,
  [ApiClaimIncidentType.TechnicalBreakdown]: [
    {
      path: `/${RoutePrefixes.CLAIMS}/incidents`,
      component: ClaimsIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
      component: VehicleIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
      component: NotifierInfo,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/accident-details`,
      component: AccidentDetails,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
      component: InsuranceSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/select-workshop`,
      component: WorkshopSelectionView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
      component: CommunicationPreference,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/claim-received`,
      component: ClaimReceived,
    },
  ],
  [ApiClaimIncidentType.Theft]: [
    {
      path: `/${RoutePrefixes.CLAIMS}/incidents`,
      component: ClaimsIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
      component: VehicleIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/photo`,
      component: PhotosView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
      component: NotifierInfo,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/accident-details`,
      component: AccidentDetails,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
      component: InsuranceSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
      component: CommunicationPreference,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/claim-received`,
      component: ClaimReceived,
    },
  ],
  [ApiClaimIncidentType.VandalismOrFire]: [
    {
      path: `/${RoutePrefixes.CLAIMS}/incidents`,
      component: ClaimsIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/vehicle-details`,
      component: VehicleIncidentSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/damage-location`,
      component: DamageLocation,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/photo`,
      component: PhotosView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/notifier-info`,
      component: NotifierInfo,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/accident-details`,
      component: AccidentDetails,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/insurance-selection`,
      component: InsuranceSelection,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/select-workshop`,
      component: WorkshopSelectionView,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/communication-preferences`,
      component: CommunicationPreference,
    },
    {
      path: `/${RoutePrefixes.CLAIMS}/claim-received`,
      component: ClaimReceived,
    },
  ],
  [ApiClaimIncidentType.Other]: ClaimRoutesForStandardFlow,
};

export const getNextRoute = (
  incident: ApiClaimIncidentType,
  showInsuranceSelection: boolean | undefined,
  skipWorkshop: boolean | undefined
): string | null => {
  const currentRoute = getRelativeCurrentUrl();
  // @ts-ignore
  let routes = Object.entries(claimRoutesByIncidents).find(
    (item: any) => item[0] === incident
  )[1] as Array<any>;
  if (skipWorkshop) {
    routes = routes.filter((route) => route.path !== '/claims/select-workshop');
  }
  const nextRouteIndex = routes.findIndex((route: any) => route.path.includes(currentRoute));
  if (nextRouteIndex >= 0) {
    if (nextRouteIndex === routes.length - 1) {
      return null;
    } else {
      const route = routes[nextRouteIndex + 1].path;
      if (route == '/claims/insurance-selection' && !showInsuranceSelection) {
        return routes[nextRouteIndex + 2].path;
      } else {
        return route;
      }
    }
  }

  return null;
};

export const getSteps = (
  incident?: ApiClaimIncidentType,
  showInsuranceSelection?: boolean
): number => {
  if (!incident) {
    return ClaimRoutes.length;
  }
  // @ts-ignore
  const routes = Object.entries(claimRoutesByIncidents).find(
    (item: any) => item[0] === incident
  )[1];
  const subtractInsuranceSelectionStep = showInsuranceSelection ? 2 : 3;
  return routes && routes.length ? routes.length - subtractInsuranceSelectionStep : 0;
};

export const checkIfStepIsValid = (incident?: ApiClaimIncidentType): boolean | undefined => {
  if (incident) {
    const currentRoute = getRelativeCurrentUrl();
    // @ts-ignore
    const routes = Object.entries(claimRoutesByIncidents).find(
      (item: any) => item[0] === incident
    )[1];

    return !!(
      routes &&
      routes.length &&
      (routes as any).find((route: any) => route.path === currentRoute)
    );
  } else return;
};
