/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiPerson,
    ApiPersonFromJSON,
    ApiPersonFromJSONTyped,
    ApiPersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiObjectParty
 */
export interface ApiObjectParty {
    /**
     * 
     * @type {number}
     * @memberof ApiObjectParty
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiObjectParty
     */
    description: string;
    /**
     * 
     * @type {ApiPerson}
     * @memberof ApiObjectParty
     */
    person?: ApiPerson;
}

export function ApiObjectPartyFromJSON(json: any): ApiObjectParty {
    return ApiObjectPartyFromJSONTyped(json, false);
}

export function ApiObjectPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiObjectParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': json['description'],
        'person': !exists(json, 'person') ? undefined : ApiPersonFromJSON(json['person']),
    };
}

export function ApiObjectPartyToJSON(value?: ApiObjectParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'person': ApiPersonToJSON(value.person),
    };
}

