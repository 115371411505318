import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCommunicationPreference, ApiCommunicationPreferenceType } from '../../../api';

export interface CommunicationPreferencesState {
  communicationPreferences: ApiCommunicationPreferenceType[];
}

const initialState: CommunicationPreferencesState = {
  communicationPreferences: [],
};

const communicationPreferenceSlice = createSlice({
  name: 'communicationPreference',
  initialState,
  reducers: {
    setCommunicationPreference: (
      state: CommunicationPreferencesState,
      action: PayloadAction<ApiCommunicationPreference>
    ) => {
      if (action.payload) {
        state.communicationPreferences = action.payload.communicationPreferences;
      }
    },
    clearCommunicationPreference: (state: CommunicationPreferencesState) => {
      state.communicationPreferences = [];
    },
  },
});

const { actions, reducer } = communicationPreferenceSlice;

export const { setCommunicationPreference, clearCommunicationPreference } = actions;

export default reducer;
