import * as yup from 'yup';

export const REQUIRED_FIELD = 'Required field';
export const INVALID_PHONE_NUMBER = 'Must be a valid phone number';
export const INVALID_PERSONAL_CODE =
  'Personal code can not be empty and should be in correct format!';
export const MISSING_LICENSE_PLATE = 'Provide vehicle plate number';
export const INVALID_LICENSE_PLATE_NUMBER = 'Only digits and letters allowed.';
export const MISSING_NAME = 'Provide your name';
export const MISSING_PHONE_NUMBER = 'Provide your phone number';

export const phoneNumberFormat = /^[0-9]+$/;
// Currently only Esonian and Latvian formats are supported
export const personalCodeFormat =
  /(^[1-6]\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])\d{4}$)|(^\d{6}-\d{5}$)/;
export const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const plateNumberFormat = /^[a-zA-Z0-9]+$/i;

export const phoneValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0)
  .matches(phoneNumberFormat, INVALID_PHONE_NUMBER);

export const emailValidation = yup
  .string()
  .trim()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0)
  .matches(emailFormat, INVALID_PHONE_NUMBER);

export const driverNameValidation = yup
  .string()
  .test('len', REQUIRED_FIELD, (v) => v == undefined || (v !== undefined && v.trim().length > 0));

export const driverPhoneValidation = yup
  .string()
  .test('len', REQUIRED_FIELD, (v) => v == undefined || (v !== undefined && v.trim().length > 0))
  .matches(phoneNumberFormat, INVALID_PHONE_NUMBER);

export const driverEmailValidation = yup
  .string()
  .trim()
  .test('len', REQUIRED_FIELD, (v) => v == undefined || (v !== undefined && v.trim().length > 0))
  .matches(emailFormat, INVALID_PHONE_NUMBER);

export const nameValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0);

export const isDriverValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0);

export const personalCodeValidation = yup
  .string()
  .trim()
  .test('format', INVALID_PERSONAL_CODE, (v) => !v || personalCodeFormat.test(v));

export const plateNumberValidationSchema = yup
  .string()
  .required(REQUIRED_FIELD)
  .matches(plateNumberFormat, INVALID_LICENSE_PLATE_NUMBER);

export const descriptionValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0);

export const removeInvalidCharacters = (value: string): string => {
  return value.replace(/[^A-Za-z0-9]/gi, '');
};
