import { ClientError } from '../../../common/exception/ClientError';
import {
  claimApi,
  CreateClaimInsuranceInfoRequest,
  GetAllInsurancesRequest,
  insuranceProviderApi,
} from '../../../apis';
import * as Sentry from '@sentry/react';
import { NetworkError } from '../../../common/exception/NetworkError';
import { Dispatch } from 'redux';
import { setCascoId, setCascoInsurances, setMtplId, setMtplInsurances } from '../claimReducer';

export const saveInsuranceInfo =
  (mtpl: string | undefined, casco: string | undefined, claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let cascoId = undefined,
        mtplId = undefined;
      if (casco != '-1' && casco != undefined) {
        cascoId = parseInt(casco);
      }
      if (mtpl != '-1' && mtpl != undefined) {
        mtplId = parseInt(mtpl);
      }
      const request: CreateClaimInsuranceInfoRequest = {
        id: claimId,
        apiInsuranceInfo: {
          mtplInsuranceId: mtplId,
          cascoInsuranceId: cascoId,
        },
      };
      await claimApi.createClaimInsuranceInfo(request);
      casco != undefined ? dispatch(setCascoId(parseInt(casco))) : '';
      mtpl != undefined ? dispatch(setMtplId(parseInt(mtpl))) : '';
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };

export const getInsuranceInfo =
  (claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: GetAllInsurancesRequest = {
        claimId: claimId,
      };
      const resp = await insuranceProviderApi.getAllInsurances(request);
      dispatch(setMtplInsurances(resp.mtplInsurances ?? []));
      dispatch(setCascoInsurances(resp.cascoInsurances ?? []));
      if (resp.selectedCasco != null) dispatch(setCascoId(resp.selectedCasco.id));
      if (resp.selectedMtpl != null) dispatch(setMtplId(resp.selectedMtpl.id));
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };
