const CLAIM_ID = 'claimId';

export class ClaimStorage {
  public static hasClaim(): boolean {
    return this.getClaimId() != null;
  }

  public static getClaimId(): string | null {
    const id = localStorage.getItem(CLAIM_ID);
    return id !== undefined && id !== null ? id : null;
  }

  public static setClaimId(id: string): void {
    localStorage.setItem(CLAIM_ID, id);
  }

  public static clearClaimId(): void {
    localStorage.removeItem(CLAIM_ID);
  }
}
