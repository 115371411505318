import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TermsAndPrivacy } from './TermsAndPrivacy';
import { useLocation } from 'react-router-dom';

import { ApiLanguage } from '../../api';

export interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  color: string;
  language: ApiLanguage;
}

const TermsModal: React.FC<React.PropsWithChildren<TermsModalProps>> = ({
  isOpen,
  onClose,
  color,
  language,
}: TermsModalProps) => {
  const [showModal, setShowModal] = useState(isOpen);
  const { hash } = useLocation();
  const MODAL_HASH_OPEN = '#modal-open';
  const MODAL_HASH_CLOSE = '#modal-close';

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (hash === MODAL_HASH_CLOSE) {
      onClose();
    }
    setShowModal(hash === MODAL_HASH_OPEN);
  }, [hash]);

  const handleCloseTerms = () => {
    window.location.replace(MODAL_HASH_CLOSE);
    onClose();
  };

  const handleShowTerms = () => {
    window.location.assign(MODAL_HASH_OPEN);
  };

  return (
    <Modal
      show={showModal}
      onShow={handleShowTerms}
      onHide={handleCloseTerms}
      backdrop={true}
      keyboard={true}
      dialogClassName='modal-lg hide-backdrop-400'
    >
      <Modal.Body>
        <TermsAndPrivacy onClose={handleCloseTerms} color={color} language={language} />
      </Modal.Body>
    </Modal>
  );
};

export default TermsModal;
