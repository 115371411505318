import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.css';

export const Loader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className='d-flex justify-content-center'>
      <Spinner
        data-testid='spinner'
        className='spinner-center'
        animation='border'
        variant='secondary'
      />
    </div>
  );
};
