import {
  ApiCommunicationPreference,
  SaveCommunicationPreferenceRequest,
  SubmitClaimRequest,
} from '../../../api';
import { claimApi } from '../../../apis';
import { Dispatch } from 'redux';
import { setCommunicationPreference } from './communicationPreferencesReducer';
import { push } from 'redux-first-history';
import * as Sentry from '@sentry/react';

export const saveCommunicationPreference =
  (preference: ApiCommunicationPreference, claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: SaveCommunicationPreferenceRequest = {
        apiCommunicationPreference: preference,
        id: claimId,
      };
      await claimApi.saveCommunicationPreference(request);
      dispatch(setCommunicationPreference(preference));
    } catch (err) {
      if (err) throw err;
      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };

export const submitClaim =
  (claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: SubmitClaimRequest = {
        claimId: claimId,
      };
      await claimApi.submitClaim(request);
      dispatch(push('/claims/claim-received'));
    } catch (err) {
      if (err) throw err;
      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };
