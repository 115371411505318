/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiAssistanceRequestStatus {
    Draft = 'DRAFT',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Finished = 'FINISHED',
    TimedOut = 'TIMED_OUT',
    ClientCancelled = 'CLIENT_CANCELLED'
}

export function ApiAssistanceRequestStatusFromJSON(json: any): ApiAssistanceRequestStatus {
    return ApiAssistanceRequestStatusFromJSONTyped(json, false);
}

export function ApiAssistanceRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestStatus {
    return json as ApiAssistanceRequestStatus;
}

export function ApiAssistanceRequestStatusToJSON(value?: ApiAssistanceRequestStatus | null): any {
    return value as any;
}

