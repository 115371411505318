import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiServiceThemeData } from '../../api';

export interface ThemeState {
  color: string;
  logo: string | undefined;
}

const initialState: ThemeState = {
  color: 'red',
  logo: undefined,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state: ThemeState, action: PayloadAction<ApiServiceThemeData>) => {
      state.logo = action.payload.logo;
      state.color = action.payload.color;
    },
  },
});

const { actions, reducer } = themeSlice;

export const { setTheme } = actions;

export default reducer;
