/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAssistanceRequestLink
 */
export interface ApiAssistanceRequestLink {
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLink
     */
    phoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestLink
     */
    serviceId: number;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiAssistanceRequestLink
     */
    language?: ApiLanguage;
}

export function ApiAssistanceRequestLinkFromJSON(json: any): ApiAssistanceRequestLink {
    return ApiAssistanceRequestLinkFromJSONTyped(json, false);
}

export function ApiAssistanceRequestLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'],
        'serviceId': json['serviceId'],
        'language': !exists(json, 'language') ? undefined : ApiLanguageFromJSON(json['language']),
    };
}

export function ApiAssistanceRequestLinkToJSON(value?: ApiAssistanceRequestLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phoneNumber': value.phoneNumber,
        'serviceId': value.serviceId,
        'language': ApiLanguageToJSON(value.language),
    };
}

