import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { MixpanelConfiguration } from '../configs/mixpanel';
import { isProduction } from './utils';
import {
  ApiAccidentDetails,
  ApiClaimIncidentType,
  ApiCommunicationPreference,
  ApiDamageArea,
  ApiVehicleDetails,
  ApiWorkshop,
  SubmitAssistanceRequestRequest,
} from '../api';
import { ServiceStorage } from '../common/ServiceStorage';

mixpanel.init(MixpanelConfiguration.token);

const env = isProduction;
const actions = {
  track: (
    name: string,
    props?: Dict,
    options?: RequestOptions | Callback,
    callback?: Callback
  ): void => {
    if (env && !ServiceStorage.isTestService()) {
      mixpanel.track(name, props, options, callback);
    }
  },
};

export const Mixpanel = actions;

const trackEvent = (eventName: string, payload: Dict = {}): void => {
  Mixpanel.track(eventName, {
    data: payload,
  });
};

export const MixPanelEvent = {
  trackAssistanceRequestInitializedEvent: (requestId: number): void => {
    trackEvent('User initialized assistance request', { assistanceId: requestId });
  },

  trackIncidentViewEvent: (requestId?: number | null): void => {
    trackEvent('User open the incident selection view', { assistanceId: requestId });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackIncidentSelectionEvent: (incidentSelectEvent: any): void => {
    trackEvent('User choose a incident type', {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      assistanceId: incidentSelectEvent.id!,
      event: incidentSelectEvent,
    });
  },

  trackLocationViewEvent: (requestId: number): void => {
    trackEvent('User open the map view', {
      assistanceId: requestId,
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackLocationSelectionEvent: (locationSelectEvent: any): void => {
    trackEvent('User choose a location', {
      assistanceId: locationSelectEvent.id,
      event: locationSelectEvent,
    });
  },

  trackVehicleDetailsViewEvent: (requestId: number): void => {
    trackEvent('User open the vehicle details view', {
      assistanceId: requestId,
    });
  },

  trackAdditionalDetailsViewEvent: (requestId: number): void => {
    trackEvent('User open additional details view', {
      assistanceId: requestId,
    });
  },

  trackVehicleDetailsPlateNumberSearchEvent: (
    requestId: number,
    verified: boolean | undefined,
    plateNumber: string
  ): void => {
    trackEvent('User made a vehicle data search', {
      assistanceId: requestId,
      event: {
        verified: verified,
        plateNumber: plateNumber,
      },
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackContactDataSubmitEvent: (contactDataSubmitEvent: any): void => {
    trackEvent('User submits contact data by request', {
      assistanceId: contactDataSubmitEvent.id,
      event: contactDataSubmitEvent,
    });
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackVehicleDataSubmitEvent: (vehicleDataSubmitEvent: any): void => {
    trackEvent('User submits vehicle data by request', {
      assistanceId: vehicleDataSubmitEvent.id,
      event: vehicleDataSubmitEvent,
    });
  },

  trackRequestSubmitEvent: (data: SubmitAssistanceRequestRequest): void => {
    trackEvent('User submitted assistance request', {
      assistanceId: data.id,
    });
  },

  trackFindingTowTruckViewEvent: (requestId: number): void => {
    trackEvent('User is navigated to the finding tow truck view', {
      assistanceId: requestId,
    });
  },

  trackTowTruckFoundEvent: (requestId: number): void => {
    trackEvent('A tow truck driver accepted the user request', {
      assistanceId: requestId,
    });
  },

  trackTowTruckNotFoundEvent: (requestId: number): void => {
    trackEvent('No tow truck driver accepted the request', {
      assistanceId: requestId,
    });
  },

  trackClaimLinkInitializedEvent: (claimId: string): void => {
    trackEvent('User initialized claim link', {
      claimId: claimId,
    });
  },

  trackClaimIncidentViewEvent: (claimId: string | null): void => {
    trackEvent('User opens claim incident selection view', { claimId: claimId });
  },

  trackClaimIncidentSelectionEvent: (claimId: string, incidentType: ApiClaimIncidentType): void => {
    trackEvent('User selects claim incident type', {
      claimId: claimId,
      incidentType: incidentType,
    });
  },

  trackClaimVehicleDetailsViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim vehicle details view', {
      claimId: claimId,
    });
  },

  trackClaimVehicleDetailsSearchEvent: (
    claimId: string,
    verified: boolean | undefined,
    plateNumber: string
  ): void => {
    trackEvent('User makes claim vehicle data search', {
      claimId: claimId,
      verified: verified,
      plateNumber: plateNumber,
    });
  },

  trackClaimVehicleDetailsSubmitEvent: (
    claimId: string,
    vehicleDetails: ApiVehicleDetails
  ): void => {
    trackEvent('User submits claim vehicle data', {
      claimId: claimId,
      plateNumber: vehicleDetails.licensePlate,
    });
  },

  trackClaimDamageViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim vehicle damage view', { claimId: claimId });
  },

  trackClaimDamageAreaSelectionEvent: (claimId: string | undefined, id: string): void => {
    trackEvent('User selects vehicle damage area', {
      claimId: claimId,
      areaId: id,
    });
  },

  trackClaimDamageLocationSubmitEvent: (claimId: string, apiDamageArea: ApiDamageArea): void => {
    trackEvent('User submits claim vehicle damage data', {
      claimId: claimId,
      templateId: apiDamageArea.damageAreaTemplateId,
    });
  },

  trackClaimPhotosViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim photos view', { claimId: claimId });
  },

  trackClaimPhotosSkipEvent: (claimId: string | undefined): void => {
    trackEvent('User skips claim photos page', { claimId: claimId });
  },

  trackClaimPhotoDeleteEvent: (claimId: string | undefined, photoId: number): void => {
    trackEvent('User deletes claim photo', {
      claimId: claimId,
      photoId: photoId,
    });
  },

  trackClaimPhotosSubmitEvent: (claimId: string | undefined, photoCount: number): void => {
    trackEvent('User submits claim photos', {
      claimId: claimId,
      photoCount: photoCount,
    });
  },

  trackClaimNotifierInfoViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim notifier info view', { claimId: claimId });
  },

  trackClaimNotifierInfoSubmit: (claimId: string | undefined, isDriver: boolean): void => {
    trackEvent('User submits claim notifier info', {
      claimId: claimId,
      isDriver: isDriver,
    });
  },

  trackClaimOtherPartiesViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens other parties view', { claimId: claimId });
  },

  trackClaimOtherPartyAddEvent: (claimId: string | undefined, partyId: number): void => {
    trackEvent('User adds claim other party', {
      claimId: claimId,
      partyId: partyId,
    });
  },

  trackClaimOtherPartyDeleteEvent: (claimId: string | undefined, partyId: number): void => {
    trackEvent('User deletes claim other party', {
      claimId: claimId,
      partyId: partyId,
    });
  },

  trackClaimOtherPartiesSubmitEvent: (claimId: string | undefined, partyCount: number): void => {
    trackEvent('User submits claim other parties', {
      claimId: claimId,
      partyCount: partyCount,
    });
  },

  trackClaimAccidentDetailsViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim accident details view', { claimId: claimId });
  },

  trackClaimAccidentLocationSelectEvent: (
    claimId: string | undefined,
    latitude: number,
    longitude: number
  ): void => {
    trackEvent('User selects claim accident location', {
      claimId: claimId,
      latitude: latitude,
      longitude: longitude,
    });
  },

  trackClaimAccidentDetailsSubmitEvent: (
    claimId: string | undefined,
    accidentDetails: ApiAccidentDetails
  ): void => {
    trackEvent('User submits claim accident details', {
      claimId: claimId,
      accidentDetails: accidentDetails,
    });
  },

  trackClaimWorkshopSelectionViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens workshop selection view', { claimId: claimId });
  },

  trackClaimWorkshopSelectionEvent: (claimId: string, selectedWorkshop: ApiWorkshop): void => {
    trackEvent('User selects workshop', {
      claimId,
      selectedWorkshop,
    });
  },

  trackClaimCommunicationViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim communication preferences view', { claimId: claimId });
  },

  trackClaimCommunicationPreferenceSubmitEvent: (
    claimId: string | undefined,
    communicationPreferences: ApiCommunicationPreference
  ): void => {
    trackEvent('User submits claim communication preferences', {
      claimId: claimId,
      communicationPreferences: communicationPreferences,
    });
  },

  trackClaimSubmitEvent: (claimId: string | undefined): void => {
    trackEvent('User submits the claim', {
      claimId: claimId,
    });
  },

  trackClaimReceivedViewEvent: (claimId: string | undefined): void => {
    trackEvent('User opens claim received view', { claimId: claimId });
  },

  trackTermsAndPrivacyClickEvent: (id: string | undefined): void => {
    trackEvent('User opens terms and privacy conditions', { id });
  },

  trackExceptionEvent: (
    objectId: number | string | null,
    action: string,
    message: string
  ): void => {
    trackEvent('Exception on ' + action, {
      objectId: objectId,
      message: message,
    });
  },
};
