import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const TermsLatvian: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SectionHeader text={'PAKALPOJUMA VISPĀRĪGIE NOTEIKUMI'} />
      <div>
        <p>
          <b>Yaway OÜ</b> (reģistrācijas numurs 16406514, atrašanās vieta Valukoja 8/1, 11415,
          Tallinn, Igaunija) (turpmāk „<b>Yaway</b>“, „<b>mēs</b>” un „<b>mūsu</b>“) pārvalda
          platformu adresē https://yaway.ai un tās apakšdomēnus un mobilo lietotni, un saistīto
          programmatūru, aparatūru, datu bāzes, saskarnes, saistītās lapas, dokumentāciju,
          atjauninājumus un citus pievienotos vai piesaistītos komponentus un materiālus (turpmāk „
          <b>Platforma</b>“).
        </p>
        <p>
          Lietotne piedāvā virtuālu vidi, ar kuras palīdzību transportlīdzekļa lietotājs var
          gadījumā, ja ar transportlīdzekli ir problēma, pasūtīt auto palīdzību (vilcēju) vai
          paziņot par kaitējumu transportlīdzeklim (apdrošināšanas gadījuma izskatīšanai) un
          konkrētos gadījumos arī pārskatu par gadījuma statusu. Saņem paziņojumus e-pastā vai ar
          īsziņu.
        </p>
        <p>
          „<b>Lietotājs</b>“ apzīmē personu, kas izmanto Platformu, lai pasūtītu auto palīdzību vai
          paziņotu par kaitējumu transportlīdzeklim. „<b>Pakalpojuma sniedzējs</b>“ apzīmē personu,
          kas izmanto Platformu auto palīdzības pakalpojuma sniegšanai (piemēram, vilcēju) vai kā
          apdrošinājuma devējs. „<b>Pasūtījums</b>“ ir pasūtījums, piemēram, auto palīdzības
          pakalpojuma saņemšanai vai kaitējuma gadījuma izskatīšanai, kuru Lietotājs iesniedz
          Pakalpojuma sniedzējam, izmantojot Platformu.
        </p>
        <p>
          Priekšnoteikums Platformas izmantošanai ir piekrišana šiem lietošanas noteikumiem (turpmāk
          „<b>Noteikumi</b>“) un iepazīšanās ar privātuma noteikumiem{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a> („<b>Privātuma noteikum</b>“). Lietotājs,
          izmantojot Platformu, apstiprina, ka ir rūpīgi izlasījis Noteikumus un piekrīt tiem.
          Piekrītot Noteikumiem, tie kļūst par starp Jums un mums noslēgtu saistošu līgumu (turpmāk
          „<b>Līgums</b>“).
        </p>
        {NumericSection('1.', <b>PLATFORMA UN TĀS IZMANTOŠANA</b>)}
        {NumericSection(
          '1.1.',
          `Platforma rada virtuālu vidi, ar kuras starpniecību Lietotājs var iesniegt 
          Pakalpojuma sniedzējam pasūtījumus, piemēram, ja ar transportlīdzekli ir problēma, pasūtīt 
          auto palīdzību (vilcēju) vai paziņot par kaitējumu transportlīdzeklim (kaitējuma 
          gadījuma izskatīšanai) un Pakalpojuma sniedzējs var pieņemt Lietotāja Pasūtījumu.`
        )}
        {NumericSection(
          '1.2.',
          `Lai izmantotu Pakalpojumu, Lietotājam ir jāiesniedz Pasūtījums Platformā 
          (izmantojot interneta vietni vai mobilo lietotni) un Pakalpojuma sniedzējam 
          ir Pasūtījums jāpieņem (jāakceptē Pasūtījums).`
        )}
        {NumericSection(
          '1.3.',
          `Lietotājs saprot, ka Yaway caur Platformu piedāvā tikai 
          starpniecības pakalpojumu. Yaway nav ne apdrošinājuma devējs, ne auto 
          palīdzības pakalpojuma sniedzējs. Līdz ar to Yaway nav tiesisku attiecību, 
          kas rodas starp Lietotāju un Pakalpojuma sniedzēju, puse, t. sk. Yaway 
          neatbild par saistību, kas izriet no Lietotāja un Pakalpojuma sniedzēja savstarpējām 
          attiecībām, izpildi atbilstoši prasībām. Turklāt Yaway neatbild par Lietotāja 
          Platformā publicētās informācijas atbilstību patiesībai, ne arī likumību.`
        )}
        {NumericSection('2.', <b>LIETOTĀJA TIESĪBAS UN PIENĀKUMI</b>)}
        {NumericSection('', <b>Lietotājs apņemas:</b>)}
        {NumericSection(
          '2.1.',
          `izmantojot Platformu, sniegt patiesus personas datus, t. sk. iesniegt pieprasījumus 
          ar savu īsto un pilno vārdu, kā arī izmantot derīgu e-pasta adresi;`
        )}
        {NumericSection(
          '2.2.',
          `nodrošināt, ka Platformas izmantošanas laikā sniegtā informācija nav nepareiza, 
          maldinoša vai neprecīza, nepārkāpj trešo personu tiesības, kā arī nav pretrunā ar 
          spēkā esošajām tiesībām un labām paražām;`
        )}
        {NumericSection('', <b>Lietotājam ir aizliegts</b>)}
        {NumericSection(
          '2.3.',
          `izmantot Platformu krāpšanai vai jebkādam citam nelikumīgam mērķim;`
        )}
        {NumericSection(
          '2.4.',
          `pierunāt citus veikt jebkādas nelikumīgas darbības vai piedalīties tādās;`
        )}
        {NumericSection(
          '2.5.',
          `augšupielādēt vai pārsūtīt vīrusus vai jebkāda cita tipa ļaunprogrammatūru, kuru 
          izmanto vai var izmantot veidā, kas ietekmē Platformas funkcionalitāti vai darbību;`
        )}
        {NumericSection(
          '2.6.',
          `izmantojot Platformu, nosūtīt informāciju, pasūtījumus, komentārus vai jebkādu citu 
          saturu, kura nosūtīšanai un publicēšanai Lietotājam nav tiesību.`
        )}
        {NumericSection('3.', <b>YAWAY TIESĪBAS UN PIENĀKUMI</b>)}
        {NumericSection(
          '3.1.',
          `Yaway ir tiesības pēc savas izvēles ierobežot vai anulēt Lietotāja tiesības lietot 
          Platformu, ja:`
        )}
        {NumericSection(
          '3.1.1.',
          `Lietotājs būtiski pārkāpj Noteikumus vai neizlabo jebkādu pārkāpumu pēc Yaway piešķirta saprātīga termiņa;`,
          2
        )}
        {NumericSection(
          '3.1.2.',
          `Lietotājs, izmantojot Platformu, ir iesniedzis informāciju, kas ir nepatiesa, maldinoša un/
          vai neprecīza;`,
          2
        )}
        {NumericSection(
          '3.1.3.',
          `Iestājoties jebkādam citam pamatam, kas Yaway dod pamatu šaubīties par 
          Lietotāja uzticamību.`,
          2
        )}
        {NumericSection(
          '3.2.',
          `Yaway ir tiesības jebkurā laikā, izrietot no Platformas un pakalpojuma 
          attīstības, kā arī to labākas un drošākas izmantošanas interesēs, vienpusēji grozīt un 
          papildināt Noteikumus.`
        )}
        {NumericSection(
          '3.3.',
          `Platforma un visas tās satura intelektuālā īpašuma tiesības pieder Yaway. 
          Nosūtot Platformai ar autortiesībām vai citām intelektuālā īpašuma tiesībām aizsargātus 
          darbus (fotogrāfijas), Lietotājs nodod Yaway bezmaksas vienkāršo licenci 
          attiecībā uz šiem darbiem spēkā esošo mantisko tiesību īstenošanai, t. sk. mums ir 
          tiesības Lietotāja nosūtītos darbus izmantot pašiem jebkādā veidā, atļaut un aizliegt to 
          izmantošanu Platformā, reproducēt, izplatīt, tulkot, pielāgot, pievienot darbus 
          sakopojumiem vai datu bāzēm un nosūtīt sabiedrībai.`
        )}
        {NumericSection('4.', <b>ATBILDĪBA UN KAITĒJUMA KOMPENSĀCIJA</b>)}
        {NumericSection(
          '4.1.',
          `Saturu, pakalpojumus vai funkcijas, kas ir padarīti pieejami saistībā ar Platformu vai caur 
          to, piedāvā pēc principa “kā ir” un “kā pieejams”, bez jebkāda tieši vai netieši izteikta 
          nodrošinājuma. Mēs atsakāmies no visām garantijām tik daudz, cik tas ir iespējams uz 
          piemērojamo tiesību pamata.`
        )}
        {NumericSection(
          '4.2.',
          `Yaway nenodrošina un negarantē, ka Platforma ir savietojama ar jebkādu 
          Lietotāja izmantotu aparatūru un programmatūru, ir vienmēr pieejama vai pieejama kādā 
          konkrētā laikā, jebkurā laikā strādā bez pārtraukumiem, ir droša vai bez kļūdām, atbilst 
          jūsu jebkādām vajadzībām vai ir bez vīrusiem, traucējumiem, aizsargāta pret uzlaušanu 
          un citu drošību samazinošu ielaušanos.`
        )}
        {NumericSection('4.3.', `Yaway neatbild arī par, bet ne tikai, šādiem apstākļiem:`)}
        {NumericSection(
          '4.3.1.',
          `Platformas vai tur piedāvāta pakalpojuma jebkāds pārtraukums, apstāšanās, kavēšanās
           vai cita nepieejamība;`,
          2
        )}
        {NumericSection(
          '4.3.2.',
          `Jebkādas programmas kļūdas, vīrusi, Trojas zirgi u. tml., kuru kāds var nosūtīt uz 
          Platformu vai tur piedāvāto pakalpojumu, vai caur tiem;`,
          2
        )}
        {NumericSection(
          '4.3.3.',
          `Jebkāda satura vai informācijas izdzēšana vai satura vai datu saglabāšanas neiespējamība;`,
          2
        )}
        {NumericSection(
          '4.3.4.',
          `Jebkāda autorizācijas mandāta publiskošana, pazušana vai neatļauta izmantošana, jo
           jūs neesat saglabājis sava mandāta konfidencialitāti;`,
          2
        )}
        {NumericSection('4.3.5.', `Par Lietotāja uzvedību Platformas izmantošanas laikā;`, 2)}
        {NumericSection(
          '4.3.6.',
          `Tehnisko līdzekļu, kurus izmanto, lai vērstos Platformā, nesavietojamība.`,
          2
        )}

        {NumericSection('5.', <b>CITI NOSACĪJUMI</b>)}
        {NumericSection(
          '5.1.',
          `No Platformas lietošanas izrietošās tiesiskās attiecības starp mums un Lietotāju tiek regulētas pēc Igaunijas Republikas likumiem.`
        )}
      </div>
    </>
  );
};

export default TermsLatvian;
