import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../map/Map.css';
import './IncidentSelection.css';
import '../../../common/components/incidents/incident.css';
import { IncidentList } from './IncidentTypeList';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { connect, ResolveThunks } from 'react-redux';
import { saveIncident } from './incidentActions';
import { AppState } from '../../../store/store';
import { ApiAssistanceIncidentType } from '../../../api';
import { trackIncidentViewEvent } from '../../../analytics/Analytics';
import {
  loadAssistanceRequestDataAction,
  saveIncidentTypeAction,
} from '../assistanceRequestActions';
import { AssistanceStorage } from '../../../common/AssistanceStorage';
import { push } from 'redux-first-history';
import Layout from '../../../components/layouts/Layout';
import { Translate } from 'react-redux-i18n';
import Incident, { IncidentProps } from '../../../common/components/incidents/Incident';
import { Loader } from '../../../components/loader/Loader';

export const IncidentSelection: React.FC<React.PropsWithChildren<IncidentSelectionProps>> = ({
  setIncident,
  language,
  selectedIncident,
  saveIncidentType,
  navigateToNextStep,
  loadAssistanceRequestData,
  navigateToFallbackPage,
  color,
}: IncidentSelectionProps) => {
  const [incidents, setIncidents] = useState(IncidentList);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    trackIncidentViewEvent(AssistanceStorage.getAssistanceRequestId());
  }, []);

  useEffect(() => {
    setIncidents(IncidentList);
  }, [language]);

  useEffect(() => {
    const preloadData = async () => {
      const assistanceId = AssistanceStorage.getAssistanceRequestId();
      if (AssistanceStorage.hasAssistanceRequest()) {
        loadAssistanceRequestData(Number(assistanceId));
      } else navigateToFallbackPage();
    };
    preloadData();
  }, []);

  const onSelect = async (incident: ApiAssistanceIncidentType) => {
    setDisabled(true);
    await setIncident(incident);

    const id = AssistanceStorage.getAssistanceRequestId();
    if (id != null) {
      await saveIncidentType(id, { incidentType: incident }).then(() => {
        navigateToNextStep();
      });
    }
    setDisabled(false);
  };
  return (
    <Layout>
      <Container
        data-testid='content-container'
        className={disabled ? 'content yaway-container disabled-area' : 'content yaway-container'}
        fluid
      >
        <SectionHeader text={<Translate value={'assistanceIncident.WHAT_IS_THE_ISSUE'} />} />
        {disabled && <Loader />}
        <Row className='py-auto mx-auto w-sm-75 w-md-100'>
          {incidents &&
            incidents.map((incident: IncidentProps, index: number) => (
              <Col xs={6} md={4} key={index}>
                <Incident
                  icon={incident.icon}
                  text={<Translate value={`assistanceIncident.${incident.text}`} />}
                  onClick={() => onSelect(incident.text as ApiAssistanceIncidentType)}
                  isSelected={incident.text == selectedIncident}
                  color={color}
                />
              </Col>
            ))}
        </Row>
      </Container>
    </Layout>
  );
};

const mapDispatchToProps = {
  setIncident: saveIncident,
  saveIncidentType: saveIncidentTypeAction,
  loadAssistanceRequestData: loadAssistanceRequestDataAction,
  navigateToNextStep: () => push('/assistance/map'),
  navigateToFallbackPage: () => push('/'),
};

const mapStateToProps = ({ i18n, assistanceIncident, theme }: AppState) => ({
  language: i18n.locale,
  selectedIncident: assistanceIncident.incident,
  color: theme.color,
});

export type IncidentSelectionProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentSelection);
