import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const TermsEnglish: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SectionHeader text={'BENDROSIOS PASLAUGŲ TEIKIMO SĄLYGOS'} />
      <div>
        <p>
          <b>Yaway OÜ</b> (registracijos kodas 16406514, adresas Valukoja 8/1, 11415, Talinas,
          Estija) (toliau – &quot;<b>Yaway</b>&quot;, &quot;<b>mes</b>&quot; ir &quot;<b>mūsų</b>
          &quot;) valdo platformą https://yaway.ai ir jos subdomenus, mobilias programėles,
          susijusią programinę įrangą, aparatinę įrangą, duomenų bazes, sąsajas, susijusius
          puslapius, dokumentaciją, atnaujinimus bei kitus sujungtus ar integruotus komponentus ir
          medžiagas (toliau – &quot;<b>Platforma</b>&quot;).
        </p>
        <p>
          Programėlė siūlo virtualią aplinką, per kurią transporto priemonės naudotojas gali
          užsisakyti pagalbą kelyje (vilkiką) esant problemoms su transporto priemone arba pranešti
          apie transporto priemonei padarytą žalą (draudimo pretenzijų apdorojimui) ir tam tikrais
          atvejais gauti bylos būsenos apžvalgą. Pranešimai gali būti gaunami el. paštu arba SMS
          žinutėmis.
        </p>
        <p>
          &quot;<b>Naudotojas</b>&quot; reiškia asmenį, kuris naudoja Platformą pagalbos kelyje
          užsakymui arba pranešti apie transporto priemonei padarytą žalą. &quot;
          <b>Paslaugų teikėjas</b>
          &quot; reiškia asmenį, kuris naudoja Platformą teikti pagalbos kelyje paslaugas (pvz.,
          vilkiko paslaugas) arba veikia kaip draudikas. &quot;<b>Užsakymas</b>&quot; yra Užsakymas,
          kurį Naudotojas pateikia per Platformą Paslaugų teikėjui, pavyzdžiui, gauti pagalbą kelyje
          arba dėl žalos tvarkymo pretenzijos.
        </p>
        <p>
          Platformos naudojimas reikalauja sutikimo su šiomis naudojimo sąlygomis (toliau – &quot;
          <b>Sąlygos</b>&quot;) ir susipažinimo su privatumo sąlygomis, pateiktomis{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a> (&quot;<b>Privatumo sąlygos</b>&quot;).
          Naudodamasis Platforma, Naudotojas patvirtina, kad atidžiai perskaitė Sąlygas ir su jomis
          sutinka. Sutikdamas su Sąlygomis, Naudotojas sudaro privalomą sutartį (toliau – &quot;
          <b>Sutartis</b>&quot;) tarp jūsų ir mūsų.
        </p>
        {NumericSection('1.', <b>PLATFORMA IR JOS NAUDOJIMAS</b>)}
        {NumericSection(
          '1.1.',
          `Platforma sukuria virtualią aplinką, per kurią Naudotojas gali pateikti
          Užsakymus Paslaugų teikėjams, pvz., užsakyti pagalbą kelyje (vilkiką) esant problemoms su transporto
          priemone arba pranešti apie transporto priemonei padarytą žalą (žalos tvarkymo pretenzijų apdorojimui),
          o Paslaugų teikėjas gali priimti Naudotojo Užsakymą.`
        )}
        {NumericSection(
          '1.2.',
          `Norėdamas naudotis paslauga, Naudotojas turi pateikti Užsakymą Platformoje (per svetainę ar mobiliąją
          programėlę), o Paslaugų teikėjas turi priimti (patvirtinti) Užsakymą.`
        )}
        {NumericSection(
          '1.3.',
          `Naudotojas supranta, kad Yaway teikia tik tarpininkavimo paslaugas per Platformą.
          Yaway nėra draudikas ar pagalbos kelyje paslaugų teikėjas. Todėl Yaway nėra
          šalių teisinių santykių tarp Naudotojo ir Paslaugų teikėjo šalis ir Yaway
          neatsako už tinkamą įsipareigojimų, kylančių iš santykių tarp
          Naudotojo ir Paslaugų teikėjo, vykdymą. Be to, Yaway neatsako už
          Naudotojo pateiktos informacijos tikslumą ar teisėtumą Platformoje.`
        )}
        {NumericSection('2.', <b>NAUDOTOJO TEISĖS IR PAREIGOS</b>)}
        {NumericSection('', <b>Naudotojas įsipareigoja:</b>)}
        {NumericSection(
          '2.1.',
          `pateikti teisingą informaciją naudojantis Platforma, įskaitant prašymų pateikimą tikru ir pilnu
          vardu bei naudojant galiojantį el. pašto adresą;`
        )}
        {NumericSection(
          '2.2.',
          `užtikrinti, kad Platformoje pateikti duomenys nebūtų melagingi, klaidinantys ar
          netikslūs, nepažeistų trečiųjų šalių teisių ir nebūtų prieštarauja
          taikomiems teisės aktams ir geroms moralės normoms;`
        )}
        {NumericSection('', <b>Naudotojui draudžiama:</b>)}
        {NumericSection('2.3.', `naudoti Platformą sukčiavimui ar kitam neteisėtam tikslui;`)}
        {NumericSection(
          '2.4.',
          `kviesti kitus dalyvauti ar dalyvauti bet kokiuose neteisėtuose veiksmuose;`
        )}
        {NumericSection(
          '2.5.',
          `įkelti ar perduoti virusų ar bet kokio kito tipo kenkėjišką kodą, kuris gali ar yra naudojamas bet 
          kokiu būdu, kuris veikia Platformos funkcionalumą ar veikimą;`
        )}
        {NumericSection(
          '2.6.',
          `perduoti per Platformą bet kokius duomenis, užsakymus, komentarus ar kitą turinį, kurį
          Naudotojas neturi teisės perduoti ir skelbti.`
        )}
        {NumericSection('3.', <b>YAWAY TEISĖS IR PAREIGOS</b>)}
        {NumericSection(
          '3.1.',
          `Yaway pasilieka teisę savo nuožiūra apriboti arba atšaukti Naudotojo teisę
          naudotis Platforma, jei:`
        )}
        {NumericSection(
          '3.1.1.',
          `Naudotojas reikšmingai pažeidžia Sąlygas arba nepašalina pažeidimo per
          Yaway nustatytą pagrįstą terminą;`,
          2
        )}
        {NumericSection(
          '3.1.2.',
          `Naudotojas pateikė informaciją naudodamasis Platforma, kuri yra melaginga,
          klaidinanti ir (arba) netiksli;`,
          2
        )}
        {NumericSection(
          '3.1.3.',
          `Dėl bet kokios kitos priežasties, dėl kurios Yaway kyla abejonių dėl Naudotojo patikimumo.`,
          2
        )}
        {NumericSection(
          '3.2.',
          `Yaway pasilieka teisę vienašališkai keisti ir papildyti Sąlygas bet kuriuo metu
          Platformos ir paslaugos vystymo interesais bei siekiant užtikrinti jų geresnį ir saugesnį naudojimą.`
        )}
        {NumericSection(
          '3.3.',
          `Platformos ir viso jos turinio intelektinės nuosavybės teisės priklauso Yaway.
          Pateikdamas Platformai autorių teisių ar kitų intelektinės nuosavybės teisių apsaugotus 
          darbus (pvz., nuotraukas), Naudotojas suteikia Yaway nemokamą neišimtinę licenciją dėl šių darbų atitinkamų
          nuosavybės teisių. Tai reiškia, kad mes turime teisę naudoti Naudotojo pateiktus darbus
          bet kokiu būdu, leisti ar drausti jų naudojimą Platformoje, atgaminti, platinti, versti, pritaikyti,
          įtraukti darbus į rinkinius ar duomenų bazes ir padaryti juos prieinamus visuomenei.`
        )}
        {NumericSection('4.', <b>ATSAKOMYBĖ IR ŽALOS ATLYGINIMAS</b>)}
        {NumericSection(
          '4.1.',
          `Turinys, paslaugos ar funkcijos, prieinamos susijusios su Platforma arba per ją, yra teikiamos
          „kaip yra“ ir „kaip prieinamos“ pagrindu, be jokių išreikštų ar numanomų garantijų.
          Mes atsisakome visų garantijų, kiek tai leidžia taikomi teisės aktai.`
        )}
        {NumericSection(
          '4.2.',
          `Yaway negarantuoja ar nepatvirtina, kad Platforma yra suderinama su bet kokia Naudotojo naudojama 
          aparatine ar programine įranga, visada prieinama ar prieinama tam tikru konkrečiu metu, veikia 
          nepertraukiamai visada, yra saugi ar be klaidų, atitinka bet kokius jūsų specifinius poreikius,
          ar yra laisva nuo virusų, sutrikimų, apsaugota nuo įsilaužimo ir kitų pažeidimų, mažinančių saugumą.`
        )}
        {NumericSection(
          '4.3.',
          `Be kita ko, Yaway neatsako už, bet neapsiribojant, šias aplinkybes:`
        )}
        {NumericSection(
          '4.3.1.',
          `Bet kokį Platformos ar bet kokios jos paslaugos pertrūkimą, sustabdymą, sustojimą ar kitą nepasiekiamumą;`,
          2
        )}
        {NumericSection(
          '4.3.2.',
          `Bet kokius programinės įrangos klaidas, virusus, Trojos arklius ir pan., kuriuos kas nors gali 
          perduoti per Platformą ar jos paslaugą;`,
          2
        )}
        {NumericSection(
          '4.3.3.',
          `Bet kokį turinio ar duomenų ištrynimą arba nesugebėjimą išsaugoti turinio ar duomenų;`,
          2
        )}
        {NumericSection(
          '4.3.4.',
          `Bet kokį prisijungimo duomenų atskleidimą, praradimą ar neteisėtą naudojimą dėl to, kad
          nesugebėjote išlaikyti savo duomenų konfidencialumo;`,
          2
        )}
        {NumericSection('4.3.5.', `Naudotojo elgesį naudojantis Platforma;`, 2)}
        {NumericSection(
          '4.3.6.',
          `Techninių priemonių, naudojamų prisijungimui prie Platformos, nesuderinamumą.`,
          2
        )}

        {NumericSection('5.', <b>KITOS NUOSTATOS</b>)}
        {NumericSection(
          '5.1.',
          `Teisiniai santykiai, kylantys iš Platformos naudojimo tarp mūsų ir Naudotojo,
          reglamentuojami Lietuvos Respublikos įstatymais.`
        )}
      </div>
    </>
  );
};

export default TermsEnglish;
