import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiAssistanceRequestTowTruckDriver } from '../../api';
import { AssistanceRequestDetails } from './assistanceRequestActions';

export interface AssistanceRequestState {
  pendingRequestId: number;
  towTruckDriver: ApiAssistanceRequestTowTruckDriver | undefined;
  startTime: number | undefined;
  submitTime: number | undefined;
  requestExternalId: string | undefined;
  serviceContact: string | undefined;
  assistanceFlowInitialized: boolean;
  serviceCommunicationPreferences: string;
  serviceId: number | undefined;
}

const initialState: AssistanceRequestState = {
  pendingRequestId: 0,
  towTruckDriver: undefined,
  startTime: undefined,
  submitTime: undefined,
  requestExternalId: undefined,
  serviceContact: undefined,
  assistanceFlowInitialized: false,
  serviceCommunicationPreferences: 'EMAIL, SMS',
  serviceId: undefined,
};

const assistanceRequestSlice = createSlice({
  name: 'assistanceRequest',
  initialState,
  reducers: {
    saveAssistanceRequestDetails: (
      state: AssistanceRequestState,
      action: PayloadAction<AssistanceRequestDetails>
    ) => {
      state.pendingRequestId = action.payload.requestId;
      state.submitTime = action.payload.submitTime?.getTime();
      state.requestExternalId = action.payload.requestExternalId;
    },
    setTowTruckDriver: (
      state: AssistanceRequestState,
      action: PayloadAction<ApiAssistanceRequestTowTruckDriver>
    ) => {
      state.towTruckDriver = action.payload;
    },
    setServiceContactForAssistanceRequest: (
      state: AssistanceRequestState,
      action: PayloadAction<string | undefined>
    ) => {
      state.serviceContact = action.payload;
    },
    resetTowtruckDriver: (state: AssistanceRequestState) => {
      state.towTruckDriver = initialState.towTruckDriver;
    },
    setStartTime: (state: AssistanceRequestState, action: PayloadAction<Date>) => {
      state.startTime = action.payload.getTime();
    },
    setAssistanceFlowInitialized: (
      state: AssistanceRequestState,
      action: PayloadAction<boolean>
    ) => {
      state.assistanceFlowInitialized = action.payload;
    },
    resetStartTime: (state: AssistanceRequestState) => {
      state.startTime = initialState.startTime;
    },
    setSubmitTime: (state: AssistanceRequestState, action: PayloadAction<Date>) => {
      state.submitTime = action.payload.getTime();
    },
    setServiceCommunicationPreferences: (
      state: AssistanceRequestState,
      action: PayloadAction<string>
    ) => {
      state.serviceCommunicationPreferences = action.payload;
    },
    setServiceId: (state: AssistanceRequestState, action: PayloadAction<number | undefined>) => {
      state.serviceId = action.payload;
    },
    clearAssistanceRequestDetails: (state: AssistanceRequestState) => {
      state.pendingRequestId = 0;
      state.requestExternalId = undefined;
      state.towTruckDriver = undefined;
      state.startTime = undefined;
      state.submitTime = undefined;
      state.serviceContact = undefined;
      state.assistanceFlowInitialized = false;
      state.serviceCommunicationPreferences = '';
      state.serviceId = undefined;
    },
  },
});

const { actions, reducer } = assistanceRequestSlice;

export const {
  saveAssistanceRequestDetails,
  setTowTruckDriver,
  resetTowtruckDriver,
  setStartTime,
  setAssistanceFlowInitialized,
  resetStartTime,
  setSubmitTime,
  clearAssistanceRequestDetails,
  setServiceContactForAssistanceRequest,
  setServiceCommunicationPreferences,
  setServiceId,
} = actions;

export default reducer;
