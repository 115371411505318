import * as Sentry from '@sentry/react';
import { isProduction } from '../analytics/utils';
import { SentryConfiguration } from '../configs/sentry';

export const initialize = (): void => {
  if (isProduction) {
    Sentry.init({
      dsn: SentryConfiguration.DSN,
      integrations: SentryConfiguration.integrations,
      tracesSampleRate: SentryConfiguration.tracesSampleRate,
      replaysSessionSampleRate: SentryConfiguration.replaysSessionSampleRate,
      replaysOnErrorSampleRate: SentryConfiguration.replaysOnErrorSampleRate,
      debug: SentryConfiguration.debug,
      ignoreErrors: [
        'TypeError: Illegal invocation',
        'Error: GA4React Timeout',
        'TypeError: Failed to fetch',
        'Error: GA4React is being initialized',
      ],
    });
  }
};
