import React, { ChangeEvent, SyntheticEvent } from 'react';
import './FormInput.css';
import { Form } from 'react-bootstrap';
import { FormInputProps } from './FormInput';

// eslint-disable-next-line react/display-name
export const TextAreaInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (props: FormInputProps, ref: any) => {
    const {
      label,
      controlId,
      value,
      onChangeHandler,
      name,
      placeholder,
      onBlurHandler,
      isInvalid,
      validationError,
      testId,
    } = props;

    return (
      <>
        <Form.Group controlId={controlId} className='my-4'>
          <Form.Label className='input-lbl'>{label}</Form.Label>
          <Form.Control
            as='textarea'
            rows={4}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e: ChangeEvent) => onChangeHandler && onChangeHandler(e)}
            ref={ref}
            onBlur={(e: SyntheticEvent) => onBlurHandler && onBlurHandler(e)}
            isInvalid={isInvalid}
            data-testid={testId}
          />
          {isInvalid && (
            <Form.Control.Feedback type='invalid'>{validationError}</Form.Control.Feedback>
          )}
        </Form.Group>
      </>
    );
  }
);
