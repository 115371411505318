import { ApiAssistanceRequestStatus } from '../api';

const ASSISTANCE_ID_KEY = 'assistanceRequestId';
const ASSISTANCE_STATUS_KEY = 'assistanceRequestStatus';
const ASSISTANCE_SMS_ID_KEY = 'assistanceRequestSmsId';

export class AssistanceStorage {
  public static hasAssistanceRequest(): boolean {
    return this.getAssistanceRequestId() != null;
  }

  public static getAssistanceRequestId(): number | null {
    const id = localStorage.getItem(ASSISTANCE_ID_KEY);
    return id !== undefined && id !== null ? Number(id) : null;
  }

  public static getAssistanceRequestStatus(): ApiAssistanceRequestStatus | null {
    const status = localStorage.getItem(ASSISTANCE_STATUS_KEY);
    return status !== undefined && status !== null ? (status as ApiAssistanceRequestStatus) : null;
  }

  public static setAssistanceRequestId(id: number): void {
    localStorage.setItem(ASSISTANCE_ID_KEY, id.toString());
  }

  public static setAssistanceRequestStatus(status: ApiAssistanceRequestStatus): void {
    localStorage.setItem(ASSISTANCE_STATUS_KEY, status.toString());
  }

  public static getAssistanceRequestSmSID(): string | undefined {
    const id = localStorage.getItem(ASSISTANCE_SMS_ID_KEY);
    return id !== undefined && id !== null ? id : undefined;
  }

  public static setAssistanceRequestSmSID(id: string): void {
    localStorage.setItem(ASSISTANCE_SMS_ID_KEY, id.toString());
  }

  public static clearAssistanceRequestId(): void {
    localStorage.removeItem(ASSISTANCE_ID_KEY);
    localStorage.removeItem(ASSISTANCE_STATUS_KEY);
  }
}
