import React from 'react';
import './SectionHeader.css';

export interface SectionHeaderProps {
  text: string | any;
  testId?: string;
}

export const SectionHeader: React.FC<React.PropsWithChildren<SectionHeaderProps>> = ({
  text,
  testId,
}: SectionHeaderProps) => {
  const testid = testId ? { 'data-testid': testId } : {};
  return (
    <h4 className='text-center text-uppercase text-default-color p-2 my-2' {...testid}>
      {text}
    </h4>
  );
};
