import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiClaimIncidentType } from '../../../api/models/ApiClaimIncidentType';

export interface ClaimIncidentState {
  incident: ApiClaimIncidentType | undefined;
}

const initialState: ClaimIncidentState = {
  incident: undefined,
};

const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    setIncident: (state: ClaimIncidentState, action: PayloadAction<ApiClaimIncidentType>) => {
      if (action.payload) {
        state.incident = action.payload;
      }
    },
    clearIncident: (state: ClaimIncidentState) => {
      state.incident = initialState.incident;
    },
  },
});

const { actions, reducer } = incidentSlice;

export const { setIncident, clearIncident } = actions;

export default reducer;
