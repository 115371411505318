import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const Other: React.FC<React.PropsWithChildren<ThemeProps>> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='57'
        height='55'
        viewBox='0 0 57 55'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M31.2759 40.7026C31.0263 39.8911 30.6517 39.0796 30.2148 38.393L31.7129 36.1458C31.2135 35.6464 30.9014 35.3967 30.7766 35.2719C30.7766 35.2719 30.7766 35.2719 30.6517 35.147C30.5269 35.0222 30.2148 34.7725 29.7154 34.2731L27.6555 35.9585C26.9064 35.5216 26.0949 35.2719 25.2834 35.0846L24.7841 32.4629L23.4108 32.5253L22.0375 32.5877L21.7254 35.2719C20.9139 35.5216 20.1024 35.8961 19.4158 36.3331L17.1686 34.8349L15.8577 36.2706L15.3583 36.8324L15.9201 37.5191L17.0437 38.8924C16.6068 39.6414 16.3571 40.4529 16.1698 41.2644H16.1074L13.5481 41.7638C13.5481 42.4504 13.6105 42.8249 13.6105 43.0122C13.6105 43.0122 13.6105 43.0746 13.6105 43.1995C13.6105 43.3867 13.6105 43.7613 13.6729 44.4479L16.2322 44.6976H16.2947C16.5444 45.5091 16.9189 46.3206 17.3558 47.0072L16.3571 48.6302L15.8577 49.3792L16.4195 49.8786L17.8552 51.1895L19.9151 49.5041C20.6642 49.941 21.4757 50.1907 22.2872 50.378L22.7865 52.9997L24.1598 52.9373L25.5331 52.8749L25.8452 50.1907C26.6567 49.941 27.4682 49.5665 28.1548 49.1296L30.402 50.6277C30.9014 50.1283 31.1511 49.8162 31.2759 49.6914C31.2759 49.6914 31.2759 49.6914 31.4008 49.5665C31.5256 49.4417 31.7753 49.1296 32.2747 48.6302L30.5893 46.5703C31.0262 45.8212 31.2759 45.0097 31.4632 44.1982L34.0849 43.6988C34.0849 43.0122 34.0225 42.6377 34.0225 42.4504C34.0225 42.4504 34.0225 42.388 34.0225 42.2631C34.0225 42.0759 34.0225 41.7013 33.9601 41.0147L31.2759 40.7026ZM23.9101 46.7575C21.7254 46.8824 19.8527 45.1346 19.7279 42.9498C19.603 40.765 21.3508 38.8924 23.5356 38.7675C25.7204 38.6427 27.593 40.3905 27.7179 42.5753C27.8427 44.8224 26.1573 46.6951 23.9101 46.7575Z'
          stroke={color}
          strokeWidth='2.7347'
          strokeMiterlimit='10'
        />
        <path
          d='M50.0025 33.5254L56.3696 46.0098C56.9938 47.2582 56.3696 48.8188 54.9963 49.5054C53.623 50.1921 52.0001 49.7551 51.3758 48.5691L45.0088 36.0847'
          fill='black'
        />
        <path
          d='M49.3907 29.8519L42.3828 33.4219L43.4028 35.4241L50.4107 31.8541L49.3907 29.8519Z'
          fill='black'
        />
        <path
          d='M37.1436 6.80802C40.9513 6.7456 44.6342 8.80552 46.5069 12.426C47.2559 13.8617 47.568 15.3598 47.6304 16.7955'
          stroke='black'
          strokeWidth='2.7347'
          strokeMiterlimit='10'
        />
        <path
          d='M44.2598 28.5312L46.0076 31.9645'
          stroke='black'
          strokeWidth='2.7347'
          strokeMiterlimit='10'
        />
        <path
          d='M27.5306 8.18102C27.3433 7.99376 25.6579 6.62047 24.8464 5.87141L21.9126 8.05618C20.9139 7.43196 19.7903 6.99501 18.6667 6.6829L18.1049 3H16.2322H14.3596L13.7978 6.6829C12.6117 6.93258 11.5506 7.43196 10.5518 8.05618L7.55556 5.87141L4.87141 8.55556L7.05618 11.5518C6.43196 12.5506 5.99501 13.6742 5.6829 14.7978L2 15.3596C2 20.0412 2 14.4856 2 19.1673L5.6829 19.7291C5.93258 20.9151 6.43196 21.9763 7.05618 22.975L4.87141 25.9713C8.17978 29.2797 4.24719 25.3471 7.55556 28.6554L10.5518 26.4707C11.5506 27.0949 12.6742 27.5318 13.7978 27.8439L14.3596 31.5268H16.2322H18.1049L18.6667 27.8439C19.8527 27.5943 20.9139 27.0949 21.9126 26.4707L24.9089 28.6554C28.2172 25.3471 24.9089 28.6554 28.2172 25.3471L24.5 17L27.5306 8.18102ZM16.1698 22.7878C13.1111 22.7878 10.6142 20.2909 10.6142 17.2322C10.6142 14.1735 13.1111 11.6767 16.1698 11.6767C19.2285 11.6767 21.7253 14.1735 21.7253 17.2322C21.7253 20.2909 19.2285 22.7878 16.1698 22.7878Z'
          stroke={color}
          strokeWidth='2.7347'
          strokeMiterlimit='10'
        />
        <path
          d='M38.0798 29.5918C45.4229 29.5918 51.3757 23.639 51.3757 16.2959C51.3757 8.95277 45.4229 3 38.0798 3C30.7367 3 24.7839 8.95277 24.7839 16.2959C24.7839 23.639 30.7367 29.5918 38.0798 29.5918Z'
          stroke='black'
          strokeWidth='4.102'
          strokeMiterlimit='10'
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Other);
