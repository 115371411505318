import React, { ChangeEvent, SyntheticEvent } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Col, Form, Row } from 'react-bootstrap';
import './NumberInputWithCountryCode.css';

export interface NumberInputWithCountryCodeProps {
  name?: string;
  label: JSX.Element;
  countryCode: string;
  onChangeHandler?: (e: ChangeEvent) => void;
  onCountryCodeChange: (code: string) => void;
  testId?: string;
  controlId?: string;
  isInvalid?: boolean;
  validationError?: JSX.Element;
  countryListForCodes: string[];
  countryCodes: CountryCodePair;
  onBlurHandler?: (e: SyntheticEvent) => void;
  type: string;
}

export interface CountryCodePair {
  [key: string]: string;
}

// eslint-disable-next-line react/display-name
export const NumberInputWithCountryCode = React.forwardRef<
  HTMLInputElement,
  NumberInputWithCountryCodeProps
>((props: NumberInputWithCountryCodeProps, ref: any) => {
  const {
    name,
    label,
    countryCode,
    onCountryCodeChange,
    onChangeHandler,
    testId,
    controlId,
    isInvalid,
    validationError,
    countryListForCodes,
    countryCodes,
    onBlurHandler,
  } = props;

  return (
    <>
      <Form.Group controlId={controlId} className='my-4'>
        <Form.Label className='input-lbl'>{label}</Form.Label>
        <Row
          className={
            isInvalid ? 'no-gutters phone-number-row-invalid' : 'no-gutters phone-number-row'
          }
        >
          <Col xs={4} sm={3}>
            <ReactFlagsSelect
              selected={countryCode}
              onSelect={(code) => onCountryCodeChange(code)}
              countries={countryListForCodes}
              customLabels={countryCodes}
              fullWidth={true}
              className='country-code-dropdown'
            />
          </Col>
          <Col xs={8} sm={9} className={'phone-number'}>
            <Form.Control
              name={name}
              onChange={(e: ChangeEvent) => onChangeHandler && onChangeHandler(e)}
              ref={ref}
              onBlur={(e: SyntheticEvent) => onBlurHandler && onBlurHandler(e)}
              className='number-input'
              isInvalid={isInvalid}
              data-testid={testId}
              type={'number'}
            />
          </Col>
        </Row>
        {/* Form feedback is custom and the reason for that is the nature of Form
              Feedback component. Form Feedback w'ont work if it is not just after control.
          */}
        {isInvalid && <div className='invalid-tel-input'>{validationError}</div>}
      </Form.Group>
    </>
  );
});
