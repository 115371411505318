import React from 'react';
import CarAccident from '../../../assets/claims/images/incidents/CarAccident';
import Other from '../../../assets/claims/images/incidents/Other';
import WindshieldDamage from '../../../assets/claims/images/incidents/WindshieldDamage';
import HitWildAnimal from '../../../assets/claims/images/incidents/HitWildAnimal';
import Theft from '../../../assets/claims/images/incidents/Theft';
import VandalismOrFire from '../../../assets/claims/images/incidents/VandalismOrFire';
import { IncidentProps } from '../../../common/components/incidents/Incident';
import { ApiClaimIncidentType } from '../../../api/models/ApiClaimIncidentType';

export const IncidentList: IncidentProps[] = [
  {
    icon: <WindshieldDamage />,
    text: ApiClaimIncidentType.WindshieldDamage.toString(),
  },
  {
    icon: <CarAccident />,
    text: ApiClaimIncidentType.TrafficAccident.toString(),
  },
  {
    icon: <HitWildAnimal />,
    text: ApiClaimIncidentType.HitWildAnimal.toString(),
  },
  {
    icon: <Theft />,
    text: ApiClaimIncidentType.Theft.toString(),
  },
  {
    icon: <VandalismOrFire />,
    text: ApiClaimIncidentType.VandalismOrFire.toString(),
  },
  {
    icon: <Other />,
    text: ApiClaimIncidentType.Other.toString(),
  },
];
