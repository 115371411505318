/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCoordinate,
    ApiCoordinateFromJSON,
    ApiCoordinateFromJSONTyped,
    ApiCoordinateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiDamageArea
 */
export interface ApiDamageArea {
    /**
     * 
     * @type {number}
     * @memberof ApiDamageArea
     */
    id?: number;
    /**
     * 
     * @type {Blob}
     * @memberof ApiDamageArea
     */
    image?: Blob;
    /**
     * 
     * @type {number}
     * @memberof ApiDamageArea
     */
    damageAreaTemplateId: number;
    /**
     * 
     * @type {Array<ApiCoordinate>}
     * @memberof ApiDamageArea
     */
    coordinates: Array<ApiCoordinate>;
}

export function ApiDamageAreaFromJSON(json: any): ApiDamageArea {
    return ApiDamageAreaFromJSONTyped(json, false);
}

export function ApiDamageAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDamageArea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'damageAreaTemplateId': json['damageAreaTemplateId'],
        'coordinates': ((json['coordinates'] as Array<any>).map(ApiCoordinateFromJSON)),
    };
}

export function ApiDamageAreaToJSON(value?: ApiDamageArea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'image': value.image,
        'damageAreaTemplateId': value.damageAreaTemplateId,
        'coordinates': ((value.coordinates as Array<any>).map(ApiCoordinateToJSON)),
    };
}

