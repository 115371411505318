import React from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import './Progress.css';
import { connect } from 'react-redux';
import { AppState } from '../../store/store';

export interface ProgressProps {
  total: number;
  currentStep: number;
  noMargin?: boolean;
}

export const Progress: React.FC<React.PropsWithChildren<Props>> = ({
  total,
  currentStep,
  color,
  noMargin,
}: Props) => {
  return (
    <>
      <style type='text/css'>
        {`.progress-bar {
            background-color: ${color} !important;
          }
        `}
      </style>
      <div
        className={`progress-bar-wrapper yaway-container ${!noMargin && 'mt-5'}`}
        title='progress'
      >
        <Row>
          <Col xs={1} className='progress-label'>
            <small className='mt-auto text-muted font-weight-bold'>{`${currentStep}/${total}`}</small>
          </Col>
          <Col>
            <ProgressBar min={0} max={total} now={currentStep} />
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type Props = ProgressProps & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Progress);
