import React from 'react';
import './Header.css';
import { Navbar } from 'react-bootstrap';
import LanguageSelector from '../../languageSelector/LanguageSelector';
import { AppState } from '../../../store/store';
import { connect } from 'react-redux';

export interface HeaderProps {
  hideLogo?: boolean;
}

export const Header: React.FC<React.PropsWithChildren<Props>> = ({
  logo,
  color,
  hideLogo = false,
}: Props) => {
  const logoElement = hideLogo ? (
    <div style={{ height: 55, maxWidth: 150 }} />
  ) : (
    <img src={logo} style={{ maxHeight: 55, maxWidth: 150 }} alt='website logo' />
  );

  return (
    <Navbar
      expand='lg'
      className='theme-border justify-content-between'
      style={{ borderLeft: `4px solid ${color}` }}
    >
      {logoElement}
      <LanguageSelector />
    </Navbar>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  logo: theme.logo,
  color: theme.color,
});

export type Props = ReturnType<typeof mapStateToProps> & HeaderProps;

export default connect(mapStateToProps)(Header);
