import { CountryCodePair } from '../components/controls/NumberInputWithCountryCode';

export const countryCodes: CountryCodePair = {
  EE: '+372',
  LV: '+371',
  LT: '+370',
  FI: '+358',
  SE: '+46',
  NO: '+47',
  PL: '+48',
};
export const countryListForCodes = Object.keys(countryCodes);
