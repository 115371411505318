/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiVehicleBodyType,
    ApiVehicleBodyTypeFromJSON,
    ApiVehicleBodyTypeFromJSONTyped,
    ApiVehicleBodyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiDamageAreaTemplate
 */
export interface ApiDamageAreaTemplate {
    /**
     * 
     * @type {number}
     * @memberof ApiDamageAreaTemplate
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDamageAreaTemplate
     */
    imageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDamageAreaTemplate
     */
    rows: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDamageAreaTemplate
     */
    columns: number;
    /**
     * 
     * @type {ApiVehicleBodyType}
     * @memberof ApiDamageAreaTemplate
     */
    vehicleBodyType?: ApiVehicleBodyType;
}

export function ApiDamageAreaTemplateFromJSON(json: any): ApiDamageAreaTemplate {
    return ApiDamageAreaTemplateFromJSONTyped(json, false);
}

export function ApiDamageAreaTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDamageAreaTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'],
        'rows': json['rows'],
        'columns': json['columns'],
        'vehicleBodyType': !exists(json, 'vehicleBodyType') ? undefined : ApiVehicleBodyTypeFromJSON(json['vehicleBodyType']),
    };
}

export function ApiDamageAreaTemplateToJSON(value?: ApiDamageAreaTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'imageUrl': value.imageUrl,
        'rows': value.rows,
        'columns': value.columns,
        'vehicleBodyType': ApiVehicleBodyTypeToJSON(value.vehicleBodyType),
    };
}

