import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InsuranceState {
  id: number;
  name: string;
}
export interface ClaimState {
  claimId: string | undefined;
  serviceContact: string | undefined;
  showInsuranceSelection: boolean;
  cascoInsurances: InsuranceState[];
  mtplInsurances: InsuranceState[];
  selectedCascoId: number | undefined;
  selectedMtplId: number | undefined;
}

const initialState: ClaimState = {
  claimId: undefined,
  serviceContact: undefined,
  cascoInsurances: [],
  mtplInsurances: [],
  selectedCascoId: undefined,
  selectedMtplId: undefined,
  showInsuranceSelection: false,
};

const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    saveClaimId: (state: ClaimState, action: PayloadAction<string>) => {
      state.claimId = action.payload;
    },
    clearClaimId: (state: ClaimState) => {
      state.claimId = undefined;
    },
    setServiceContactForClaim: (state: ClaimState, action: PayloadAction<string | undefined>) => {
      state.serviceContact = action.payload;
    },
    setCascoInsurances: (state: ClaimState, action: PayloadAction<InsuranceState[]>) => {
      state.cascoInsurances = action.payload;
    },
    setMtplInsurances: (state: ClaimState, action: PayloadAction<InsuranceState[]>) => {
      state.mtplInsurances = action.payload;
    },
    setInsuranceInfo: (state: ClaimState, action: PayloadAction<InsuranceState>) => {
      if (action.payload.name === 'MTPL') {
        state.mtplInsurances = [action.payload];
      } else if (action.payload.name === 'CASCO') {
        state.cascoInsurances = [action.payload];
      }
    },
    setCascoId: (state: ClaimState, action: PayloadAction<number | undefined>) => {
      state.selectedCascoId = action.payload;
    },
    setMtplId: (state: ClaimState, action: PayloadAction<number | undefined>) => {
      state.selectedMtplId = action.payload;
    },
    setShowInsuranceSelection: (state: ClaimState, action: PayloadAction<boolean>) => {
      state.showInsuranceSelection = action.payload;
      state.selectedMtplId = undefined;
      state.selectedCascoId = undefined;
    },
    resetClaim: (state: ClaimState) => {
      state.claimId = initialState.claimId;
      state.serviceContact = initialState.serviceContact;
      state.cascoInsurances = initialState.cascoInsurances;
      state.mtplInsurances = initialState.mtplInsurances;
      state.selectedCascoId = initialState.selectedCascoId;
      state.selectedMtplId = initialState.selectedMtplId;
      state.showInsuranceSelection = initialState.showInsuranceSelection;
    },
  },
});

const { actions, reducer } = claimSlice;

export const {
  saveClaimId,
  clearClaimId,
  setServiceContactForClaim,
  setMtplInsurances,
  setCascoInsurances,
  setCascoId,
  setMtplId,
  setShowInsuranceSelection,
  resetClaim,
} = actions;

export default reducer;
