import { Dispatch } from 'redux';
import { ApiAssistanceRequestLinkResponse } from '../../../api';
import { saveAssistanceRequestDetails } from '../../assistance-requests/assistanceRequestReducer';
import { AssistanceStorage } from '../../../common/AssistanceStorage';
import { clearAssistanceRequestData } from '../../assistance-requests/assistanceRequestActions';

export const initializeAssistanceRequest =
  (apiAssistanceRequestLinkResponse: ApiAssistanceRequestLinkResponse) =>
  (dispatch: Dispatch): void => {
    clearAssistanceRequestData(dispatch);
    // TODO: reload translations
    dispatch(
      saveAssistanceRequestDetails({
        requestId: apiAssistanceRequestLinkResponse.assistanceRequestId,
        submitTime: undefined,
        requestExternalId: apiAssistanceRequestLinkResponse.assistanceRequestExternalId,
      })
    );
    AssistanceStorage.setAssistanceRequestId(apiAssistanceRequestLinkResponse.assistanceRequestId);
    AssistanceStorage.setAssistanceRequestSmSID(
      apiAssistanceRequestLinkResponse.assistanceRequestExternalId
    );
  };
