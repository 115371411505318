import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LocaleState {
  countryCode: string;
}

const initialState: LocaleState = {
  countryCode: 'EE',
};

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setCountry: (state: LocaleState, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
  },
});

const { actions, reducer } = localeSlice;

export const { setCountry } = actions;

export default reducer;
