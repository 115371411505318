import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const EngineHeat: React.FC<React.PropsWithChildren<ThemeProps>> = ({
  color,
}: ThemeProps) => {
  return (
    <>
      <svg
        width='46'
        height='50'
        viewBox='0 0 46 50'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M28.81 37.0101V24.9336C28.81 24.2913 28.2902 23.7715 27.6485 23.7715C27.0069 23.7715 26.487 24.2913 26.487 24.9336V37.0096C25.5609 37.4473 24.9148 38.3833 24.9148 39.4743C24.9148 40.9809 26.1408 42.2069 27.6485 42.2069C29.1551 42.2069 30.3812 40.9809 30.3812 39.4743C30.3812 38.3839 29.7351 37.449 28.81 37.0101Z'
          fill={color}
        />
        <path
          d='M43.652 11.0893C43.4392 10.748 43.0666 10.5397 42.6642 10.5397H39.093C38.4519 10.5397 37.9321 11.0595 37.9321 11.7017V14.3054H35.9326V10.71C35.9326 10.0678 35.4128 9.54793 34.7722 9.54793H29.5953V7.06448C29.5953 6.42335 29.0743 5.90351 28.4338 5.90351H23.0909V3.44321H27.4878C28.4393 3.44321 29.2088 2.67309 29.2088 1.72216C29.2088 0.771222 28.4387 0 27.4878 0H11.7012C10.7502 0 9.97902 0.771222 9.97902 1.72216C9.97902 2.67309 10.7502 3.44321 11.7012 3.44321H16.2734V5.90351H11.7012C11.059 5.90351 10.5391 6.42335 10.5391 7.06448V9.54793H6.31531C5.67418 9.54793 5.15324 10.0678 5.15324 10.71V18.0611H2.32414V10.7172C2.32414 10.0755 1.80429 9.5551 1.16207 9.5551C0.519844 9.5551 0 10.0755 0 10.7172V27.9602C0 28.6019 0.519844 29.1217 1.16207 29.1217C1.80429 29.1217 2.32414 28.6019 2.32414 27.9602V20.3853H5.15324V27.9608C5.15324 28.6014 5.67418 29.1223 6.31531 29.1223H17.1769L21.0633 33.8654C21.2827 34.135 21.614 34.2904 21.9613 34.2904H23.4586V35.0699C22.2546 36.2237 21.5727 37.7948 21.5727 39.4431C21.5727 42.8031 24.3064 45.5379 27.6664 45.5379C31.0286 45.5379 33.7623 42.8031 33.7623 39.4431C33.7623 37.7965 33.0798 36.2248 31.8764 35.0699V34.2904H34.7722C35.4128 34.2904 35.9326 33.7706 35.9326 33.1283V27.5126H37.9321V30.501C37.9321 31.1416 38.4519 31.6614 39.093 31.6614H42.6642C43.1454 31.6614 43.577 31.3654 43.7496 30.9161C48.351 18.9018 43.8461 11.4035 43.652 11.0893ZM18.5975 3.44321H20.7673V5.90351H18.5975V3.44321ZM32.0412 39.4431C32.0412 41.8538 30.0782 43.8158 27.6658 43.8158C25.2546 43.8158 23.2927 41.8538 23.2927 39.4431C23.2927 38.1702 23.8687 36.9558 24.872 36.1124C25.0666 35.9486 25.1791 35.7083 25.1791 35.4536V23.2011C25.1791 21.829 26.2943 20.7133 27.6658 20.7133C29.038 20.7133 30.1543 21.829 30.1543 23.2011V35.4536C30.1543 35.7083 30.2673 35.9486 30.4608 36.1124C31.4652 36.9558 32.0412 38.1702 32.0412 39.4431Z'
          fill='black'
        />
        <path
          d='M32.4006 46.1729L33.7424 48.0704C34.4524 47.5682 35.1002 46.9778 35.6669 46.3157L33.9012 44.8047C33.4585 45.3212 32.9547 45.7821 32.4006 46.1729Z'
          fill={color}
        />
        <path
          d='M26.9097 47.6421L26.6997 49.9563C27.0189 49.9861 27.3436 50.0004 27.6661 50.0004C28.2135 50.0004 28.7642 49.9574 29.3 49.8736L28.9406 47.5781C28.2763 47.6818 27.5817 47.7027 26.9097 47.6421Z'
          fill={color}
        />
        <path
          d='M35.6196 41.4892L37.8732 42.0564C38.0854 41.2152 38.1924 40.3459 38.1924 39.4727H35.8682C35.8688 40.1551 35.7856 40.8321 35.6196 41.4892Z'
          fill={color}
        />
        <path
          d='M20.1187 46.8105C20.725 47.4356 21.4075 47.9841 22.1495 48.4411L23.3689 46.4626C22.7912 46.1065 22.2576 45.6787 21.7846 45.1914L20.1187 46.8105Z'
          fill={color}
        />
        <path
          d='M17.1609 40.1425C17.2155 41.0135 17.3786 41.8751 17.6455 42.7031L19.8571 41.9909C19.6504 41.347 19.5231 40.6767 19.4812 39.998L17.1609 40.1425Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(EngineHeat);
