import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const BatteryDead: React.FC<React.PropsWithChildren<ThemeProps>> = ({
  color,
}: ThemeProps) => {
  return (
    <>
      <svg
        width='53'
        height='40'
        viewBox='0 0 53 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='18.3999' y='9.59961' width='16' height='28' fill={color} />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M38.4011 2.2934V0H43.8273V2.2934H52.9007C52.9007 2.94334 52.9007 4.61718 52.9007 5.2668H0C0 4.61718 0 2.94334 0 2.2934H9.92922V0H15.3554V2.2934H38.4011ZM34.2022 9.82871L28.0792 19.4105L32.1854 18.1096L18.6988 36.4678L25.2351 23.261L20.7188 24.8599L27.004 13.316L34.2022 9.82871ZM50.4059 6.56217H2.49507C2.49507 17.7069 2.49507 28.8549 2.49507 40H50.4059C50.4059 28.8549 50.4059 17.7069 50.4059 6.56217ZM39.8597 17.1662V14.8618H37.5305V13.2762H39.8597V10.9719H41.4082V13.2762H43.7374V14.8618H41.4082V17.1662H39.8597ZM9.16325 14.9177V13.2206H13.9866V14.9177H9.16325Z'
          fill='black'
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(BatteryDead);
