import { Dispatch } from 'redux';
import {
  assistanceApi,
  claimApi,
  UpdateAssistanceRequestLanguageRequest,
  UpdateClaimLanguageRequest,
} from '../apis';
import { getLanguageEnumFromType } from './languageMap';
import { loadTranslations, setLocale } from 'react-redux-i18n';
import { fallbackLocale, isSupportedLocale, LanguageType, translations } from './locale';
import { RoutePrefixes } from '../routing/RouteNames';
import * as Sentry from '@sentry/react';

export const saveCurrentLanguage =
  (language: LanguageType, assistanceRequestId: number, prefix: string | null, claimId?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    setLocaleWithFallback(language as string)(dispatch);

    try {
      if (prefix && prefix === RoutePrefixes.ASSISTANCE) {
        if (assistanceRequestId == 0) return;

        const request: UpdateAssistanceRequestLanguageRequest = {
          id: assistanceRequestId,
          apiLanguageUpdate: { language: getLanguageEnumFromType(language) },
        };
        await assistanceApi.updateAssistanceRequestLanguage(request);
      }

      if (prefix && prefix === RoutePrefixes.CLAIMS) {
        if (!claimId) return;
        const request: UpdateClaimLanguageRequest = {
          claimId,
          apiLanguageUpdate: { language: getLanguageEnumFromType(language) },
        };
        await claimApi.updateClaimLanguage(request);
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throw new Error(exception.message ?? 'Something went wrong');
    }
  };

export const setLocaleWithFallback =
  (desiredLocale: string) =>
  (dispatch: Dispatch): void => {
    if (!desiredLocale) return;
    const finalLocale = isSupportedLocale(desiredLocale) ? desiredLocale : fallbackLocale;
    // @ts-ignore
    dispatch(setLocale(finalLocale));
  };

export const refreshTranslations =
  () =>
  (dispatch: Dispatch): void => {
    // @ts-ignore
    dispatch(loadTranslations(translations));
  };
