import {
  ApiAssistanceIncidentType,
  ApiAssistanceRequestAdditionalDetails,
  ApiAssistanceRequestStatus,
  ApiAssistanceRequestTowTruckDriver,
  ApiContact,
  ApiIncident,
  ApiLocation,
  ApiVehicleDetails,
  assistanceApi,
  SubmitAssistanceRequestRequest,
  towTruckApi,
  UpdateAssistanceRequestContactRequest,
  UpdateAssistanceRequestIncidentRequest,
  UpdateAssistanceRequestLocationRequest,
  UpdateAssistanceRequestVehicleRequest,
} from '../../apis';
import {
  clearAssistanceRequestDetails,
  saveAssistanceRequestDetails,
  setAssistanceFlowInitialized,
  setStartTime,
  setSubmitTime,
  setTowTruckDriver,
} from './assistanceRequestReducer';
import { ClientError } from '../../common/exception/ClientError';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { AssistanceStorage } from '../../common/AssistanceStorage';
import { clearIncident, setIncident } from './incidents/incidentReducer';
import { clearContactData, setContactData } from './vehicle-details/contactDataReducer';
import { clearLocation, setLocation } from './map/mapReducer';
import {
  trackContactDataSubmitEvent,
  trackExceptionEvent,
  trackIncidentSelectionEvent,
  trackLocationSelectionEvent,
  trackRequestSubmitEvent,
  trackVehicleDataSubmitEvent,
} from '../../analytics/Analytics';
import { setAdditionalDetailsApi } from './additional-details/additionDetailsReducer';
import {
  clearVehicleDetails,
  setVehicleDetails,
} from '../../common/components/vehicle-details/vehicleDetailsReducer';
import { ServiceStorage } from '../../common/ServiceStorage';
import * as Sentry from '@sentry/react';
import { NetworkError } from '../../common/exception/NetworkError';

export interface AssistanceRequestDetails {
  requestId: number;
  requestExternalId: string | undefined;
  submitTime: Date | undefined;
}

export const setAssistanceFlowIsInitialized =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setAssistanceFlowInitialized(true));
  };

export const submitAssistanceRequestAction =
  (assistanceRequestId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: SubmitAssistanceRequestRequest = { id: assistanceRequestId };
      const response = await assistanceApi.submitAssistanceRequest(request);
      if (response.errors && response.errors.length > 0) {
        throw new ClientError(response.errors.join('.\n'));
      }
      response.startTime && dispatch(setStartTime(response.startTime));
      response.submitTime && dispatch(setSubmitTime(response.submitTime));

      trackRequestSubmitEvent(request);
    } catch (err) {
      trackExceptionEvent(assistanceRequestId, 'assistance request submit', err.message);
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      Sentry.captureException(err);
      throw Error('errors.tryAgain');
    }
  };

export const saveIncidentTypeAction =
  (assistanceRequestId: number, apiIncident: ApiIncident) => async (): Promise<void> => {
    try {
      const request: UpdateAssistanceRequestIncidentRequest = {
        id: assistanceRequestId,
        apiIncident: apiIncident,
      };

      await assistanceApi.updateAssistanceRequestIncident(request);
      trackIncidentSelectionEvent(request);
    } catch (err) {
      trackExceptionEvent(
        assistanceRequestId,
        'assistance request select incident type',
        err.message
      );
      if (err instanceof ClientError || err instanceof NetworkError) throw err;

      Sentry.captureException(err);
      throw Error('Sorry, something went wrong: ' + err.toString());
    }
  };

export const saveMapLocationAction =
  (assistanceRequestId: number, apiLocation: ApiLocation) => async (): Promise<void> => {
    try {
      const request: UpdateAssistanceRequestLocationRequest = {
        id: assistanceRequestId,
        apiLocation: apiLocation,
      };

      await assistanceApi.updateAssistanceRequestLocation(request);
      trackLocationSelectionEvent(request);
    } catch (err) {
      trackExceptionEvent(assistanceRequestId, 'assistance request save map location', err.message);
      if (err instanceof ClientError || err instanceof NetworkError) throw err;

      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };

export const saveContactDataAction =
  (assistanceRequestId: number, apiContact: ApiContact) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: UpdateAssistanceRequestContactRequest = {
        id: assistanceRequestId,
        apiContact: apiContact,
      };
      await assistanceApi.updateAssistanceRequestContact(request);
      dispatch(setContactData(apiContact));
      trackContactDataSubmitEvent(request);
    } catch (err) {
      trackExceptionEvent(
        assistanceRequestId,
        'assistance request update contact data',
        err.message
      );
      if (err instanceof ClientError || err instanceof NetworkError) throw err;

      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };

export const saveVehicleDataAction =
  (assistanceRequestId: number, apiVehicleDetails: ApiVehicleDetails) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: UpdateAssistanceRequestVehicleRequest = {
        id: assistanceRequestId,
        apiVehicleDetails: apiVehicleDetails,
      };
      await assistanceApi.updateAssistanceRequestVehicle(request);
      trackVehicleDataSubmitEvent(request);
      dispatch(push('/assistance/incidents'));
    } catch (err) {
      trackExceptionEvent(
        assistanceRequestId,
        'assistance request update vehicle data',
        err.message
      );
      if (err instanceof ClientError || err instanceof NetworkError) throw err;

      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };

export const loadAssistanceStatus =
  (id: number) => async (): Promise<ApiAssistanceRequestStatus> => {
    try {
      const status = await assistanceApi.getAssistanceRequestStatusById({ id });
      if (
        status === ApiAssistanceRequestStatus.TimedOut ||
        status === ApiAssistanceRequestStatus.Finished
      ) {
        ServiceStorage.clear();
      }
      return status;
    } catch (err) {
      trackExceptionEvent(id, 'assistance request status request', err.message);
      if (err instanceof ClientError || err instanceof NetworkError) throw err;

      Sentry.captureException(err);
      throw Error('Failed to load status. Please refresh the page to see latest status.');
    }
  };

export const clearAssistanceRequestData = (dispatch: Dispatch): void => {
  AssistanceStorage.clearAssistanceRequestId();
  dispatch(clearAssistanceRequestDetails());
  dispatch(clearIncident());
  dispatch(clearLocation());
  dispatch(clearContactData());
  dispatch(clearVehicleDetails());
};

export const loadAssistanceRequestDataAction =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      let assistanceRequest;
      let towTruckDriver;
      try {
        assistanceRequest = await assistanceApi.getAssistanceRequestData({ id });
      } catch (error) {
        clearAssistanceRequestData(dispatch);
        return;
      }
      if (assistanceRequest.status === ApiAssistanceRequestStatus.Accepted) {
        try {
          towTruckDriver = await towTruckApi.getTowTruckDriverByAssistanceRequest({
            assistanceRequestId: id,
          });
          // eslint-disable-next-line no-empty
        } catch (_) {}
      }

      dispatch(
        saveAssistanceRequestDetails({
          requestId: assistanceRequest.id,
          requestExternalId: assistanceRequest.externalId,
          submitTime: assistanceRequest.submitTime,
        })
      );
      loadIncident(assistanceRequest.incidentType, dispatch);
      loadLocation(assistanceRequest.location, dispatch);
      loadContact(assistanceRequest.contact, dispatch);
      loadVehicleData(assistanceRequest.vehicle, dispatch);
      loadAdditionalDetails(assistanceRequest.additionalDetails, dispatch);
      loadTowTruckDriver(towTruckDriver, dispatch);
      loadStartTime(assistanceRequest.startTime, dispatch);
      loadSubmitTime(assistanceRequest.submitTime, dispatch);
    } catch (error) {
      console.error('Store populating failed:', error);
    }
  };

const loadIncident = (incidentType: ApiAssistanceIncidentType | undefined, dispatch: Dispatch) => {
  if (incidentType == null) dispatch(clearIncident());
  else dispatch(setIncident(incidentType));
};

const loadLocation = (location: ApiLocation | undefined, dispatch: Dispatch) => {
  location &&
    dispatch(
      setLocation({
        lat: location.latitude,
        lng: location.longitude,
      })
    );
};

const loadContact = (contact: ApiContact | undefined, dispatch: Dispatch) => {
  contact && dispatch(setContactData(contact));
};

const loadVehicleData = (vehicle: ApiVehicleDetails | undefined, dispatch: Dispatch) => {
  vehicle &&
    dispatch(
      setVehicleDetails({
        licensePlate: vehicle.licensePlate,
        vinCode: vehicle.vinCode,
        manufacturer: vehicle.mark,
        model: vehicle.model,
        year: vehicle.year,
      })
    );
};

const loadAdditionalDetails = (
  additionalDetails: ApiAssistanceRequestAdditionalDetails | undefined,
  dispatch: Dispatch
) => {
  additionalDetails && dispatch(setAdditionalDetailsApi(additionalDetails));
};

const loadTowTruckDriver = (
  towTruckDriver: ApiAssistanceRequestTowTruckDriver | undefined,
  dispatch: Dispatch
) => {
  towTruckDriver && dispatch(setTowTruckDriver(towTruckDriver));
};

const loadStartTime = (startTime: Date | undefined, dispatch: Dispatch) => {
  startTime && dispatch(setStartTime(startTime));
};

const loadSubmitTime = (startTime: Date | undefined, dispatch: Dispatch) => {
  startTime && dispatch(setSubmitTime(startTime));
};
