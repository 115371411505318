import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiNotifier, ApiNotifierInfo } from '../../../api';

export interface NotifierInfoState {
  isContactDriver: boolean | undefined;
  driver: ApiNotifier | undefined;
  notifier: ApiNotifier | undefined;
}

const initialState: NotifierInfoState = {
  driver: undefined,
  notifier: undefined,
  isContactDriver: undefined,
};

const notifierSlice = createSlice({
  name: 'notifierInfo',
  initialState,
  reducers: {
    setNotifierInfo: (state: NotifierInfoState, action: PayloadAction<ApiNotifierInfo>) => {
      if (action.payload) {
        state.notifier = action.payload.notifier;
        state.isContactDriver = action.payload.isContactDriver;
        if (action.payload.isContactDriver) {
          state.driver = undefined;
        } else {
          state.driver = action.payload.driver;
        }
      }
    },
    resetNotifierInfoDriverStatus: (state: NotifierInfoState) => {
      state.isContactDriver = undefined;
    },
    clearNotifierInfo: (state: NotifierInfoState) => {
      state.notifier = undefined;
      state.driver = undefined;
      state.isContactDriver = undefined;
    },
  },
});

const { actions, reducer } = notifierSlice;

export const { setNotifierInfo, clearNotifierInfo, resetNotifierInfoDriverStatus } = actions;

export default reducer;
