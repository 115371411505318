import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';

export const SentryConfiguration = {
  DSN: 'https://b9bbd9ead39447fe9bbe0a5f414cfd02@o1061091.ingest.sentry.io/6517562',
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  integrations: [
    new BrowserTracing(),
    new Replay({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
};
