import {
  feedbackApi,
  FeedbackData,
  FeedbackLinkCaseType,
  FeedbackLinkStatus,
  GetClaimFeedbackUuidRequest,
  SubmitFeedbackRequest,
} from '../../apis';
import * as Sentry from '@sentry/react';
import { NetworkError } from '../../common/exception/NetworkError';

export const checkFeedbackLinkValidAction = async (
  uuid: string,
  caseType: FeedbackLinkCaseType
): Promise<FeedbackLinkStatus> => {
  const request = { uuid: uuid, caseType: caseType };
  try {
    return await feedbackApi.validateLink(request);
  } catch (e: any) {
    if (e instanceof NetworkError) throw e;
    if (e.status == 404) {
      return FeedbackLinkStatus.NotFound;
    }
    Sentry.captureException(e);
    throw e;
  }
};

export const submitFeedbackAction = async (
  uuid: string,
  caseType: FeedbackLinkCaseType,
  data: FeedbackData
): Promise<void> => {
  const request: SubmitFeedbackRequest = {
    uuid: uuid,
    caseType: caseType,
    feedbackData: {
      stars: data.stars,
      comment: data.comment,
    },
  };
  return feedbackApi.submitFeedback(request);
};

export const getClaimFeedbackUuidAction = async (claimUuid: string): Promise<string> => {
  const request: GetClaimFeedbackUuidRequest = {
    claimUuid: claimUuid,
  };
  return feedbackApi.getClaimFeedbackUuid(request);
};
