import { useQuery } from 'react-query';
import { ApiServiceLink, assistanceRequestLinkApi } from '../../../apis';
import { linkValidationErrors } from '../../assistance-requests/link/LinkValidation';
import { TRY_AGAIN } from '../../../common/exception/ErrorMessages';
import { getLanguageEnumFromType } from '../../../locale/languageMap';
import { LanguageType } from '../../../locale/locale';
import * as Sentry from '@sentry/react';

const initializePartnerAssistanceRequest = async (
  name: string,
  language: LanguageType | undefined
) => {
  const apiServiceLink: ApiServiceLink = {
    name,
    language: language && getLanguageEnumFromType(language),
  };
  try {
    return await assistanceRequestLinkApi.initializeAssistanceRequestFromServiceLink({
      apiServiceLink,
    });
  } catch (error) {
    if (error.status === 404) {
      throw new Error(linkValidationErrors.INVALID_LINK);
    } else {
      Sentry.captureException(error);
      throw new Error(TRY_AGAIN);
    }
  }
};

export const useAssistanceRequestPartnerInitialization = (
  name: string,
  language: LanguageType | undefined
): any => {
  return useQuery(
    'initializePartnerAssistanceRequest',
    () => initializePartnerAssistanceRequest(name, language),
    { retry: false }
  );
};
