/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiVehicleDetailsPreview,
    ApiVehicleDetailsPreviewFromJSON,
    ApiVehicleDetailsPreviewFromJSONTyped,
    ApiVehicleDetailsPreviewToJSON,
    ApiVehicleInsurance,
    ApiVehicleInsuranceFromJSON,
    ApiVehicleInsuranceFromJSONTyped,
    ApiVehicleInsuranceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiVehicleAndInsuranceVerification
 */
export interface ApiVehicleAndInsuranceVerification {
    /**
     * 
     * @type {ApiVehicleDetailsPreview}
     * @memberof ApiVehicleAndInsuranceVerification
     */
    vehicle?: ApiVehicleDetailsPreview;
    /**
     * 
     * @type {ApiVehicleInsurance}
     * @memberof ApiVehicleAndInsuranceVerification
     */
    insurance?: ApiVehicleInsurance;
}

export function ApiVehicleAndInsuranceVerificationFromJSON(json: any): ApiVehicleAndInsuranceVerification {
    return ApiVehicleAndInsuranceVerificationFromJSONTyped(json, false);
}

export function ApiVehicleAndInsuranceVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVehicleAndInsuranceVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicle': !exists(json, 'vehicle') ? undefined : ApiVehicleDetailsPreviewFromJSON(json['vehicle']),
        'insurance': !exists(json, 'insurance') ? undefined : ApiVehicleInsuranceFromJSON(json['insurance']),
    };
}

export function ApiVehicleAndInsuranceVerificationToJSON(value?: ApiVehicleAndInsuranceVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicle': ApiVehicleDetailsPreviewToJSON(value.vehicle),
        'insurance': ApiVehicleInsuranceToJSON(value.insurance),
    };
}

