/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Response object for assistance request submit
 * @export
 * @interface ApiAssistanceSubmitResponse
 */
export interface ApiAssistanceSubmitResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAssistanceSubmitResponse
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ApiAssistanceSubmitResponse
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiAssistanceSubmitResponse
     */
    submitTime?: Date;
}

export function ApiAssistanceSubmitResponseFromJSON(json: any): ApiAssistanceSubmitResponse {
    return ApiAssistanceSubmitResponseFromJSONTyped(json, false);
}

export function ApiAssistanceSubmitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceSubmitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'submitTime': !exists(json, 'submitTime') ? undefined : (new Date(json['submitTime'])),
    };
}

export function ApiAssistanceSubmitResponseToJSON(value?: ApiAssistanceSubmitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': value.errors,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'submitTime': value.submitTime === undefined ? undefined : (value.submitTime.toISOString()),
    };
}

