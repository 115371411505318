import React, { useEffect, useState } from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ApiLanguage } from '../../../api';
import './CustomDateTimePicker.scss';
import 'dayjs/locale/et';
import 'dayjs/locale/lt';
import 'dayjs/locale/ru';
import 'dayjs/locale/lv';
import 'dayjs/locale/en';

export interface CustomDateTimePickerProps {
  dateTime: Dayjs | null;
  onDateChange?: (newDate: Dayjs | null) => void;
  language: ApiLanguage;
  setCallerDateError?: (error: boolean) => void;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
  dateTime,
  onDateChange,
  language,
  setCallerDateError,
}: CustomDateTimePickerProps) => {
  const [locale, setLocale] = useState<string>('en');

  useEffect(() => {
    switch (language) {
      case ApiLanguage.Estonian:
        setLocale('et');
        break;
      case ApiLanguage.Russian:
        setLocale('ru');
        break;
      case ApiLanguage.Latvian:
        setLocale('lv');
        break;
      case ApiLanguage.Lithuanian:
        setLocale('lt');
        break;
      default:
        setLocale('en');
    }
  }, [language]);

  return (
    <div data-testid='dateTimePicker' className={'yaway-date'}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <MuiDateTimePicker
          value={dateTime}
          onChange={(newDate) => {
            onDateChange?.(newDate);
          }}
          maxDateTime={dayjs()}
          timeSteps={{ minutes: 1 }}
          onError={(error) => {
            if (error != null) {
              if (setCallerDateError) {
                setCallerDateError(true);
              }
            } else {
              if (setCallerDateError) {
                setCallerDateError(false);
              }
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDateTimePicker;
