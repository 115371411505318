import { TRY_AGAIN, VEHICLE_NOT_FOUND } from '../common/exception/ErrorMessages';
import { linkValidationErrors } from '../pages/assistance-requests/link/LinkValidation';
import { en } from './languages/en';
import { et } from './languages/et';
import { ru } from './languages/ru';
import { lv } from './languages/lv';
import { lt } from './languages/lt';
import {
  INVALID_LICENSE_PLATE_NUMBER,
  INVALID_PHONE_NUMBER,
  MISSING_LICENSE_PLATE,
  MISSING_NAME,
  MISSING_PHONE_NUMBER,
  REQUIRED_FIELD,
} from '../common/FormValidations';
import { Translate } from 'react-redux-i18n';
import React from 'react';
import { incidentSelectionErrors } from '../pages/claims/incidents/IncidentSelection';
import { INVALID_EMAIL } from '../pages/assistance-requests/additional-details/additionDetailsValidations';

export const translations = {
  en: en,
  et: et,
  ru: ru,
  lv: lv,
  lt: lt,
};

export const getLocalizedMessageElement = (errorMessage: string | undefined): JSX.Element => {
  return errorMessage ? <Translate value={getErrorMessageKey(errorMessage)} /> : <></>;
};

export const getErrorMessageKey = (errorMessage: string): string => {
  switch (errorMessage) {
    case TRY_AGAIN:
      return 'errors.tryAgain';
    case VEHICLE_NOT_FOUND:
      return 'errors.noVehicleFound';
    case MISSING_LICENSE_PLATE:
      return 'vehicleDetails.missingLicensePlate';
    case INVALID_LICENSE_PLATE_NUMBER:
      return 'vehicleDetails.invalidLicensePlate';
    case MISSING_NAME:
      return 'vehicleDetails.missingName';
    case MISSING_PHONE_NUMBER:
      return 'vehicleDetails.missingPhoneNumber';
    case REQUIRED_FIELD:
      return 'vehicleDetails.requiredField';
    case INVALID_PHONE_NUMBER:
      return 'vehicleDetails.invalidPhoneNumber';
    case INVALID_EMAIL:
      return 'additionalDetails.invalidEmail';
    case linkValidationErrors.LINK_VALIDATION_FAILED:
      return 'linkValidation.linkValidationFailed';
    case linkValidationErrors.LINK_HAS_EXPIRED:
      return 'linkValidation.linkHasExpired';
    case linkValidationErrors.LINK_ALREADY_PROCESSED:
      return 'linkValidation.linkAlreadyProcessed';
    case linkValidationErrors.INVALID_LINK:
      return 'linkValidation.invalidLink';
    case linkValidationErrors.SORRY_SOMETHING_WENT_WRONG:
      return 'linkValidation.sorrySomethingWentWrong';
    case incidentSelectionErrors.INVALID_CLAIM:
      return 'claimIncident.invalidClaim';
    default:
      return errorMessage;
  }
};

export const supportedLocales = {
  en: 'English',
  et: 'Estonian',
  ru: 'Russian',
  lv: 'Latvian',
  lt: 'Lithuanian',
};

export const isSupportedLocale = (locale: string | null): boolean =>
  locale != null && Object.keys(supportedLocales).includes(locale);

export const isSupportedLanguageByValue = (lang: string | null): boolean =>
  lang != null &&
  Object.values(supportedLocales)
    .map((it) => it.toLocaleLowerCase())
    .includes(lang.toLocaleLowerCase());

export type LanguageType = 'en' | 'et' | 'ru' | 'lv' | 'lt' | undefined;

export const fallbackLocale = 'en';
