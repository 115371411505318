import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import './CheckBox.css';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';

// eslint-disable-next-line
export const CheckBox: React.FC<React.PropsWithChildren<Props>> = (props: FormCheckProps, checked: boolean = false!) => {
  const { color } = props;
  return (
    <>
      <style type='text/css'>
        {`
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
            background-color: ${color} !important;
            border-color: ${color};
         }
         .custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
            background-color: ${color} !important;
            border-color: ${color};
          }
        `}
      </style>
      <FormCheck checked={checked} {...props} custom bsCustomPrefix='custom-control' />
    </>
  );
};

const mapStateToProp = ({ theme }: AppState) => ({
  color: theme.color,
});

export type Props = ReturnType<typeof mapStateToProp> & FormCheckProps;

export default connect(mapStateToProp)(CheckBox);
