import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container } from 'react-bootstrap';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { Translate } from 'react-redux-i18n';
import './PartnerLanding.css';
import { AppState } from '../../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import fileAClaimImg from '../../../assets/images/file-a-claim.png';
import roadsideAssistanceImg from '../../../assets/images/assistance-request.png';
import { PartnerLandingSection } from './PartnerLandingSection';
import { getParamFromUrl, getRelativeCurrentUrl } from '../../../common/utils/URLUtils';
import { push } from 'redux-first-history';
import { getServiceThemeDataByServiceName } from '../../../common/theme/themeActions';
import { Loader } from '../../../components/loader/Loader';
import { refreshTranslations, setLocaleWithFallback } from '../../../locale/languageActions';
import { isSupportedLocale } from '../../../locale/locale';

export enum Type {
  ROADSIDE_ASSISTANCE,
  FILE_A_CLAIM,
}

export const PartnerLanding: React.FC<React.PropsWithChildren<PartnerLandingProps>> = ({
  color,
  redirectToClaimLink,
  redirectToAssistanceLink,
  getThemeByServiceName,
  refreshTranslations,
  setLocale,
  locale,
}: PartnerLandingProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const language = getParamFromUrl('language');

  useEffect(() => {
    setLoading(true);
    refreshTranslations();
    getThemeByServiceName(extractServiceName())
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
        language && isSupportedLocale(language) && setLocale(language);
      });
  }, []);

  const extractServiceName = () => {
    const relativePath = getRelativeCurrentUrl().split('/');
    return relativePath[relativePath.length - 1];
  };

  const redirectTo = (type: Type) => {
    if (type === Type.FILE_A_CLAIM) {
      redirectToClaimLink(extractServiceName(), locale);
    } else if (type === Type.ROADSIDE_ASSISTANCE) {
      redirectToAssistanceLink(extractServiceName(), locale);
    }
  };
  return error ? (
    <div className='d-flex justify-content-center'>
      <p className='vertical-center'>{<Translate value={'errors.tryAgain'} />}</p>
    </div>
  ) : (
    <Layout>
      <Container className='content px-0 yaway-container' fluid>
        <SectionHeader text={<Translate value='partnerLanding.title' />} />

        {loading ? (
          <Loader />
        ) : (
          <div className='flex-1 mt-5 narrow-md'>
            <PartnerLandingSection
              color={color}
              title={'partnerLanding.fileAClaim'}
              imageSrc={fileAClaimImg}
              onClick={() => redirectTo(Type.FILE_A_CLAIM)}
            />
            <PartnerLandingSection
              color={color}
              title={'partnerLanding.roadsideAssistance'}
              imageSrc={roadsideAssistanceImg}
              reverse
              onClick={() => redirectTo(Type.ROADSIDE_ASSISTANCE)}
            />
          </div>
        )}
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ theme, i18n }: AppState) => ({
  color: theme.color,
  locale: i18n.locale,
});

const mapDispatchToProp = {
  redirectToClaimLink: (serviceName: string, language?: string) =>
    push(`/partner/claim/${serviceName}?language=${language}`),
  redirectToAssistanceLink: (serviceName: string, language?: string) =>
    push(`/partner/assistance-request/${serviceName}?language=${language}`),
  getThemeByServiceName: getServiceThemeDataByServiceName,
  refreshTranslations: refreshTranslations,
  setLocale: setLocaleWithFallback,
};
export type PartnerLandingProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(PartnerLanding);
