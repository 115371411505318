import { ApiFile, claimFileApi } from '../../../apis';
import { Dispatch } from 'redux';
import { removePhoto, savePhoto } from './photosReducer';
import { CLAIM_FILE_TYPE } from '../consts';

export const uploadPhotosAction =
  (claimId: string, photos: Blob[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    await Promise.all(
      photos.map(async (photo: Blob) => {
        const apiFile = await claimFileApi.uploadClaimFile({
          id: claimId,
          file: photo,
          fileType: CLAIM_FILE_TYPE.DAMAGE_PHOTO,
        });
        dispatch(savePhoto(apiFile));
      })
    );
  };

export const deleteFileAction =
  (claimId: string, apiFile: ApiFile) =>
  async (dispatch: Dispatch): Promise<void> => {
    await claimFileApi.deleteClaimFile({ id: claimId, fileId: apiFile.id });
    dispatch(removePhoto(apiFile));
  };
