import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiVehicleInsurance } from '../../../api';

export interface InsuranceState {
  verified: boolean | undefined;
}

const initialState: InsuranceState = {
  verified: undefined,
};

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    setInsurance: (
      state: InsuranceState,
      action: PayloadAction<ApiVehicleInsurance | undefined>
    ) => {
      if (action.payload) {
        state.verified = action.payload.verified;
      }
    },
  },
});

const { actions, reducer } = insuranceSlice;

export const { setInsurance } = actions;

export default reducer;
