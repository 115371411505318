/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiContact,
    ApiContactFromJSON,
    ApiContactFromJSONTyped,
    ApiContactToJSON,
} from './';

/**
 * Notifier Info
 * @export
 * @interface ApiNotifier
 */
export interface ApiNotifier {
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiNotifier
     */
    contact: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    personalCode?: string;
}

export function ApiNotifierFromJSON(json: any): ApiNotifier {
    return ApiNotifierFromJSONTyped(json, false);
}

export function ApiNotifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNotifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contact': ApiContactFromJSON(json['contact']),
        'personalCode': !exists(json, 'personalCode') ? undefined : json['personalCode'],
    };
}

export function ApiNotifierToJSON(value?: ApiNotifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': ApiContactToJSON(value.contact),
        'personalCode': value.personalCode,
    };
}

