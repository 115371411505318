import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCoordinate, ApiDamageAreaTemplate, ApiFile, ApiVehicleBodyType } from '../../../api';

export interface DamageLocationState {
  id?: number;
  image?: File[] | Blob | Blob[] | ApiFile[];
  bodyType: ApiVehicleBodyType;
  coordinates: ApiCoordinate[];
  templates?: ApiDamageAreaTemplate[];
  selectedTemplate?: ApiDamageAreaTemplate;
}

const initialState: DamageLocationState = {
  bodyType: ApiVehicleBodyType.Sedan,
  coordinates: [],
  templates: [],
};

const additionalDetailsSlice = createSlice({
  name: 'damageLocations',
  initialState,
  reducers: {
    setDamageLocation: (state: DamageLocationState, action: PayloadAction<DamageLocationState>) => {
      const data = action.payload;
      state.id = data.id;
      state.coordinates = data.coordinates;
      state.bodyType = data.bodyType;
    },
    resetDamageLocation: (state: DamageLocationState) => {
      state.id = initialState.id;
      state.coordinates = initialState.coordinates;
      state.bodyType = initialState.bodyType;
      state.image = initialState.image;
      state.templates = initialState.templates;
      state.selectedTemplate = initialState.selectedTemplate;
    },
    setTemplates: (state: DamageLocationState, action: PayloadAction<ApiDamageAreaTemplate[]>) => {
      state.templates = action.payload;
    },
    setSelectedTemplates: (
      state: DamageLocationState,
      action: PayloadAction<ApiDamageAreaTemplate>
    ) => {
      state.selectedTemplate = action.payload;
    },
    setSelectedTemplateById: (state: DamageLocationState, action: PayloadAction<number>) => {
      const template = state.templates?.find((item) => item.id === action.payload);
      template && (state.selectedTemplate = template);
    },
  },
});

const { actions, reducer } = additionalDetailsSlice;

export const {
  setDamageLocation,
  resetDamageLocation,
  setTemplates,
  setSelectedTemplates,
  setSelectedTemplateById,
} = actions;

export default reducer;
