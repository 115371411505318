/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiParties,
    ApiPartiesFromJSON,
    ApiPartiesToJSON,
    ApiPartyData,
    ApiPartyDataFromJSON,
    ApiPartyDataToJSON,
} from '../models';

export interface CreatePartiesRequest {
    claimId: string;
    apiParties: ApiParties;
}

export interface DeletePartyRequest {
    claimId: string;
    partyId: number;
}

export interface GetPartiesByClaimIdRequest {
    claimId: string;
}

export interface UpdatePartyRequest {
    claimId: string;
    apiPartyData: ApiPartyData;
}

/**
 * 
 */
export class OtherPartiesApi extends runtime.BaseAPI {

    /**
     * add all the parties
     */
    async createPartiesRaw(requestParameters: CreatePartiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createParties.');
        }

        if (requestParameters.apiParties === null || requestParameters.apiParties === undefined) {
            throw new runtime.RequiredError('apiParties','Required parameter requestParameters.apiParties was null or undefined when calling createParties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/other-parties`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPartiesToJSON(requestParameters.apiParties),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * add all the parties
     */
    async createParties(requestParameters: CreatePartiesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createPartiesRaw(requestParameters, initOverrides);
    }

    /**
     * deletes an existing party
     */
    async deletePartyRaw(requestParameters: DeletePartyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling deleteParty.');
        }

        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling deleteParty.');
        }

        const queryParameters: any = {};

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}/other-parties`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes an existing party
     */
    async deleteParty(requestParameters: DeletePartyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePartyRaw(requestParameters, initOverrides);
    }

    /**
     * returns all parties by claimId
     */
    async getPartiesByClaimIdRaw(requestParameters: GetPartiesByClaimIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiPartyData>>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getPartiesByClaimId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}/other-parties`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiPartyDataFromJSON));
    }

    /**
     * returns all parties by claimId
     */
    async getPartiesByClaimId(requestParameters: GetPartiesByClaimIdRequest, initOverrides?: RequestInit): Promise<Array<ApiPartyData>> {
        const response = await this.getPartiesByClaimIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update a claim info
     */
    async updatePartyRaw(requestParameters: UpdatePartyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiPartyData>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateParty.');
        }

        if (requestParameters.apiPartyData === null || requestParameters.apiPartyData === undefined) {
            throw new runtime.RequiredError('apiPartyData','Required parameter requestParameters.apiPartyData was null or undefined when calling updateParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/other-parties`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPartyDataToJSON(requestParameters.apiPartyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPartyDataFromJSON(jsonValue));
    }

    /**
     * update a claim info
     */
    async updateParty(requestParameters: UpdatePartyRequest, initOverrides?: RequestInit): Promise<ApiPartyData> {
        const response = await this.updatePartyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
