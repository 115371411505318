import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiAssistanceIncidentType } from '../../../api';

export interface AssistanceIncidentState {
  incident: ApiAssistanceIncidentType | undefined;
}

const initialState: AssistanceIncidentState = {
  incident: undefined,
};

const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    setIncident: (
      state: AssistanceIncidentState,
      action: PayloadAction<ApiAssistanceIncidentType>
    ) => {
      if (action.payload) {
        state.incident = action.payload;
      }
    },
    clearIncident: (state: AssistanceIncidentState) => {
      state.incident = undefined;
    },
  },
});

const { actions, reducer } = incidentSlice;

export const { setIncident, clearIncident } = actions;

export default reducer;
