/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiFile,
    ApiFileFromJSON,
    ApiFileToJSON,
} from '../models';

export interface DeleteClaimFileRequest {
    id: string;
    fileId: number;
}

export interface UploadClaimFileRequest {
    id: string;
    file: Blob;
    fileType: string;
}

/**
 * 
 */
export class ClaimFileApi extends runtime.BaseAPI {

    /**
     * Delete a claim file by path
     */
    async deleteClaimFileRaw(requestParameters: DeleteClaimFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClaimFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteClaimFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{id}/files/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a claim file by path
     */
    async deleteClaimFile(requestParameters: DeleteClaimFileRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteClaimFileRaw(requestParameters, initOverrides);
    }

    /**
     * Upload file related to claim
     */
    async uploadClaimFileRaw(requestParameters: UploadClaimFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadClaimFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadClaimFile.');
        }

        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling uploadClaimFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.fileType !== undefined) {
            formParams.append('fileType', requestParameters.fileType as any);
        }

        const response = await this.request({
            path: `/claims/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFileFromJSON(jsonValue));
    }

    /**
     * Upload file related to claim
     */
    async uploadClaimFile(requestParameters: UploadClaimFileRequest, initOverrides?: RequestInit): Promise<ApiFile> {
        const response = await this.uploadClaimFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
