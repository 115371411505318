import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiAccidentCulprit, ApiAccidentDetails, ApiLocation } from '../../../api/models';
import { getFormattedDate, getFormattedShort24hTime } from '../../../common/utils/DateTimeUtils';

export interface AccidentDetailsState {
  date?: string;
  time?: string;
  location?: ApiLocation;
  address?: string;
  description?: string;
  whoCaused: ApiAccidentCulprit | undefined;
  authoritiesNotified: boolean | undefined;
  personalInjury: boolean | undefined;
}

const initialState: AccidentDetailsState = {
  date: undefined,
  time: undefined,
  location: undefined,
  address: undefined,
  description: undefined,
  whoCaused: undefined,
  authoritiesNotified: undefined,
  personalInjury: undefined,
};

const accidentDetailsSlice = createSlice({
  name: 'accidentDetails',
  initialState,
  reducers: {
    setAccidentDetails: (
      state: AccidentDetailsState,
      action: PayloadAction<ApiAccidentDetails>
    ) => {
      if (action && action.payload) {
        if (action.payload.accidentDatetime) {
          state.date = getFormattedDate(action.payload.accidentDatetime);
          state.time = getFormattedShort24hTime(action.payload.accidentDatetime);
        }
        state.location = action.payload.location;
        state.address = action.payload.address;
        state.description = action.payload.description;
        state.whoCaused = action.payload.whoCaused;
        state.authoritiesNotified = action.payload.authoritiesNotified;
        state.personalInjury = action.payload.personalInjury;
      }
    },
    resetAccidentDetails: (state: AccidentDetailsState) => {
      state.date = initialState.date;
      state.time = initialState.time;
      state.location = initialState.location;
      state.address = initialState.address;
      state.description = initialState.description;
      state.whoCaused = initialState.whoCaused;
      state.authoritiesNotified = initialState.authoritiesNotified;
      state.personalInjury = initialState.personalInjury;
    },
  },
});

const { actions, reducer } = accidentDetailsSlice;

export const { setAccidentDetails, resetAccidentDetails } = actions;

export default reducer;
