/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Insurance Info
 * @export
 * @interface ApiInsuranceInfo
 */
export interface ApiInsuranceInfo {
    /**
     * 
     * @type {number}
     * @memberof ApiInsuranceInfo
     */
    mtplInsuranceId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiInsuranceInfo
     */
    cascoInsuranceId?: number;
}

export function ApiInsuranceInfoFromJSON(json: any): ApiInsuranceInfo {
    return ApiInsuranceInfoFromJSONTyped(json, false);
}

export function ApiInsuranceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInsuranceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mtplInsuranceId': !exists(json, 'mtplInsuranceId') ? undefined : json['mtplInsuranceId'],
        'cascoInsuranceId': !exists(json, 'cascoInsuranceId') ? undefined : json['cascoInsuranceId'],
    };
}

export function ApiInsuranceInfoToJSON(value?: ApiInsuranceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mtplInsuranceId': value.mtplInsuranceId,
        'cascoInsuranceId': value.cascoInsuranceId,
    };
}

