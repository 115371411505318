/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiLinkValidation
 */
export interface ApiLinkValidation {
    /**
     * 
     * @type {boolean}
     * @memberof ApiLinkValidation
     */
    isValid: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiLinkValidation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkValidation
     */
    externalId?: string;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiLinkValidation
     */
    language: ApiLanguage;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkValidation
     */
    msg?: string;
}

export function ApiLinkValidationFromJSON(json: any): ApiLinkValidation {
    return ApiLinkValidationFromJSONTyped(json, false);
}

export function ApiLinkValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiLinkValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': json['isValid'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'language': ApiLanguageFromJSON(json['language']),
        'msg': !exists(json, 'msg') ? undefined : json['msg'],
    };
}

export function ApiLinkValidationToJSON(value?: ApiLinkValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isValid': value.isValid,
        'id': value.id,
        'externalId': value.externalId,
        'language': ApiLanguageToJSON(value.language),
        'msg': value.msg,
    };
}

