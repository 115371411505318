/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiInsurance,
    ApiInsuranceFromJSON,
    ApiInsuranceFromJSONTyped,
    ApiInsuranceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiInsurances
 */
export interface ApiInsurances {
    /**
     * 
     * @type {Array<ApiInsurance>}
     * @memberof ApiInsurances
     */
    cascoInsurances?: Array<ApiInsurance>;
    /**
     * 
     * @type {Array<ApiInsurance>}
     * @memberof ApiInsurances
     */
    mtplInsurances?: Array<ApiInsurance>;
    /**
     * 
     * @type {ApiInsurance}
     * @memberof ApiInsurances
     */
    selectedMtpl?: ApiInsurance;
    /**
     * 
     * @type {ApiInsurance}
     * @memberof ApiInsurances
     */
    selectedCasco?: ApiInsurance;
}

export function ApiInsurancesFromJSON(json: any): ApiInsurances {
    return ApiInsurancesFromJSONTyped(json, false);
}

export function ApiInsurancesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInsurances {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cascoInsurances': !exists(json, 'cascoInsurances') ? undefined : ((json['cascoInsurances'] as Array<any>).map(ApiInsuranceFromJSON)),
        'mtplInsurances': !exists(json, 'mtplInsurances') ? undefined : ((json['mtplInsurances'] as Array<any>).map(ApiInsuranceFromJSON)),
        'selectedMtpl': !exists(json, 'selectedMtpl') ? undefined : ApiInsuranceFromJSON(json['selectedMtpl']),
        'selectedCasco': !exists(json, 'selectedCasco') ? undefined : ApiInsuranceFromJSON(json['selectedCasco']),
    };
}

export function ApiInsurancesToJSON(value?: ApiInsurances | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cascoInsurances': value.cascoInsurances === undefined ? undefined : ((value.cascoInsurances as Array<any>).map(ApiInsuranceToJSON)),
        'mtplInsurances': value.mtplInsurances === undefined ? undefined : ((value.mtplInsurances as Array<any>).map(ApiInsuranceToJSON)),
        'selectedMtpl': ApiInsuranceToJSON(value.selectedMtpl),
        'selectedCasco': ApiInsuranceToJSON(value.selectedCasco),
    };
}

