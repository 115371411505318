import { ApiWorkshop } from '../../../api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WorkshopState {
  workshops: ApiWorkshop[];
  selectedWorkshop: ApiWorkshop | undefined;
}

export const initialState: WorkshopState = {
  workshops: [],
  selectedWorkshop: undefined,
};

const workshopSlice = createSlice({
  name: 'workshop',
  initialState,
  reducers: {
    setWorkshops: (state: WorkshopState, action: PayloadAction<ApiWorkshop[]>) => {
      state.workshops = action.payload;
    },
    setSelectedWorkshop: (state: WorkshopState, action: PayloadAction<ApiWorkshop>) => {
      state.selectedWorkshop = action.payload;
    },
    resetSelectedWorkshop: (state: WorkshopState) => {
      state.selectedWorkshop = initialState.selectedWorkshop;
    },
    clearWorkshops: (state: WorkshopState) => {
      state.workshops = initialState.workshops;
      state.selectedWorkshop = initialState.selectedWorkshop;
    },
  },
});

const { actions, reducer } = workshopSlice;

export const { setWorkshops, clearWorkshops, setSelectedWorkshop, resetSelectedWorkshop } = actions;

export default reducer;
