import { Dispatch } from 'redux';
import { claimLinkApi } from '../../../apis';
import { ClientError } from '../../../common/exception/ClientError';
import { getLanguageTypeFromEnum } from '../../../locale/languageMap';
import { setLocale } from 'react-redux-i18n';
import { linkValidationErrors } from './LinkValidation';
import { saveClaimId } from '../claimReducer';
import { ClaimStorage } from '../../../common/ClaimStorage';
import { clearClaimData } from '../claimActions';
import { reloadTranslationsFromStore } from '../../commonActions';
import { ServiceStorage } from '../../../common/ServiceStorage';

export const validateLinkAction =
  (code: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    localStorage.clear();
    await clearClaimData(dispatch);
    reloadTranslationsFromStore(dispatch);
    const response = await claimLinkApi.validateClaimLink({ code: code });
    if (response.isValid && response.externalId) {
      dispatch(saveClaimId(response.externalId));
      // @ts-ignore
      dispatch(setLocale(getLanguageTypeFromEnum(response.language)));
      ClaimStorage.setClaimId(response.externalId);
    } else {
      ClaimStorage.clearClaimId();
      ServiceStorage.clear();
      throw new ClientError(
        response.msg ? response.msg : linkValidationErrors.LINK_VALIDATION_FAILED
      );
    }
  };
