/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiClaimLinkResponse
 */
export interface ApiClaimLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiClaimLinkResponse
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof ApiClaimLinkResponse
     */
    claimExternalId: string;
}

export function ApiClaimLinkResponseFromJSON(json: any): ApiClaimLinkResponse {
    return ApiClaimLinkResponseFromJSONTyped(json, false);
}

export function ApiClaimLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': json['link'],
        'claimExternalId': json['claimExternalId'],
    };
}

export function ApiClaimLinkResponseToJSON(value?: ApiClaimLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
        'claimExternalId': value.claimExternalId,
    };
}

