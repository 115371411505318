import { loadTranslations } from 'react-redux-i18n';
import { translations } from '../locale/locale';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { ClaimRoutes, RouteName } from '../routing/RouteNames';

export const reloadTranslationsFromStore = (dispatch: Dispatch): void => {
  // @ts-ignore
  dispatch(loadTranslations(translations));
};

export const directToErrorPage =
  // eslint-disable-next-line
    (error: any) =>
    async (dispatch: Dispatch): Promise<void> => {
      const errorBody = await error.json();
      if (errorBody && errorBody.message) {
        const path = pathResolver(errorBody.message);
        path && dispatch(push(path));
      }
    };

const pathResolver = (messagePath: string): string | undefined => {
  const pathArray = messagePath.split(':');
  if (pathArray.length > 0) {
    const path = pathArray[0].trim();
    return ClaimRoutes.find((route: RouteName) => route.path.includes(path))?.path;
  }
};
