import { Translate } from 'react-redux-i18n';
import React from 'react';

export const getNameLabel = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.name'} />
    </>
  ) : (
    <Translate value={'notifierInfo.name'} />
  );
};
export const getPersonalCodeLabel = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.personalCode'} />
    </>
  ) : (
    <Translate value={'notifierInfo.personalCode'} />
  );
};
export const getTelephoneLabel = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.telephone'} />
    </>
  ) : (
    <Translate value={'notifierInfo.telephone'} />
  );
};
export const getEmailLabel = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.email'} />
    </>
  ) : (
    <Translate value={'notifierInfo.email'} />
  );
};

export const getNameValidationError = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.errors.name'} />
    </>
  ) : (
    <Translate value={'notifierInfo.errors.name'} />
  );
};
export const getTelephoneValidationError = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} />{' '}
      <Translate value={'notifierInfo.errors.telephone'} />
    </>
  ) : (
    <Translate value={'notifierInfo.errors.telephone'} />
  );
};
export const getEmailValidationError = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} /> <Translate value={'notifierInfo.errors.email'} />
    </>
  ) : (
    <Translate value={'notifierInfo.errors.email'} />
  );
};
export const getPersonalCodeValidationError = (isDriver: boolean): JSX.Element => {
  return isDriver ? (
    <>
      <Translate value={'notifierInfo.driver'} />
      &nbsp;
      <Translate value={'notifierInfo.errors.personalCode'} />
    </>
  ) : (
    <Translate value={'notifierInfo.errors.personalCode'} />
  );
};
