import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const TermsRussian: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SectionHeader text={'ОБЩИЕ УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ'} />
      <div>
        <p>
          <b>Yaway OÜ</b> (регистрационный код 16406514, расположенный по адресу Valukoja 8/1,
          11415, Таллинн, Эстония) (в дальнейшем именуемое как &quot;<b>Yaway</b>&quot;, &quot;
          <b>мы</b>&quot; и &quot;<b>нас</b>&quot;) управляет платформой на https://yaway.ai, ее
          субдоменами, мобильными приложениями, связанным программным обеспечением, оборудованием,
          базами данных, интерфейсами, сопутствующими страницами, документацией, обновлениями и
          другими связанными или интегрированными компонентами и материалами (в дальнейшем именуемое
          как &quot;<b>Платформа</b>&quot;).
        </p>
        <p>
          Приложение предлагает виртуальную среду, в которой пользователь транспортного средства
          может заказать дорожную помощь (эвакуацию) в случае проблемы с транспортным средством или
          сообщить о возникшем ущербе транспортному средству (для обработки страховых претензий) и,
          в некоторых случаях, также получить обзор статуса дела. Уведомления могут поступать по
          электронной почте или SMS.
        </p>
        <p>
          &quot;<b>Пользователь</b>&quot; - это лицо, которое использует Платформу для заказа
          дорожной помощи или для сообщения о возникшем ущербе транспортному средству. &quot;
          <b>Поставщик услуг</b>&quot; относится к лицу, которое использует Платформу для
          предоставления услуг по дорожной помощи (например, эвакуация) или выступает в роли
          страховщика. &quot;<b>Заказ</b>&quot; - это заказ, представленный Пользователем через
          Платформу Поставщику услуг, например, для получения дорожной помощи или обработки
          страховой претензии.
        </p>
        <p>
          Использование Платформы требует согласия с этими условиями использования (в дальнейшем
          именуемые как &quot;<b>Условия</b>&quot;) и ознакомления с политикой конфиденциальности на{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a> (&quot;<b>Условия конфиденциальности</b>
          &quot;). Используя Платформу, Пользователь подтверждает, что он тщательно прочитал Условия
          и согласен с ними. Соглашаясь с Условиями, они становятся обязательным контрактом (в
          дальнейшем именуемым как &quot;<b>Договор</b>&quot;) между вами и нами.
        </p>
        {NumericSection('1.', <b>ПЛАТФОРМА И ЕЕ ИСПОЛЬЗОВАНИЕ</b>)}
        {NumericSection(
          '1.1.',
          `Платформа создает виртуальную среду, через которую Пользователь может отправлять заказы Поставщикам услуг, 
          например, для заказа дорожной помощи (эвакуации) в случае проблемы с транспортным средством или для сообщения 
          о возникшем ущербе транспортному средству (для обработки ущербных претензий), и Поставщик услуг может 
          принимать Заказ Пользователя.`
        )}
        {NumericSection(
          '1.2.',
          `Для использования услуги Пользователь должен отправить Заказ на Платформе (через веб-сайт или мобильное 
          приложение), и Поставщик услуг должен принять (одобрить) Заказ.`
        )}
        {NumericSection(
          '1.3.',
          `Пользователь понимает, что Yaway предоставляет только посредническую услугу через Платформу. Yaway не является 
          страховщиком или поставщиком услуг по дорожной помощи. Таким образом, Yaway не является стороной в правоотношении, 
          возникающем между Пользователем и Поставщиком услуг, и Yaway не несет ответственности за должное исполнение 
          обязательств, возникающих из отношений между Пользователем и Поставщиком услуг. Кроме того, Yaway не несет 
          ответственности за точность или законность информации, опубликованной Пользователем на Платформе.`
        )}
        {NumericSection('2.', <b>ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</b>)}
        {NumericSection('', <b>Пользователь обязуется:</b>)}
        {NumericSection(
          '2.1.',
          `предоставлять правдивую информацию при использовании Платформы, включая отправку запросов под своим 
          настоящим и полным именем, а также использование действительного адреса электронной почты;`
        )}
        {NumericSection(
          '2.2.',
          `гарантировать, что представленные данные при использовании Платформы не являются ложными, 
          вводящими в заблуждение или неточными, не нарушают права третьих лиц и не противоречат 
          действующему законодательству и нормам морали;`
        )}
        {NumericSection('', <b>Пользователю запрещается:</b>)}
        {NumericSection(
          '2.3.',
          `использовать Платформу для мошенничества или какой-либо иной незаконной цели;`
        )}
        {NumericSection(
          '2.4.',
          `соблазнять других участвовать или принимать участие в любых незаконных действиях;`
        )}
        {NumericSection(
          '2.5.',
          `загружать или передавать вирусы или любой другой тип вредоносного кода, который может или используется 
          любым образом, влияющим на функциональность или работу Платформы;`
        )}
        {NumericSection(
          '2.6.',
          `передавать через Платформу какие-либо данные, заказы, комментарии или любой другой контент, 
          право на передачу и публикацию которого у Пользователя отсутствует.`
        )}
        {NumericSection('3.', <b>ПРАВА И ОБЯЗАННОСТИ YAWAY</b>)}
        {NumericSection(
          '3.1.',
          `Yaway оставляет за собой право по своему усмотрению ограничивать или отзывать право Пользователя 
          на использование Платформы в случае, если:`
        )}
        {NumericSection(
          '3.1.1.',
          `Пользователь существенно нарушает Условия или не устраняет любое нарушение в разумный срок, 
          установленный Yaway;`,
          2
        )}
        {NumericSection(
          '3.1.2.',
          `Пользователь предоставил информацию во время использования Платформы, которая является ложной, 
          вводящей в заблуждение и/или неточной;`,
          2
        )}
        {NumericSection(
          '3.1.3.',
          `По любой другой причине, которая вызывает у Yaway сомнения в надежности Пользователя.`,
          2
        )}
        {NumericSection(
          '3.2.',
          `Yaway оставляет за собой право в одностороннем порядке изменять и дополнять Условия в любое время 
          в интересах развития Платформы и услуги и для обеспечения их лучшего и безопасного использования.`
        )}
        {NumericSection(
          '3.3.',
          `Интеллектуальные права на Платформу и все ее содержание принадлежат Yaway. Передавая произведения, 
          защищенные авторским правом или другими правами интеллектуальной собственности (например, фотографии) 
          на Платформу, Пользователь предоставляет Yaway бесплатную неисключительную лицензию в отношении 
          применимых прав собственности на эти произведения. Это означает, что у нас есть право использовать 
          произведения, представленные Пользователем любым образом, разрешать или запрещать их использование 
          на Платформе, воспроизводить, распространять, переводить, адаптировать, включать произведения в 
          коллекции или базы данных и делать их доступными для общественности.`
        )}
        {NumericSection('4.', <b>ОТВЕТСТВЕННОСТЬ И КОМПЕНСАЦИЯ УЩЕРБА</b>)}
        {NumericSection(
          '4.1.',
          `Контент, услуги или функции, предоставляемые в связи с или через Платформу, предоставляются 
          на условиях "как есть" и "как доступно", без каких-либо явно выраженных или подразумеваемых гарантий. 
          Мы отказываемся от всех гарантий в мере, допустимой применимым законодательством.`
        )}
        {NumericSection(
          '4.2.',
          `Yaway не гарантирует и не утверждает, что Платформа совместима с любым оборудованием или программным 
          обеспечением, используемым каким-либо Пользователем, всегда доступна или доступна в любое конкретное время, 
          работает без перебоев в любое время, является безопасной или без ошибок, удовлетворяет любым вашим 
          конкретным потребностям или свободна от вирусов, перебоев, защищена от взлома и других вторжений, 
          уменьшающих безопасность.`
        )}
        {NumericSection(
          '4.3.',
          `Между прочим, Yaway не несет ответственности, в том числе, но не ограничиваясь следующими обстоятельствами:`
        )}
        {NumericSection(
          '4.3.1.',
          `Любое прерывание, остановка, остановка или другая недоступность Платформы или любой предлагаемой на ней услуги;`,
          2
        )}
        {NumericSection(
          '4.3.2.',
          `Любые программные ошибки, вирусы, троянские кони и т. д., которые кто-либо может передавать на Платформу 
          или через любую предлагаемую на ней услугу;`,
          2
        )}
        {NumericSection(
          '4.3.3.',
          `Любое удаление контента или данных или невозможность сохранить контент или данные;`,
          2
        )}
        {NumericSection(
          '4.3.4.',
          `Любое раскрытие, утрата или несанкционированное использование учетных данных из-за вашего несоблюдения 
          конфиденциальности ваших учетных данных;`,
          2
        )}
        {NumericSection('4.3.5.', `Поведение Пользователя при использовании Платформы;`, 2)}
        {NumericSection(
          '4.3.6.',
          `Несовместимость технических средств, используемых для доступа к Платформе.`,
          2
        )}

        {NumericSection('5.', <b>ДРУГИЕ ПОЛОЖЕНИЯ</b>)}
        {NumericSection(
          '5.1.',
          `Правовые отношения, возникающие из использования Платформы между нами и Пользователем, 
          регулируются законами Эстонской Республики.`
        )}
      </div>
    </>
  );
};

export default TermsRussian;
