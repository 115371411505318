import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../../../common/types';

export interface MapState {
  location: Location;
}

const initialState: MapState = {
  location: { lat: 59.424065, lng: 24.791512 },
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setLocation: (state: MapState, action: PayloadAction<Location>) => {
      if (action.payload) {
        state.location = action.payload;
      }
    },
    clearLocation: (state: MapState) => {
      state.location = initialState.location;
    },
  },
});

const { actions, reducer } = mapSlice;

export const { setLocation, clearLocation } = actions;

export default reducer;
