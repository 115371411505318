import { Dispatch } from 'redux';
import { ApiClaimLinkResponse } from '../../../api';
import { ClaimStorage } from '../../../common/ClaimStorage';
import { saveClaimId } from '../../claims/claimReducer';
import { clearClaimData } from '../../claims/claimActions';

export const initializeClaim =
  (apiClaimLinkResponse: ApiClaimLinkResponse) =>
  (dispatch: Dispatch): void => {
    clearClaimData(dispatch);
    // TODO: reload translations
    dispatch(saveClaimId(apiClaimLinkResponse.claimExternalId));

    ClaimStorage.setClaimId(apiClaimLinkResponse.claimExternalId);
  };
