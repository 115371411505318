import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCaseDetails } from '../../../api';

export interface CaseStatusState {
  caseDetails?: ApiCaseDetails;
  error?: string;
}

const initialState: CaseStatusState = {
  caseDetails: undefined,
};

const caseStatusSlice = createSlice({
  name: 'caseStatus',
  initialState,
  reducers: {
    setCaseDetails: (state: CaseStatusState, action: PayloadAction<ApiCaseDetails>) => {
      state.caseDetails = action.payload;
      state.error = undefined;
    },
    setError: (state: CaseStatusState, action: PayloadAction<string | undefined>) => {
      state.caseDetails = undefined;
      state.error = action.payload;
    },
    resetCaseStatus: (state: CaseStatusState) => {
      state.caseDetails = undefined;
      state.error = undefined;
    },
  },
});

const { actions, reducer } = caseStatusSlice;

export const { setCaseDetails, setError, resetCaseStatus } = actions;

export default reducer;
