import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiObjectParty, ApiPerson, ApiVehicleParty } from '../../../api';
import { FieldErrors } from 'react-hook-form';

export type PartyType = 'vehicle' | 'object' | undefined;

export interface Party {
  id: number;
  person?: ApiPerson;
  type?: PartyType;
  vehicle?: ApiVehicleParty;
  object?: ApiObjectParty;
  createdTime?: Date;
  errors?: FieldErrors<any>;
}

export interface OtherPartiesState {
  partiesInvolved?: boolean;
  havePartyInfo?: boolean;
  parties: Array<Party>;
  count: number;
  redirect?: boolean;
}

const initialState: OtherPartiesState = {
  parties: [],
  count: 0,
};

const OtherPartySlice = createSlice({
  name: 'otherParties',
  initialState,
  reducers: {
    addParty: (state: OtherPartiesState, action: PayloadAction<Party>) => {
      if (action.payload.id !== undefined) {
        const existingParty = state.parties.find((party: Party) => party.id === action.payload.id);
        if (existingParty) {
          state.parties = state.parties.map((party: Party) => {
            if (party.id === action.payload.id) {
              party = {
                ...party,
                vehicle: action.payload.vehicle,
                object: action.payload.object,
                person: action.payload.person,
                type: action.payload.type,
                errors: undefined,
              };
            }
            return party;
          });
        } else {
          state.parties.push(action.payload);
        }
        const errorParties = state.parties.filter((party: Party) => party.errors !== undefined);
        if (state.count === state.parties.length && errorParties.length === 0) {
          state.redirect = true;
        }
      }
    },
    setParties: (state: OtherPartiesState, action: PayloadAction<Array<Party>>) => {
      state.parties = action.payload;
    },
    removeParty: (state: OtherPartiesState, action: PayloadAction<number>) => {
      state.parties = state.parties.filter((party: Party) => party.id !== action.payload);
    },
    resetParties: (state: OtherPartiesState) => {
      state.parties = initialState.parties;
    },
    clearPartyDetails: (state: OtherPartiesState) => {
      state.parties = initialState.parties;
      state.havePartyInfo = undefined;
      state.partiesInvolved = undefined;
    },
    setOtherPartiesInvolved: (
      state: OtherPartiesState,
      action: PayloadAction<boolean | undefined>
    ) => {
      if (action.payload) {
        state.partiesInvolved = action.payload;
      } else {
        state.partiesInvolved = action.payload;
        state.havePartyInfo = action.payload;
      }
    },
    setOtherPartiesInfo: (state: OtherPartiesState, action: PayloadAction<boolean | undefined>) => {
      state.havePartyInfo = action.payload;
    },
    setPartyError: (
      state: OtherPartiesState,
      action: PayloadAction<{ partyId: number; values: Party }>
    ) => {
      const existingParty = state.parties.find(
        (party: Party) => party.id === action.payload.partyId
      );
      if (existingParty) {
        state.parties = state.parties.map((party: Party) => {
          if (party.id === action.payload.partyId) {
            party = action.payload.values;
          }
          return party;
        });
      } else {
        state.parties.push(action.payload.values);
      }
    },
    setPartyCount: (state: OtherPartiesState, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setRedirect: (state: OtherPartiesState, action: PayloadAction<boolean | undefined>) => {
      state.redirect = action.payload;
    },
  },
});

const { actions, reducer } = OtherPartySlice;

export const {
  addParty,
  removeParty,
  resetParties,
  setParties,
  setOtherPartiesInvolved,
  setOtherPartiesInfo,
  setPartyError,
  setPartyCount,
  setRedirect,
  clearPartyDetails,
} = actions;

export default reducer;
