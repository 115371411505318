/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FeedbackLinkStatus {
    Expired = 'EXPIRED',
    AlreadyGiven = 'ALREADY_GIVEN',
    NotFound = 'NOT_FOUND',
    Valid = 'VALID'
}

export function FeedbackLinkStatusFromJSON(json: any): FeedbackLinkStatus {
    return FeedbackLinkStatusFromJSONTyped(json, false);
}

export function FeedbackLinkStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackLinkStatus {
    return json as FeedbackLinkStatus;
}

export function FeedbackLinkStatusToJSON(value?: FeedbackLinkStatus | null): any {
    return value as any;
}

