/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiDriveType,
    ApiDriveTypeFromJSON,
    ApiDriveTypeFromJSONTyped,
    ApiDriveTypeToJSON,
} from './';

/**
 * Vehicle details
 * @export
 * @interface ApiVehicleDetails
 */
export interface ApiVehicleDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    licensePlate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    vinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    mark: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    transmissionType?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    weightKg?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    fullWeightKg?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    bodyType?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    color?: string;
    /**
     * 
     * @type {ApiDriveType}
     * @memberof ApiVehicleDetails
     */
    driveType?: ApiDriveType;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleDetails
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleDetails
     */
    petrolType?: string;
}

export function ApiVehicleDetailsFromJSON(json: any): ApiVehicleDetails {
    return ApiVehicleDetailsFromJSONTyped(json, false);
}

export function ApiVehicleDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVehicleDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licensePlate': json['licensePlate'],
        'vinCode': !exists(json, 'vinCode') ? undefined : json['vinCode'],
        'mark': json['mark'],
        'model': json['model'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'transmissionType': !exists(json, 'transmissionType') ? undefined : json['transmissionType'],
        'weightKg': !exists(json, 'weightKg') ? undefined : json['weightKg'],
        'fullWeightKg': !exists(json, 'fullWeightKg') ? undefined : json['fullWeightKg'],
        'bodyType': !exists(json, 'bodyType') ? undefined : json['bodyType'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'driveType': !exists(json, 'driveType') ? undefined : ApiDriveTypeFromJSON(json['driveType']),
        'length': !exists(json, 'length') ? undefined : json['length'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'petrolType': !exists(json, 'petrolType') ? undefined : json['petrolType'],
    };
}

export function ApiVehicleDetailsToJSON(value?: ApiVehicleDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licensePlate': value.licensePlate,
        'vinCode': value.vinCode,
        'mark': value.mark,
        'model': value.model,
        'year': value.year,
        'transmissionType': value.transmissionType,
        'weightKg': value.weightKg,
        'fullWeightKg': value.fullWeightKg,
        'bodyType': value.bodyType,
        'color': value.color,
        'driveType': ApiDriveTypeToJSON(value.driveType),
        'length': value.length,
        'width': value.width,
        'height': value.height,
        'petrolType': value.petrolType,
    };
}

