/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiDamageArea,
    ApiDamageAreaFromJSON,
    ApiDamageAreaToJSON,
} from '../models';

export interface CreateDamageAreasRequest {
    claimId: string;
    apiDamageArea: ApiDamageArea;
}

export interface GetDamageAreasRequest {
    claimId: string;
}

/**
 * 
 */
export class ClaimDamageAreaApi extends runtime.BaseAPI {

    /**
     * creates damage areas
     */
    async createDamageAreasRaw(requestParameters: CreateDamageAreasRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createDamageAreas.');
        }

        if (requestParameters.apiDamageArea === null || requestParameters.apiDamageArea === undefined) {
            throw new runtime.RequiredError('apiDamageArea','Required parameter requestParameters.apiDamageArea was null or undefined when calling createDamageAreas.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/damage-location-template`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDamageAreaToJSON(requestParameters.apiDamageArea),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * creates damage areas
     */
    async createDamageAreas(requestParameters: CreateDamageAreasRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createDamageAreasRaw(requestParameters, initOverrides);
    }

    /**
     * get damage areas
     */
    async getDamageAreasRaw(requestParameters: GetDamageAreasRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiDamageArea>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getDamageAreas.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}/damage-location-template`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDamageAreaFromJSON(jsonValue));
    }

    /**
     * get damage areas
     */
    async getDamageAreas(requestParameters: GetDamageAreasRequest, initOverrides?: RequestInit): Promise<ApiDamageArea> {
        const response = await this.getDamageAreasRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
