import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const CarAccident: React.FC<React.PropsWithChildren<ThemeProps>> = ({
  color,
}: ThemeProps) => {
  return (
    <>
      <svg
        width='63'
        height='50'
        viewBox='0 0 63 50'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 9.89258V19.2508H24.2646C16.9785 15.8417 4.01067 9.89258 0 9.89258Z'
          fill='black'
        />
        <path
          d='M55.0132 27.8761C56.0827 29.213 57.6201 30.1488 59.3581 30.483C59.6923 29.0124 58.7565 27.6087 57.3527 27.2745C56.4838 27.1408 55.6148 27.3413 55.0132 27.8761Z'
          fill='black'
        />
        <path
          d='M56.6843 25.8689C58.8901 25.8689 60.6949 27.6737 60.6949 29.8796C60.6949 30.147 60.6949 30.3475 60.6281 30.6149H62.6334C62.3661 28.8101 61.4971 27.2058 60.1602 26.0026C58.2217 24.3315 55.8821 23.0615 53.342 22.3262V23.262C53.342 24.4652 53.6094 25.6684 54.211 26.7379C54.9463 26.2032 55.8153 25.8689 56.6843 25.8689Z'
          fill='black'
        />
        <path
          d='M39.9728 35.293C37.3659 35.293 35.2937 37.3652 35.2937 39.9721C35.2937 42.579 37.3659 44.6512 39.9728 44.6512C42.5798 44.6512 44.6519 42.579 44.6519 39.9721C44.6519 37.3652 42.5798 35.293 39.9728 35.293ZM38.7696 42.1111C38.5022 42.3785 38.1012 42.3785 37.8338 42.1111C37.5664 41.8437 37.5664 41.4427 37.8338 41.1753C38.1012 40.9079 38.5022 40.9079 38.7696 41.1753C39.037 41.4427 39.037 41.8437 38.7696 42.1111ZM38.7696 38.7689C38.5022 39.0363 38.1012 39.0363 37.8338 38.7689C37.5664 38.5015 37.5664 38.1004 37.8338 37.8331C38.1012 37.5657 38.5022 37.5657 38.7696 37.8331C39.037 38.1004 39.037 38.5015 38.7696 38.7689ZM40.4407 40.44C40.1734 40.7074 39.7723 40.7074 39.5049 40.44C39.2375 40.1726 39.2375 39.7716 39.5049 39.5042C39.7723 39.2368 40.1734 39.2368 40.4407 39.5042C40.7081 39.7716 40.7081 40.1726 40.4407 40.44ZM42.1118 42.1111C41.8445 42.3785 41.4434 42.3785 41.176 42.1111C40.9086 41.8437 40.9086 41.4427 41.176 41.1753C41.4434 40.9079 41.8445 40.9079 42.1118 41.1753C42.3792 41.4427 42.3792 41.8437 42.1118 42.1111ZM42.1118 38.7689C41.8445 39.0363 41.4434 39.0363 41.176 38.7689C40.9086 38.5015 40.9086 38.1004 41.176 37.8331C41.4434 37.5657 41.8445 37.5657 42.1118 37.8331C42.3792 38.1004 42.3792 38.5015 42.1118 38.7689Z'
          fill='black'
        />
        <path
          d='M49.9994 39.3051C49.9326 38.3024 49.732 37.2997 49.331 36.4307L48.1278 36.9655L47.593 35.7623L48.7962 35.2276C48.462 34.5591 47.9941 33.9575 47.5262 33.4227L46.5903 34.3586L45.6545 33.4227L46.5903 32.4869C45.9887 31.9522 45.3203 31.4843 44.585 31.0832L44.0502 32.2864L42.847 31.7516L43.3818 30.5484C42.5128 30.2142 41.6438 30.0805 40.708 30.0137V31.3506H39.3711V30.0805C38.5021 30.1474 37.5663 30.3479 36.7642 30.6153L37.2989 31.8185L36.0957 32.3532L35.4941 31.0163C34.7589 31.4174 34.0236 31.8853 33.422 32.4201L34.3578 33.3559L33.3551 34.2917L32.4193 33.3559C31.8177 34.0243 31.3498 34.7596 30.9487 35.5618L32.1519 36.0297L31.6172 37.2329L30.414 36.765C30.1466 37.5671 30.0129 38.3692 29.946 39.2382H31.2829V40.5751H30.0129C30.0797 41.5778 30.2803 42.5805 30.6813 43.4494L31.8845 42.9147L32.4193 44.1179L31.2161 44.6526C31.5503 45.3211 32.0182 45.9227 32.4861 46.4574L33.3551 45.5216L34.2909 46.4574L33.3551 47.5269C33.9567 48.0617 34.6252 48.5296 35.3605 48.9307L35.8952 47.7275L37.0984 48.2622L36.5637 49.4654C37.4326 49.7997 38.3016 49.9334 39.2374 50.0002V48.7302H40.5743V50.0002C41.4433 49.9334 42.3791 49.7328 43.1813 49.4654L42.7134 48.2622L43.9834 47.7275L44.5182 48.9307C45.2534 48.5296 45.9887 48.0617 46.5903 47.5269L45.6545 46.5911L46.5903 45.6553L47.5262 46.5911C48.1278 45.9227 48.5957 45.1874 48.9967 44.3853L47.7935 43.9173L48.3283 42.6473L49.5315 43.1152C49.7989 42.3131 49.9326 41.5109 49.9994 40.642H48.7294V39.3051H49.9994ZM39.9727 45.9895C36.6305 45.9895 33.9567 43.3157 33.9567 39.9735C33.9567 36.6313 36.6305 33.9575 39.9727 33.9575C43.315 33.9575 45.9887 36.6313 45.9887 39.9735C45.9887 43.3157 43.315 45.9895 39.9727 45.9895Z'
          fill='black'
        />
        <path
          d='M35.0263 19.2512L50.4674 3.81014C51.5369 2.74063 51.5369 1.06951 50.4674 0L31.6172 18.8502C32.3525 19.0507 32.4193 19.1175 32.9541 19.2512H35.0263Z'
          fill='black'
        />
        <path
          d='M62.7002 35.9615V31.9508H60.6949C55.8821 31.9508 52.0051 28.0738 52.0051 23.261V21.9241C48.1949 20.9214 44.2511 20.4535 40.3073 20.5872H32.687L29.4116 19.0498C21.3903 15.2397 4.67912 7.28516 0 7.28516V8.5552C4.3449 8.5552 16.9785 14.3038 27.54 19.3172L30.2137 20.5872H0V39.3037H26.0025C26.5373 31.5497 33.2218 25.6674 40.9089 26.2022C48.1949 26.6701 53.8767 32.6861 54.0104 39.9722H52.6735C52.6735 32.9535 46.9917 27.2717 39.9731 27.2717C32.9544 27.2717 27.2726 32.9535 27.2726 39.9722V40.6406H0V41.9775H28.8769C27.7405 35.8278 31.818 29.8786 37.9677 28.7423C44.1174 27.6059 50.0666 31.6834 51.2029 37.8331C51.4703 39.17 51.4703 40.5738 51.2029 41.9106H62.7671V37.2315H61.0291L57.8874 40.3732L56.9516 39.4374L60.4943 35.8946L62.7002 35.9615Z'
          fill='black'
        />
        <path
          d='M49.7905 18.3706C49.7805 18.3706 49.7701 18.3706 49.7601 18.3706C49.3518 18.3706 48.9964 18.2361 48.7258 17.9976C48.1042 18.2377 47.6151 18.0976 47.3313 17.9559C46.1969 17.3892 45.9231 15.6364 46.0172 14.5973C46.1304 13.3348 46.7293 12.9765 47.0763 12.8749C47.3249 12.802 47.6563 12.788 48.0213 12.9946C48.4921 12.7772 49.0456 12.756 49.5788 12.9585C50.3325 13.2455 50.8541 13.8872 50.9766 14.6353C52.1173 15.0888 52.2282 15.8885 52.2094 16.2716C52.1526 17.4136 50.8397 18.3534 49.7905 18.3706Z'
          fill={color}
        />
        <path
          d='M55.4156 14.7576C55.2515 14.7864 55.077 14.7684 54.9129 14.6967C54.6555 14.5826 54.3569 14.5606 54.0106 14.535C53.3202 14.485 52.3743 14.4153 51.5402 13.4727C50.6928 12.5156 50.4494 11.2336 50.9482 10.355C51.049 10.1768 51.26 9.88504 51.637 9.6793C51.5197 9.24781 51.5181 8.78669 51.6402 8.32037C51.932 7.20962 52.8779 6.3018 53.9934 6.06244C54.784 5.89272 55.5657 6.07244 56.2342 6.55957C56.8706 6.12408 57.6351 6.06764 58.4004 6.42829C59.7638 7.07072 60.9153 8.96922 60.8193 10.2825C60.9201 10.4154 60.981 10.5755 60.993 10.7452C61.1227 12.6513 59.4307 15.0253 57.6415 15.45C57.4446 15.4969 57.2504 15.5197 57.0639 15.5197C56.4271 15.5197 55.8583 15.2547 55.4156 14.7576Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(CarAccident);
