import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const Theft: React.FC<React.PropsWithChildren<ThemeProps>> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='61'
        height='54'
        viewBox='0 0 61 54'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.905762 0.823242V5.43194H14.8023C16.8831 5.43194 18.7559 6.82715 19.2173 8.67243L22.6914 20.6262C23.1528 22.2375 21.9948 23.6507 20.3753 23.6507C16.4398 23.4167 7.39261 22.7145 0.905762 22.7145V46.9102H17.1184V51.5189C17.1184 52.9051 18.0412 53.8232 19.4344 53.8232H26.3827C27.776 53.8232 28.6988 52.9051 28.6988 51.5189V26.6031C28.6988 24.7579 28.4364 22.9576 27.975 21.3463L23.8495 7.52025C22.4562 3.60466 18.973 0.823242 14.8023 0.823242H0.905762ZM19.4344 30.7798C21.9858 30.7798 24.0666 32.8501 24.0666 35.3885C24.0666 37.9268 21.9858 39.9972 19.4344 39.9972C16.8831 39.9972 14.8023 37.9268 14.8023 35.3885C14.8023 32.8501 16.8831 30.7798 19.4344 30.7798Z'
          fill='black'
        />
        <path
          d='M35.6471 8.88782C35.6471 5.71034 38.2436 3.12695 41.4373 3.12695C44.6309 3.12695 47.2275 5.71034 47.2275 8.88782C47.2275 12.0653 44.6309 14.6487 41.4373 14.6487C38.2436 14.6487 35.6471 12.0653 35.6471 8.88782Z'
          fill={color}
        />
        <path
          d='M47.0104 13.4965C47.7884 12.8034 48.7112 12.4164 49.616 12.3443C51.1268 12.2363 52.5834 12.9924 53.452 14.4327L60.1831 27.1066C61.5764 29.645 60.9069 32.8224 58.5908 34.6677L52.0768 39.2764L58.8079 49.646C59.5046 50.7981 59.0251 52.4094 57.867 53.1025C56.709 53.7956 55.0895 53.6066 54.3929 52.4544L46.2866 40.7166C45.3638 39.3304 45.3457 37.2061 46.5037 35.8199L52.2939 30.7791L47.2275 21.1297L43.7534 24.2981C42.5953 25.2163 40.9487 25.7384 39.5555 25.7384L30.7978 25.2343C29.4045 25.2343 28.4817 24.0821 28.4817 22.9299C28.4817 21.5437 29.6216 20.6256 31.0149 20.6256L40.2792 20.8416L47.0104 13.4965Z'
          fill={color}
        />
        <path
          d='M45.1285 48.0617C44.2057 45.7574 44.2057 43.237 45.1285 40.9327L49.9778 48.0617L50.9188 50.7981C51.3802 52.4094 50.2131 53.8406 48.8198 53.6066C47.897 53.6066 47.0285 53.1565 46.7932 52.2384L45.1285 48.0617Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Theft);
