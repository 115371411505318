import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import { Router } from './routing/routes';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as sentry from './monitoring/sentry';
import { loadTranslations, Translate } from 'react-redux-i18n';
import { translations } from './locale/locale';
import { useServiceWorker } from './service-worker/useServiceWorker';
import { ModalPopup } from './components/controls/ModalPopup';

const queryClient = new QueryClient();

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [refreshPagePopup, setRefreshPagePopup] = useState(<></>);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setRefreshPagePopup(
        <ModalPopup
          message={<Translate value={'refreshPageToSeeUpdates'} />}
          closeText={<Translate value={'refresh'} />}
          closeHandler={() => {
            setRefreshPagePopup(<></>);
            reloadPage();
          }}
        />
      );
    }
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    store.dispatch(loadTranslations(translations));
  }, []);

  sentry.initialize();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <Router />
          {refreshPagePopup}
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
