/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiObjectParty,
    ApiObjectPartyFromJSON,
    ApiObjectPartyFromJSONTyped,
    ApiObjectPartyToJSON,
    ApiPerson,
    ApiPersonFromJSON,
    ApiPersonFromJSONTyped,
    ApiPersonToJSON,
    ApiVehicleParty,
    ApiVehiclePartyFromJSON,
    ApiVehiclePartyFromJSONTyped,
    ApiVehiclePartyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiPartyData
 */
export interface ApiPartyData {
    /**
     * 
     * @type {ApiVehicleParty}
     * @memberof ApiPartyData
     */
    vehicleParty?: ApiVehicleParty;
    /**
     * 
     * @type {ApiObjectParty}
     * @memberof ApiPartyData
     */
    objectParty?: ApiObjectParty;
    /**
     * 
     * @type {Date}
     * @memberof ApiPartyData
     */
    createdTime?: Date;
    /**
     * 
     * @type {ApiPerson}
     * @memberof ApiPartyData
     */
    person?: ApiPerson;
}

export function ApiPartyDataFromJSON(json: any): ApiPartyData {
    return ApiPartyDataFromJSONTyped(json, false);
}

export function ApiPartyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPartyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleParty': !exists(json, 'vehicleParty') ? undefined : ApiVehiclePartyFromJSON(json['vehicleParty']),
        'objectParty': !exists(json, 'objectParty') ? undefined : ApiObjectPartyFromJSON(json['objectParty']),
        'createdTime': !exists(json, 'createdTime') ? undefined : (new Date(json['createdTime'])),
        'person': !exists(json, 'person') ? undefined : ApiPersonFromJSON(json['person']),
    };
}

export function ApiPartyDataToJSON(value?: ApiPartyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleParty': ApiVehiclePartyToJSON(value.vehicleParty),
        'objectParty': ApiObjectPartyToJSON(value.objectParty),
        'createdTime': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'person': ApiPersonToJSON(value.person),
    };
}

