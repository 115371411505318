import Resizer from 'react-image-file-resizer';

export const FULL_HD_WIDTH = 1920;
export const FULL_HD_HEIGHT = 1080;
export const HD_WIDTH = 1280;
export const HD_HEIGHT = 720;
export const HEIC_FORMAT = 'heic';

export const IMAGE_HEIC_FORMAT = 'image/heic';
export const IMAGE_JPEG_EXTENSION = 'image/jpeg';

export enum ResizerOutputFormat {
  blob = 'blob',
  file = 'file',
  base64 = 'base64',
}

export const rotateImage = (
  base64data: string,
  givenDegrees: number,
  callback: (base64Image: string) => any
): void => {
  const degrees = givenDegrees % 360;
  if (base64data == null || degrees % 90 !== 0 || degrees === 0) {
    callback(base64data);
    return;
  }

  const image = new Image();
  image.src = base64data;
  image.onload = function () {
    const canvas = document.createElement('canvas');
    if (degrees === 180) {
      canvas.width = image.width;
      canvas.height = image.height;
    } else {
      canvas.width = image.height;
      canvas.height = image.width;
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      callback(base64data);
      return;
    }
    ctx.rotate((degrees * Math.PI) / 180);
    if (degrees === 90) {
      ctx.translate(0, -canvas.width);
    } else if (degrees === 180) {
      ctx.translate(-canvas.width, -canvas.height);
    } else if (degrees === 270) {
      ctx.translate(-canvas.height, 0);
    }
    ctx.drawImage(image, 0, 0);
    callback(canvas.toDataURL());
  };
};

export const resizeImage = (
  photo: Blob,
  isLandscapeOrientation: boolean,
  outputType: ResizerOutputFormat
): Promise<unknown> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      photo,
      isLandscapeOrientation ? FULL_HD_HEIGHT : FULL_HD_WIDTH,
      isLandscapeOrientation ? FULL_HD_WIDTH : FULL_HD_HEIGHT,
      'JPEG',
      100,
      0,
      async (uri) => {
        resolve(uri);
      },
      outputType,
      isLandscapeOrientation ? HD_WIDTH : HD_HEIGHT,
      isLandscapeOrientation ? HD_HEIGHT : HD_WIDTH
    );
  });
};
