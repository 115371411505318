/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FeedbackData,
    FeedbackDataFromJSON,
    FeedbackDataToJSON,
    FeedbackLinkCaseType,
    FeedbackLinkCaseTypeFromJSON,
    FeedbackLinkCaseTypeToJSON,
    FeedbackLinkStatus,
    FeedbackLinkStatusFromJSON,
    FeedbackLinkStatusToJSON,
} from '../models';

export interface GetClaimFeedbackUuidRequest {
    claimUuid: string;
}

export interface SubmitFeedbackRequest {
    uuid: string;
    caseType: FeedbackLinkCaseType;
    feedbackData: FeedbackData;
}

export interface ValidateLinkRequest {
    uuid: string;
    caseType: FeedbackLinkCaseType;
}

/**
 * 
 */
export class FeedbackApi extends runtime.BaseAPI {

    /**
     * get feedback by type
     */
    async getClaimFeedbackUuidRaw(requestParameters: GetClaimFeedbackUuidRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.claimUuid === null || requestParameters.claimUuid === undefined) {
            throw new runtime.RequiredError('claimUuid','Required parameter requestParameters.claimUuid was null or undefined when calling getClaimFeedbackUuid.');
        }

        const queryParameters: any = {};

        if (requestParameters.claimUuid !== undefined) {
            queryParameters['claimUuid'] = requestParameters.claimUuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * get feedback by type
     */
    async getClaimFeedbackUuid(requestParameters: GetClaimFeedbackUuidRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getClaimFeedbackUuidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * submit feedback
     */
    async submitFeedbackRaw(requestParameters: SubmitFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling submitFeedback.');
        }

        if (requestParameters.caseType === null || requestParameters.caseType === undefined) {
            throw new runtime.RequiredError('caseType','Required parameter requestParameters.caseType was null or undefined when calling submitFeedback.');
        }

        if (requestParameters.feedbackData === null || requestParameters.feedbackData === undefined) {
            throw new runtime.RequiredError('feedbackData','Required parameter requestParameters.feedbackData was null or undefined when calling submitFeedback.');
        }

        const queryParameters: any = {};

        if (requestParameters.caseType !== undefined) {
            queryParameters['caseType'] = requestParameters.caseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/feedback/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackDataToJSON(requestParameters.feedbackData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * submit feedback
     */
    async submitFeedback(requestParameters: SubmitFeedbackRequest, initOverrides?: RequestInit): Promise<void> {
        await this.submitFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * validate feedback link
     */
    async validateLinkRaw(requestParameters: ValidateLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeedbackLinkStatus>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling validateLink.');
        }

        if (requestParameters.caseType === null || requestParameters.caseType === undefined) {
            throw new runtime.RequiredError('caseType','Required parameter requestParameters.caseType was null or undefined when calling validateLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.caseType !== undefined) {
            queryParameters['caseType'] = requestParameters.caseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback/{uuid}/validate`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackLinkStatusFromJSON(jsonValue));
    }

    /**
     * validate feedback link
     */
    async validateLink(requestParameters: ValidateLinkRequest, initOverrides?: RequestInit): Promise<FeedbackLinkStatus> {
        const response = await this.validateLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
