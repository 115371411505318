/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiObjectParty,
    ApiObjectPartyFromJSON,
    ApiObjectPartyFromJSONTyped,
    ApiObjectPartyToJSON,
    ApiVehicleParty,
    ApiVehiclePartyFromJSON,
    ApiVehiclePartyFromJSONTyped,
    ApiVehiclePartyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiParties
 */
export interface ApiParties {
    /**
     * 
     * @type {Array<ApiVehicleParty>}
     * @memberof ApiParties
     */
    vehicleParties?: Array<ApiVehicleParty>;
    /**
     * 
     * @type {Array<ApiObjectParty>}
     * @memberof ApiParties
     */
    objectParties?: Array<ApiObjectParty>;
}

export function ApiPartiesFromJSON(json: any): ApiParties {
    return ApiPartiesFromJSONTyped(json, false);
}

export function ApiPartiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiParties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleParties': !exists(json, 'vehicleParties') ? undefined : ((json['vehicleParties'] as Array<any>).map(ApiVehiclePartyFromJSON)),
        'objectParties': !exists(json, 'objectParties') ? undefined : ((json['objectParties'] as Array<any>).map(ApiObjectPartyFromJSON)),
    };
}

export function ApiPartiesToJSON(value?: ApiParties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleParties': value.vehicleParties === undefined ? undefined : ((value.vehicleParties as Array<any>).map(ApiVehiclePartyToJSON)),
        'objectParties': value.objectParties === undefined ? undefined : ((value.objectParties as Array<any>).map(ApiObjectPartyToJSON)),
    };
}

