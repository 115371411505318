import React, { useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import './Camera.css';
import { BsArrowRepeat } from 'react-icons/bs';
import { rotateImage } from '../../../common/utils/ImageUtils';
import { Translate } from 'react-redux-i18n';

export interface CameraProps {
  onScreenshotCallback: (imgSrc?: string) => any;
}

interface Orientation {
  absolute: boolean;
  alpha: number | null;
  beta: number | null;
  gamma: number | null;
}

enum Layout {
  LANDSCAPE = 'L',
  PORTRAIT = 'P',
  UNKNOWN = 'U',
}

const LANDSCAPE_OFFSET = 25;
const LANDSCAPE_ORIENTATION_CONSTRAINTS = {
  xMinDegree: -LANDSCAPE_OFFSET,
  xMaxDegree: LANDSCAPE_OFFSET,
};

const UPSIDE_DOWN_OFFSET = 40;
const UPSIDE_DOWN_ORIENTATION_CONSTRAINTS = {
  xMinDegree: -90 - UPSIDE_DOWN_OFFSET,
  xMaxDegree: -90 + UPSIDE_DOWN_OFFSET,
};

const LEFT_LANDSCAPE_CONSTRAINTS = {
  yMinDegree: -90,
  yMaxDegree: -50,
};

export const Camera: React.FC<React.PropsWithChildren<CameraProps>> = (props: CameraProps) => {
  useEffect(() => {
    if (document.documentElement.requestFullscreen && !document.fullscreenElement) {
      document.documentElement.requestFullscreen({ navigationUI: 'hide' });
    }
  }, []);

  const webcamRef = React.useRef(null);

  const videoConstraints = {
    facingMode: 'environment',
    width: { ideal: 1920 },
    height: { ideal: 1080 },
  };

  const calcOrientation = (orientation: Orientation): Layout => {
    return orientation.beta
      ? orientation.beta >= LANDSCAPE_ORIENTATION_CONSTRAINTS.xMinDegree &&
        orientation.beta <= LANDSCAPE_ORIENTATION_CONSTRAINTS.xMaxDegree
        ? Layout.LANDSCAPE
        : Layout.PORTRAIT
      : Layout.UNKNOWN;
  };
  const [orientation, setOrientation] = useState(Layout.UNKNOWN);
  const [isUpsideDown, setUpsideDown] = useState(false);
  const [isLeftLandscape, setLeftLandscape] = useState(false);
  const handleOrientation = (e: DeviceOrientationEvent) => {
    setOrientation(
      calcOrientation({ absolute: e.absolute, alpha: e.alpha, beta: e.beta, gamma: e.gamma })
    );
    setUpsideDown(
      e.beta != null &&
        e.beta > UPSIDE_DOWN_ORIENTATION_CONSTRAINTS.xMinDegree &&
        e.beta < UPSIDE_DOWN_ORIENTATION_CONSTRAINTS.xMaxDegree
    );
    setLeftLandscape(
      e.gamma != null &&
        e.gamma >= LEFT_LANDSCAPE_CONSTRAINTS.yMinDegree &&
        e.gamma <= LEFT_LANDSCAPE_CONSTRAINTS.yMaxDegree
    );
  };
  const [layout, setLayout] = useState(Layout.UNKNOWN);
  const handleWindowResize = () =>
    setLayout(window.innerHeight > window.innerWidth ? Layout.PORTRAIT : Layout.LANDSCAPE);
  useEffect(() => {
    setLayout(window.innerHeight > window.innerWidth ? Layout.PORTRAIT : Layout.LANDSCAPE);

    if (window.DeviceOrientationEvent)
      window.addEventListener('deviceorientation', handleOrientation, true);
    window.addEventListener('resize', handleWindowResize);
  }, []);

  const capture = React.useCallback(() => {
    // @ts-ignore
    const imageSrc = webcamRef.current.getScreenshot();

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (layout == Layout.PORTRAIT && orientation != Layout.UNKNOWN) {
      rotateImage(imageSrc, isLeftLandscape ? 270 : 90, (base64Image: string) => {
        props.onScreenshotCallback(base64Image);
      });
    } else {
      props.onScreenshotCallback(imageSrc);
    }
  }, [webcamRef, isLeftLandscape, layout]);

  const takePhotoIconCssClass = layout == Layout.LANDSCAPE ? 'cam-icon-r' : 'cam-icon-b';

  const takePhotoIcon = (
    <div className={takePhotoIconCssClass}>
      <div onClick={capture} data-testid='take-photo-ctrl'>
        <div className='cam-icon-outer-circle'>
          <div className='cam-icon-inner-circle' />
        </div>
      </div>
    </div>
  );

  return (
    <div className='cam-wrap text-center' data-testid='camera'>
      <Webcam
        audio={false}
        videoConstraints={videoConstraints}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        screenshotQuality={1}
        forceScreenshotSourceSize={true}
        imageSmoothing={false}
        mirrored={false}
        width='100%'
        height='100%'
      />
      {orientation == Layout.LANDSCAPE ? (
        <>
          <div className='cam-frame' style={{ top: '5%', left: '5%' }} />
          <div
            className='cam-frame'
            style={{ top: '5%', right: '5%', transform: 'rotate(90deg)' }}
          />
          <div
            className='cam-frame'
            style={{ bottom: '5%', right: '5%', transform: 'rotate(180deg)' }}
          />
          <div
            className='cam-frame'
            style={{ bottom: '5%', left: '5%', transform: 'rotate(-90deg)' }}
          />
          <div className='cam-guide'>
            <div
              className='cam-hint'
              style={
                layout == Layout.PORTRAIT
                  ? isLeftLandscape
                    ? { transform: 'rotate(90deg)' }
                    : { transform: 'rotate(-90deg)' }
                  : {}
              }
            >
              <Translate value='photos.takeAPhotoOfDamage' />
            </div>
          </div>
          {takePhotoIcon}
        </>
      ) : orientation == Layout.PORTRAIT ? (
        <div className='cam-hint-wrap' style={isUpsideDown ? { transform: 'rotate(180deg)' } : {}}>
          <div>
            <BsArrowRepeat size='90' className='my-5' />
          </div>
          <div className='cam-hint'>
            <Translate value='photos.turnYourPhoneHorizontally' />
          </div>
        </div>
      ) : (
        <>
          <div
            className='cam-hint-wrap'
            style={isUpsideDown ? { transform: 'rotate(180deg)' } : {}}
          >
            <div className='cam-hint'>
              <Translate value='photos.takeAPhotoOfDamage' />
            </div>
          </div>
          {takePhotoIcon}
        </>
      )}
    </div>
  );
};
