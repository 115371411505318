/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ServiceNotificationResponse,
    ServiceNotificationResponseFromJSON,
    ServiceNotificationResponseToJSON,
} from '../models';

export interface IsRequestFeedbackEnabledUponClaimRegistrationRequest {
    claimUuid: string;
}

/**
 * 
 */
export class ServiceNotificationApi extends runtime.BaseAPI {

    /**
     * check if getting feedback upon claim submission is enabled or not
     */
    async isRequestFeedbackEnabledUponClaimRegistrationRaw(requestParameters: IsRequestFeedbackEnabledUponClaimRegistrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ServiceNotificationResponse>> {
        if (requestParameters.claimUuid === null || requestParameters.claimUuid === undefined) {
            throw new runtime.RequiredError('claimUuid','Required parameter requestParameters.claimUuid was null or undefined when calling isRequestFeedbackEnabledUponClaimRegistration.');
        }

        const queryParameters: any = {};

        if (requestParameters.claimUuid !== undefined) {
            queryParameters['claimUuid'] = requestParameters.claimUuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/service-notification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceNotificationResponseFromJSON(jsonValue));
    }

    /**
     * check if getting feedback upon claim submission is enabled or not
     */
    async isRequestFeedbackEnabledUponClaimRegistration(requestParameters: IsRequestFeedbackEnabledUponClaimRegistrationRequest, initOverrides?: RequestInit): Promise<ServiceNotificationResponse> {
        const response = await this.isRequestFeedbackEnabledUponClaimRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
