/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCaseDetails,
    ApiCaseDetailsFromJSON,
    ApiCaseDetailsToJSON,
} from '../models';

export interface GetCaseByClaimOrAssistanceRequest {
    claimId?: string;
    assistanceId?: string;
}

/**
 * 
 */
export class VehicleRepairCaseApi extends runtime.BaseAPI {

    /**
     * gets case details by claim Id or assistance Id
     */
    async getCaseByClaimOrAssistanceRaw(requestParameters: GetCaseByClaimOrAssistanceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCaseDetails>> {
        const queryParameters: any = {};

        if (requestParameters.claimId !== undefined) {
            queryParameters['claimId'] = requestParameters.claimId;
        }

        if (requestParameters.assistanceId !== undefined) {
            queryParameters['assistanceId'] = requestParameters.assistanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCaseDetailsFromJSON(jsonValue));
    }

    /**
     * gets case details by claim Id or assistance Id
     */
    async getCaseByClaimOrAssistance(requestParameters: GetCaseByClaimOrAssistanceRequest, initOverrides?: RequestInit): Promise<ApiCaseDetails> {
        const response = await this.getCaseByClaimOrAssistanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
