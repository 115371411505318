import {
  ApiVehicleAndInsuranceVerification,
  ApiVehicleDetails,
  ApiVehicleDetailsPreview,
  ApiVehicleInsurance,
} from '../../../api';
import { VEHICLE_NOT_FOUND } from '../../exception/ErrorMessages';
import { VehicleDetailsState } from './vehicleDetailsReducer';

export const validVehicleDetailsState: VehicleDetailsState = {
  plateNumber: '123ABC',
  vinCode: 'VINTEST1381654',
  mark: 'Toyota',
  model: 'Corolla',
  year: '2019',
  error: '',
};

export const validVehicleDetailsStateNullYear: VehicleDetailsState = {
  plateNumber: '123ABC',
  vinCode: 'VINTEST1381654',
  mark: 'Toyota',
  model: 'Corolla',
  year: null,
  error: '',
};

export const invalidVehicleDetailsState: VehicleDetailsState = {
  plateNumber: '',
  vinCode: '',
  mark: '',
  model: '',
  year: '',
  error: '',
};

export const invalidVehicleDetailsStateWithError: VehicleDetailsState = {
  plateNumber: '',
  vinCode: '',
  mark: '',
  model: '',
  year: '',
  error: 'TRY_AGAIN',
};

export const vehicleDetailsPreview: ApiVehicleDetailsPreview = {
  licensePlate: validVehicleDetailsState.plateNumber,
  manufacturer: validVehicleDetailsState.mark,
  model: validVehicleDetailsState.model,
  year: parseInt(validVehicleDetailsState.year == null ? '0' : validVehicleDetailsState.year),
};

export const vehicleDetailsPreviewError: ApiVehicleDetailsPreview = {
  error: VEHICLE_NOT_FOUND,
};

export const insurance: ApiVehicleInsurance = {
  verified: true,
};

export const vehicleDetailsAndInsuranceVerification: ApiVehicleAndInsuranceVerification = {
  vehicle: vehicleDetailsPreview,
  insurance: insurance,
};

export const apiVehicleDetails: ApiVehicleDetails = {
  licensePlate: validVehicleDetailsState.plateNumber,
  mark: validVehicleDetailsState.mark,
  model: validVehicleDetailsState.model,
  year: parseInt(validVehicleDetailsState.year == null ? '0' : validVehicleDetailsState.year),
};
