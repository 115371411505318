import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import './Radio.css';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';

// eslint-disable-next-line react/display-name
export const Radio = React.forwardRef<HTMLInputElement, Props>((props: Props, ref: any) => {
  const { color } = props;
  return (
    <>
      <style type='text/css'>
        {`
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background-color: ${color} !important;
            border-color: ${color};
         }
         .custom-radio .custom-control-input:active ~ .custom-control-label::before {
            background-color: ${color} !important;
            border-color: ${color};
          }
        `}
      </style>
      <FormCheck {...props} custom bsCustomPrefix='custom-control' ref={ref} />
    </>
  );
});

const mapStateToProp = ({ theme }: AppState) => ({
  color: theme.color,
});

export type Props = ReturnType<typeof mapStateToProp> & FormCheckProps;

export default connect(mapStateToProp)(Radio);
