import { MixPanelEvent } from './mixpanel';
import { GAEvents } from './googleAnalytics';
import {
  ApiAccidentDetails,
  ApiClaimIncidentType,
  ApiCommunicationPreference,
  ApiDamageArea,
  ApiVehicleDetails,
  ApiWorkshop,
  SubmitAssistanceRequestRequest,
} from '../api';

export const trackAssistanceRequestInitializedEvent = (requestId: number): void => {
  MixPanelEvent.trackAssistanceRequestInitializedEvent(requestId);
  GAEvents.trackAssistanceRequestInitializedEvent();
};

export const trackIncidentViewEvent = (requestId?: number | null): void => {
  MixPanelEvent.trackIncidentViewEvent(requestId);
  GAEvents.trackIncidentViewEvent();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackIncidentSelectionEvent = (incidentSelectEvent: any): void => {
  MixPanelEvent.trackIncidentSelectionEvent(incidentSelectEvent);
  GAEvents.trackIncidentSelectionEvent();
};

export const trackLocationViewEvent = (requestId: number): void => {
  MixPanelEvent.trackLocationViewEvent(requestId);
  GAEvents.trackLocationViewEvent();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackLocationSelectionEvent = (locationSelectEvent: any): void => {
  MixPanelEvent.trackLocationSelectionEvent(locationSelectEvent);
  GAEvents.trackLocationSelectionEvent();
};

export const trackVehicleDetailsViewEvent = (requestId: number): void => {
  MixPanelEvent.trackVehicleDetailsViewEvent(requestId);
  GAEvents.trackVehicleDetailsViewEvent();
};

export const trackVehicleDetailsPlateNumberSearchEvent = (
  requestId: number,
  verified: boolean | undefined,
  plateNumber: string
): void => {
  MixPanelEvent.trackVehicleDetailsPlateNumberSearchEvent(requestId, verified, plateNumber);
  GAEvents.trackVehicleDetailsPlateNumberSearchEvent();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackContactDataSubmitEvent = (contactDataSubmitEvent: any): void => {
  MixPanelEvent.trackContactDataSubmitEvent(contactDataSubmitEvent);
  GAEvents.trackContactDataSubmitEvent();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackVehicleDataSubmitEvent = (vehicleDataSubmitEvent: any): void => {
  MixPanelEvent.trackVehicleDataSubmitEvent(vehicleDataSubmitEvent);
  GAEvents.trackVehicleDataSubmitEvent();
};

export const trackAdditionalDetailsViewEvent = (requestId: number): void => {
  MixPanelEvent.trackAdditionalDetailsViewEvent(requestId);
  GAEvents.trackAdditionalDetailsViewEvent();
};

export const trackRequestSubmitEvent = (data: SubmitAssistanceRequestRequest): void => {
  MixPanelEvent.trackRequestSubmitEvent(data);
  GAEvents.trackRequestSubmitEvent();
};

export const trackFindingTowTruckViewEvent = (requestId: number): void => {
  MixPanelEvent.trackFindingTowTruckViewEvent(requestId);
  GAEvents.trackFindingTowTruckViewEvent();
};

export const trackTowTruckFoundEvent = (requestId: number): void => {
  MixPanelEvent.trackTowTruckFoundEvent(requestId);
  GAEvents.trackTowTruckFoundEvent();
};

export const trackTowTruckNotFoundEvent = (requestId: number): void => {
  MixPanelEvent.trackTowTruckNotFoundEvent(requestId);
  GAEvents.trackTowTruckNotFoundEvent();
};

export const trackClaimLinkInitializedEvent = (claimId: string): void => {
  MixPanelEvent.trackClaimLinkInitializedEvent(claimId);
  GAEvents.trackClaimLinkInitializedEvent();
};

export const trackClaimIncidentViewEvent = (claimId: string | null): void => {
  MixPanelEvent.trackClaimIncidentViewEvent(claimId);
  GAEvents.trackClaimIncidentViewEvent();
};

export const trackClaimIncidentSelectionEvent = (
  claimId: string,
  incidentType: ApiClaimIncidentType
): void => {
  MixPanelEvent.trackClaimIncidentSelectionEvent(claimId, incidentType);
  GAEvents.trackClaimIncidentSelectionEvent();
};

export const trackClaimVehicleDetailsViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimVehicleDetailsViewEvent(claimId);
  GAEvents.trackClaimVehicleDetailsViewEvent();
};

export const trackClaimVehicleDetailsSearchEvent = (
  claimId: string,
  verified: boolean | undefined,
  plateNumber: string
): void => {
  MixPanelEvent.trackClaimVehicleDetailsSearchEvent(claimId, verified, plateNumber);
  GAEvents.trackClaimVehicleDetailsSearchEvent();
};

export const trackClaimVehicleDetailsSubmitEvent = (
  claimId: string,
  vehicleDetails: ApiVehicleDetails
): void => {
  MixPanelEvent.trackClaimVehicleDetailsSubmitEvent(claimId, vehicleDetails);
  GAEvents.trackClaimVehicleDetailsSubmitEvent();
};

export const trackClaimDamageViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimDamageViewEvent(claimId);
  GAEvents.trackClaimDamageViewEvent();
};

export const trackClaimDamageAreaSelectionEvent = (
  claimId: string | undefined,
  id: string
): void => {
  MixPanelEvent.trackClaimDamageAreaSelectionEvent(claimId, id);
  GAEvents.trackClaimDamageAreaSelectionEvent();
};

export const trackClaimDamageLocationSubmitEvent = (
  claimId: string,
  apiDamageArea: ApiDamageArea
): void => {
  MixPanelEvent.trackClaimDamageLocationSubmitEvent(claimId, apiDamageArea);
  GAEvents.trackClaimDamageLocationSubmitEvent();
};

export const trackClaimPhotosViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimPhotosViewEvent(claimId);
  GAEvents.trackClaimPhotosViewEvent();
};

export const trackClaimPhotosSkipEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimPhotosSkipEvent(claimId);
  GAEvents.trackClaimPhotosSkipEvent();
};

export const trackClaimPhotoDeleteEvent = (claimId: string | undefined, photoId: number): void => {
  MixPanelEvent.trackClaimPhotoDeleteEvent(claimId, photoId);
  GAEvents.trackClaimPhotoDeleteEvent();
};

export const trackClaimPhotosSubmitEvent = (claimId: string, photoCount: number): void => {
  MixPanelEvent.trackClaimPhotosSubmitEvent(claimId, photoCount);
  GAEvents.trackClaimPhotosSubmitEvent();
};

export const trackClaimNotifierInfoViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimNotifierInfoViewEvent(claimId);
  GAEvents.trackClaimNotifierInfoViewEvent();
};

export const trackClaimNotifierInfoSubmit = (claimId: string, isDriver: boolean): void => {
  MixPanelEvent.trackClaimNotifierInfoSubmit(claimId, isDriver);
  GAEvents.trackClaimNotifierInfoSubmit();
};

export const trackClaimOtherPartiesViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimOtherPartiesViewEvent(claimId);
  GAEvents.trackClaimOtherPartiesViewEvent();
};

export const trackClaimOtherPartyAddEvent = (
  claimId: string | undefined,
  partyId: number
): void => {
  MixPanelEvent.trackClaimOtherPartyAddEvent(claimId, partyId);
  GAEvents.trackClaimOtherPartyAddEvent();
};

export const trackClaimOtherPartyDeleteEvent = (
  claimId: string | undefined,
  partyId: number
): void => {
  MixPanelEvent.trackClaimOtherPartyDeleteEvent(claimId, partyId);
  GAEvents.trackClaimOtherPartyDeleteEvent();
};

export const trackClaimOtherPartiesSubmitEvent = (
  claimId: string | undefined,
  partyCount: number
): void => {
  MixPanelEvent.trackClaimOtherPartiesSubmitEvent(claimId, partyCount);
  GAEvents.trackClaimOtherPartiesSubmitEvent();
};

export const trackClaimAccidentDetailsViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimAccidentDetailsViewEvent(claimId);
  GAEvents.trackClaimAccidentDetailsViewEvent();
};

export const trackClaimAccidentLocationSelectEvent = (
  claimId: string | undefined,
  latitude: number,
  longitude: number
): void => {
  MixPanelEvent.trackClaimAccidentLocationSelectEvent(claimId, latitude, longitude);
  GAEvents.trackClaimAccidentLocationSelectEvent();
};

export const trackClaimAccidentDetailsSubmitEvent = (
  claimId: string | undefined,
  accidentDetails: ApiAccidentDetails
): void => {
  MixPanelEvent.trackClaimAccidentDetailsSubmitEvent(claimId, accidentDetails);
  GAEvents.trackClaimAccidentDetailsSubmitEvent();
};

export const trackClaimWorkshopSelectionViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimWorkshopSelectionViewEvent(claimId);
  GAEvents.trackClaimWorkshopSelectionViewEvent();
};

export const trackClaimWorkshopSelectionEvent = (
  claimId: string,
  selectedWorkshop: ApiWorkshop
): void => {
  MixPanelEvent.trackClaimWorkshopSelectionEvent(claimId, selectedWorkshop);
  GAEvents.trackClaimWorkshopSelectionEvent();
};

export const trackClaimCommunicationViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimCommunicationViewEvent(claimId);
  GAEvents.trackClaimCommunicationViewEvent();
};

export const trackClaimCommunicationPreferenceSubmitEvent = (
  claimId: string | undefined,
  communicationPreferences: ApiCommunicationPreference
): void => {
  MixPanelEvent.trackClaimCommunicationPreferenceSubmitEvent(claimId, communicationPreferences);
  GAEvents.trackClaimCommunicationPreferenceSubmitEvent();
};

export const trackClaimSubmitEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimSubmitEvent(claimId);
  GAEvents.trackClaimSubmitEvent();
};

export const trackClaimReceivedViewEvent = (claimId: string | undefined): void => {
  MixPanelEvent.trackClaimReceivedViewEvent(claimId);
  GAEvents.trackClaimReceivedViewEvent();
};

export const trackTermsAndPrivacyClickEvent = (id: string | undefined): void => {
  MixPanelEvent.trackTermsAndPrivacyClickEvent(id);
  GAEvents.trackTermsAndPrivacyClickEvent();
};

export const trackExceptionEvent = (
  objectId: number | string | null,
  action: string,
  message: string
): void => {
  MixPanelEvent.trackExceptionEvent(objectId, action, message);
  GAEvents.trackExceptionEvent(action);
};
