/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCaseStepEstimationType,
    ApiCaseStepEstimationTypeFromJSON,
    ApiCaseStepEstimationTypeFromJSONTyped,
    ApiCaseStepEstimationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCaseProgress
 */
export interface ApiCaseProgress {
    /**
     * 
     * @type {number}
     * @memberof ApiCaseProgress
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseProgress
     */
    caseStepId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseProgress
     */
    previousCaseProgressId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseProgress
     */
    estimatedTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseProgress
     */
    completedTimeMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiCaseProgress
     */
    nameKey: string;
    /**
     * 
     * @type {ApiCaseStepEstimationType}
     * @memberof ApiCaseProgress
     */
    estimationType: ApiCaseStepEstimationType;
}

export function ApiCaseProgressFromJSON(json: any): ApiCaseProgress {
    return ApiCaseProgressFromJSONTyped(json, false);
}

export function ApiCaseProgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseProgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'caseStepId': !exists(json, 'caseStepId') ? undefined : json['caseStepId'],
        'previousCaseProgressId': !exists(json, 'previousCaseProgressId') ? undefined : json['previousCaseProgressId'],
        'estimatedTimeMs': !exists(json, 'estimatedTimeMs') ? undefined : json['estimatedTimeMs'],
        'completedTimeMs': !exists(json, 'completedTimeMs') ? undefined : json['completedTimeMs'],
        'nameKey': json['nameKey'],
        'estimationType': ApiCaseStepEstimationTypeFromJSON(json['estimationType']),
    };
}

export function ApiCaseProgressToJSON(value?: ApiCaseProgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'caseStepId': value.caseStepId,
        'previousCaseProgressId': value.previousCaseProgressId,
        'estimatedTimeMs': value.estimatedTimeMs,
        'completedTimeMs': value.completedTimeMs,
        'nameKey': value.nameKey,
        'estimationType': ApiCaseStepEstimationTypeToJSON(value.estimationType),
    };
}

