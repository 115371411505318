import { ApiLanguage } from '../api';
import { fallbackLocale, LanguageType } from './locale';

export const getLanguageTypeFromEnum = (apiLanguage: ApiLanguage): string => {
  switch (apiLanguage) {
    case ApiLanguage.Estonian:
      return 'et';
    case ApiLanguage.Russian:
      return 'ru';
    case ApiLanguage.Latvian:
      return 'lv';
    case ApiLanguage.Lithuanian:
      return 'lt';
    default:
      return fallbackLocale;
  }
};

export const getLanguageEnumFromType = (language: LanguageType): ApiLanguage => {
  switch (language) {
    case 'et':
      return ApiLanguage.Estonian;
    case 'ru':
      return ApiLanguage.Russian;
    case 'lv':
      return ApiLanguage.Latvian;
    case 'lt':
      return ApiLanguage.Lithuanian;
    default:
      return ApiLanguage.English;
  }
};
