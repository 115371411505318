import { Dispatch } from 'redux';
import { ApiVehicleDetailsPreview } from '../../../api';
import {
  clearVehicleDetails,
  setVehicleDetails,
  showError,
  clearError,
} from './vehicleDetailsReducer';
import { vehiclesApi } from '../../../apis';
import { TRY_AGAIN, VEHICLE_NOT_FOUND } from '../../exception/ErrorMessages';
import { setInsurance } from '../../../pages/assistance-requests/vehicle-details/insuranceReducer';
import {
  trackExceptionEvent,
  trackVehicleDetailsPlateNumberSearchEvent,
} from '../../../analytics/Analytics';

export const saveVehicleDetails =
  (vehicleDetails: ApiVehicleDetailsPreview) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setVehicleDetails(vehicleDetails));
  };

export const getVehicleDetailsByPlateNumberAction =
  (plateNumber: string, assistanceRequestId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    plateNumber = plateNumber.toUpperCase().trim();
    if (plateNumber == '') return;
    try {
      const response = await vehiclesApi.getVehicleAndVerifyInsurance({
        licensePlate: plateNumber,
        requestId: assistanceRequestId,
      });

      if (
        !response.vehicle?.licensePlate &&
        !response.vehicle?.manufacturer &&
        response.vehicle?.error != ''
      ) {
        dispatch(clearVehicleDetails());
        dispatch(setInsurance({ verified: false }));
        dispatch(showError(response.vehicle?.error || VEHICLE_NOT_FOUND));
      } else {
        dispatch(setVehicleDetails(response.vehicle));
        dispatch(setInsurance(response.insurance));
        dispatch(clearError());
      }
      trackVehicleDetailsPlateNumberSearchEvent(
        assistanceRequestId,
        response.insurance?.verified,
        plateNumber
      );
    } catch (e) {
      dispatch(clearVehicleDetails());
      trackExceptionEvent(
        assistanceRequestId,
        'assistance request load vehicle details',
        e.message
      );
      dispatch(showError(TRY_AGAIN));
    }
  };
