import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { TextInput } from '../../../components/controls/TextInput';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLocalizedMessageElement } from '../../../locale/locale';
import { PartyState } from './OtherParties';
import { useDispatch } from 'react-redux';
import { setPartyErrorAction } from './otherPartiesActions';
import { PhoneNumberInput } from '../../../components/controls/PhoneNumberInput';
import { countryCodes, countryListForCodes } from '../../commonConstants';
import { INVALID_PHONE_NUMBER, phoneNumberFormat } from '../../../common/FormValidations';

const validationSchema = yup.object().shape({
  description: yup.string().required('Required field'),
  phone: yup.string().matches(phoneNumberFormat, {
    excludeEmptyString: true,
    message: INVALID_PHONE_NUMBER,
  }),
});

export interface ObjectPartyFormProps {
  country: string;
  handlePartyHeader: (data: string, formData: PartyState) => void;
  formData: PartyState;
  setErrors: (errors: FieldErrors<any>, formData: PartyState) => void;
}

export const ObjectPartyForm: React.FC<React.PropsWithChildren<ObjectPartyFormProps>> = ({
  country,
  formData,
  handlePartyHeader,
  setErrors,
}: ObjectPartyFormProps) => {
  const { submitCount } = formData;
  const { register, handleSubmit, errors, getValues } = useForm<any>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: formData.object?.description,
      fullName: formData.person?.fullName,
      personalCode: formData.person?.personalCode,
      phone: formData.person?.phone,
      email: formData.person?.email,
    },
  });

  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState(country);

  const formSubmit = (data: any) => {
    const party: PartyState = {
      ...formData,
      object: {
        ...formData.object,
        description: data.description as string,
      },
      person: {
        ...formData.person,
        fullName: data.fullName,
        email: data.email,
        phonePrefix: data.phone ? countryCodes[countryCode] : '',
        phone: data.phone,
        personalCode: data.personalCode,
      },
    };
    party.submit(party);
  };

  useEffect(() => {
    if (submitCount > 0) {
      handleSubmit(formSubmit)();
    }
  }, [submitCount]);

  useEffect(() => {
    setErrors(errors, formData);
    if (Object.keys(errors).length > 0) {
      dispatch(
        setPartyErrorAction(formData.id, {
          errors: errors,
          object: {
            description: getValues('description'),
          },
          person: {
            fullName: getValues('fullName'),
            personalCode: getValues('personalCode'),
            phonePrefix: countryCodes[countryCode],
            phone: getValues('phone'),
            email: getValues('email'),
          },
          id: formData.id,
        })
      );
    }
  }, [errors]);

  return (
    <Form className='mx-auto' onSubmit={handleSubmit(formData.submit)}>
      <TextInput
        label={<Translate value='otherParties.objectParty.description' />}
        name='description'
        controlId='otherPartyForm.description'
        ref={register}
        testId='description'
        onChangeHandler={() => handlePartyHeader(getValues('description'), formData)}
        isInvalid={!!errors.description}
        validationError={getLocalizedMessageElement(errors.description?.message)}
      />

      <TextInput
        label={<Translate value='otherParties.objectParty.name' />}
        name='fullName'
        controlId='otherPartyForm.name'
        ref={register}
      />

      <TextInput
        label={<Translate value='otherParties.objectParty.personalCode' />}
        name='personalCode'
        controlId='otherPartyForm.personalCode'
        ref={register}
      />

      <PhoneNumberInput
        label={<Translate value='otherParties.objectParty.phoneNumber' />}
        name='phone'
        controlId='otherPartyForm.phoneNumber'
        ref={register}
        testId={'phoneNumber'}
        onCountryCodeChange={(code: string) => setCountryCode(code)}
        countryCode={countryCode}
        countryCodes={countryCodes}
        countryListForCodes={countryListForCodes}
        isInvalid={!!errors.phone}
      />

      <TextInput
        label={<Translate value='otherParties.objectParty.email' />}
        name='email'
        controlId='otherPartyForm.email'
        ref={register}
      />
    </Form>
  );
};

export default ObjectPartyForm;
