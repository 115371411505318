import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { Translate } from 'react-redux-i18n';

export interface ModalPopupProps {
  message: string | JSX.Element;
  closeHandler: () => void;
  closeText?: string | JSX.Element | undefined;
  hideCloseButton?: boolean;
}

export const ModalPopup: React.FC<React.PropsWithChildren<ModalPopupProps>> = ({
  message,
  closeHandler,
  closeText,
  hideCloseButton,
}: ModalPopupProps) => {
  ModalPopup.defaultProps = { hideCloseButton: false };
  return (
    <Modal show={true} onHide={closeHandler} centered style={{ zIndex: 1071 }}>
      <Modal.Header className='border-bottom-0' />
      <Modal.Body className='text-center'>
        <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
      </Modal.Body>
      <Modal.Footer className='justify-content-center border-top-0'>
        {hideCloseButton != true && (
          <Button variant='outline-secondary' onClick={closeHandler} data-testid={'closeButton'}>
            {closeText != undefined ? closeText : <Translate value={'ok'} />}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
