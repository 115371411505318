/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiClaimLink,
    ApiClaimLinkFromJSON,
    ApiClaimLinkToJSON,
    ApiClaimLinkResponse,
    ApiClaimLinkResponseFromJSON,
    ApiClaimLinkResponseToJSON,
    ApiLinkValidation,
    ApiLinkValidationFromJSON,
    ApiLinkValidationToJSON,
    ApiServiceLink,
    ApiServiceLinkFromJSON,
    ApiServiceLinkToJSON,
} from '../models';

export interface InitializeClaimRequest {
    apiClaimLink: ApiClaimLink;
}

export interface InitializeClaimFromServiceLinkRequest {
    apiServiceLink: ApiServiceLink;
}

export interface ValidateClaimLinkRequest {
    code: string;
}

/**
 * 
 */
export class ClaimLinkApi extends runtime.BaseAPI {

    /**
     * Initializes a new claim and sends out the link for accessing it
     */
    async initializeClaimRaw(requestParameters: InitializeClaimRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiClaimLinkResponse>> {
        if (requestParameters.apiClaimLink === null || requestParameters.apiClaimLink === undefined) {
            throw new runtime.RequiredError('apiClaimLink','Required parameter requestParameters.apiClaimLink was null or undefined when calling initializeClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claim-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiClaimLinkToJSON(requestParameters.apiClaimLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiClaimLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new claim and sends out the link for accessing it
     */
    async initializeClaim(requestParameters: InitializeClaimRequest, initOverrides?: RequestInit): Promise<ApiClaimLinkResponse> {
        const response = await this.initializeClaimRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initializes a new claim using the service link
     */
    async initializeClaimFromServiceLinkRaw(requestParameters: InitializeClaimFromServiceLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiClaimLinkResponse>> {
        if (requestParameters.apiServiceLink === null || requestParameters.apiServiceLink === undefined) {
            throw new runtime.RequiredError('apiServiceLink','Required parameter requestParameters.apiServiceLink was null or undefined when calling initializeClaimFromServiceLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claim/service-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiServiceLinkToJSON(requestParameters.apiServiceLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiClaimLinkResponseFromJSON(jsonValue));
    }

    /**
     * Initializes a new claim using the service link
     */
    async initializeClaimFromServiceLink(requestParameters: InitializeClaimFromServiceLinkRequest, initOverrides?: RequestInit): Promise<ApiClaimLinkResponse> {
        const response = await this.initializeClaimFromServiceLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * validate claim link
     */
    async validateClaimLinkRaw(requestParameters: ValidateClaimLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiLinkValidation>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling validateClaimLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claim-link/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiLinkValidationFromJSON(jsonValue));
    }

    /**
     * validate claim link
     */
    async validateClaimLink(requestParameters: ValidateClaimLinkRequest, initOverrides?: RequestInit): Promise<ApiLinkValidation> {
        const response = await this.validateClaimLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
