/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAddress
 */
export interface ApiAddress {
    /**
     * 
     * @type {string}
     * @memberof ApiAddress
     */
    address?: string;
}

export function ApiAddressFromJSON(json: any): ApiAddress {
    return ApiAddressFromJSONTyped(json, false);
}

export function ApiAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

export function ApiAddressToJSON(value?: ApiAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
    };
}

