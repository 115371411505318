import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IoIosRemoveCircle } from 'react-icons/io';
import { AiFillCamera, AiOutlineCloudUpload } from 'react-icons/ai';
import { Camera } from './Camera';
import './PhotoList.css';
import { ApiFile } from '../../../api';
import { Translate } from 'react-redux-i18n';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import {
  FULL_HD_HEIGHT,
  FULL_HD_WIDTH,
  HEIC_FORMAT,
  IMAGE_JPEG_EXTENSION,
  resizeImage,
  ResizerOutputFormat,
} from '../../../common/utils/ImageUtils';
import heic2any from 'heic2any';

export interface PhotoListItem {
  raw?: Blob;
  apiFile?: ApiFile;
}

export interface PhotoListProps {
  photos: PhotoListItem[];
  setPhotos: (photos: PhotoListItem[]) => void;
  openTheCamera: boolean;
  themeColor: string;
  deletePhoto: (apiFile: ApiFile) => any;
}

export const PhotoList: React.FC<React.PropsWithChildren<PhotoListProps>> = ({
  photos,
  setPhotos,
  openTheCamera,
  themeColor,
  deletePhoto,
}: PhotoListProps) => {
  const [cameraIsOpen, setCameraIsOpen] = useState(openTheCamera);
  const [inputTrigger, setInputTrigger] = useState<HTMLInputElement | null>();
  const [errorPopup, setErrorPopup] = useState(<></>);
  const openCamera = () => {
    setCameraIsOpen(true);
    cameraOpenNavigationHandling();
  };
  const closeCamera = () => setCameraIsOpen(false);
  const MAX_IMAGE_COUNT = 9;
  const photosUploaded: PhotoListItem[] = [];
  useEffect(() => {
    if (openTheCamera) {
      openCamera();
      cameraOpenNavigationHandling();
    } else {
      closeCamera();
    }
  }, [openTheCamera]);

  const onScreenshotTaken = async (imgBase64?: string) => {
    if (imgBase64) {
      const blobPhoto = await fetch(imgBase64).then((res) => res.blob());
      setPhotos([...photos, { raw: blobPhoto }]);
    }
    closeCamera();
  };
  const removeImage = (index: number) => {
    const removedFiles: PhotoListItem[] = photos.splice(index, 1);
    setPhotos([...photos]);
    removedFiles.forEach((file) => {
      if (file.apiFile != undefined) {
        deletePhoto(file.apiFile);
      }
    });
  };

  const openImageSelector = () => {
    if (inputTrigger) {
      inputTrigger.click();
    }
  };

  const cameraOpenNavigationHandling = () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener(
      'popstate',
      function () {
        setCameraIsOpen(false);
      },
      { once: true }
    );
  };

  const fileUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files)
      if (files.length + photos.length > MAX_IMAGE_COUNT) {
        convertAndPreviewImages(files, MAX_IMAGE_COUNT - photos.length);
        setErrorPopup(
          <ModalPopup
            message={<Translate value='photos.photoUploadLimitReached' number={MAX_IMAGE_COUNT} />}
            closeHandler={() => {
              setErrorPopup(<></>);
            }}
          />
        );
      } else {
        convertAndPreviewImages(files, files.length);
      }
  };

  const convertAndPreviewImages = (files: FileList, iterations: number) => {
    let reader;
    for (let i = 0; i < iterations; i++) {
      reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = async (e) => {
        if (e.target && e.target.result) {
          if (files[i].name.substring(files[i].name.lastIndexOf('.') + 1) != HEIC_FORMAT) {
            const image = new Image();
            image.src = e.target.result as string;
            image.onload = async function () {
              let blobPhoto: Blob = await fetch(image.src.toString()).then((res) => res.blob());
              if (image.height > FULL_HD_HEIGHT || image.width > FULL_HD_WIDTH) {
                const blob = await resizeImage(
                  blobPhoto,
                  image.width > image.height,
                  ResizerOutputFormat.blob
                );
                blobPhoto = blob as Blob;
              }
              photosUploaded.push({ raw: blobPhoto });
              setPhotos([...photos, ...photosUploaded]);
            };
          } else {
            const blobPhoto = await fetch(e.target.result.toString()).then((res) => res.blob());
            photosUploaded.push({
              raw: (await heic2any({
                blob: blobPhoto,
                toType: IMAGE_JPEG_EXTENSION,
                quality: 0.5,
              })) as Blob,
            });
          }
          if (photosUploaded.length === iterations) {
            setPhotos([...photos, ...photosUploaded]);
          }
        }
      };
    }
  };

  return (
    <>
      <div className='photo-list'>
        {photos.length > 0 ? (
          <Row>
            {photos.length < MAX_IMAGE_COUNT && (
              <>
                <Col xs={4} md={3} className='mb-4'>
                  <Container className='my-auto text-center add-more'>
                    <AiFillCamera
                      className='add-more-icon'
                      size={50}
                      onClick={openCamera}
                      style={{ color: themeColor }}
                    />
                  </Container>
                </Col>
                <Col xs={4} md={3} className='mb-4'>
                  <Container className='my-auto text-center add-more'>
                    <AiOutlineCloudUpload
                      className='add-more-icon'
                      size={50}
                      onClick={openImageSelector}
                      style={{ color: themeColor }}
                    />
                  </Container>
                </Col>
              </>
            )}
            {photos.map((photoFile: PhotoListItem, index: number) => {
              const imageUrl = photoFile.apiFile
                ? photoFile.apiFile.url
                : photoFile.raw
                ? URL.createObjectURL(photoFile.raw)
                : '';

              return (
                <Col xs={4} md={3} key={index} className='mb-4'>
                  <Container
                    className='my-auto thumbnail'
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                    data-testid={`img-ctnr-${index}`}
                  >
                    <img src={imageUrl} alt='photo' width='100%' />
                    <IoIosRemoveCircle
                      className='remove'
                      size={26}
                      onClick={() => removeImage(index)}
                      style={{ color: themeColor }}
                      data-testid='delete-icon'
                    />
                  </Container>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row data-testid='no-images-section'>
            <div className='text-center col-6 mb-2'>
              <div className='text-center' onClick={openCamera}>
                <AiFillCamera size={50} style={{ color: themeColor }} />
                <div className='add-photo-btn'>
                  <Translate value='photos.takePhotos' />
                </div>
              </div>
            </div>
            <div className='text-center  col-6 mb-2'>
              <div className='text-center' onClick={openImageSelector}>
                <AiOutlineCloudUpload size={50} style={{ color: themeColor }} />
                <div className='add-photo-btn'>
                  <Translate value='photos.uploadFromGallery' />
                </div>
              </div>
            </div>
          </Row>
        )}
        <input
          data-testid='file-input'
          type='file'
          multiple
          ref={(ip) => setInputTrigger(ip)}
          onChange={(file) => fileUploaded(file)}
          style={{ display: 'none' }}
          accept='image/*, .heic, .heif'
        />
      </div>
      {cameraIsOpen && <Camera onScreenshotCallback={onScreenshotTaken} />}
      {errorPopup}
    </>
  );
};
