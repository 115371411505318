import { ApiAssistanceIncidentType, ApiClaimIncidentType } from '../../api';
import { LanguageType } from './LanguageType';

export const lt: LanguageType = {
  ok: 'Gerai',
  close: 'UŽDARYTI',
  next: 'KITAS',
  name: 'Vardas ir pavardė',
  submit: 'PATEIKTI',
  refresh: 'Atnaujinti',
  phoneNumber: 'Telefono numeris',
  selectCounty: 'Pasirinkite apskritį',
  all: 'Visi',
  enterVehicleNumber: 'Įveskite transporto priemonės vasltybinį numerį',
  enterYourName: 'Įveskite savo vardą ir pavardę',
  enterYourPhoneNumber: 'Įveskite savo telefono numerį',
  refreshPageToSeeUpdates:
    'Atnaujinome puslapį. Atnaujinkite puslapį, kad pamatytumėte naujausius pakeitimus.',
  orderHelp: 'PAGALBOS UŽSAKYMAS',
  fileAClaimDescription: 'Draudžiamojo įvykio atveju prašome pateikti paraišką čia arba BALTA APP!',
  fileClaim: 'PARAIŠKOS PATEIKIMAS',
  assistanceIncident: {
    WHAT_IS_THE_ISSUE: 'Kokia problema?',
    [ApiAssistanceIncidentType.EmptyBattery]: 'Išsikrovė akumuliatorius',
    [ApiAssistanceIncidentType.RunOutOfFuel]: 'Baigėsi degalai',
    [ApiAssistanceIncidentType.FlatTires]: 'Nuleido padangą',
    [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Įstrigo smėlyje/sniege/purve',
    [ApiAssistanceIncidentType.DoorLocked]: 'Užsitrenkė durelės',
    [ApiAssistanceIncidentType.UnknownTechnicalIssue]: 'Nežinomas techninis gedimas',
    [ApiAssistanceIncidentType.TrafficAccident]: 'Eismo įvykis',
    [ApiAssistanceIncidentType.Other]: 'Kita',
  },
  claimIncident: {
    WHAT_IS_THE_ISSUE: 'Kokia problema?',
    [ApiClaimIncidentType.WindshieldDamage]: 'Pažeistas priekinis stiklas',
    [ApiClaimIncidentType.TrafficAccident]: 'Automobilio avarija',
    [ApiClaimIncidentType.HitWildAnimal]: 'Partrenktas laukinis gyvūnas',
    [ApiClaimIncidentType.TechnicalBreakdown]: 'Techninis gedimas',
    [ApiClaimIncidentType.Theft]: 'Vagystė',
    [ApiClaimIncidentType.VandalismOrFire]: 'Vandalizmas arba gaisras',
    [ApiClaimIncidentType.Other]: 'Kita',
    invalidClaim: 'Netinkamas užsakymas!',
  },
  vehicleDetails: {
    mark: 'Markė',
    model: 'Modelis',
    year: 'Metai',
    error: 'Norint gauti pagalbą skambinkite operatoriui.',
    callOperator: 'Skambučių',
    missingLicensePlate: 'Nurodykite transporto priemonės valstybinį numerį',
    invalidLicensePlate: 'Leidžiami tik skaitmenys ir raidės.',
    missingName: 'Nurodykite savo vardą ir pavardę',
    missingPhoneNumber: 'Nurodykite savo telefono numerį',
    requiredField: 'Privalomas laukelis!',
    invalidPhoneNumber: 'Numerį gali sudaryti, tik skaičiai',
  },
  findTowTruck: {
    message1: 'Pranešimas operatoriams',
    message2: 'Vilkiko vairuotojo suradimas trunka ne ilgiau kaip 2 min.\n',
    message3: 'Geografinės tvoros nustatymas',
    message4: 'Netoliese esančių vilkikų paieška',
    message5: 'Surasti netoliese esantys vilkikai',
    message6: 'Informacijos apie įvykį perdavimas vilkikų vairuotojams',
    message7: 'Vilkikų vairuotojai vertina pateiktus duomenis',
    message8: 'Laukia, kol vilkikų vairuotojai sureaguos',
  },
  waitForTowTruck: {
    title: 'Pagalba jau pakeliui!',
    driverComing: 'atskuba į pagalbą',
    callDriver: 'Skambinti vairuotojui',
    driverShouldBeThere: 'Vairuotojo turėtų pasirodyti neturkus',
    eta: 'Numatomas laukimo laikas',
  },
  noTowTruckFound: {
    title: 'Apgailestaujame! Šiuo metu nėra laisvų vilkikų',
    details: 'Mūsų operatoriai buvo informuoti ir paskambins jums, kai tik atsilaisvins vilkikas',
  },
  towTruckJobFinished: {
    title: 'Darbas yra baigtas',
  },
  map: {
    searchHint: 'Ieškoti',
    error: 'Nepavyko pasiekti serverio. Patikrinkite tinklo ryšį.',
    pin: 'Tempkite žemėlapį, kad pasirinktumėt vietovę',
    pleaseSetPinToYourLocation: 'Prašome pažymėti savo buvimo vietą',
    unableToRetrieveLocation: 'Vietos nepavyko rasti. Raskite vietą žemėlapyje.',
    confirmLocation: {
      modalTitle: 'Patvirtinti adresą',
      modalBody: 'Patvirtinkite, kad adresas yra <b>%{address}</b>.',
      confirm: 'Patvirtinkite',
      cancel: 'Atšaukti',
    },
    failedToRetrieveAddress: 'Nepavyko gauti adreso. Bandykite dar kartą.',
    failedToSaveLocation: 'Nepavyko išsaugoti vietos. Bandykite dar kartą.',
  },
  countdown: {
    minutes: 'Minutės',
    seconds: 'sekundės',
  },
  errors: {
    noVehicleFound: 'Transporto priemonės nerasta',
    tryAgain: 'Atsiprašome, kažkas nepavyko. Bandykite dar kartą vėliau.',
    networkProblem:
      'Aptiktas tinklo sutrikimas. Įsitikinkite, kad ryšys veikia, ir bandykite dar kartą.',
    failedToUpload:
      'Nepavyko įkelti vaizdų. Bandykite dar kartą arba pašalinkite nuotraukas, kad galėtumėte tęsti.',
    savingAccidentDetailsFailed: 'Nepavyko išsaugoti eismo įvykio duomenų. Bandykite dar kartą.',
    claimNotFound: 'Užsakymas nerastas.',
    savingPreferencesFailed: 'Nepavyko išsaugoti bendravimo nuostatų. Bandykite dar kartą.',
    claimSubmissionFailed: 'Nepavyko pateikti prašymo. Bandykite dar kartą.',
  },
  additionalDetails: {
    addPhotos: 'Spustelėkite ir pridėkite nuotraukas',
    requiredField: 'Privalomas laukelis!',
    descriptionLabel: 'Aprašymas',
    pictures: 'Nuotraukos (neprivaloma)',
    email: 'El. paštas (neprivaloma)',
    invalidEmail: 'Netinkamas el. pašto formatas!',
    descriptionPlaceholder: {
      [ApiAssistanceIncidentType.EmptyBattery]:
        'Apibūdinkite situaciją. Kiek laiko transporto priemonė stovėjo? Ar transporto priemonė stovi garaže ar požeminėje stovėjimo aikštelėje?',
      [ApiAssistanceIncidentType.RunOutOfFuel]: 'Apibūdinkite situaciją.',
      [ApiAssistanceIncidentType.FlatTires]:
        'Apibūdinkite situaciją. Kuri padanga nuleista? Ar padanga pažeista iš šono?',
      [ApiAssistanceIncidentType.DoorLocked]:
        'Apibūdinkite situaciją. Kur yra automobilio rakteliai? Kur yra atsarginiai rakteliai?',
      [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Apibūdinkite situaciją.',
      [ApiAssistanceIncidentType.UnknownTechnicalIssue]:
        'Aprašykite problemą. Ar įmanoma užvesti transporto priemonę? Ar galima įjungti neutralią pavarų dėžę? Ar galima išjungti rankinį stabdį? Ar sukasi vairas?',
      [ApiAssistanceIncidentType.TrafficAccident]:
        'Aprašykite nelaimingą atsitikimą. Ar transporto priemonė yra tinkama vairuoti? Ar yra kitų eismo įvykio dalyvių? Ar buvo informuota policija?',
      [ApiAssistanceIncidentType.Other]: 'Apibūdinkite situaciją.',
    },
  },
  landing: {
    welcome: 'Sveiki',
    callYourInsuranceCompanyToGetLink:
      'Paskambinkite savo draudimo bendrovei, kad gautumėte SMS su nuoroda į formą.',
    openTheLinkAgain: 'Jeigu gavote SMS žinutę, pakartotinai paspauskite ant nuorodos.',
  },
  linkValidation: {
    linkValidationFailed: 'Nepavyko patvirtinti nuorodos',
    linkHasExpired: 'Nuoroda nebegalioja',
    linkAlreadyProcessed: 'Nuoroda jau buvo apdorota',
    invalidLink: 'Neteisinga nuoroda',
    sorrySomethingWentWrong: 'Atsiprašom, kažkas nutiko',
    callToGetNewLink: 'Skambinkite, kad gautumėt SMS su nauja nuoroda',
  },
  trackDriver: {
    warning: 'DĖMESIO',
    towtruckDriverChanged: 'Jūsų atvejį tvarkys kitas vairuotojo!',
  },
  otherParties: {
    delete: 'Ištrinti',
    missingValue: 'Neužpildyti laukeliai',
    title: 'Kiti įvykio dalyviai',
    otherPartiesInvolved: 'Ar buvo įtraukti kiti dalyviai?',
    otherPartiesInfo: 'Ar žinote kitų dalyvių duomenis?',
    otherPartiesType: 'Pasirinkite šalies tipą',
    vehiclePartyType: 'Ar tai transporto priemonė?',
    objectPartyType: 'Ar tai objektas?',
    vehicleParty: {
      number: 'Kitos šalies transporto priemonės numeris',
      name: 'Kontaktinio asmens vardas (neprivaloma)',
      personalCode: 'Asmens kodas (neprivaloma)',
      phoneNumber: 'Telefono numeris (neprivaloma)',
      email: 'El. paštas (neprivaloma)',
    },
    objectParty: {
      description: 'Apibūdinkite objektą',
      name: 'Kontaktinio asmens vardas (neprivaloma)',
      personalCode: 'Asmens kodas (neprivaloma)',
      phoneNumber: 'Telefono numeris (neprivaloma)',
      email: 'El. paštas (neprivaloma)',
    },
    addAnotherParty: 'Pridėti dar vieną dalyvį',
    yes: 'TAIP',
    no: 'NE',
    invalidPartyPresentErrorMessage:
      'Kai kuriuose languose trūksta informacijos. Užpildykite juos arba ištrinkite papildomus langus.',
  },
  notifierInfo: {
    title: 'INFORMACIJA APIE PRANEŠĖJĄ',
    driver: 'Vairuotojo',
    name: 'Vardas ir pavardė',
    personalCode: 'Asmens kodas (neprivaloma)',
    telephone: 'Telefono numeris',
    email: 'El. paštas',
    confirmUserIsDriver: 'Ar jūs vairavote transporto priemonę eismo įvykio metu?',
    notifyUserTofillDriverDetails: '(Jei ne, pateikite informaciją apie vairuotoją)',
    affirm: 'TAIP',
    deny: 'NE',
    errors: {
      name: 'Būtina nurodyti vardą ir pavardę!',
      telephone: 'Telefono numerį gali sudaryti tik skaitmenys ir jis negali būti tuščias!',
      email: 'El. pašto adresas turi būti galiojantis!',
      personalCode: 'Asmens kodas turi būti teisingo formato!',
    },
  },
  insuranceSelection: {
    title: 'Draudimas',
    subtitle: 'Mes išsiųsime pretenziją draudikui',
    cascoSelect: 'Pasirinkite KASKO draudimą',
    mtplSelect: 'Pasirinkite TPVCA draudimą',
    CascoNoneApplicable: 'Nėra KASKO draudimo',
    MtplNotSure: 'Nežinau',
    mandatorySelectionError: 'Pasirinkimas yra privalomas!',
    casco: 'KASKO',
    mtpl: 'TPVCA',
  },
  accidentDetails: {
    title: 'Išsami informacija apie įvykį',
    affirmation: 'TAIP',
    deny: 'NE',
    dateTime: 'Įveskite avarijos datą ir laiką',
    location: 'Įvykio vieta',
    description: 'Įvykio aprašymas',
    culprit: {
      responsibleParty: 'Dėl kieno kaltės įvyko nelaimingas atsitikimas?',
      notifier: '%{licensePlate} yra kaltininkas',
      otherParty: 'Dėl kito dalyvio kaltės',
      unknown: 'Nežinoma',
    },
    dateError: 'Būtina nurodyti datą ir laiką!',
    mandatoryLocation: 'Vieta yra privaloma',
    authoritiesNotified: 'Ar buvo informuotos atitinkamos institucijos?',
    personalInjury: 'Ar patyrėte sužalojimų?',
    accidentDateIsMandatory: 'Būtina nurodyti įvykio datą!',
    accidentTimeIsMandatory: 'Būtina nurodyti įvykio laiką!',
    accidentDescriptionIsMandatory: 'Būtina nurodyti įvykio aprašymą!',
  },
  claimReceived: {
    title: 'PRANEŠIMAS GAUTAS',
    body: `Jūsų įvykis sėkmingai užregistruotas. Jei kils klausimų ar bus atnaujinimų, 
      susisieksime su jumis tiesiogiai. Galite uždaryti šį puslapį.`,
  },
  communicationPreference: {
    title: 'Pageidautinas susisiekimo būdas',
    email: 'EL. PAŠTAS',
    sms: 'SMS',
    error: 'Būtina pasirinkti bent vieną pageidautiną susisiekimo būdą!',
    submit: 'PATEIKTI',
  },
  damageLocation: {
    title: 'Pažeidimo vieta',
  },
  photos: {
    title: 'ĮKELTI NUOTRAUKAS',
    skipForNow: 'LAIKINAI PRALEISTI',
    skipStep: 'PRALEISTI ŽINGSNĮ',
    takePhotos: 'FOTOGRAFUOTI',
    uploadFromGallery: 'ĮKELTI IŠ GALERIJOS',
    photoUploadLimitReached:
      'Iš viso %{number} vaizdų galima pridėti prie šios formos. Likusi dalis buvo išmesta',
    skipTitle: `Netrukus praleisite žingsnį „įkelti nuotraukas“ Nuotraukos yra 
      svarbios paraiškos teikimui. Nepamirškite jų pridėti vėliau.`,
    turnYourPhoneHorizontally: 'Pasukite telefoną horizontaliai',
    takeAPhotoOfDamage: 'Nufotografuokite žalą',
    uploadSuggestions: {
      generalViewOfTheVehicle:
        'Transporto priemonės bendro vaizdo nuotrauka su registracijos numeriu',
      damagePicture: 'Apgadinimų nuotraukos (iš toliau ir iš arčiau)',
      availableDocuments: 'Pranešimas apie eismo įvykį arba kitas rašytinis dokumentas (jei yra)',
      driversLicense: 'Vairuotojo pažymėjimo nuotrauka',
      scenePicture: 'Įvykio vietos nuotraukos (jei yra)',
    },
  },
  partnerLanding: {
    title: 'Kaip galime padėti?',
    fileAClaim: 'Pateikti paraišką',
    roadsideAssistance: 'Pagalba kelyje',
  },
  caseStatus: {
    title: 'Bylos statusas',
    registration: 'Registracijos Nr.',
    steps: {
      caseRegistered: 'Užregistruotas įvykis',
      caseRegisteredDone: 'Užregistruotas įvykis',
      vehicleDeliveredToWorkshop: 'Į dirbtuves pristatyta transporto priemonė',
      vehicleDeliveredToWorkshopDone: 'Į dirbtuves pristatyta transporto priemonė',
      estimatedDiagnostics: 'Numatoma diagnostika',
      estimatedDiagnosticsDone: 'Atlikta diagnostika',
      preparingRepairEstimate: 'Remonto sąmatos rengimas',
      preparingRepairEstimateDone: 'Sudaryta remonto sąmata',
      confirmingRepairEstimate: 'Remonto sąmatos patvirtinimas',
      confirmingRepairEstimateDone: 'Patvirtinta remonto sąmata',
      estimatedArrivalOfSpareParts: 'Numatomas atsarginių dalių atvežimas',
      estimatedArrivalOfSparePartsDone: 'Atvyko atsarginės dalys',
      repairingStarts: 'Remonto pradžia',
      repairingStartsDone: 'Pradėtas remontas',
      estimatedEndOfRepair: 'Numatoma remonto pabaiga',
      estimatedEndOfRepairDone: 'Remontas baigtas',
      pickUpVehicle: 'Prašome pasiimti transporto priemonę',
      pickUpVehicleDone: 'Transporto priemonės paėmimas',
    },
    nextStep: 'Jūsų byla yra tvarkoma. Kitas žingsnis',
    workshopDetails: 'Autoserviso informacija',
    errors: {
      invalidLink: 'Negaliojanti nuoroda',
      caseNotFound: 'Byla nerasta',
    },
  },
  terms: {
    agreement: 'Sutinku su sąlygomis ir privatumo politika',
    termsAndPrivacy: 'Sąlygos ir privatumo politika',
    error: 'Privalote sutikti su sąlygomis ir privatumo politika',
    termsTitle: 'BENDROSIOS PASLAUGŲ TEIKIMO SĄLYGOS',
    privacyTitle: 'PRIVATUMO POLITIKA',
  },
  workshop: {
    choose: 'PASIRINKTI AUTOSERVISĄ',
    hint: '<h6><b>Pasirinkite seminarą spustelėdami <img src = %{img} width="20px" height="20px" /> žemėlapyje</b></h6>',
  },
  feedback: {
    title: 'Jūsų atsiliepimas mums labai svarbus!',
    subText: 'Praneškite mums, kokia buvo jūsų bendra patirtis.',
    emptyRatingText: 'Įvertinkite savo patirtį.',
    commentLabel: 'Kitos pastabos (neprivaloma)',
    expired: 'Nuorodos galiojimo laikas baigėsi!',
    notFound: 'Nuoroda nebegalioja!',
    alreadyGiven: 'Jūs jau pateikėte atsiliepimus!',
    success: 'Ačiū! Jūsų atsiliepimas sėkmingai apdorotas!',
    submissionError: 'Nepavyko pateikti atsiliepimo! Prašome pabandyti dar kartą vėliau.',
  },
  assistanceLanding: {
    title: 'Užsisakykite pagalbą kelyje',
    description:
      '<p>Pagalbos kelyje užsakymas paspaudus mygtuką START yra <b>75 % greitesnis</b> nei pokalbis su operatoriumi.<br><br> Tai paprastas 4 žingsnių procesas, trunkantis iki 2 minučių. Jūsų buvimo vieta nustatoma naudojant GPS.</p>',
    start: 'STARTAS',
  },
};
