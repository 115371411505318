/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiServiceLink
 */
export interface ApiServiceLink {
    /**
     * 
     * @type {string}
     * @memberof ApiServiceLink
     */
    name: string;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiServiceLink
     */
    language?: ApiLanguage;
}

export function ApiServiceLinkFromJSON(json: any): ApiServiceLink {
    return ApiServiceLinkFromJSONTyped(json, false);
}

export function ApiServiceLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiServiceLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'language': !exists(json, 'language') ? undefined : ApiLanguageFromJSON(json['language']),
    };
}

export function ApiServiceLinkToJSON(value?: ApiServiceLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'language': ApiLanguageToJSON(value.language),
    };
}

