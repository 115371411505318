import React from 'react';
import {
  NumberInputWithCountryCode,
  NumberInputWithCountryCodeProps,
} from './NumberInputWithCountryCode';

// eslint-disable-next-line react/display-name
export const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  Omit<NumberInputWithCountryCodeProps, 'type'>
>((props: Omit<NumberInputWithCountryCodeProps, 'type'>, ref: any) => {
  return <NumberInputWithCountryCode {...props} type='text' ref={ref} />;
});
