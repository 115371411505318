/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiClaimIncidentType,
    ApiClaimIncidentTypeFromJSON,
    ApiClaimIncidentTypeFromJSONTyped,
    ApiClaimIncidentTypeToJSON,
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
    ApiLocation,
    ApiLocationFromJSON,
    ApiLocationFromJSONTyped,
    ApiLocationToJSON,
    ApiNotifier,
    ApiNotifierFromJSON,
    ApiNotifierFromJSONTyped,
    ApiNotifierToJSON,
    ApiParties,
    ApiPartiesFromJSON,
    ApiPartiesFromJSONTyped,
    ApiPartiesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiClaimLink
 */
export interface ApiClaimLink {
    /**
     * 
     * @type {number}
     * @memberof ApiClaimLink
     */
    serviceId: number;
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiClaimLink
     */
    notifier: ApiNotifier;
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiClaimLink
     */
    driver?: ApiNotifier;
    /**
     * 
     * @type {string}
     * @memberof ApiClaimLink
     */
    vehiclePlateNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiClaimLink
     */
    address?: string;
    /**
     * 
     * @type {ApiLocation}
     * @memberof ApiClaimLink
     */
    location?: ApiLocation;
    /**
     * 
     * @type {number}
     * @memberof ApiClaimLink
     */
    accidentTimeMs?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiClaimLink
     */
    authoritiesNotified?: boolean;
    /**
     * 
     * @type {ApiClaimIncidentType}
     * @memberof ApiClaimLink
     */
    incident?: ApiClaimIncidentType;
    /**
     * 
     * @type {ApiParties}
     * @memberof ApiClaimLink
     */
    otherParties?: ApiParties;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiClaimLink
     */
    language?: ApiLanguage;
    /**
     * 
     * @type {boolean}
     * @memberof ApiClaimLink
     */
    sendSms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiClaimLink
     */
    injured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiClaimLink
     */
    description?: string;
}

export function ApiClaimLinkFromJSON(json: any): ApiClaimLink {
    return ApiClaimLinkFromJSONTyped(json, false);
}

export function ApiClaimLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceId': json['serviceId'],
        'notifier': ApiNotifierFromJSON(json['notifier']),
        'driver': !exists(json, 'driver') ? undefined : ApiNotifierFromJSON(json['driver']),
        'vehiclePlateNumber': !exists(json, 'vehiclePlateNumber') ? undefined : json['vehiclePlateNumber'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'accidentTimeMs': !exists(json, 'accidentTimeMs') ? undefined : json['accidentTimeMs'],
        'authoritiesNotified': !exists(json, 'authoritiesNotified') ? undefined : json['authoritiesNotified'],
        'incident': !exists(json, 'incident') ? undefined : ApiClaimIncidentTypeFromJSON(json['incident']),
        'otherParties': !exists(json, 'otherParties') ? undefined : ApiPartiesFromJSON(json['otherParties']),
        'language': !exists(json, 'language') ? undefined : ApiLanguageFromJSON(json['language']),
        'sendSms': !exists(json, 'sendSms') ? undefined : json['sendSms'],
        'injured': !exists(json, 'injured') ? undefined : json['injured'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ApiClaimLinkToJSON(value?: ApiClaimLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceId': value.serviceId,
        'notifier': ApiNotifierToJSON(value.notifier),
        'driver': ApiNotifierToJSON(value.driver),
        'vehiclePlateNumber': value.vehiclePlateNumber,
        'address': value.address,
        'location': ApiLocationToJSON(value.location),
        'accidentTimeMs': value.accidentTimeMs,
        'authoritiesNotified': value.authoritiesNotified,
        'incident': ApiClaimIncidentTypeToJSON(value.incident),
        'otherParties': ApiPartiesToJSON(value.otherParties),
        'language': ApiLanguageToJSON(value.language),
        'sendSms': value.sendSms,
        'injured': value.injured,
        'description': value.description,
    };
}

