/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiService,
    ApiServiceFromJSON,
    ApiServiceToJSON,
    FeedbackLinkCaseType,
    FeedbackLinkCaseTypeFromJSON,
    FeedbackLinkCaseTypeToJSON,
} from '../models';

export interface GetServiceDetailsByAssistanceIdRequest {
    id: number;
}

export interface GetServiceDetailsByClaimIdRequest {
    id: string;
}

export interface GetServiceDetailsByFeedbackUuidAndCaseTypeRequest {
    uuid: string;
    caseType: FeedbackLinkCaseType;
}

export interface GetServiceDetailsByServiceRequest {
    serviceName: string;
}

export interface GetServiceDetailsByServiceExternalIdRequest {
    serviceExternalId: number;
}

/**
 * 
 */
export class ServiceThemeApi extends runtime.BaseAPI {

    /**
     * returns the service details for an assistance request
     */
    async getServiceDetailsByAssistanceIdRaw(requestParameters: GetServiceDetailsByAssistanceIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceDetailsByAssistanceId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{id}/service-theme`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * returns the service details for an assistance request
     */
    async getServiceDetailsByAssistanceId(requestParameters: GetServiceDetailsByAssistanceIdRequest, initOverrides?: RequestInit): Promise<ApiService> {
        const response = await this.getServiceDetailsByAssistanceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns the service theme for a claim
     */
    async getServiceDetailsByClaimIdRaw(requestParameters: GetServiceDetailsByClaimIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceDetailsByClaimId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{id}/service-theme`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * returns the service theme for a claim
     */
    async getServiceDetailsByClaimId(requestParameters: GetServiceDetailsByClaimIdRequest, initOverrides?: RequestInit): Promise<ApiService> {
        const response = await this.getServiceDetailsByClaimIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns the service theme associated with the uuid
     */
    async getServiceDetailsByFeedbackUuidAndCaseTypeRaw(requestParameters: GetServiceDetailsByFeedbackUuidAndCaseTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getServiceDetailsByFeedbackUuidAndCaseType.');
        }

        if (requestParameters.caseType === null || requestParameters.caseType === undefined) {
            throw new runtime.RequiredError('caseType','Required parameter requestParameters.caseType was null or undefined when calling getServiceDetailsByFeedbackUuidAndCaseType.');
        }

        const queryParameters: any = {};

        if (requestParameters.caseType !== undefined) {
            queryParameters['caseType'] = requestParameters.caseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedbacks/{uuid}/service-theme`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * returns the service theme associated with the uuid
     */
    async getServiceDetailsByFeedbackUuidAndCaseType(requestParameters: GetServiceDetailsByFeedbackUuidAndCaseTypeRequest, initOverrides?: RequestInit): Promise<ApiService> {
        const response = await this.getServiceDetailsByFeedbackUuidAndCaseTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns the service theme by service name
     */
    async getServiceDetailsByServiceRaw(requestParameters: GetServiceDetailsByServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling getServiceDetailsByService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/theme/{serviceName}`.replace(`{${"serviceName"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * returns the service theme by service name
     */
    async getServiceDetailsByService(requestParameters: GetServiceDetailsByServiceRequest, initOverrides?: RequestInit): Promise<ApiService> {
        const response = await this.getServiceDetailsByServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns the service theme by service external id
     */
    async getServiceDetailsByServiceExternalIdRaw(requestParameters: GetServiceDetailsByServiceExternalIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.serviceExternalId === null || requestParameters.serviceExternalId === undefined) {
            throw new runtime.RequiredError('serviceExternalId','Required parameter requestParameters.serviceExternalId was null or undefined when calling getServiceDetailsByServiceExternalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/{serviceExternalId}`.replace(`{${"serviceExternalId"}}`, encodeURIComponent(String(requestParameters.serviceExternalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * returns the service theme by service external id
     */
    async getServiceDetailsByServiceExternalId(requestParameters: GetServiceDetailsByServiceExternalIdRequest, initOverrides?: RequestInit): Promise<ApiService> {
        const response = await this.getServiceDetailsByServiceExternalIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
