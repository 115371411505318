/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiPerson,
    ApiPersonFromJSON,
    ApiPersonFromJSONTyped,
    ApiPersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiVehicleParty
 */
export interface ApiVehicleParty {
    /**
     * 
     * @type {number}
     * @memberof ApiVehicleParty
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleParty
     */
    licensePlate: string;
    /**
     * 
     * @type {ApiPerson}
     * @memberof ApiVehicleParty
     */
    person?: ApiPerson;
}

export function ApiVehiclePartyFromJSON(json: any): ApiVehicleParty {
    return ApiVehiclePartyFromJSONTyped(json, false);
}

export function ApiVehiclePartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVehicleParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'licensePlate': json['licensePlate'],
        'person': !exists(json, 'person') ? undefined : ApiPersonFromJSON(json['person']),
    };
}

export function ApiVehiclePartyToJSON(value?: ApiVehicleParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'licensePlate': value.licensePlate,
        'person': ApiPersonToJSON(value.person),
    };
}

