import { ApiAccidentDetails } from '../../../api/models';
import { Dispatch } from 'redux';
import { claimApi } from '../../../apis';
import { setAccidentDetails } from './accidentDetailsReducer';

export const saveAccidentDetailsAction =
  (claimId: string, apiAccidentDetails: ApiAccidentDetails) =>
  async (dispatch: Dispatch): Promise<void> => {
    await claimApi.createAccidentDetails({
      id: claimId,
      apiAccidentDetails: apiAccidentDetails,
    });
    dispatch(setAccidentDetails(apiAccidentDetails));
  };
