/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiVehicleAndInsuranceVerification,
    ApiVehicleAndInsuranceVerificationFromJSON,
    ApiVehicleAndInsuranceVerificationToJSON,
} from '../models';

export interface GetVehicleAndVerifyInsuranceRequest {
    licensePlate: string;
    requestId: number;
}

/**
 * 
 */
export class VehiclesApi extends runtime.BaseAPI {

    /**
     * returns vehicle details preview && verify insurance coverage
     */
    async getVehicleAndVerifyInsuranceRaw(requestParameters: GetVehicleAndVerifyInsuranceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiVehicleAndInsuranceVerification>> {
        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getVehicleAndVerifyInsurance.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling getVehicleAndVerifyInsurance.');
        }

        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vehicles/{licensePlate}`.replace(`{${"licensePlate"}}`, encodeURIComponent(String(requestParameters.licensePlate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVehicleAndInsuranceVerificationFromJSON(jsonValue));
    }

    /**
     * returns vehicle details preview && verify insurance coverage
     */
    async getVehicleAndVerifyInsurance(requestParameters: GetVehicleAndVerifyInsuranceRequest, initOverrides?: RequestInit): Promise<ApiVehicleAndInsuranceVerification> {
        const response = await this.getVehicleAndVerifyInsuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
