import { countryCodes } from './commonConstants';

export const getCountryCodeFromContact = (
  phonePrefix: string | undefined,
  phone: string,
  fallbackCountryCode: string
): string => {
  let countryCodeToReturn: string | undefined;
  if (phonePrefix && phonePrefix?.trim().length != 0) {
    countryCodeToReturn = Object.keys(countryCodes).find(
      (key) => countryCodes[key] === phonePrefix
    );
  } else {
    countryCodeToReturn = Object.keys(countryCodes).find((key) =>
      phone.startsWith(countryCodes[key])
    );
  }
  return countryCodeToReturn != undefined ? countryCodeToReturn : fallbackCountryCode;
};
