/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiClaimIncidentType {
    WindshieldDamage = 'WINDSHIELD_DAMAGE',
    TrafficAccident = 'TRAFFIC_ACCIDENT',
    HitWildAnimal = 'HIT_WILD_ANIMAL',
    TechnicalBreakdown = 'TECHNICAL_BREAKDOWN',
    Theft = 'THEFT',
    VandalismOrFire = 'VANDALISM_OR_FIRE',
    Other = 'OTHER'
}

export function ApiClaimIncidentTypeFromJSON(json: any): ApiClaimIncidentType {
    return ApiClaimIncidentTypeFromJSONTyped(json, false);
}

export function ApiClaimIncidentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimIncidentType {
    return json as ApiClaimIncidentType;
}

export function ApiClaimIncidentTypeToJSON(value?: ApiClaimIncidentType | null): any {
    return value as any;
}

