import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { AppState } from '../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = ({ claim }: AppState) => ({
  claimId: claim.claimId,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ClaimRouteProps = RouteProps & PropsFromRedux;

const ClaimRoute: React.FC<React.PropsWithChildren<ClaimRouteProps>> = ({
  element,
  claimId,
}: ClaimRouteProps) => {
  if (claimId === undefined) {
    return <Navigate to='/' replace />;
  }

  return <>{element}</>;
};

export default connector(ClaimRoute);
