/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiDirection {
    North = 'NORTH',
    South = 'SOUTH',
    East = 'EAST',
    West = 'WEST'
}

export function ApiDirectionFromJSON(json: any): ApiDirection {
    return ApiDirectionFromJSONTyped(json, false);
}

export function ApiDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDirection {
    return json as ApiDirection;
}

export function ApiDirectionToJSON(value?: ApiDirection | null): any {
    return value as any;
}

