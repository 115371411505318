/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiDriveType {
    FrontWheel = 'FRONT_WHEEL',
    RearWheel = 'REAR_WHEEL',
    FourWheel = 'FOUR_WHEEL',
    Unknown = 'UNKNOWN'
}

export function ApiDriveTypeFromJSON(json: any): ApiDriveType {
    return ApiDriveTypeFromJSONTyped(json, false);
}

export function ApiDriveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDriveType {
    return json as ApiDriveType;
}

export function ApiDriveTypeToJSON(value?: ApiDriveType | null): any {
    return value as any;
}

