import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const Towing: React.FC<React.PropsWithChildren<ThemeProps>> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='57'
        height='40'
        viewBox='0 0 57 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M46.4151 33.9629C45.0123 33.9629 43.8409 34.9287 43.5034 36.227C43.4404 36.4697 43.3962 36.7197 43.3962 36.9818C43.3962 37.2439 43.4404 37.4938 43.5034 37.7365C43.8409 39.0348 45.0123 40.0006 46.4151 40.0006C47.8179 40.0006 48.9893 39.0348 49.3268 37.7365C49.3898 37.4938 49.434 37.2439 49.434 36.9818C49.434 36.7197 49.3898 36.4697 49.3268 36.227C48.9893 34.9287 47.8179 33.9629 46.4151 33.9629Z'
          fill='black'
        />
        <path d='M40.6899 28.6802H55.0944V26.416H42.9541L40.6899 28.6802Z' fill='black' />
        <path
          d='M38.8679 32.4536C40.4421 32.4536 41.8298 33.2618 42.6415 34.4841C43.4532 33.2618 44.8409 32.4536 46.4151 32.4536C48.6545 32.4536 50.5142 34.089 50.8752 36.2272H55.0943V30.1895H38.8679H33.2075H23.7736H18.8679V36.2272H34.4078C34.7688 34.089 36.6285 32.4536 38.8679 32.4536Z'
          fill='black'
        />
        <path
          d='M32.887 17.3585L47.223 2.49484C47.3652 2.34743 47.5613 2.26415 47.7662 2.26415H53.9624H54.9986C54.6633 0.963664 53.4796 0 52.0756 0H44.8203L25.7261 17.3585H31.3209H32.887Z'
          fill='black'
        />
        <path
          d='M1.13208 36.2258H6.10591C6.46692 34.0875 8.32662 32.4522 10.566 32.4522C12.8055 32.4522 14.6652 34.0875 15.0262 36.2258H17.3585V29.4333V16.9805H3.01887C1.35392 16.9805 0 18.3348 0 19.9993V21.1314H10.566C10.9832 21.1314 11.3208 21.4693 11.3208 21.8861V27.5465C11.3208 27.9633 10.9832 28.3012 10.566 28.3012H0V35.0937C0 35.7179 0.507812 36.2258 1.13208 36.2258Z'
          fill='black'
        />
        <path d='M30.8542 26.416H26.1269L24.9949 28.6802H31.9863L30.8542 26.416Z' fill='black' />
        <path d='M30.566 18.8672H26.415V24.9049H30.566V18.8672Z' fill='black' />
        <path
          d='M38.868 33.9629C37.4652 33.9629 36.2938 34.9287 35.9563 36.227C35.8933 36.4697 35.8491 36.7197 35.8491 36.9818C35.8491 37.2439 35.8933 37.4938 35.9563 37.7365C36.2938 39.0348 37.4652 40.0006 38.868 40.0006C40.2708 40.0006 41.4422 39.0348 41.7797 37.7365C41.8427 37.4938 41.8869 37.2439 41.8869 36.9818C41.8869 36.7197 41.8427 36.4697 41.7797 36.227C41.4422 34.9287 40.2708 33.9629 38.868 33.9629Z'
          fill='black'
        />
        <path
          d='M10.566 33.9629C9.16319 33.9629 7.99178 34.9287 7.65431 36.227C7.59125 36.4697 7.54712 36.7197 7.54712 36.9818C7.54712 37.2439 7.59125 37.4938 7.65431 37.7365C7.99178 39.0348 9.16319 40.0006 10.566 40.0006C11.9688 40.0006 13.1402 39.0348 13.4777 37.7365C13.5407 37.4938 13.5849 37.2439 13.5849 36.9818C13.5849 36.7197 13.5407 36.4697 13.4777 36.227C13.1402 34.9287 11.9688 33.9629 10.566 33.9629Z'
          fill='black'
        />
        <path d='M9.81132 22.6406H0V26.7916H9.81132V22.6406Z' fill='black' />
        <path
          d='M54.717 3.77344H53.2076V16.2263C53.2076 16.6431 53.5452 16.981 53.9623 16.981C54.5866 16.981 55.0944 17.4888 55.0944 18.1131C55.0944 18.7373 54.5866 19.2451 53.9623 19.2451C53.338 19.2451 52.8302 18.7373 52.8302 18.1131C52.8302 17.6963 52.4927 17.3583 52.0755 17.3583C51.6584 17.3583 51.3208 17.6963 51.3208 18.1131C51.3208 19.5694 52.5059 20.7546 53.9623 20.7546C55.4187 20.7546 56.6038 19.5694 56.6038 18.1131C56.6038 16.9207 55.8043 15.9219 54.717 15.5947V3.77344Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Towing);
