/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAccidentCulprit,
    ApiAccidentCulpritFromJSON,
    ApiAccidentCulpritFromJSONTyped,
    ApiAccidentCulpritToJSON,
    ApiLocation,
    ApiLocationFromJSON,
    ApiLocationFromJSONTyped,
    ApiLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAccidentDetails
 */
export interface ApiAccidentDetails {
    /**
     * 
     * @type {Date}
     * @memberof ApiAccidentDetails
     */
    accidentDatetime?: Date;
    /**
     * 
     * @type {ApiLocation}
     * @memberof ApiAccidentDetails
     */
    location?: ApiLocation;
    /**
     * 
     * @type {string}
     * @memberof ApiAccidentDetails
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccidentDetails
     */
    description?: string;
    /**
     * 
     * @type {ApiAccidentCulprit}
     * @memberof ApiAccidentDetails
     */
    whoCaused?: ApiAccidentCulprit;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccidentDetails
     */
    authoritiesNotified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccidentDetails
     */
    personalInjury?: boolean;
}

export function ApiAccidentDetailsFromJSON(json: any): ApiAccidentDetails {
    return ApiAccidentDetailsFromJSONTyped(json, false);
}

export function ApiAccidentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAccidentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accidentDatetime': !exists(json, 'accidentDatetime') ? undefined : (new Date(json['accidentDatetime'])),
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'address': !exists(json, 'address') ? undefined : json['address'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'whoCaused': !exists(json, 'whoCaused') ? undefined : ApiAccidentCulpritFromJSON(json['whoCaused']),
        'authoritiesNotified': !exists(json, 'authoritiesNotified') ? undefined : json['authoritiesNotified'],
        'personalInjury': !exists(json, 'personalInjury') ? undefined : json['personalInjury'],
    };
}

export function ApiAccidentDetailsToJSON(value?: ApiAccidentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accidentDatetime': value.accidentDatetime === undefined ? undefined : (value.accidentDatetime.toISOString()),
        'location': ApiLocationToJSON(value.location),
        'address': value.address,
        'description': value.description,
        'whoCaused': ApiAccidentCulpritToJSON(value.whoCaused),
        'authoritiesNotified': value.authoritiesNotified,
        'personalInjury': value.personalInjury,
    };
}

