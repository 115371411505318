import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiFile } from '../../../api/models';

export interface ClaimPhotosState {
  photos: ApiFile[];
}

const initialState: ClaimPhotosState = {
  photos: [],
};

const photosSlice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    savePhoto: (state: ClaimPhotosState, action: PayloadAction<ApiFile>) => {
      state.photos.push(action.payload);
    },
    clearPhotos: (state: ClaimPhotosState) => {
      state.photos = initialState.photos;
    },
    removePhoto: (state: ClaimPhotosState, action: PayloadAction<ApiFile>) => {
      let idx = -1;
      for (let i = 0; i < state.photos.length; ++i) {
        if (isFilesEqual(state.photos[i], action.payload)) {
          idx = i;
          break;
        }
      }
      if (idx >= 0) {
        console.log('Removing ' + JSON.stringify(state.photos[idx]) + ' from state');
        state.photos.splice(idx, 1);
      }
    },
  },
});

const isFilesEqual = (file1: ApiFile, file2: ApiFile) => {
  return file1.id == file2.id && file1.path == file2.path && file1.url == file2.url;
};

const { actions, reducer } = photosSlice;

export const { savePhoto, clearPhotos, removePhoto } = actions;

export default reducer;
