import React, { useEffect } from 'react';
import { RouteProps, useLocation, useNavigate } from 'react-router-dom';
import { AppState } from '../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { AssistanceStorage } from '../common/AssistanceStorage';
import { insurance } from '../common/components/vehicle-details/VehicleDetailsFixture';

const mapStateToProps = ({
  assistanceRequest,
  assistanceIncident,
  map,
  vehicleDetails,
  additionalDetails,
  contactData,
}: AppState) => ({
  incident: assistanceIncident.incident,
  map: map.location,
  vehicle: vehicleDetails,
  email: additionalDetails.email,
  towTruckDriver: assistanceRequest.towTruckDriver,
  submitTime: assistanceRequest.submitTime,
  contactData: contactData,
  assistanceFlowInitialized: assistanceRequest.assistanceFlowInitialized,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AssistanceRouteProps = RouteProps & PropsFromRedux;

const AssistanceRoute: React.FC<React.PropsWithChildren<AssistanceRouteProps>> = ({
  element,
  incident,
  map,
  submitTime,
  contactData,
  assistanceFlowInitialized,
  vehicle,
}: AssistanceRouteProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!AssistanceStorage.hasAssistanceRequest()) navigate('/');
    else if (!incident && !assistanceFlowInitialized) navigate('/assistance/landing');
    else if (
      !vehicle ||
      !vehicle.plateNumber ||
      !vehicle.mark ||
      !insurance.verified ||
      !contactData ||
      !contactData.fullName ||
      !contactData.phonePrefix ||
      !contactData.phoneNumber
    ) {
      navigate('/assistance/vehicle-details');
    } else if (!incident) navigate('/assistance/incidents');
    else if (!map || (map && (!map.lat || !map.lng))) {
      navigate('/assistance/map');
    } else if (!submitTime) navigate('/assistance/additional-details');
    else navigate('/assistance/track-driver');
  }, [location.pathname]);

  return <>{element}</>;
};

export default connector(AssistanceRoute);
