/**
 * Returns given seconds as string in format "hh:mm:ss"
 * @param timeInSeconds
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormattedTime = (timeInSeconds: number) => {
  const s = timeInSeconds % 60;
  timeInSeconds -= s;
  const m = (timeInSeconds / 60) % 60;
  timeInSeconds -= m * 60;
  const h = timeInSeconds / 3600;
  const hh = h < 10 ? `0${h}` : h;
  const mm = m < 10 ? `0${m}` : m;
  const ss = s < 10 ? `0${s}` : s;

  return `${hh}:${mm}:${ss}`;
};

/**
 * Returns date part of given date in format: 'YYYY-MM-DD'
 */
export const getFormattedDate = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0')
  );
};

/**
 * Returns time of given date in format: 'hh24:mi'
 */
export const getFormattedShort24hTime = (date: Date): string => {
  return (
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0')
  );
};
