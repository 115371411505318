/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiAssistanceIncidentType {
    EmptyBattery = 'EMPTY_BATTERY',
    RunOutOfFuel = 'RUN_OUT_OF_FUEL',
    FlatTires = 'FLAT_TIRES',
    DoorLocked = 'DOOR_LOCKED',
    StuckInSandSnowMud = 'STUCK_IN_SAND_SNOW_MUD',
    UnknownTechnicalIssue = 'UNKNOWN_TECHNICAL_ISSUE',
    TrafficAccident = 'TRAFFIC_ACCIDENT',
    Other = 'OTHER'
}

export function ApiAssistanceIncidentTypeFromJSON(json: any): ApiAssistanceIncidentType {
    return ApiAssistanceIncidentTypeFromJSONTyped(json, false);
}

export function ApiAssistanceIncidentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceIncidentType {
    return json as ApiAssistanceIncidentType;
}

export function ApiAssistanceIncidentTypeToJSON(value?: ApiAssistanceIncidentType | null): any {
    return value as any;
}

