/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiClaimData,
    ApiClaimDataFromJSON,
    ApiClaimDataToJSON,
    ApiVehicleAndInsuranceVerification,
    ApiVehicleAndInsuranceVerificationFromJSON,
    ApiVehicleAndInsuranceVerificationToJSON,
    ApiVehicleDetails,
    ApiVehicleDetailsFromJSON,
    ApiVehicleDetailsToJSON,
} from '../models';

export interface CreateVehicleDetailsRequest {
    claimId: string;
    apiVehicleDetails: ApiVehicleDetails;
}

export interface GetVehicleDetailsAndVerifyInsuranceRequest {
    claimId: string;
    licensePlate: string;
}

/**
 * 
 */
export class ClaimVehicleDetailsApi extends runtime.BaseAPI {

    /**
     * creates vehicle details for claims
     */
    async createVehicleDetailsRaw(requestParameters: CreateVehicleDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiClaimData>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createVehicleDetails.');
        }

        if (requestParameters.apiVehicleDetails === null || requestParameters.apiVehicleDetails === undefined) {
            throw new runtime.RequiredError('apiVehicleDetails','Required parameter requestParameters.apiVehicleDetails was null or undefined when calling createVehicleDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/vehicle-details`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVehicleDetailsToJSON(requestParameters.apiVehicleDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiClaimDataFromJSON(jsonValue));
    }

    /**
     * creates vehicle details for claims
     */
    async createVehicleDetails(requestParameters: CreateVehicleDetailsRequest, initOverrides?: RequestInit): Promise<ApiClaimData> {
        const response = await this.createVehicleDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns vehicle details preview && verify insurance coverage
     */
    async getVehicleDetailsAndVerifyInsuranceRaw(requestParameters: GetVehicleDetailsAndVerifyInsuranceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiVehicleAndInsuranceVerification>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getVehicleDetailsAndVerifyInsurance.');
        }

        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getVehicleDetailsAndVerifyInsurance.');
        }

        const queryParameters: any = {};

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['licensePlate'] = requestParameters.licensePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}/vehicle-details`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVehicleAndInsuranceVerificationFromJSON(jsonValue));
    }

    /**
     * returns vehicle details preview && verify insurance coverage
     */
    async getVehicleDetailsAndVerifyInsurance(requestParameters: GetVehicleDetailsAndVerifyInsuranceRequest, initOverrides?: RequestInit): Promise<ApiVehicleAndInsuranceVerification> {
        const response = await this.getVehicleDetailsAndVerifyInsuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
