/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiNotifier,
    ApiNotifierFromJSON,
    ApiNotifierFromJSONTyped,
    ApiNotifierToJSON,
} from './';

/**
 * Notifier Info
 * @export
 * @interface ApiNotifierInfo
 */
export interface ApiNotifierInfo {
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiNotifierInfo
     */
    notifier: ApiNotifier;
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiNotifierInfo
     */
    driver?: ApiNotifier;
    /**
     * 
     * @type {boolean}
     * @memberof ApiNotifierInfo
     */
    isContactDriver?: boolean;
}

export function ApiNotifierInfoFromJSON(json: any): ApiNotifierInfo {
    return ApiNotifierInfoFromJSONTyped(json, false);
}

export function ApiNotifierInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNotifierInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifier': ApiNotifierFromJSON(json['notifier']),
        'driver': !exists(json, 'driver') ? undefined : ApiNotifierFromJSON(json['driver']),
        'isContactDriver': !exists(json, 'isContactDriver') ? undefined : json['isContactDriver'],
    };
}

export function ApiNotifierInfoToJSON(value?: ApiNotifierInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifier': ApiNotifierToJSON(value.notifier),
        'driver': ApiNotifierToJSON(value.driver),
        'isContactDriver': value.isContactDriver,
    };
}

