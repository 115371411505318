/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
    ApiAssistanceRequestAdditionalDetails,
    ApiAssistanceRequestAdditionalDetailsFromJSON,
    ApiAssistanceRequestAdditionalDetailsFromJSONTyped,
    ApiAssistanceRequestAdditionalDetailsToJSON,
    ApiAssistanceRequestStatus,
    ApiAssistanceRequestStatusFromJSON,
    ApiAssistanceRequestStatusFromJSONTyped,
    ApiAssistanceRequestStatusToJSON,
    ApiContact,
    ApiContactFromJSON,
    ApiContactFromJSONTyped,
    ApiContactToJSON,
    ApiLocation,
    ApiLocationFromJSON,
    ApiLocationFromJSONTyped,
    ApiLocationToJSON,
    ApiVehicleDetails,
    ApiVehicleDetailsFromJSON,
    ApiVehicleDetailsFromJSONTyped,
    ApiVehicleDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAssistanceRequestData
 */
export interface ApiAssistanceRequestData {
    /**
     * 
     * @type {ApiAssistanceRequestStatus}
     * @memberof ApiAssistanceRequestData
     */
    status?: ApiAssistanceRequestStatus;
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof ApiAssistanceRequestData
     */
    incidentType?: ApiAssistanceIncidentType;
    /**
     * 
     * @type {ApiLocation}
     * @memberof ApiAssistanceRequestData
     */
    location?: ApiLocation;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiAssistanceRequestData
     */
    contact?: ApiContact;
    /**
     * 
     * @type {ApiVehicleDetails}
     * @memberof ApiAssistanceRequestData
     */
    vehicle?: ApiVehicleDetails;
    /**
     * 
     * @type {ApiAssistanceRequestAdditionalDetails}
     * @memberof ApiAssistanceRequestData
     */
    additionalDetails?: ApiAssistanceRequestAdditionalDetails;
    /**
     * 
     * @type {Date}
     * @memberof ApiAssistanceRequestData
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiAssistanceRequestData
     */
    submitTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestData
     */
    externalId?: string;
}

export function ApiAssistanceRequestDataFromJSON(json: any): ApiAssistanceRequestData {
    return ApiAssistanceRequestDataFromJSONTyped(json, false);
}

export function ApiAssistanceRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ApiAssistanceRequestStatusFromJSON(json['status']),
        'incidentType': !exists(json, 'incidentType') ? undefined : ApiAssistanceIncidentTypeFromJSON(json['incidentType']),
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'contact': !exists(json, 'contact') ? undefined : ApiContactFromJSON(json['contact']),
        'vehicle': !exists(json, 'vehicle') ? undefined : ApiVehicleDetailsFromJSON(json['vehicle']),
        'additionalDetails': !exists(json, 'additionalDetails') ? undefined : ApiAssistanceRequestAdditionalDetailsFromJSON(json['additionalDetails']),
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'submitTime': !exists(json, 'submitTime') ? undefined : (new Date(json['submitTime'])),
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
    };
}

export function ApiAssistanceRequestDataToJSON(value?: ApiAssistanceRequestData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ApiAssistanceRequestStatusToJSON(value.status),
        'incidentType': ApiAssistanceIncidentTypeToJSON(value.incidentType),
        'location': ApiLocationToJSON(value.location),
        'contact': ApiContactToJSON(value.contact),
        'vehicle': ApiVehicleDetailsToJSON(value.vehicle),
        'additionalDetails': ApiAssistanceRequestAdditionalDetailsToJSON(value.additionalDetails),
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'submitTime': value.submitTime === undefined ? undefined : (value.submitTime.toISOString()),
        'id': value.id,
        'externalId': value.externalId,
    };
}

