/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiDriverLocationAndCaseStatus,
    ApiDriverLocationAndCaseStatusFromJSON,
    ApiDriverLocationAndCaseStatusFromJSONTyped,
    ApiDriverLocationAndCaseStatusToJSON,
    ApiTowTruckDriver,
    ApiTowTruckDriverFromJSON,
    ApiTowTruckDriverFromJSONTyped,
    ApiTowTruckDriverToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAssistanceRequestTowTruckDriver
 */
export interface ApiAssistanceRequestTowTruckDriver {
    /**
     * 
     * @type {ApiTowTruckDriver}
     * @memberof ApiAssistanceRequestTowTruckDriver
     */
    driver: ApiTowTruckDriver;
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestTowTruckDriver
     */
    agreedArrivalTime: number;
    /**
     * 
     * @type {ApiDriverLocationAndCaseStatus}
     * @memberof ApiAssistanceRequestTowTruckDriver
     */
    apiDriverLocation?: ApiDriverLocationAndCaseStatus;
}

export function ApiAssistanceRequestTowTruckDriverFromJSON(json: any): ApiAssistanceRequestTowTruckDriver {
    return ApiAssistanceRequestTowTruckDriverFromJSONTyped(json, false);
}

export function ApiAssistanceRequestTowTruckDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestTowTruckDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': ApiTowTruckDriverFromJSON(json['driver']),
        'agreedArrivalTime': json['agreedArrivalTime'],
        'apiDriverLocation': !exists(json, 'apiDriverLocation') ? undefined : ApiDriverLocationAndCaseStatusFromJSON(json['apiDriverLocation']),
    };
}

export function ApiAssistanceRequestTowTruckDriverToJSON(value?: ApiAssistanceRequestTowTruckDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': ApiTowTruckDriverToJSON(value.driver),
        'agreedArrivalTime': value.agreedArrivalTime,
        'apiDriverLocation': ApiDriverLocationAndCaseStatusToJSON(value.apiDriverLocation),
    };
}

