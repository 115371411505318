import { Dispatch } from 'redux';
import { setContactName, setContactPhone, setContactPrefix } from './contactDataReducer';

export interface ContactData {
  fullName: string;
  phonePrefix: string;
  phoneNumber: string;
}

export const saveContactNameAction =
  (name: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setContactName(name));
  };

export const saveContactPrefixAction =
  (prefix: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setContactPrefix(prefix));
  };

export const saveContactPhoneAction =
  (phone: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setContactPhone(phone));
  };
