import { Dispatch } from 'redux';
import {
  addParty,
  Party,
  removeParty,
  resetParties,
  setOtherPartiesInfo,
  setOtherPartiesInvolved,
  setParties,
  setPartyCount,
  setPartyError,
  setRedirect,
} from './otherPartiesReducer';
import {
  ApiPerson,
  ApiObjectParty,
  ApiPartyData,
  ApiVehicleParty,
  ApiOtherPartyDetails,
} from '../../../api';
import { claimApi, otherPartiesApi } from '../../../apis';

export const addPartiesAction =
  (claimId: string, parties: Party[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (parties.length > 0) {
      const vehicleParties: ApiVehicleParty[] = [];
      const objectParties: ApiObjectParty[] = [];

      try {
        parties.map(async (party: Party) => {
          if (party && party.type === 'vehicle') {
            if (party.vehicle && !party.vehicle.id) {
              vehicleParties.push(prepareVehicle(party));
            } else {
              await otherPartiesApi.updateParty({
                claimId,
                apiPartyData: {
                  vehicleParty: prepareVehicle(party),
                  person: preparePerson(party),
                },
              });
            }
          } else if (party && party.type === 'object') {
            if (party.object && !party.object.id) {
              objectParties.push(prepareObject(party));
            } else {
              await otherPartiesApi.updateParty({
                claimId,
                apiPartyData: {
                  objectParty: prepareObject(party),
                  person: preparePerson(party),
                },
              });
            }
          }
        });
        await otherPartiesApi.createParties({
          claimId,
          apiParties: {
            objectParties: objectParties,
            vehicleParties: vehicleParties,
          },
        });

        dispatch(
          addParty({
            id: parties[0].id,
            person: parties[0].person,
            type: parties[0].type,
            vehicle: parties[0].vehicle,
            object: parties[0].object,
          })
        );
      } catch (e) {
        console.log('Something went wrong');
      }
    }
  };

const preparePerson = (party: Party): ApiPerson => ({
  id: party.person?.id,
  fullName: party.person?.fullName,
  email: party.person?.email,
  personalCode: party.person?.personalCode,
  phone: party.person?.phone,
  phonePrefix: party.person?.phonePrefix,
});

const prepareVehicle = (party: Party): ApiVehicleParty => ({
  id: party.vehicle?.id,
  licensePlate: party.vehicle?.licensePlate as string,
  person: preparePerson(party),
});

const prepareObject = (party: Party): ApiObjectParty => ({
  id: party.object?.id,
  description: party.object?.description as string,
  person: preparePerson(party),
});

export const getOtherPartiesAction =
  (claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await otherPartiesApi.getPartiesByClaimId({ claimId });
      dispatch(resetParties());
      dispatch(setPartyCount(0));
      const parties = response.map((item: ApiPartyData) => {
        if (item.vehicleParty) {
          return {
            id: item.vehicleParty.id,
            person: item.person,
            vehicle: item.vehicleParty,
            type: 'vehicle',
            createdTime: item.createdTime,
          } as Party;
        }

        if (item.objectParty) {
          return {
            id: item.objectParty.id,
            person: item.person,
            object: item.objectParty,
            type: 'object',
            createdTime: item.createdTime,
          } as Party;
        }
      });
      dispatch(setParties(parties as Party[]));
    } catch (e) {
      dispatch(resetParties());
    }
  };

export const removePartyAction =
  (claimId: string, partyId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await otherPartiesApi.deleteParty({
        claimId,
        partyId: partyId,
      });
      dispatch(removeParty(partyId));
    } catch (e) {
      console.log(e);
    }
  };

export const setPartyErrorAction =
  (partyId: number, values: Party) =>
  (dispatch: Dispatch): void => {
    dispatch(
      setPartyError({
        partyId,
        values,
      })
    );
  };

export const setPartyCountAction =
  (partyCount: number) =>
  (dispatch: Dispatch): void => {
    dispatch(setPartyCount(partyCount));
  };

export const setRedirectAction =
  (redirect?: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch(setRedirect(redirect));
  };

export const updateClaimOtherPartiesDetailsAction =
  (claimId: string, apiOtherPartyDetails: ApiOtherPartyDetails) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await claimApi.updateClaimOtherPartiesDetails({
        claimId,
        apiOtherPartyDetails,
      });
      dispatch(setOtherPartiesInvolved(apiOtherPartyDetails.otherPartiesInvolved));
      dispatch(setOtherPartiesInfo(apiOtherPartyDetails.otherPartiesInfo));
    } catch (e) {
      dispatch(setOtherPartiesInvolved(false));
      dispatch(setOtherPartiesInfo(false));
    }
  };
