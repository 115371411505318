import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import Layout from '../../../components/layouts/Layout';
import { Container, Form } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { TextInput } from '../../../components/controls/TextInput';
import NextButton from '../../../components/controls/NextButton';
import { connect, ResolveThunks } from 'react-redux';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { ApiClaimIncidentType, ApiContact, ApiNotifier, ApiNotifierInfo } from '../../../api';
import { AppState } from '../../../store/store';
import { yupResolver } from '@hookform/resolvers/yup';
import './NotifierInfo.css';
import { saveNotifierInfo } from './notifierInfoActions';
import * as yup from 'yup';
import {
  driverEmailValidation,
  driverNameValidation,
  driverPhoneValidation,
  emailValidation,
  isDriverValidation,
  nameValidation,
  personalCodeValidation,
  phoneValidation,
} from '../../../common/FormValidations';
import {
  getEmailLabel,
  getEmailValidationError,
  getNameLabel,
  getNameValidationError,
  getPersonalCodeLabel,
  getPersonalCodeValidationError,
  getTelephoneLabel,
  getTelephoneValidationError,
} from './notifierInfoMessages';
import {
  trackClaimNotifierInfoSubmit,
  trackClaimNotifierInfoViewEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import { Radio } from '../../../components/controls/Radio';
import { goToNextPage } from '../../../routing/RouteActions';
import { countryCodes, countryListForCodes } from '../../commonConstants';
import { PhoneNumberInput } from '../../../components/controls/PhoneNumberInput';
import { getCountryCodeFromContact } from '../../commonUtils';

export const NotifierInfo: React.FC<React.PropsWithChildren<NotifierInfoProps>> = ({
  country,
  setNotifierInfoAction,
  notifierInfo,
  claimId,
  color,
  incident,
  goToNextPage,
}: NotifierInfoProps) => {
  const { notifier, driver, isContactDriver } = notifierInfo;
  const isNotifierNull = notifier === null || notifier === undefined;
  const isDriverNull = driver === null || driver === undefined;

  const [errorPopup, setErrorPopup] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const [isDriver, setIsDriver] = useState(isContactDriver);
  const [name, setName] = useState(isNotifierNull ? '' : notifier?.contact.name);
  const [email, setEmail] = useState(isNotifierNull ? '' : notifier?.contact.email);
  const [telephone, setTelephone] = useState(isNotifierNull ? '' : notifier?.contact.phone);
  const [personalCode, setPersonalCode] = useState(isNotifierNull ? '' : notifier?.personalCode);
  const [driverName, setDriverName] = useState(isDriverNull ? '' : driver?.contact.name);
  const [driverEmail, setDriverEmail] = useState(isDriverNull ? '' : driver?.contact.email);
  const [driverTelephone, setDriverTelephone] = useState(isDriverNull ? '' : driver?.contact.phone);
  const [countryCode, setCountryCode] = useState(
    isNotifierNull
      ? country
      : getCountryCodeFromContact(notifier?.contact.phonePrefix, notifier?.contact.phone, country)
  );
  const [driverCountryCode, setDriverCountryCode] = useState(
    isDriverNull
      ? country
      : getCountryCodeFromContact(driver?.contact.phonePrefix, driver?.contact.phone, country)
  );

  const [driverPersonalCode, setDriverPersonalCode] = useState(
    isDriverNull ? '' : driver?.personalCode
  );

  useEffect(() => {
    trackClaimNotifierInfoViewEvent(claimId);
  }, []);

  const methods = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    resolver: yupResolver(
      yup.object().shape({
        name: nameValidation,
        personalCode: personalCodeValidation,
        telephone: phoneValidation,
        email: emailValidation,
        driverName: isDriver != undefined && !isDriver ? driverNameValidation : yup.string(),
        driverPersonalCode:
          isDriver != undefined && !isDriver ? personalCodeValidation : yup.string(),
        driverTelephone: isDriver != undefined && !isDriver ? driverPhoneValidation : yup.string(),
        driverEmail: isDriver != undefined && !isDriver ? driverEmailValidation : yup.string(),
        isDriver:
          incident &&
          incident !== ApiClaimIncidentType.Theft &&
          incident !== ApiClaimIncidentType.VandalismOrFire
            ? isDriverValidation
            : yup.string(),
      })
    ),
    defaultValues: {
      name,
      email,
      telephone: telephone,
      personalCode,
      driverName,
      driverEmail,
      driverTelephone: driverTelephone,
      driverPersonalCode,
      isDriver: isDriver,
    },
  });

  const { register, handleSubmit, errors } = methods;

  enum NotifierType {
    DRIVER,
    NOTIFIER,
  }

  interface NotifierFormFields {
    name: string;
    email: string;
    telephone: string;
    personalCode: string;
    isDriver: boolean;
    driverName: string;
    driverEmail: string;
    driverTelephone: string;
    driverPersonalCode: string;
    countryCode: string;
  }

  const createNotifier = (
    name: string,
    email: string | undefined,
    phone: string,
    personalCode: string,
    countryCode: string
  ): ApiNotifier => {
    const contact: ApiContact = {
      name: name,
      email: email,
      phone: phone,
      phonePrefix: countryCode,
    };
    return {
      contact: contact,
      personalCode: personalCode,
    };
  };

  const createNotifierInfo = (notifierForm: NotifierFormFields): ApiNotifierInfo => {
    const {
      name,
      email,
      telephone,
      personalCode,
      driverName,
      driverEmail,
      driverTelephone,
      driverPersonalCode,
      isDriver,
    } = notifierForm;
    const notifier = createNotifier(
      name.trim(),
      email.trim(),
      telephone,
      personalCode.trimEnd(),
      countryCodes[countryCode]
    );
    let driver = undefined;

    if (isDriver != undefined && !isDriver) {
      driver = createNotifier(
        driverName.trim(),
        driverEmail.trim(),
        driverTelephone,
        driverPersonalCode.trimEnd(),
        countryCodes[driverCountryCode]
      );
    }

    return {
      notifier: notifier,
      isContactDriver: isDriver,
      driver: showDriverQuestion ? driver : undefined,
    };
  };
  const onSubmit = handleSubmit(async (values: NotifierFormFields) => {
    setLoading(true);
    // eslint-disable-next-line
    values.isDriver = isDriver!;
    const notifierInfo = createNotifierInfo(values);
    try {
      if (claimId != undefined) {
        await setNotifierInfoAction(notifierInfo, claimId);
        trackClaimNotifierInfoSubmit(claimId, values.isDriver);
        goToNextPage(incident);
      }
    } catch (error) {
      trackExceptionEvent(claimId || null, 'notifier info submit', error.message);
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.tryAgain'} />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    } finally {
      setLoading(false);
    }
  });

  const notifierFormStateHandler = {
    name: setName,
    email: setEmail,
    telephone: setTelephone,
    personalCode: setPersonalCode,
    driverName: setDriverName,
    driverEmail: setDriverEmail,
    driverTelephone: setDriverTelephone,
    driverPersonalCode: setDriverPersonalCode,
    countryCode: setCountryCode,
    driverCountryCode: setDriverCountryCode,
  };

  const handleInputChange = (evt: {
    target: {
      name?: string;
      value?: string;
    };
  }) => {
    const { name, value } = evt.target;
    // @ts-ignore
    notifierFormStateHandler[name](value);
  };

  const handleCheckBoxChange = (evt: {
    target: {
      defaultValue: string;
    };
  }) => {
    const isDriverSelected = evt.target.defaultValue == 'yes';
    setIsDriver(isDriverSelected);
    setChecked(isDriverSelected);
  };

  const notifierForm = (
    notifierName: string,
    notifierPersonalCode: string,
    notifierTelephone: string,
    notifierEmail: string,
    notifierType: NotifierType
  ) => {
    const isDriver = notifierType === NotifierType.DRIVER;

    return (
      <>
        <TextInput
          name={notifierName}
          onChangeHandler={handleInputChange}
          label={getNameLabel(isDriver)}
          testId='name'
          controlId='name'
          ref={register({ required: true })}
          isInvalid={isDriver ? !!errors.driverName : !!errors.name}
          validationError={getNameValidationError(isDriver)}
        />
        <TextInput
          name={notifierPersonalCode}
          onChangeHandler={handleInputChange}
          label={getPersonalCodeLabel(isDriver)}
          testId={notifierPersonalCode}
          controlId={notifierPersonalCode}
          ref={register({ required: true })}
          isInvalid={isDriver ? !!errors.driverPersonalCode : !!errors.personalCode}
          validationError={getPersonalCodeValidationError(isDriver)}
        />
        <PhoneNumberInput
          name={notifierTelephone}
          // @ts-ignore
          onChangeHandler={handleInputChange}
          onCountryCodeChange={(code: string) => {
            isDriver ? setDriverCountryCode(code) : setCountryCode(code);
          }}
          countryCode={isDriver ? driverCountryCode : countryCode}
          label={getTelephoneLabel(isDriver)}
          testId={notifierTelephone}
          controlId={notifierTelephone}
          ref={register({ required: true })}
          isInvalid={isDriver ? !!errors.driverTelephone : !!errors.telephone}
          validationError={getTelephoneValidationError(isDriver)}
          countryCodes={countryCodes}
          countryListForCodes={countryListForCodes}
        />
        <TextInput
          name={notifierEmail}
          onChangeHandler={handleInputChange}
          label={getEmailLabel(isDriver)}
          testId={notifierEmail}
          controlId={notifierEmail}
          ref={register({ required: true })}
          isInvalid={isDriver ? !!errors.driverEmail : !!errors.email}
          validationError={getEmailValidationError(isDriver)}
        />
      </>
    );
  };

  const [checked, setChecked] = useState(!isDriver);
  const [showDriverQuestion, setShowDriverQuestion] = useState(false);
  useEffect(() => {
    if (
      incident &&
      incident !== ApiClaimIncidentType.Theft &&
      incident !== ApiClaimIncidentType.VandalismOrFire
    ) {
      setShowDriverQuestion(true);
      setTimeout(() => {
        setChecked(!checked);
      }, 5);
    }
  }, []);

  // @ts-ignore
  return (
    <Layout showProgress>
      <Container className='content yaway-container' fluid>
        <SectionHeader text={<Translate value={'notifierInfo.title'} />} />
        <FormProvider {...methods}>
          <Form id='notifierInfoForm' className='my-4' onSubmit={onSubmit}>
            {notifierForm('name', 'personalCode', 'telephone', 'email', NotifierType.NOTIFIER)}

            {showDriverQuestion && (
              <Form.Group controlId='isDriver' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  <Translate value={'notifierInfo.confirmUserIsDriver'} />
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    inline
                    name='isDriver'
                    color={color}
                    type='radio'
                    label={<Translate value={'notifierInfo.affirm'} />}
                    id='isDriver-yes'
                    value={'yes'}
                    ref={register({ required: showDriverQuestion })}
                    isInvalid={!!errors.isDriver}
                    onChange={handleCheckBoxChange}
                    checked={isDriver != undefined && checked}
                  />
                  <Radio
                    inline
                    name='isDriver'
                    color={color}
                    type='radio'
                    label={<Translate value={'notifierInfo.deny'} />}
                    id='isDriver-no'
                    value={'no'}
                    ref={register({ required: showDriverQuestion })}
                    isInvalid={!!errors.isDriver}
                    onChange={handleCheckBoxChange}
                    checked={isDriver != undefined && !checked}
                  />
                </Form.Row>
                <Form.Control.Feedback type='invalid'>REQ</Form.Control.Feedback>
              </Form.Group>
            )}
            {isDriver != undefined &&
              !isDriver &&
              showDriverQuestion &&
              notifierForm(
                'driverName',
                'driverPersonalCode',
                'driverTelephone',
                'driverEmail',
                NotifierType.DRIVER
              )}
            {errorPopup}
          </Form>
        </FormProvider>
        <NextButton
          type='submit'
          form='notifierInfoForm'
          data-testid='submitBtn'
          loading={loading}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ locale, claimNotifierInfo, claim, theme, claimIncident }: AppState) => ({
  country: locale.countryCode,
  notifierInfo: claimNotifierInfo,
  claimId: claim.claimId,
  color: theme.color,
  incident: claimIncident.incident,
});

const mapDispatchToProps = {
  setNotifierInfoAction: saveNotifierInfo,
  goToNextPage: goToNextPage,
};

export type NotifierInfoProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NotifierInfo);
