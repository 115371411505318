import React, { useEffect } from 'react';
import Header from './header/Header';
import Progress from '../controls/Progress';
import { getClaimRouteOrder, getSteps, RoutePrefixes } from '../../routing/RouteNames';
import { AppState } from '../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import { redirectToHome } from '../../routing/RouteActions';
import { getRelativeCurrentUrl } from '../../common/utils/URLUtils';

interface Props {
  children?: React.ReactNode;
  hideLogo?: boolean;
  showProgress?: boolean;
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  hideLogo,
  showProgress = false,
  incident,
  redirectToHome,
  showInsuranceSelection,
}: LayoutProps) => {
  useEffect(() => {
    const currentRoute = getRelativeCurrentUrl();
    if (currentRoute.includes(RoutePrefixes.CLAIMS)) {
      redirectToHome(incident);
    }
  }, []);
  return (
    <>
      <header id='header'>
        <Header hideLogo={hideLogo} />
        {showProgress && (
          <Progress
            total={getSteps(incident, showInsuranceSelection)}
            currentStep={getClaimRouteOrder(incident)}
          />
        )}
      </header>
      {children}
    </>
  );
};

const mapStateToProps = ({ claimIncident, claim }: AppState) => ({
  incident: claimIncident.incident,
  showInsuranceSelection: claim.showInsuranceSelection,
});

const mapDispatchToProps = {
  redirectToHome: redirectToHome,
};

export type LayoutProps = Props &
  ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
