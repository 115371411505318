/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAddress,
    ApiAddressFromJSON,
    ApiAddressToJSON,
    ApiAssistanceRequestAdditionalDetails,
    ApiAssistanceRequestAdditionalDetailsFromJSON,
    ApiAssistanceRequestAdditionalDetailsToJSON,
    ApiAssistanceRequestData,
    ApiAssistanceRequestDataFromJSON,
    ApiAssistanceRequestDataToJSON,
    ApiAssistanceRequestStatus,
    ApiAssistanceRequestStatusFromJSON,
    ApiAssistanceRequestStatusToJSON,
    ApiAssistanceSubmitResponse,
    ApiAssistanceSubmitResponseFromJSON,
    ApiAssistanceSubmitResponseToJSON,
    ApiContact,
    ApiContactFromJSON,
    ApiContactToJSON,
    ApiIncident,
    ApiIncidentFromJSON,
    ApiIncidentToJSON,
    ApiLanguageUpdate,
    ApiLanguageUpdateFromJSON,
    ApiLanguageUpdateToJSON,
    ApiLocation,
    ApiLocationFromJSON,
    ApiLocationToJSON,
    ApiVehicleDetails,
    ApiVehicleDetailsFromJSON,
    ApiVehicleDetailsToJSON,
} from '../models';

export interface GetAssistanceRequestAddressFromCoordinatesRequest {
    id: number;
    latitude: number;
    longitude: number;
}

export interface GetAssistanceRequestDataRequest {
    id: number;
}

export interface GetAssistanceRequestStatusByIdRequest {
    id: number;
}

export interface SubmitAssistanceRequestRequest {
    id: number;
}

export interface UpdateAssistanceRequestAdditionalInfoRequest {
    id: number;
    apiAssistanceRequestAdditionalDetails: ApiAssistanceRequestAdditionalDetails;
}

export interface UpdateAssistanceRequestContactRequest {
    id: number;
    apiContact: ApiContact;
}

export interface UpdateAssistanceRequestIncidentRequest {
    id: number;
    apiIncident: ApiIncident;
}

export interface UpdateAssistanceRequestLanguageRequest {
    id: number;
    apiLanguageUpdate: ApiLanguageUpdate;
}

export interface UpdateAssistanceRequestLocationRequest {
    id: number;
    apiLocation: ApiLocation;
}

export interface UpdateAssistanceRequestVehicleRequest {
    id: number;
    apiVehicleDetails: ApiVehicleDetails;
}

/**
 * 
 */
export class AssistanceRequestApi extends runtime.BaseAPI {

    /**
     * retrieve assistance address from coordinates
     */
    async getAssistanceRequestAddressFromCoordinatesRaw(requestParameters: GetAssistanceRequestAddressFromCoordinatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAssistanceRequestAddressFromCoordinates.');
        }

        if (requestParameters.latitude === null || requestParameters.latitude === undefined) {
            throw new runtime.RequiredError('latitude','Required parameter requestParameters.latitude was null or undefined when calling getAssistanceRequestAddressFromCoordinates.');
        }

        if (requestParameters.longitude === null || requestParameters.longitude === undefined) {
            throw new runtime.RequiredError('longitude','Required parameter requestParameters.longitude was null or undefined when calling getAssistanceRequestAddressFromCoordinates.');
        }

        const queryParameters: any = {};

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{id}/geocode`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAddressFromJSON(jsonValue));
    }

    /**
     * retrieve assistance address from coordinates
     */
    async getAssistanceRequestAddressFromCoordinates(requestParameters: GetAssistanceRequestAddressFromCoordinatesRequest, initOverrides?: RequestInit): Promise<ApiAddress> {
        const response = await this.getAssistanceRequestAddressFromCoordinatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns assistance request data
     */
    async getAssistanceRequestDataRaw(requestParameters: GetAssistanceRequestDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAssistanceRequestData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestDataFromJSON(jsonValue));
    }

    /**
     * returns assistance request data
     */
    async getAssistanceRequestData(requestParameters: GetAssistanceRequestDataRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestData> {
        const response = await this.getAssistanceRequestDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns roadside assistance request status by id
     */
    async getAssistanceRequestStatusByIdRaw(requestParameters: GetAssistanceRequestStatusByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceRequestStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAssistanceRequestStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceRequestStatusFromJSON(jsonValue));
    }

    /**
     * returns roadside assistance request status by id
     */
    async getAssistanceRequestStatusById(requestParameters: GetAssistanceRequestStatusByIdRequest, initOverrides?: RequestInit): Promise<ApiAssistanceRequestStatus> {
        const response = await this.getAssistanceRequestStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async submitAssistanceRequestRaw(requestParameters: SubmitAssistanceRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiAssistanceSubmitResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitAssistanceRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssistanceSubmitResponseFromJSON(jsonValue));
    }

    /**
     */
    async submitAssistanceRequest(requestParameters: SubmitAssistanceRequestRequest, initOverrides?: RequestInit): Promise<ApiAssistanceSubmitResponse> {
        const response = await this.submitAssistanceRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAssistanceRequestAdditionalInfoRaw(requestParameters: UpdateAssistanceRequestAdditionalInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestAdditionalInfo.');
        }

        if (requestParameters.apiAssistanceRequestAdditionalDetails === null || requestParameters.apiAssistanceRequestAdditionalDetails === undefined) {
            throw new runtime.RequiredError('apiAssistanceRequestAdditionalDetails','Required parameter requestParameters.apiAssistanceRequestAdditionalDetails was null or undefined when calling updateAssistanceRequestAdditionalInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/additional-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssistanceRequestAdditionalDetailsToJSON(requestParameters.apiAssistanceRequestAdditionalDetails),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestAdditionalInfo(requestParameters: UpdateAssistanceRequestAdditionalInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestAdditionalInfoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateAssistanceRequestContactRaw(requestParameters: UpdateAssistanceRequestContactRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestContact.');
        }

        if (requestParameters.apiContact === null || requestParameters.apiContact === undefined) {
            throw new runtime.RequiredError('apiContact','Required parameter requestParameters.apiContact was null or undefined when calling updateAssistanceRequestContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/contact`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiContactToJSON(requestParameters.apiContact),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestContact(requestParameters: UpdateAssistanceRequestContactRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestContactRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateAssistanceRequestIncidentRaw(requestParameters: UpdateAssistanceRequestIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestIncident.');
        }

        if (requestParameters.apiIncident === null || requestParameters.apiIncident === undefined) {
            throw new runtime.RequiredError('apiIncident','Required parameter requestParameters.apiIncident was null or undefined when calling updateAssistanceRequestIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/incident`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiIncidentToJSON(requestParameters.apiIncident),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestIncident(requestParameters: UpdateAssistanceRequestIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestIncidentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateAssistanceRequestLanguageRaw(requestParameters: UpdateAssistanceRequestLanguageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestLanguage.');
        }

        if (requestParameters.apiLanguageUpdate === null || requestParameters.apiLanguageUpdate === undefined) {
            throw new runtime.RequiredError('apiLanguageUpdate','Required parameter requestParameters.apiLanguageUpdate was null or undefined when calling updateAssistanceRequestLanguage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/language`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiLanguageUpdateToJSON(requestParameters.apiLanguageUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestLanguage(requestParameters: UpdateAssistanceRequestLanguageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestLanguageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateAssistanceRequestLocationRaw(requestParameters: UpdateAssistanceRequestLocationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestLocation.');
        }

        if (requestParameters.apiLocation === null || requestParameters.apiLocation === undefined) {
            throw new runtime.RequiredError('apiLocation','Required parameter requestParameters.apiLocation was null or undefined when calling updateAssistanceRequestLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/location`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiLocationToJSON(requestParameters.apiLocation),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestLocation(requestParameters: UpdateAssistanceRequestLocationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestLocationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateAssistanceRequestVehicleRaw(requestParameters: UpdateAssistanceRequestVehicleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAssistanceRequestVehicle.');
        }

        if (requestParameters.apiVehicleDetails === null || requestParameters.apiVehicleDetails === undefined) {
            throw new runtime.RequiredError('apiVehicleDetails','Required parameter requestParameters.apiVehicleDetails was null or undefined when calling updateAssistanceRequestVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/assistance-request/{id}/vehicle`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVehicleDetailsToJSON(requestParameters.apiVehicleDetails),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAssistanceRequestVehicle(requestParameters: UpdateAssistanceRequestVehicleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAssistanceRequestVehicleRaw(requestParameters, initOverrides);
    }

}
