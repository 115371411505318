import { ApiClaimIncidentType } from '../api';
import { Dispatch } from 'redux';
import { checkIfStepIsValid, getNextRoute } from './RouteNames';
import { push } from 'redux-first-history';

export const goToNextPage =
  (incident?: ApiClaimIncidentType, showInsuranceSelection?: boolean, skipWorkshop?: boolean) =>
  (dispatch: Dispatch): void => {
    if (incident) {
      const nextRoute = getNextRoute(incident, showInsuranceSelection, skipWorkshop);
      nextRoute && dispatch(push(nextRoute));
    }
  };

export const redirectToHome =
  (incident?: ApiClaimIncidentType) =>
  (dispatch: Dispatch): void => {
    if (!checkIfStepIsValid(incident)) {
      dispatch(push('/claims/incidents'));
    }
  };
