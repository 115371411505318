/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiTowTruckDriver
 */
export interface ApiTowTruckDriver {
    /**
     * 
     * @type {number}
     * @memberof ApiTowTruckDriver
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTowTruckDriver
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTowTruckDriver
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTowTruckDriver
     */
    company: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTowTruckDriver
     */
    companyId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiTowTruckDriver
     */
    isTransferred?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiTowTruckDriver
     */
    isManager?: boolean;
}

export function ApiTowTruckDriverFromJSON(json: any): ApiTowTruckDriver {
    return ApiTowTruckDriverFromJSONTyped(json, false);
}

export function ApiTowTruckDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTowTruckDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': json['fullName'],
        'phoneNumber': json['phoneNumber'],
        'company': json['company'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'isTransferred': !exists(json, 'isTransferred') ? undefined : json['isTransferred'],
        'isManager': !exists(json, 'isManager') ? undefined : json['isManager'],
    };
}

export function ApiTowTruckDriverToJSON(value?: ApiTowTruckDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'phoneNumber': value.phoneNumber,
        'company': value.company,
        'companyId': value.companyId,
        'isTransferred': value.isTransferred,
        'isManager': value.isManager,
    };
}

