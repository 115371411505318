import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiAssistanceRequestAdditionalDetails, ApiFile } from '../../../api';

export interface AdditionalDetailsState {
  description?: string;
  images?: File[] | Blob | Blob[] | ApiFile[];
  email: string;
}

const initialState: AdditionalDetailsState = {
  email: '',
};

const additionalDetailsSlice = createSlice({
  name: 'additionalDetails',
  initialState,
  reducers: {
    setAdditionalDetails: (
      state: AdditionalDetailsState,
      action: PayloadAction<AdditionalDetailsState>
    ) => {
      if (action && action.payload) {
        state.email = action.payload.email;
        state.description = action.payload.description;
      }
    },
    setAdditionalDetailsApi: (
      state: ApiAssistanceRequestAdditionalDetails,
      action: PayloadAction<ApiAssistanceRequestAdditionalDetails>
    ) => {
      if (action && action.payload) {
        state.email = action.payload.email;
        state.description = action.payload.description;
      }
    },
    resetAdditionalDetails: (state: AdditionalDetailsState) => {
      state.email = initialState.email;
      state.description = undefined;
      state.images = undefined;
    },
    saveImages: (state: AdditionalDetailsState, action: PayloadAction<AdditionalDetailsState>) => {
      if (action && action.payload) {
        state.images = action.payload.images;
      } else state.images = [];
    },
  },
});

const { actions, reducer } = additionalDetailsSlice;

export const { setAdditionalDetails, setAdditionalDetailsApi, resetAdditionalDetails, saveImages } =
  actions;

export default reducer;
