import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React from 'react';

export const NumericSection = (
  section = '',
  content: JSX.Element | string,
  gutter = 1,
  nested?: boolean,
  nestedGutter?: number
): JSX.Element =>
  nested ? (
    <Row>
      <Col xs={gutter} />
      <Col>
        <Row>
          <Col xs={nestedGutter ?? gutter} md={gutter}>
            <b>{section}</b>
          </Col>
          <Col>
            <p>{content}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col xs={gutter}>
        <b>{section}</b>
      </Col>
      <Col>
        <p>{content}</p>
      </Col>
    </Row>
  );
