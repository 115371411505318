/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAccidentDetails,
    ApiAccidentDetailsFromJSON,
    ApiAccidentDetailsFromJSONTyped,
    ApiAccidentDetailsToJSON,
    ApiClaimIncidentType,
    ApiClaimIncidentTypeFromJSON,
    ApiClaimIncidentTypeFromJSONTyped,
    ApiClaimIncidentTypeToJSON,
    ApiFile,
    ApiFileFromJSON,
    ApiFileFromJSONTyped,
    ApiFileToJSON,
    ApiNotifierInfo,
    ApiNotifierInfoFromJSON,
    ApiNotifierInfoFromJSONTyped,
    ApiNotifierInfoToJSON,
    ApiOtherPartyDetails,
    ApiOtherPartyDetailsFromJSON,
    ApiOtherPartyDetailsFromJSONTyped,
    ApiOtherPartyDetailsToJSON,
    ApiVehicleDetails,
    ApiVehicleDetailsFromJSON,
    ApiVehicleDetailsFromJSONTyped,
    ApiVehicleDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiClaimData
 */
export interface ApiClaimData {
    /**
     * 
     * @type {ApiClaimIncidentType}
     * @memberof ApiClaimData
     */
    incidentType?: ApiClaimIncidentType;
    /**
     * 
     * @type {ApiVehicleDetails}
     * @memberof ApiClaimData
     */
    vehicleDetails?: ApiVehicleDetails;
    /**
     * 
     * @type {ApiAccidentDetails}
     * @memberof ApiClaimData
     */
    accidentDetails?: ApiAccidentDetails;
    /**
     * 
     * @type {ApiNotifierInfo}
     * @memberof ApiClaimData
     */
    notifierInfo?: ApiNotifierInfo;
    /**
     * 
     * @type {Array<ApiFile>}
     * @memberof ApiClaimData
     */
    uploadedPhotos?: Array<ApiFile>;
    /**
     * 
     * @type {string}
     * @memberof ApiClaimData
     */
    externalId: string;
    /**
     * 
     * @type {number}
     * @memberof ApiClaimData
     */
    damageAreaTemplateId?: number;
    /**
     * 
     * @type {ApiOtherPartyDetails}
     * @memberof ApiClaimData
     */
    otherParties?: ApiOtherPartyDetails;
}

export function ApiClaimDataFromJSON(json: any): ApiClaimData {
    return ApiClaimDataFromJSONTyped(json, false);
}

export function ApiClaimDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidentType': !exists(json, 'incidentType') ? undefined : ApiClaimIncidentTypeFromJSON(json['incidentType']),
        'vehicleDetails': !exists(json, 'vehicleDetails') ? undefined : ApiVehicleDetailsFromJSON(json['vehicleDetails']),
        'accidentDetails': !exists(json, 'accidentDetails') ? undefined : ApiAccidentDetailsFromJSON(json['accidentDetails']),
        'notifierInfo': !exists(json, 'notifierInfo') ? undefined : ApiNotifierInfoFromJSON(json['notifierInfo']),
        'uploadedPhotos': !exists(json, 'uploadedPhotos') ? undefined : ((json['uploadedPhotos'] as Array<any>).map(ApiFileFromJSON)),
        'externalId': json['externalId'],
        'damageAreaTemplateId': !exists(json, 'damageAreaTemplateId') ? undefined : json['damageAreaTemplateId'],
        'otherParties': !exists(json, 'otherParties') ? undefined : ApiOtherPartyDetailsFromJSON(json['otherParties']),
    };
}

export function ApiClaimDataToJSON(value?: ApiClaimData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidentType': ApiClaimIncidentTypeToJSON(value.incidentType),
        'vehicleDetails': ApiVehicleDetailsToJSON(value.vehicleDetails),
        'accidentDetails': ApiAccidentDetailsToJSON(value.accidentDetails),
        'notifierInfo': ApiNotifierInfoToJSON(value.notifierInfo),
        'uploadedPhotos': value.uploadedPhotos === undefined ? undefined : ((value.uploadedPhotos as Array<any>).map(ApiFileToJSON)),
        'externalId': value.externalId,
        'damageAreaTemplateId': value.damageAreaTemplateId,
        'otherParties': ApiOtherPartyDetailsToJSON(value.otherParties),
    };
}

