import {
  IsRequestFeedbackEnabledUponClaimRegistrationRequest,
  ServiceNotificationResponse,
} from '../../../api';
import { serviceNotificationApi } from '../../../apis';

export const isGetClaimSubmissionFeedbackEnabled = async (
  id: string
): Promise<ServiceNotificationResponse> => {
  const request: IsRequestFeedbackEnabledUponClaimRegistrationRequest = {
    claimUuid: id,
  };
  return await serviceNotificationApi.isRequestFeedbackEnabledUponClaimRegistration(request);
};
