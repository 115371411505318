import { Dispatch } from 'redux';
import { setNotifierInfo } from './notifierInfoReducer';
import { ApiNotifierInfo } from '../../../api';
import { CreateNotifierInfoRequest } from '../../../api/apis/ClaimApi';
import { ClientError } from '../../../common/exception/ClientError';
import { claimApi } from '../../../apis';
import * as Sentry from '@sentry/react';
import { NetworkError } from '../../../common/exception/NetworkError';

export const saveNotifierInfo =
  (notifierInfo: ApiNotifierInfo, claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: CreateNotifierInfoRequest = {
        apiNotifierInfo: notifierInfo,
        id: claimId,
      };
      await claimApi.createNotifierInfo(request);
      dispatch(setNotifierInfo(notifierInfo));
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      Sentry.captureException(err);
      throw Error('Sorry, something went wrong');
    }
  };
