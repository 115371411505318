import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const PrivacyEstonian: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SectionHeader text={'PRIVAATSUSTINGIMUSED'} />
      <div>
        <p>
          <b>SISSEJUHATUS</b>
        </p>
        <p>
          Käesolev isikuandmete töötlemise tingimuste dokument (edaspidi „
          <b>Privaatsustingimused</b>“) kirjeldab, kuidas Yaway töötleb isikuandmeid enda teenuste
          raames. Privaatsustingimuste eesmärk on anda selget ja läbipaistvat teavet isikuandmete
          töötlemise kohta kooskõlas kehtiva regulatsiooniga.
        </p>

        <p>
          Kui Teil tekib täpsustavaid küsimusi selle kohta, kuidas me Teie isikuandmeid töötleme või
          kui Te soovite esitada meile Teie isikuandmete töötlemisega kaasnevate õiguste
          teostamiseks taotlusi, võtke meiega ühendust allpool jaotises „Kontakt“ toodud
          kontaktandmetel.
        </p>

        <p>
          Yaway võib käesolevaid Privaatsustingimusi aeg-ajalt muuta. Ajakohased
          Privaatsustingimused on alati avaldatud veebilehel{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a>.
        </p>

        {NumericSection('1.', <b>MÕISTED</b>)}
        {NumericSection('Andmesubjekt', <>Füüsiline isik, kelle andmeid Yaway töötleb;</>, 4)}

        {NumericSection(
          'IKÜM',
          <>
            Euroopa Parlamendi ja nõukogu määrus (EL) 2016/679, 27. aprill 2016, füüsiliste isikute
            kaitse kohta isikuandmete töötlemisel ja selliste andmete vaba liikumise ning direktiivi
            95/46/EÜ kehtetuks tunnistamise kohta (isikuandmete kaitse üldmäärus);
          </>,
          4
        )}

        {NumericSection(
          'Isikuandmed',
          <>
            Igasugune teave tuvastatud või tuvastatava füüsilise isiku („andmesubjekti“) kohta;
            tuvastatav füüsiline isik on isik, keda saab otseselt või kaudselt tuvastada, eelkõige
            sellise identifitseerimistunnuse põhjal nagu nimi, isikukood, asukohateave,
            võrguidentifikaator või selle füüsilise isiku ühe või mitme füüsilise, füsioloogilise,
            geneetilise, vaimse, majandusliku, kultuurilise või sotsiaalse tunnuse põhjal;
          </>,
          4
        )}

        {NumericSection(
          'Kehtiv õigus',
          <>
            Kõik kehtivad Euroopa Liidu õigusaktid ning kõik kehtivad Eesti Vabariigi õigusaktid,
            sealhulgas, kuid mitte ainult, IKÜM-i riigisisesed rakendusaktid, mis kehtivad
            käesolevate Privaatsustingimuste kehtivuse ajal või hakkavad kehtima pärast tingimuste
            kehtestamist;
          </>,
          4
        )}

        {NumericSection(
          'Kasutaja',
          <>
            kes kasutab Platvormi autoabi tellimiseks või sõidukiga toimunud kahjust teatamiseks;
          </>,
          4
        )}

        {NumericSection(
          'Yaway',
          <>Yaway OÜ, registrikood 16406514, asukoht Peterburi tee 2f, 11415, Tallinn, Eesti;</>,
          4
        )}

        {NumericSection(
          'Platvorm',
          <>
            Veebikeskkonnas <a href='https://yaway.ai'>https://yaway.ai </a> Yaway poolt hallatav
            platvorm, mille kaudu saab tellida autoabi või edastada teateid sõidukiga toimunud
            kahjujuhtumite kohta;
          </>,
          4
        )}

        {NumericSection(
          'Töötlemine',
          <>
            Isikuandmete või nende kogumitega tehtav automatiseeritud või automatiseerimata toiming
            või toimingute kogum, nagu kogumine, dokumenteerimine, korrastamine, struktureerimine,
            säilitamine, kohandamine ja muutmine, päringute tegemine, lugemine, kasutamine,
            edastamise, levitamise või muul moel kättesaadavaks tegemise teel avalikustamine,
            ühitamine või ühendamine, piiramine, kustutamine või hävitamine;
          </>,
          4
        )}

        {NumericSection(
          'Teenuse-pakkuja',
          <>
            on teenusepakkuja, kellele Kasutaja saab Platvormi kaudu esitada Tellimuse
            autoabiteenuse saamiseks või sõidukiga toimunud kahjujuhtumi menetlemiseks;
          </>,
          4
        )}

        {NumericSection(
          'Tellimus',
          <>
            on Kasutaja poolt Platvormi kaudu Teenusepakkujale esitatud tellimus, näiteks
            autoabiteenuse saamiseks või kahjujuhtumi käsitlemiseks;
          </>,
          4
        )}

        {NumericSection(
          'Vastutav Töötleja',
          <>
            Füüsiline või juriidiline isik, avaliku sektori asutus, amet või muu organ, kes üksi või
            koos teistega määrab kindlaks isikuandmete töötlemise eesmärgid ja vahendid. Käesolevate
            Tingimuste tähenduses on töötaja isikuandmete vastutav töötleja Yaway; Teenusepakkuja ja
            Kasutaja vahel Platvormi kaudu tekkinud õigussuhte raames on Kasutaja isikuandmete
            vastutav töötleja Teenusepakkuja;
          </>,
          4
        )}

        {NumericSection(
          'Volitatud Töötleja',
          <>
            Füüsiline või juriidiline isik, avaliku sektori asutus, amet või muu organ, kes töötleb
            isikuandmeid vastutava töötleja nimel;
          </>,
          4
        )}

        {NumericSection(
          'Üldtingimused või Tingimused',
          <>
            kasutustingimused, mis kohalduvad Yaway ja Kasutajate vahelisele suhtele seoses
            Platvormi kasutamisega on kättesaadavad <a href='https://yaway.ai'>https://yaway.ai </a>
          </>,
          4
        )}

        {NumericSection('2.', <b>MILLAL JA MIKS ME ISIKUANDMEID TÖÖTLEME?</b>)}
        {NumericSection(
          '2.1',
          <>
            Kui olete otsustanud kasutada Yaway Platvormi, peame töötlema teie isikuandmeid, et
            võimaldada Platvormi kasutamist kooskõlas Tingimustega.
          </>
        )}
        {NumericSection(
          '2.2',
          <>
            Töötleme isikuandmeid, mille olete edastanud Yaway&lsquo;le seoses Platvormi
            kasutamisega. Need andmed on muu hulgas järgmised:
          </>
        )}
        {NumericSection(
          '2.2.1',
          <>
            <u>üldised isikuandmed</u>: nimi (eesnimi, perekonnanimi);{' '}
          </>,
          2
        )}
        {NumericSection(
          '2.2.2',
          <>
            <u>kontaktandmed</u>: e-posti aadress; telefoninumber;{' '}
          </>,
          2
        )}
        {NumericSection(
          '2.2.3',
          <>
            <u>Autoabiteenuse (puksiiri) tellimiseks vajalikud andmed</u>: sõiduki andmed (mark,
            mudel, aasta, numbrimärk), aadress ja koordinaadid, juhtumi kirjeldus (Kasutaja poolt
            Platvormi kasutamisel edastatud vabatekstiline kirjeldus juhtumi üksikasjade kohta);
          </>,
          2
        )}
        {NumericSection(
          '2.2.4',
          <>
            <u>Kahjujuhtumi käsitlemiseks vajalikud andmed</u>: sõiduki andmed (mark, mudel, aasta,
            numbrimärk), juhtumi kirjeldus (Kasutaja poolt Platvormi kasutamisel edastatud
            vabatekstiline kirjeldus juhtumi üksikasjade kohta); fotod;{' '}
          </>,
          2
        )}
        {NumericSection(
          '2.2.5',
          <>
            <u>Muud andmed</u>: muud andmed, mida Kasutaja võib Platvormi kaudu edastada;{' '}
          </>,
          2
        )}
        {NumericSection(
          '2.2.6',
          <>
            <u>tehnilised andmed</u>: tehnilised andmed, mida kogutakse Platvormi pakkumisega
            seoses, mida kogutakse küpsistega (lugege allpool jagu „Küpsised“).{' '}
          </>,
          2
        )}

        {NumericSection('3.', <b>MIS ON TEIE ISIKUANDMETE TÖÖTLEMISE ÕIGUSLIK ALUS?</b>)}
        {NumericSection(
          '3.1',
          <>
            Võime töödelda isikuandmeid Platvormi pakkumiseks kooskõlas Yaway Tingimustega. Sellise
            andmetöötluse õiguslik alus on IKÜM artikkel 6 (1) (b), st isikuandmete töötlemine on
            vajalik andmesubjekti osalusel sõlmitud lepingu täitmiseks või lepingu sõlmimisele
            eelnevate meetmete võtmiseks vastavalt andmesubjekti taotlusele.
          </>
        )}
        {NumericSection(
          '3.2',
          <>
            Võime isikuandmeid töödelda andmesubjekti antud nõusoleku alusel. Sellise andmetöötluse
            õiguslik alus on IKÜM artikkel 6 (1) (a). Sellistes olukordades töötleb Yaway
            isikuandmeid tingimustel, mis on määratud nõusolekus, mille iga andmesubjekt on meile
            andnud.
          </>
        )}
        {NumericSection(
          '3.3',
          <>
            Võime töödelda isikuandmeid, kui töötlemine on vajalik meile kohalduva juriidilise
            kohustuse täitmiseks, näiteks raamatupidamise eesmärkidel kohalduva
            raamatupidamisseaduse alusel või kui Yaway&lsquo;lt küsitakse isikuandmeid pädeva
            ametiasutuse taotluse alusel, näiteks kehtiva kohtumääruse alusel. Sellise andmetöötluse
            õiguslik alus on IKÜM artikkel 6 (1) (c).
          </>
        )}
        {NumericSection(
          '3.4',
          <>
            Teatud juhtudel võime isikuandmeid töödelda ka juhul, kui isikuandmete töötlemine on
            vajalik meie õigustatud huvide eesmärgil. Sellise andmetöötluse õiguslik alus on IKÜM
            artikkel 6 (1) (f). Sellisel juhul tagab Yaway, et töötlemine on proportsionaalne ning
            me oleme teinud oma õigustatud huvi mõjuhinnangu.
          </>
        )}

        {NumericSection('4.', <b>ISIKUANDMETE SÄILITAMINE </b>)}
        {NumericSection(
          '4.1',
          <>
            Yaway ei säilita isikuandmeid kauem, kui see on vajalik lähtuvalt isikuandmete
            töötlemise eesmärgist või kehtiva õiguse alusel. Üldiselt kohaldame järgmisi säilitamise
            tähtaegasid.{' '}
          </>
        )}
        {NumericSection(
          '4.2',
          <>
            Lepingutega seotud isikuandmeid võib säilitada lepingu kehtivuse ajal ning meie
            õigustatud huvi alusel IKÜM artikkel 6 (1) (f) kohaselt kuni kohalduvast seadusest
            tulenevate kohustuslike aegumistähtaegadeni. Üldiselt säilitab Yaway teenuse osutamisega
            seoses kogutud andmeid nii kaua, kuni see on vajalik teenuste osutamiseks andmesubjekti
            ja Yaway vahel sõlmitud lepingu kehtivuse jooksul ning 3 aastat pärast lepingu
            lõppemist. Üldreeglina, kui kustutatakse kõik Kasutajaga seotud isikuandmed 3 aasta
            möödumisel pärast Kasutaja poolt esitatud Tellimuse edastamist Teenusepakkujale, välja
            arvatud juhul, kui meil on õiguslik alus teie isikuandmeid kauem säilitada.{' '}
          </>
        )}
        {NumericSection(
          '4.3',
          <>
            Nõusoleku alusel kogutud andmeid säilitame kuni nõusoleku tagasivõtmiseni. Kui
            andmesubjekt ei ole oma nõusolekut tagasi võtnud, siis kohaldab Yaway nõusoleku alusel
            kogutud isikuandmete säilitamisele sama säilitamistähtaega nagu Platvormi tagamiseks
            kogutud isikuandmetele. Üldreeglina, kui Platvormi pole kasutatud 3 aastat, kustutatakse
            kõik kasutajaga seotud isikuandmed, välja arvatud juhul, kui meil on õiguslik alus teie
            isikuandmeid kauem säilitada.
          </>
        )}
        {NumericSection(
          '4.4',
          <>
            Raamatupidamise alusdokumentide ja raamatupidamiskannetega seotud isikuandmeid tuleb
            säilitada kooskõlas vastavate raamatupidamisseadustega. Seega raamatupidamisseaduse
            alusel säilitame raamatupidamislikke dokumente 7 aastat.{' '}
          </>
        )}

        {NumericSection('5.', <b>ISIKUANDMETE TÖÖTLEMISE ÜLEVAADE </b>)}
        {NumericSection(
          '',
          <>
            Yaway töötleb isikuandmeid järgmistel spetsiifilistel eesmärkidel järgmiste
            säilitamistähtaegade jooksul:{' '}
          </>
        )}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='134'>
                  <p>
                    <strong>T&ouml;&ouml;tlemise eesm&auml;rk</strong>&nbsp;
                  </p>
                </td>
                <td width='132'>
                  <p>
                    <strong>Isikuandmete kategooriad&nbsp;</strong>&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>
                    <strong>Kuidas me oleme isikuandmed saanud</strong>&nbsp;
                  </p>
                </td>
                <td width='174'>
                  <p>
                    <strong>S&auml;ilitamist&auml;htaeg</strong>&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Platvormi kaudu autoabiteenuse tellimine (puksiirteenuse Tellimuse edastamise
                    v&otilde;imaldamine Kasutajale koosk&otilde;las Tingimustega)&nbsp;
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Eesnimi, perekonnanimi, e-postiaadress, juhtumi t&uuml;&uuml;p, aadress ja
                    koordinaadid, s&otilde;iduki andmed, kirjeldus juhtumi kohta, fotod
                    juhtumist&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>Otse igalt andmesubjektilt (Kasutajalt)&nbsp;&nbsp;</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Tingimuste kehtivuse ajal ja kuni 3 aastat p&auml;rast andmesubjektiga
                    s&otilde;lmitud lepingu l&otilde;ppemist, Yaway &otilde;igustatud huvi alusel
                    kohalduvast seadusest tulenevate aegumist&auml;htaegade l&otilde;puni.&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Platvormi kaudu kahjujuhtumi kohta teate edastamine&nbsp;(kahjuk&auml;sitluse
                    teenuse Tellimuse edastamise v&otilde;imaldamine Kasutajale koosk&otilde;las
                    Tingimustega)&nbsp;
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Eesnimi, perekonnanimi, e-postiaadress,&nbsp;juhtumi t&uuml;&uuml;p,
                    s&otilde;iduki andmed, kirjeldus juhtumi kohta, fotod juhtumist&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>Otse igalt andmesubjektilt (Kasutajalt)&nbsp;&nbsp;</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Tingimuste kehtivuse ajal ja kuni 3 aastat
                    p&auml;rast&nbsp;andmesubjektiga s&otilde;lmitud lepingu l&otilde;ppemist, Yaway
                    &otilde;igustatud huvi alusel kohalduvast seadusest tulenevate
                    aegumist&auml;htaegade l&otilde;puni.&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>K&otilde;nekeskuse kaudu esitatud Tellimuse menetlemine&nbsp;</p>
                </td>
                <td width='132'>
                  <p>
                    Eesnimi, perekonnanimi, e-postiaadress, juhtumi t&uuml;&uuml;p, s&otilde;iduki
                    andmed, kirjeldus juhtumi kohta ja muud andmed, mida Kasutaja k&otilde;ne
                    k&auml;igus avaldab&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>Otse igalt andmesubjektilt (Kasutajalt)&nbsp;&nbsp;</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Tingimuste kehtivuse ajal ja kuni 3 aastat p&auml;rast andmesubjektiga
                    s&otilde;lmitud lepingu l&otilde;ppemist, Yaway &otilde;igustatud huvi alusel
                    kohalduvast seadusest tulenevate aegumist&auml;htaegade l&otilde;puni.&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Andmesubjekti p&auml;ringutele vastamine&nbsp;&nbsp;</p>
                </td>
                <td width='132'>
                  <p>
                    Nimi, e-postiaadress ja muud isikuandmed, mille te meile otse esitate ka siis,
                    kui p&ouml;&ouml;rdute meie poole p&auml;ringu v&otilde;i k&uuml;simusega, kas
                    Platvormi kaudu v&otilde;i mis tahes muu kanali kaudu (n&auml;iteks e-kirja
                    saatmisel).&nbsp;&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>Otse igalt andmesubjektilt.&nbsp;</p>
                </td>
                <td width='174'>
                  <p>
                    Kuni esitatud p&auml;ringuga seotud n&otilde;ude aegumist&auml;htajani.
                    &Uuml;ldiselt on see t&auml;htaeg 3 aastat.&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Konto registreerimine puksiirijuhile (Teenusepakkuja
                    t&ouml;&ouml;taja)&nbsp;&nbsp;
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Eesnimi, perekonnanimi, telefon, e-postiaadress, kasutajakontole valitud
                    salas&otilde;na.&nbsp;
                  </p>
                </td>
                <td width='133'>
                  <p>Otse igalt andmesubjektilt (puksiirijuhilt).&nbsp;</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Tingimuste kehtivuse ajal ja kuni 3 aastat p&auml;rast andmesubjektiga
                    s&otilde;lmitud lepingu l&otilde;ppemist, Yaway &otilde;igustatud huvi alusel
                    kohalduvast seadusest tulenevate aegumist&auml;htaegade l&otilde;puni.&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Tehnilised andmed&nbsp;</p>
                </td>
                <td colSpan={3} width='441'>
                  <p>Vt osa &bdquo;K&uuml;psised&ldquo;.&nbsp;</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {NumericSection('6.', <b>KUIDAS SAAVAD ISIKAUNDMETELE JUURDEPÄÄSU TEENUSEPAKKUJAD?</b>)}
        {NumericSection(
          '6.1',
          <>Platvorm võimaldab Tellimuste vahendamist Kasutajate ja Teenuseosutajate vahel.</>
        )}
        {NumericSection(
          '6.2',
          <>
            Teenuse tellimisel edastab Yaway Kasutaja Tellimusega seotud andmed kohasele
            Teenuseosutajale.
          </>
        )}
        {NumericSection(
          '6.3',
          <>
            Tellimuse esitamisel on Tellimuse täitmisega seotud Kasutaja isikuandmete vastutav
            töötleja Teenuseosutaja. Isikuandmete töötlemisega seotud küsimustega seoses
            Teenuseosutaja poolse töötlemisega, palun kontakteeruge Teenuseosutajaga.
          </>
        )}

        {NumericSection('7.', <b>MILLAL YAWAY ISIKUANDMEID KOLMANDATE ISIKTUEGA JAGADA VÕIB?</b>)}
        {NumericSection(
          '7.1',
          <>
            Yaway võib jagada teie isikuandmeid enda volitatud töötlejatega, nt teatud kolmandatest
            isikutest teenuseosutajatega, nt IT-teenuse pakkujate või muude teenuseosutajatega.
          </>
        )}
        {NumericSection(
          '7.2',
          <>
            Võime jagada teie isikuandmeid kolmandate isikutega ka juhul, kui oleme selleks
            õiguslikult kohustatud, näiteks, kui isikuandmeid nõuab meilt mis tahes ametiasutus,
            kellel on pädevus neid andmeid nõuda, näiteks juhul, kui andmeid küsib meilt kohus või
            õiguskaitseasutus.
          </>
        )}
        {NumericSection(
          '7.3',
          <>
            Yaway ei edasta isikuandmeid väljapoole Euroopa Liitu või Euroopa Majanduspiirkonda,
            v.a. kui omame selleks kehtivat õiguslikku alust ja käesolevates privaatsuspõhimõtetes
            selgitatud eesmärkidel. Teie isikuandmete edastamisel kolmandatesse riikidesse tagame,
            et edastamisele kohaldatakse nõuetekohaseid turvameetmeid ning teie õigused on kaitstud,
            näiteks Euroopa Komisjoni näidislepingud isikuandmete edastamiseks kolmandatesse
            riikidesse (st lepingulised standardklauslid). Võite taotleda koopiat turvameetmetest,
            mille oleme kehtestanud seoses isikuandmete edastamisega, võttes meiega ühendust
            alltoodud kontaktandmetel.
          </>
        )}

        {NumericSection('8.', <b>KUIDAS YAWAY TEIE ISIKUANDMEID KAITSEB? </b>)}
        {NumericSection(
          '',
          <>
            Selleks, et kaitsta teie isikuandmeid loata juurdepääsu, ebaseadusliku töötlemise või
            avaldamise, juhusliku kaotsimineku, muutmise või hävimise eest, kasutame nõuetekohaseid
            tehnilisi ja korralduslikke meetmeid, mis on kooskõlas kohalduvate seadustega. Need
            meetmed on muu hulgas, kuid mitte ainult, nõuetekohaste arvuti turvasüsteemide
            rakendamine, paberil ja elektroonilisel kujul failide kaitsmine tehniliste ja füüsiliste
            vahenditega, dokumentidele ja hoonetele juurdepääsu kontrollimine ja piiramine.
          </>
        )}

        {NumericSection('9.', <b>KÜPSISED </b>)}
        {NumericSection(
          '9.1',
          <>
            Yaway Platvorm kasutab küpsiseid. Käesolev jagu hõlmab meie küpsiste
            kasutamisepõhimõtteid (<b>küpsiste põhimõtted</b>), mis kohalduvad, kui kasutate
            platvormi.
          </>
        )}
        {NumericSection(
          '9.2',
          <>
            Küpsised on väikesed tekstifailid, mis veebilehe poolt teie kõvakettale salvestatakse.
            Küpsised aitavad meil jälgida ja parandada oma platvormi toimimist ja kasutamist ning
            teie kogemust platvormil. Võime kasutada küpsiseid, et näha, millised alad ja
            funktsioonid on populaarsed ning loendada külastusi meie platvormile, et tuvastada teid
            korduva külastajana ning kohandada teie kogemust platvormil teie eelistuste järgi. Võime
            kasutada küpsiseid ka suunamise või reklaami eesmärkidel.
          </>
        )}
        {NumericSection('9.3', <>Kasutame oma platvormil järgmist tüüpi küpsiseid:</>)}
        {NumericSection(
          '9.3.1',
          <>
            <u>Hädavajalikud küpsised</u>, mis on vältimatult vajalikud platvormi ja selle
            funktsioonide kasutamiseks.
          </>,
          2
        )}
        {NumericSection(
          '9.3.2',
          <>
            <u>Funktsionaalsed küpsised</u>, mis salvestavad teavet teie tehtud valikute kohta ja
            võimaldavad kohandada platvormi vastavalt teie vajadustele. Funktsionaalsed küpsised
            salvestavad teie tehtud valikuid. Meie kasutatavad funktsionaalsed küpsised salvestavad
            e-postiaadressi pärast sisselogimist, nii et kui logite platvormile sisse mitme
            e-postiaadressiga, võime teil soovitada liita erinevad platvormi kontod, mis peaks teie
            protsesse lihtsustama.
          </>,
          2
        )}
        {NumericSection(
          '9.3.3',
          <>
            <u>Analüütika küpsised</u>, mis salvestavad informatsiooni selle kohta, kuidas meie
            platvormi kasutatakse, et saada teavet selle kohta, kui sageli meie platvormi
            külastatakse, kus meie platvormil veedavad külastajad kõige rohkem aega, kui sage on
            interaktsioon lehe või lehe osaga, mis võimaldab meil muuta platvormi ülesehitus, sellel
            liikumine ning platvormi sisu võimalikult kasutajasõbralikuks.
          </>,
          2
        )}
        {NumericSection(
          '9.3.4',
          <>
            <u>Reklaamiküpsised</u>, mis võimaldavad reklaamijatel jälgida, kuidas te portaali
            kasutate, et suunata reklaami ja sisu, mis võiks teile kõige rohkem huvi pakkuda.
          </>,
          2
        )}
        {NumericSection('9.4', <>Konkreetsed küpsised, mida platvorm kasutab, on järgmised:</>)}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='188'>
                  <p>
                    <strong>K&uuml;psis&nbsp;</strong>&nbsp;
                  </p>
                </td>
                <td width='139'>
                  <p>
                    <strong>Kirjeldus</strong>&nbsp;
                  </p>
                </td>
                <td width='135'>
                  <p>
                    <strong>Kestus</strong>&nbsp;
                  </p>
                </td>
                <td width='138'>
                  <p>
                    <strong>T&uuml;&uuml;p</strong>&nbsp;
                  </p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_ga&nbsp;</p>
                </td>
                <td width='139'>
                  <p>
                    Selle k&uuml;psise paigaldab Google&nbsp;Analytics. K&uuml;psist kasutatakse
                    k&uuml;lastaja, sessiooni, kampaania andmete arvutamiseks ning selleks, et
                    j&auml;lgida lehek&uuml;lje kasutamist lehek&uuml;lje anal&uuml;&uuml;tilise
                    aruande jaoks. K&uuml;psised talletavad andmeid anon&uuml;&uuml;mselt ning
                    m&auml;&auml;ravad suvaliselt valitud numbri iga k&uuml;lastaja
                    tuvastamiseks.&nbsp;
                  </p>
                </td>
                <td width='135'>
                  <p>2 aastat&nbsp;</p>
                </td>
                <td width='138'>
                  <p>Anal&uuml;&uuml;tika&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_gid&nbsp;</p>
                </td>
                <td width='139'>
                  <p>
                    Selle k&uuml;psise paigaldab Google&nbsp;Analytics. K&uuml;psist kasutatakse
                    selleks, et talletada informatsiooni, kuidas k&uuml;lastajad veebilehte
                    kasutavad, ning see aitab koostrada anal&uuml;&uuml;tilise aruande veebilehe
                    tulemuste kohta. Kogutud andmed on muu hulgas k&uuml;lastajate arv, nende
                    p&auml;ritolu allikas ning lehek&uuml;ljed, mida k&uuml;lastatakse,
                    anon&uuml;&uuml;msel kujul.&nbsp;
                  </p>
                </td>
                <td width='135'>
                  <p>1 p&auml;ev&nbsp;</p>
                </td>
                <td width='138'>
                  <p>Anal&uuml;&uuml;tika&nbsp;</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {NumericSection(
          '9.5',
          <>
            Võite küpsised kustutada või blokeerida igal ajal oma veebilehitseja seadetes. Mõned
            küpsised võivad olla aga vajalikud platvormi toimimiseks. Seega peate mõistma, et
            küpsiste blokeerimisel või kustutamisel ei pruugi mõned platvormi osad korrektselt
            toimida.
          </>
        )}
        {NumericSection(
          '9.6',
          <>
            Rohkem üldist informatsiooni küpsiste kohta, sealhulgas sessiooniküpsiste ja alaliste
            küpsiste erinevuste kohta, leiate{' '}
            <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>
          </>
        )}
        {NumericSection(
          '9.7',
          <>
            Kui teil on küsimusi küpsiste põhimõtete kohta, võite meiega ühendust võtta alltoodud
            kontaktandmetel.
          </>
        )}

        {NumericSection('10.', <b>TEIE ÕIGUSED </b>)}
        {NumericSection(
          '10.1',
          <>
            Yaway soovib tagada, et kõik andmesubjekti õigused, mis tulenevad kohalduvatest
            õigusaktidest, on teile alati garanteeritud. Eelkõige on igal andmesubjektil:
          </>,
          2
        )}
        {NumericSection(
          '10.1.1',
          <>õigus tutvuda isikuandmetega, mida Yaway teie kohta töötleb;</>,
          2
        )}
        {NumericSection(
          '10.1.2',
          <>õigus nõuda, et Yaway parandaks mis tahes ebaõiged isikuandmed;</>,
          2
        )}
        {NumericSection(
          '10.1.3',
          <>
            õigus nõuda, et Yaway kustutaks teie isikuandmed ja/või piiraks teie isikuandmete
            töötlemist, kui meil ei ole töötlemiseks kehtivat õiguslikku alust;
          </>,
          2
        )}
        {NumericSection(
          '10.1.4',
          <>
            õigus saada oma töödeldud isikuandmed struktureeritud, üldkasutatavas ja masinloetavas
            vormis ning õigus edastada oma isikuandmed muule vastutavale töötlejale.
          </>,
          2
        )}
        {NumericSection(
          '10.1.5',
          <>õigus esitada vastuväiteid oma isikuandmete töötlemisele;</>,
          2
        )}
        {NumericSection(
          '10.2',
          <>
            Kui leiate, et teie õigusi on rikutud, võite võtta ühendust oma jurisdiktsiooni
            järelevalveasutusega (Eestis Andmekaitse Inspektsioon, aadress Tatari 39, Tallinn 10134,
            <a href='mailto:info@aki.ee'>info@aki.ee</a>).
          </>,
          2
        )}

        {NumericSection('11.', <b>KOHALDUV ÕIGUS JA JURISDIKTSIOON</b>)}
        {NumericSection(
          '',
          <>
            Privaatsustingimustele kohalduvad Eesti Vabariigi seadused. Mis tahes
            privaatsuspõhimõtetest tulenevad vaidlused lahendatakse Harju Maakohtus Eesti
            Vabariigis, välja arvatud juhul, kui teil on seadusest tulenev õigus pöörduda oma
            elukohajärgsesse kohtusse.
          </>
        )}

        {NumericSection('12.', <b>KONTAKTANDMED</b>)}
        {NumericSection(
          '',
          <>
            Kui teil on küsimusi privaatsuspõhimõtete või küpsiste põhimõtete kohta või mure seoses
            sellega, kuidas me teie isikuandmeid kasutame või kui tahate teostada oma õigusi
            ülalkirjeldatud viisil, võtke meiega ühendust e-posti teel või kirjalikult alltoodud
            kontaktandmete vahendusel.
          </>
        )}

        <p>
          <b>YAWAY OÜ </b>
        </p>
        <p>
          E-mail: <a href='mailto:hello@yaway.ai'>hello@yaway.ai</a>
        </p>
        <p>Address: Peterburi tee 2F, 11415 Tallinn</p>
      </div>
    </>
  );
};

export default PrivacyEstonian;
