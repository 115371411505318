import { Dispatch } from 'redux';
import { ApiClaimIncidentType } from '../../../api';
import { setIncident } from './incidentReducer';
import { ApiClaimIncident, claimApi, UpdateClaimIncidentRequest } from '../../../apis';
import { trackClaimIncidentSelectionEvent } from '../../../analytics/Analytics';
import { resetNotifierInfoDriverStatus } from '../notifier-info/notifierInfoReducer';
import { clearWorkshops } from '../select-workshop/workshopReducer';

export const saveClaimIncident =
  (incidentType: ApiClaimIncidentType, claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const incident: ApiClaimIncident = {
      claimIncidentType: incidentType,
    };
    const request: UpdateClaimIncidentRequest = {
      id: claimId,
      apiClaimIncident: incident,
    };
    await claimApi.updateClaimIncident(request);
    dispatch(resetNotifierInfoDriverStatus());
    if (incidentType === ApiClaimIncidentType.Theft) {
      dispatch(clearWorkshops());
    }
    trackClaimIncidentSelectionEvent(claimId, incidentType);
    dispatch(setIncident(incidentType));
  };
