import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiContact } from '../../../api';

export interface ContactDataState {
  fullName: string;
  phoneNumber: string;
  phonePrefix: string | undefined;
}

const initialState: ContactDataState = {
  fullName: '',
  phoneNumber: '',
  phonePrefix: '',
};

const contactDataSlice = createSlice({
  name: 'contactData',
  initialState,
  reducers: {
    setContactData: (state: ContactDataState, action: PayloadAction<ApiContact>) => {
      if (action.payload) {
        state.fullName = action.payload.name;
        state.phoneNumber = action.payload.phone;
        state.phonePrefix = action.payload.phonePrefix;
      }
    },
    setContactName: (state: ContactDataState, action: PayloadAction<string>) => {
      if (action.payload) state.fullName = action.payload;
    },
    setContactPhone: (state: ContactDataState, action: PayloadAction<string>) => {
      if (action.payload) state.phoneNumber = action.payload;
    },
    setContactPrefix: (state: ContactDataState, action: PayloadAction<string>) => {
      if (action.payload) state.phonePrefix = action.payload;
    },
    clearContactData: (state: ContactDataState) => {
      state.fullName = initialState.fullName;
      state.phoneNumber = initialState.phoneNumber;
      state.phonePrefix = initialState.phonePrefix;
    },
  },
});

const { actions, reducer } = contactDataSlice;

export const {
  setContactData,
  setContactName,
  setContactPhone,
  setContactPrefix,
  clearContactData,
} = actions;

export default reducer;
