import { Dispatch } from 'redux';
import {
  ApiClaimIncidentType,
  ApiCoordinate,
  ApiDamageArea,
  ApiVehicleBodyType,
} from '../../../api';
import {
  resetDamageLocation,
  setDamageLocation,
  setSelectedTemplateById,
  setSelectedTemplates,
  setTemplates,
} from './damageLocationReducer';
import { claimDamageAreaApi, claimDamageTemplateApi, claimFileApi } from '../../../apis';
import { CLAIM_FILE_TYPE } from '../consts';
import {
  trackClaimDamageLocationSubmitEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import { goToNextPage } from '../../../routing/RouteActions';
import * as Sentry from '@sentry/react';

export const saveDamageLocationsAction =
  (
    claimId: string,
    coordinates: string[],
    imageUrl: string,
    templateId: number,
    incident?: ApiClaimIncidentType
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    const apiCoordinates = toApiCoordinates(coordinates);

    const apiDamageArea: ApiDamageArea = {
      coordinates: apiCoordinates,
      damageAreaTemplateId: templateId,
    };

    try {
      await claimDamageAreaApi.createDamageAreas({
        claimId,
        apiDamageArea,
      });

      if (apiDamageArea.coordinates?.length > 0) {
        await claimFileApi.uploadClaimFile({
          id: claimId,
          file: await base64ToBlob(imageUrl),
          fileType: CLAIM_FILE_TYPE.DAMAGE_AREA_SCREENSHOT,
        });
      }

      dispatch(
        setDamageLocation({
          coordinates: apiCoordinates,
          bodyType: ApiVehicleBodyType.Sedan, // TODO: change that when we have multiple templates/body types
        })
      );
      trackClaimDamageLocationSubmitEvent(claimId, apiDamageArea);
      goToNextPage(incident)(dispatch);
    } catch (e) {
      trackExceptionEvent(claimId, 'claim damage area submit', e.message);
      Sentry.captureException(e);
      dispatch(resetDamageLocation());
    }
  };

const toApiCoordinates = (coordinates: string[]): ApiCoordinate[] => {
  return coordinates.map((value) => {
    const axes = value.split('-');
    try {
      return {
        x: parseInt(axes[0]),
        y: parseInt(axes[1]),
      };
    } catch (e) {
      throw Error(e);
    }
  });
};

const base64ToBlob = async (url: string) => {
  const response = await fetch(url);
  return await response.blob();
};

export const getDamageVehicleTemplatesAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await claimDamageTemplateApi.getDamageLocationTemplates();
      dispatch(setTemplates(response));

      dispatch(
        setSelectedTemplates(
          response.find((template) => template.vehicleBodyType === ApiVehicleBodyType.Sedan) ??
            response[0]
        )
      );
    } catch (e) {
      dispatch(setTemplates([]));
    }
  };

export const getDamageAreaAction =
  (claimId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const damageAreas = await claimDamageAreaApi.getDamageAreas({ claimId });

      dispatch(
        setDamageLocation({
          id: damageAreas.id,
          coordinates: damageAreas.coordinates,
          bodyType: ApiVehicleBodyType.Sedan, // TODO: change it later
        })
      );

      dispatch(setSelectedTemplateById(damageAreas.damageAreaTemplateId));
    } catch (e) {
      dispatch(resetDamageLocation());
    }
  };
