import React, { ChangeEvent, KeyboardEvent, SyntheticEvent } from 'react';
import './FormInput.css';
import { Form } from 'react-bootstrap';

export interface FormInputProps {
  label: JSX.Element;
  controlId?: string;
  value?: string;
  onChangeHandler?: any;
  name?: string;
  placeholder?: string;
  onBlurHandler?: any;
  onEnterKeyHandler?: any;
  isInvalid?: boolean;
  validationError?: JSX.Element;
  testId?: string;
  cssClass?: string;
  max?: string;
}

interface Props extends FormInputProps {
  type: string;
}

// eslint-disable-next-line react/display-name
export const FormInput = React.forwardRef<HTMLInputElement, Props>((props: Props, ref: any) => {
  const {
    label,
    controlId,
    value,
    onChangeHandler,
    name,
    placeholder,
    onBlurHandler,
    onEnterKeyHandler,
    isInvalid,
    validationError,
    testId,
    cssClass,
    max,
    type,
  } = props;

  return (
    <>
      <Form.Group controlId={controlId} className='my-4'>
        <Form.Label className='input-lbl'>{label}</Form.Label>
        <Form.Control
          type={type}
          max={max}
          className={(isInvalid ? 'txt-input_invalid ' : 'txt-input') + ' ' + cssClass}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e: ChangeEvent) => onChangeHandler && onChangeHandler(e)}
          ref={ref}
          onBlur={(e: SyntheticEvent) => onBlurHandler && onBlurHandler(e)}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              onEnterKeyHandler && onEnterKeyHandler(e);
            }
          }}
          isInvalid={isInvalid}
          data-testid={testId}
        />
        {isInvalid && (
          <Form.Control.Feedback type='invalid'>{validationError}</Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
});
