/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiAccidentCulprit {
    Me = 'ME',
    Other = 'OTHER',
    Unknown = 'UNKNOWN'
}

export function ApiAccidentCulpritFromJSON(json: any): ApiAccidentCulprit {
    return ApiAccidentCulpritFromJSONTyped(json, false);
}

export function ApiAccidentCulpritFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAccidentCulprit {
    return json as ApiAccidentCulprit;
}

export function ApiAccidentCulpritToJSON(value?: ApiAccidentCulprit | null): any {
    return value as any;
}

