/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiWorkshop
 */
export interface ApiWorkshop {
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshop
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshop
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshop
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshop
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshop
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshop
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshop
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshop
     */
    county?: string;
}

export function ApiWorkshopFromJSON(json: any): ApiWorkshop {
    return ApiWorkshopFromJSONTyped(json, false);
}

export function ApiWorkshopFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWorkshop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'county': !exists(json, 'county') ? undefined : json['county'],
    };
}

export function ApiWorkshopToJSON(value?: ApiWorkshop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'address': value.address,
        'phone': value.phone,
        'description': value.description,
        'county': value.county,
    };
}

