import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const VandalismOrFire: React.FC<React.PropsWithChildren<ThemeProps>> = ({
  color,
}: ThemeProps) => {
  return (
    <>
      <svg
        width='61'
        height='59'
        viewBox='0 0 40 59'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M23.0391 11.7266C24.6904 14.0384 25.5781 16.8085 25.5781 19.6496V22.3906C26.4484 22.3906 27.2937 22.0997 27.9797 21.5641C28.6656 21.0286 29.1529 20.2791 29.364 19.4348L29.6406 18.3281L30.3912 19.2289C31.6013 20.6809 32.258 22.5146 32.2449 24.4047C32.2318 26.2948 31.5497 28.1192 30.3196 29.5543C29.8586 30.0921 29.6175 30.7841 29.6446 31.4919C29.6718 32.1997 29.965 32.8711 30.4658 33.3721C30.7454 33.6517 31.0809 33.8692 31.4503 34.0103C31.8197 34.1515 32.2147 34.2132 32.6095 34.1913C33.0043 34.1694 33.3901 34.0645 33.7416 33.8834C34.0932 33.7023 34.4025 33.4491 34.6496 33.1403L36.75 30.5156L38.7812 35.5938C39.7023 38.6641 39.7749 41.9265 38.9913 45.0348C38.2076 48.143 36.597 50.9811 34.3304 53.2477L34.0742 53.5039C30.5852 56.9929 25.8531 58.953 20.9189 58.9531H18.8464C15.6336 58.9531 12.4756 58.1212 9.67982 56.5382C6.88405 54.9553 4.54584 52.6754 2.89287 49.9204C0.913569 46.6215 0.00556877 42.7902 0.29388 38.9539C0.582191 35.1177 2.05263 31.4651 4.50276 28.4991L12.7248 18.546C13.535 17.5652 14.0557 16.378 14.2284 15.1176C14.4011 13.8573 14.2189 12.5737 13.7023 11.4112C13.0468 9.93656 12.9338 8.27766 13.3832 6.72774C13.8327 5.17782 14.8156 3.83669 16.1582 2.94141L20.5 0.046875V3.80354C20.5 6.64462 21.3877 9.4147 23.0391 11.7266Z'
          fill='black'
        />
        <path
          d='M25.3296 51.1933L25.3357 51.1874C26.404 50.1376 27.1661 48.8293 27.5437 47.3969C27.9213 45.9646 27.9007 44.46 27.4841 43.038L25.4861 44.9865C25.2976 45.1704 25.0737 45.3163 24.8274 45.4158C24.581 45.5154 24.3169 45.5666 24.0503 45.5666C23.7836 45.5666 23.5195 45.5154 23.2732 45.4158C23.0268 45.3163 22.8029 45.1704 22.6144 44.9865C22.256 44.6371 22.0446 44.1697 22.0215 43.676C21.9985 43.1824 22.1656 42.6982 22.49 42.3185C23.1579 41.5369 23.5777 40.5823 23.698 39.5716C23.8183 38.561 23.6339 37.538 23.1673 36.628L19.9293 30.3125L19.4314 35.6539C19.3717 36.2945 19.1799 36.9167 18.8676 37.4833C18.5552 38.0499 18.1287 38.5491 17.6137 38.9509L14.783 41.1594C14.0316 41.7456 13.4251 42.4891 13.0082 43.3349C12.5914 44.1806 12.375 45.107 12.375 46.0453V46.8054C12.375 47.7227 12.5818 48.6287 12.9807 49.4594C13.3797 50.29 13.961 51.025 14.6836 51.6122C15.8534 52.5629 17.3318 53.0768 18.8545 53.0622L20.8392 53.043C22.5273 53.0266 24.1407 52.362 25.3296 51.1933Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(VandalismOrFire);
