import { Dispatch } from 'redux';
import { ClaimStorage } from '../../common/ClaimStorage';
import {
  ApiAccidentDetails,
  ApiClaimIncidentType,
  ApiFile,
  ApiNotifierInfo,
  ApiOtherPartyDetails,
  ApiVehicleDetails,
  claimApi,
} from '../../apis';
import { clearIncident, setIncident } from './incidents/incidentReducer';
import { clearClaimId, saveClaimId } from './claimReducer';
import {
  clearVehicleDetails,
  setVehicleDetails,
} from '../../common/components/vehicle-details/vehicleDetailsReducer';
import {
  resetAccidentDetails,
  setAccidentDetails,
} from './accident-details/accidentDetailsReducer';
import {
  clearPartyDetails,
  resetParties,
  setOtherPartiesInfo,
  setOtherPartiesInvolved,
} from './other-parties/otherPartiesReducer';
import { clearPhotos, savePhoto } from './photos/photosReducer';
import { clearNotifierInfo, setNotifierInfo } from './notifier-info/notifierInfoReducer';
import { clearCommunicationPreference } from './communication-preferences/communicationPreferencesReducer';
import { clearWorkshops } from './select-workshop/workshopReducer';

export const clearClaimData = (dispatch: Dispatch): void => {
  ClaimStorage.clearClaimId();
  dispatch(clearClaimId());
  dispatch(clearIncident());
  dispatch(clearVehicleDetails());
  dispatch(resetParties());
  dispatch(clearPhotos());
  dispatch(resetAccidentDetails());
  dispatch(clearNotifierInfo());
  dispatch(resetParties());
  dispatch(clearPartyDetails());
  dispatch(clearWorkshops());
  dispatch(clearCommunicationPreference());
};

export const loadClaimDataAction =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      clearClaimData(dispatch);
      const claim = await claimApi.getClaimData({ id });
      ClaimStorage.setClaimId(id);
      dispatch(saveClaimId(id));
      // TODO: save claim details into redux store
      loadIncident(claim.incidentType, dispatch);
      loadVehicleData(claim.vehicleDetails, dispatch);
      loadAccidentDetails(claim.accidentDetails, dispatch);
      loadNotifierInfo(claim.notifierInfo, dispatch);
      loadUploadedPhotos(claim.uploadedPhotos, dispatch);
      loadOtherParties(claim.otherParties, dispatch);
    } catch (error) {
      console.error('Store populating failed:', error);
    }
  };

const loadIncident = (incidentType: ApiClaimIncidentType | undefined, dispatch: Dispatch) => {
  if (incidentType != null) dispatch(setIncident(incidentType));
};

const loadOtherParties = (
  otherPartiesDetails: ApiOtherPartyDetails | undefined,
  dispatch: Dispatch
) => {
  if (otherPartiesDetails) {
    dispatch(setOtherPartiesInvolved(otherPartiesDetails.otherPartiesInvolved));
    dispatch(setOtherPartiesInfo(otherPartiesDetails.otherPartiesInfo));
  } else {
    dispatch(setOtherPartiesInvolved());
    dispatch(setOtherPartiesInfo());
  }
};

const loadVehicleData = (vehicle: ApiVehicleDetails | undefined, dispatch: Dispatch) => {
  vehicle &&
    vehicle.licensePlate &&
    dispatch(
      setVehicleDetails({
        licensePlate: vehicle.licensePlate,
        manufacturer: vehicle.mark,
        model: vehicle.model,
        year: vehicle.year,
      })
    );
};

const loadAccidentDetails = (
  accidentDetails: ApiAccidentDetails | undefined,
  dispatch: Dispatch
) => {
  accidentDetails && dispatch(setAccidentDetails(accidentDetails));
};

const loadNotifierInfo = (notifierInfo: ApiNotifierInfo | undefined, dispatch: Dispatch) => {
  notifierInfo && dispatch(setNotifierInfo(notifierInfo));
};

const loadUploadedPhotos = (uploadedPhotos: ApiFile[] | undefined, dispatch: Dispatch) => {
  if (uploadedPhotos && uploadedPhotos.length > 0) {
    uploadedPhotos.forEach((photo) => dispatch(savePhoto(photo)));
  }
};
