import { GA4React } from 'ga-4-react';
import { GoogleAnalyticsConfiguration } from '../configs/GoogleAnalytics';
import { gtagAction } from 'ga-4-react/src/models/gtagModels';
import { gtagCategory, gtagLabel } from 'ga-4-react/dist/models/gtagModels';
import { isProduction } from './utils';
import { ServiceStorage } from '../common/ServiceStorage';

const ga4react = new GA4React(GoogleAnalyticsConfiguration.GA_CODE);
const env = isProduction;

const actions = {
  event: async (
    action: gtagAction,
    label: gtagLabel,
    category: gtagCategory,
    nonInteraction?: boolean
  ): Promise<void> => {
    if (env && !ServiceStorage.isTestService()) {
      await ga4react.initialize();
      ga4react.event(action, label, category, nonInteraction);
    }
  },
  pageView: async (
    path: string | Location,
    location?: string | Location | undefined,
    title?: string
  ): Promise<void> => {
    if (env) {
      await ga4react.initialize();
      ga4react.pageview(path, location, title);
    }
  },
};

export const GoogleAnalytics = actions;

export const GAEvents = {
  trackAssistanceRequestInitializedEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User initialized assistance request',
      'assistance-init',
      'end-user',
      false
    );
  },

  trackIncidentViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/incidents',
      window.location,
      'User open the incident selection view'
    );
  },

  trackIncidentSelectionEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User choose a incident type',
      'incident-selection',
      'end-user',
      false
    );
  },

  trackLocationViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView('/maps', window.location, 'User open the map view');
  },

  trackLocationSelectionEvent: async (): Promise<void> => {
    await GoogleAnalytics.event('User choose a location', 'map', 'end-user', false);
  },

  trackVehicleDetailsViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/vehicle-details',
      window.location,
      'User opened assistance request vehicle details view'
    );
  },

  trackAdditionalDetailsViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/additional-details',
      window.location,
      'User opened assistance request additional details view'
    );
  },

  trackVehicleDetailsPlateNumberSearchEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User made a vehicle data search',
      'vehicle-details',
      'end-user',
      false
    );
  },

  trackContactDataSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits contact data by request',
      'assistance-contact-submit',
      'end-user',
      false
    );
  },

  trackVehicleDataSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits vehicle data by request',
      'assistance-vehicle-submit',
      'end-user',
      false
    );
  },

  trackRequestSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submitted assistance request',
      'assistance-submit',
      'end-user',
      false
    );
  },

  trackFindingTowTruckViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/track-driver',
      window.location,
      'User is navigated to the finding tow truck view'
    );
  },

  trackTowTruckFoundEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'A tow truck driver accept the user request',
      'request-accepted',
      'end-user',
      true
    );
  },

  trackTowTruckNotFoundEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'No tow trucker accepts the request',
      'no-truck-available',
      'end-user',
      true
    );
  },

  trackClaimLinkInitializedEvent: async (): Promise<void> => {
    await GoogleAnalytics.event('User initialized claim link', 'claim-init', 'end-user', true);
  },

  trackClaimIncidentViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/incidents',
      window.location,
      'User opens claim incident selection view'
    );
  },

  trackClaimIncidentSelectionEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User selects claim incident type',
      'claim-incident-selection',
      'end-user',
      false
    );
  },

  trackClaimVehicleDetailsViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/vehicle-details',
      window.location,
      'User opens claim vehicle details view'
    );
  },

  trackClaimVehicleDetailsSearchEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User makes claim vehicle data search',
      'vehicle-details',
      'end-user',
      false
    );
  },

  trackClaimVehicleDetailsSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim vehicle data',
      'claim-vehicle-submit',
      'end-user',
      false
    );
  },

  trackClaimDamageViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/damage-location',
      window.location,
      'User opens claim vehicle damage view'
    );
  },

  trackClaimDamageAreaSelectionEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User selects vehicle damage area',
      'claim-damage-select',
      'end-user',
      false
    );
  },

  trackClaimDamageLocationSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim vehicle damage data',
      'claim-damage-submit',
      'end-user',
      false
    );
  },

  trackClaimPhotosViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView('/photos', window.location, 'User opens claim photos view');
  },

  trackClaimPhotosSkipEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User skips claim photos page',
      'claim-photos-skip',
      'end-user',
      false
    );
  },

  trackClaimPhotoDeleteEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User deletes claim photo',
      'claim-photos-delete',
      'end-user',
      false
    );
  },

  trackClaimPhotosSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim photos',
      'claim-photos-submit',
      'end-user',
      false
    );
  },

  trackClaimNotifierInfoViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/notifier-info',
      window.location,
      'User opens notifier info view'
    );
  },

  trackClaimNotifierInfoSubmit: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim notifier info',
      'claim-notifier-submit',
      'end-user',
      false
    );
  },

  trackClaimOtherPartiesViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/other-parties',
      window.location,
      'User opens other parties view'
    );
  },

  trackClaimOtherPartyAddEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User adds claim other party',
      'claim-other-party-add',
      'end-user',
      false
    );
  },

  trackClaimOtherPartyDeleteEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User deletes claim other party',
      'claim-other-party-delete',
      'end-user',
      false
    );
  },

  trackClaimOtherPartiesSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim other parties',
      'claim-other-party-submit',
      'end-user',
      false
    );
  },

  trackClaimAccidentDetailsViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/accident-details',
      window.location,
      'User opens accident details view'
    );
  },

  trackClaimAccidentLocationSelectEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User selects claim accident location',
      'claim-accident-location',
      'end-user',
      false
    );
  },

  trackClaimAccidentDetailsSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim accident details',
      'claim-accident-details-submit',
      'end-user',
      false
    );
  },

  trackClaimWorkshopSelectionViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/select-workshop',
      window.location,
      'User opens claim workshop selection view'
    );
  },

  trackClaimWorkshopSelectionEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User selects claim workshop',
      'claim-workshop-selection',
      'end-user',
      false
    );
  },

  trackClaimCommunicationViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/communication-preferences',
      window.location,
      'User opens claim communication preferences view'
    );
  },

  trackClaimCommunicationPreferenceSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User submits claim communication preferences',
      'claim-communication-preference-submit',
      'end-user',
      false
    );
  },

  trackClaimSubmitEvent: async (): Promise<void> => {
    await GoogleAnalytics.event('User submits the claim', 'claim-submit', 'end-user', false);
  },

  trackClaimReceivedViewEvent: async (): Promise<void> => {
    await GoogleAnalytics.pageView(
      '/claim-received',
      window.location,
      'User opens claim received view'
    );
  },

  trackTermsAndPrivacyClickEvent: async (): Promise<void> => {
    await GoogleAnalytics.event(
      'User opens terms and privacy conditions',
      'terms-and-privacy-click',
      'end-user',
      false
    );
  },

  trackExceptionEvent: async (action: string): Promise<void> => {
    await GoogleAnalytics.event('Exception on ' + action, 'exception', 'end-user', false);
  },
};
