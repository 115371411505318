import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/loader/Loader';
import { getErrorMessageKey, isSupportedLocale, LanguageType } from '../../../locale/locale';
import '../partnerLanding/PartnerLanding.css';
import { initializeClaim } from './claimPartnerActions';
import { getServiceThemeDataByClaimId } from '../../../common/theme/themeActions';
import { useClaimPartnerInitialization } from './useClaimPartnerInitialization';
import { Translate } from 'react-redux-i18n';
import { getParamFromUrl } from '../../../common/utils/URLUtils';

type PathParameters = {
  serviceLinkName: string;
};

export const ClaimPartnerLanding: React.FC<React.PropsWithChildren<ClaimPartnerLandingProps>> = ({
  navigateToNextStep,
  initializeClaim,
  getServiceThemeDataByClaimId,
}: ClaimPartnerLandingProps) => {
  const language = getParamFromUrl('language');
  const { serviceLinkName } = useParams<PathParameters>();
  const { isFetching, data, error } = useClaimPartnerInitialization(
    serviceLinkName ?? '',
    isSupportedLocale(language) ? (language as LanguageType) : undefined
  );

  if (isFetching) {
    return <Loader />;
  }
  if (error) {
    return (
      <div className='d-flex justify-content-center'>
        <p className='vertical-center'>
          {<Translate value={getErrorMessageKey((error as Error).message)} />}
        </p>
      </div>
    );
  }
  if (data) {
    initializeClaim(data);
    getServiceThemeDataByClaimId(data.claimExternalId).then(() =>
      navigateToNextStep(data.claimExternalId)
    );
  }
  return <div />;
};

const mapDispatchToProps = {
  initializeClaim,
  getServiceThemeDataByClaimId,
  navigateToNextStep: (code: string) => push(`/claim-link/${code}`),
};

export type ClaimPartnerLandingProps = ResolveThunks<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(ClaimPartnerLanding);
