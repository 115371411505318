/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiFile,
    ApiFileFromJSON,
    ApiFileToJSON,
} from '../models';

export interface DeleteRequest {
    assistanceRequestId: number;
    filePath: string;
}

export interface UploadFileRequest {
    id: number;
    smsIdentifier: string;
    file: Blob;
}

/**
 * 
 */
export class AssistanceRequestFilesApi extends runtime.BaseAPI {

    /**
     * Delete a file using path
     */
    async _deleteRaw(requestParameters: DeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.assistanceRequestId === null || requestParameters.assistanceRequestId === undefined) {
            throw new runtime.RequiredError('assistanceRequestId','Required parameter requestParameters.assistanceRequestId was null or undefined when calling _delete.');
        }

        if (requestParameters.filePath === null || requestParameters.filePath === undefined) {
            throw new runtime.RequiredError('filePath','Required parameter requestParameters.filePath was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/assistance-request/{assistanceRequestId}/files/{filePath}`.replace(`{${"assistanceRequestId"}}`, encodeURIComponent(String(requestParameters.assistanceRequestId))).replace(`{${"filePath"}}`, encodeURIComponent(String(requestParameters.filePath))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a file using path
     */
    async _delete(requestParameters: DeleteRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this._deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads assistance request file
     */
    async uploadFileRaw(requestParameters: UploadFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadFile.');
        }

        if (requestParameters.smsIdentifier === null || requestParameters.smsIdentifier === undefined) {
            throw new runtime.RequiredError('smsIdentifier','Required parameter requestParameters.smsIdentifier was null or undefined when calling uploadFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.smsIdentifier !== undefined) {
            formParams.append('smsIdentifier', requestParameters.smsIdentifier as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/assistance-request/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFileFromJSON(jsonValue));
    }

    /**
     * Uploads assistance request file
     */
    async uploadFile(requestParameters: UploadFileRequest, initOverrides?: RequestInit): Promise<ApiFile> {
        const response = await this.uploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
