import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const PrivacyEnglish: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SectionHeader text={'PRIVATUMO SĄLYGOS'} />
      <div>
        <p>
          <b>ĮVADAS</b>
        </p>
        <p>
          Šis asmens duomenų tvarkymo sąlygų dokumentas (toliau – &quot;
          <b>Privatumo sąlygos</b>&quot;) aprašo, kaip Yaway tvarko asmens duomenis savo paslaugų
          teikimo metu. Privatumo sąlygų tikslas – pateikti aiškią ir skaidrią informaciją apie
          asmens duomenų tvarkymą pagal taikomus teisės aktus.
        </p>

        <p>
          Jei turite papildomų klausimų apie tai, kaip mes tvarkome jūsų asmens duomenis, arba
          norite pateikti prašymą dėl savo teisių, susijusių su asmens duomenų tvarkymu,
          įgyvendinimo, prašome susisiekti su mumis, naudodamiesi žemiau pateiktais kontaktais.
        </p>

        <p>
          Yaway gali kartais keisti šias Privatumo sąlygas. Dabartinės Privatumo sąlygos visada
          skelbiamos svetainėje <a href='https://yaway.ai'>https://yaway.ai</a>.
        </p>

        {NumericSection('1.', <b>APIBRĖŽIMAI</b>)}
        {NumericSection('Duomenų subjektas', <>Fizinis asmuo, kurio duomenis tvarko Yaway;</>, 4)}

        {NumericSection(
          'BDAR',
          <>
            Europos Parlamento ir Tarybos reglamentas (ES) 2016/679, priimtas 2016 m. balandžio 27
            d., dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl tokių duomenų laisvo
            judėjimo, kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos
            reglamentas);
          </>,
          4
        )}

        {NumericSection(
          'Asmens duomenys',
          <>
            Bet kokia informacija, susijusi su identifikuotu arba identifikuojamu fiziniu asmeniu
            (&quot;duomenų subjektu&quot;); identifikuojamas fizinis asmuo yra tas, kurį galima
            tiesiogiai ar netiesiogiai identifikuoti, ypač remiantis identifikatoriumi, tokiu kaip
            vardas, identifikacijos numeris, buvimo vietos duomenys, internetinis identifikatorius
            arba vienu ar keliais specifiniais fizinio, fiziologinio, genetinio, psichologinio,
            ekonominio, kultūrinio ar socialinio tapatybės požymiais;
          </>,
          4
        )}

        {NumericSection(
          'Taikomi teisės aktai',
          <>
            Visi taikomi Europos Sąjungos teisės aktai ir visi taikomi Lietuvos Respublikos teisės
            aktai, įskaitant, bet neapsiribojant, BDAR įgyvendinimo nacionaliniais aktais, kurie
            galioja šių Privatumo sąlygų galiojimo metu arba įsigalios po jų patvirtinimo;
          </>,
          4
        )}

        {NumericSection(
          'Naudotojas',
          <>
            kuris naudoja Platformą, kad užsisakytų pagalbą kelyje arba praneštų apie transporto
            priemonės padarytą žalą;
          </>,
          4
        )}

        {NumericSection(
          'Yaway',
          <>
            Yaway OÜ, registracijos kodas 16406514, adresas Valukoja 8/1, 11415, Talinas, Estija;
          </>,
          4
        )}

        {NumericSection(
          'Platforma',
          <>
            Platforma, kurią valdo Yaway svetainėje <a href='https://yaway.ai'>https://yaway.ai</a>,
            per kurią galima užsisakyti pagalbą kelyje arba pranešti apie transporto priemonės
            padarytą žalą;
          </>,
          4
        )}

        {NumericSection(
          'Tvarkymas',
          <>
            Bet kokia operacija ar operacijų rinkinys, atliekamas su asmens duomenimis arba asmens
            duomenų rinkiniais, automatizuotomis arba neautomatizuotomis priemonėmis, pvz.,
            rinkimas, įrašymas, organizavimas, struktūravimas, saugojimas, adaptacija ar pakeitimas,
            išgavimas, konsultavimas, naudojimas, atskleidimas persiuntimu, platinimu arba kitaip
            prieinamumo užtikrinimas, sulygiavimas arba sujungimas, apribojimas, ištrynimas ar
            sunaikinimas;
          </>,
          4
        )}

        {NumericSection(
          'Paslaugų teikėjas',
          <>
            paslaugų teikėjas, kuriam Naudotojas gali pateikti Užsakymą per Platformą, kad gautų
            pagalbą kelyje arba dėl transporto priemonės padarytos žalos tvarkymo;
          </>,
          4
        )}

        {NumericSection(
          'Užsakymas',
          <>
            Užsakymas, kurį Naudotojas pateikė per Platformą Paslaugų teikėjui, pvz., kad gautų
            pagalbą kelyje arba dėl transporto priemonės padarytos žalos tvarkymo;
          </>,
          4
        )}

        {NumericSection(
          'Duomenų valdytojas',
          <>
            Fizinis ar juridinis asmuo, viešosios valdžios institucija, agentūra arba kita
            institucija, kuri viena arba kartu su kitais nustato asmens duomenų tvarkymo tikslus ir
            priemones. Šių Sąlygų kontekste darbuotojų asmens duomenų valdytojas yra Yaway;
            teisiniame santykyje tarp Paslaugų teikėjo ir Naudotojo, atsirandančiame per Platformą,
            Naudotojo asmens duomenų valdytojas yra Paslaugų teikėjas;
          </>,
          4
        )}

        {NumericSection(
          'Duomenų tvarkytojas',
          <>
            Fizinis ar juridinis asmuo, viešosios valdžios institucija, agentūra arba kita
            institucija, kuri tvarko asmens duomenis Duomenų valdytojo vardu;
          </>,
          4
        )}

        {NumericSection(
          'Bendrosios sąlygos arba Sąlygos',
          <>
            Naudojimosi sąlygos, kurios taikomos santykiams tarp Yaway ir Naudotojų, susijusių su
            Platformos naudojimu, yra pateikiamos svetainėje{' '}
            <a href='https://yaway.ai'>https://yaway.ai</a>.
          </>,
          4
        )}

        {NumericSection('2.', <b>KADA IR KODĖL MES TVARKOME ASMENS DUOMENIS?</b>)}
        {NumericSection(
          '2.1',
          <>
            Jei nusprendėte naudoti Yaway Platformą, mes turime tvarkyti jūsų asmens duomenis, kad
            būtų galima naudotis Platforma pagal Sąlygas.
          </>
        )}
        {NumericSection(
          '2.2',
          <>
            Mes tvarkome asmens duomenis, kuriuos jūs pateikėte Yaway, susijusius su Platformos
            naudojimu. Šie duomenys, be kita ko, apima:
          </>
        )}
        {NumericSection(
          '2.2.1',
          <>
            <u>bendrieji asmens duomenys</u>: vardas (vardas, pavardė);
          </>,
          2
        )}
        {NumericSection(
          '2.2.2',
          <>
            <u>kontaktiniai duomenys</u>: el. pašto adresas; telefono numeris;
          </>,
          2
        )}
        {NumericSection(
          '2.2.3',
          <>
            <u>Duomenys, būtini užsakant pagalbą kelyje (vilkikas)</u>: transporto priemonės
            informacija (markė, modelis, metai, valstybinis numeris), adresas ir koordinatės,
            incidento aprašymas (laisvos formos incidento detalių aprašymas, pateiktas Naudotojo
            naudojant Platformą);
          </>,
          2
        )}
        {NumericSection(
          '2.2.4',
          <>
            <u>Duomenys, būtini žalos tvarkymui</u>: transporto priemonės informacija (markė,
            modelis, metai, valstybinis numeris), incidento aprašymas (laisvos formos incidento
            detalių aprašymas, pateiktas Naudotojo naudojant Platformą); nuotraukos;
          </>,
          2
        )}
        {NumericSection(
          '2.2.5',
          <>
            <u>Kiti duomenys</u>: kiti duomenys, kuriuos Naudotojas gali perduoti per Platformą;
          </>,
          2
        )}
        {NumericSection(
          '2.2.6',
          <>
            <u>techniniai duomenys</u>: techniniai duomenys, renkami teikiant Platformą, kurie
            renkami naudojant slapukus (skaitykite žemiau esančią skyrių „Slapukai“).
          </>,
          2
        )}

        {NumericSection('3.', <b>KOKS YRA TEISINIS JŪSŲ ASMENS DUOMENŲ TVARKYMO PAGRINDAS?</b>)}
        {NumericSection(
          '3.1',
          <>
            Mes galime tvarkyti asmens duomenis, kad teiktume Platformą pagal Yaway Sąlygas. Tokio
            duomenų tvarkymo teisinis pagrindas yra BDAR 6 straipsnio 1 dalies b punktas, t. y.
            asmens duomenų tvarkymas yra būtinas norint įvykdyti sutartį, kurios šalis yra duomenų
            subjektas, arba imtis veiksmų duomenų subjekto prašymu prieš sudarant sutartį.
          </>
        )}
        {NumericSection(
          '3.2',
          <>
            Mes galime tvarkyti asmens duomenis remiantis duomenų subjekto duotu sutikimu. Tokio
            duomenų tvarkymo teisinis pagrindas yra BDAR 6 straipsnio 1 dalies a punktas. Tokiais
            atvejais Yaway tvarko asmens duomenis pagal kiekvieno duomenų subjekto duotą sutikimą.
          </>
        )}
        {NumericSection(
          '3.3',
          <>
            Mes galime tvarkyti asmens duomenis, jei tvarkymas yra būtinas mums taikomų teisinių
            įsipareigojimų vykdymui, pavyzdžiui, apskaitos tikslais pagal taikomus apskaitos teisės
            aktus, arba jei Yaway prašo kompetentinga institucija, pvz., teismo sprendimo pagrindu,
            pateikti asmens duomenis. Tokio duomenų tvarkymo teisinis pagrindas yra BDAR 6
            straipsnio 1 dalies c punktas.
          </>
        )}
        {NumericSection(
          '3.4',
          <>
            Tam tikrais atvejais mes galime taip pat tvarkyti asmens duomenis, jei asmens duomenų
            tvarkymas yra būtinas mūsų teisėtam interesui. Tokio duomenų tvarkymo teisinis pagrindas
            yra BDAR 6 straipsnio 1 dalies f punktas. Tokiu atveju Yaway užtikrina, kad tvarkymas
            būtų proporcingas ir būtų atlikta mūsų teisėto intereso vertinimas.
          </>
        )}

        {NumericSection('4.', <b>ASMENS DUOMENŲ SAUGOJIMAS</b>)}
        {NumericSection(
          '4.1',
          <>
            Yaway nesaugo asmens duomenų ilgiau, nei būtina pagal asmens duomenų tvarkymo tikslą
            arba taikomus teisės aktus. Paprastai mes taikome šiuos saugojimo laikotarpius.
          </>
        )}
        {NumericSection(
          '4.2',
          <>
            Asmens duomenys, susiję su sutartimis, gali būti saugomi sutarties galiojimo laikotarpiu
            ir remiantis mūsų teisėtu interesu pagal BDAR 6 straipsnio 1 dalies f punktą, kol
            galioja teisės aktuose nustatyti privalomi apribojimo laikotarpiai. Paprastai Yaway
            saugo duomenis, surinktus teikiant paslaugas, tiek laiko, kiek tai būtina paslaugų
            teikimui sutarties tarp duomenų subjekto ir Yaway galiojimo metu ir 3 metus po sutarties
            pabaigos. Bendra taisyklė yra ta, kad visi Naudotojo asmens duomenys yra ištrinami
            praėjus 3 metams po užsakymo įvykdymo, nebent turime teisinį pagrindą saugoti jūsų
            asmens duomenis ilgesnį laiką.
          </>
        )}
        {NumericSection(
          '4.3',
          <>
            Duomenys, surinkti pagal sutikimą, yra saugomi iki tol, kol sutikimas yra atšaukiamas.
            Jei duomenų subjektas neatšaukė savo sutikimo, Yaway taiko tą patį asmens duomenų
            saugojimo laikotarpį, kaip ir asmens duomenims, surinktiems platformos palaikymui.
            Bendra taisyklė yra ta, kad jei Platforma nebuvo naudojama 3 metus, visi su naudotoju
            susiję asmens duomenys yra ištrinami, nebent mes turime teisinį pagrindą saugoti jūsų
            asmens duomenis ilgesnį laiką.
          </>
        )}
        {NumericSection(
          '4.4',
          <>
            Asmens duomenys, susiję su apskaitos šaltiniais ir apskaitos įrašais, turi būti saugomi
            pagal atitinkamus apskaitos teisės aktus. Todėl pagal apskaitos teisės aktus mes saugome
            apskaitos dokumentus 7 metus.
          </>
        )}

        {NumericSection('5.', <b>ASMENS DUOMENŲ TVARKYMO APŽVALGA</b>)}
        {NumericSection(
          '',
          <>
            Yaway tvarko asmens duomenis šiais konkrečiais tikslais ir taikant šiuos saugojimo
            laikotarpius:
          </>
        )}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='134'>
                  <p>
                    <strong>Tvarkymo tikslas</strong>
                  </p>
                </td>
                <td width='132'>
                  <p>
                    <strong>Asmens duomenų kategorijos</strong>
                  </p>
                </td>
                <td width='133'>
                  <p>
                    <strong>Kaip gauti asmens duomenys</strong>
                  </p>
                </td>
                <td width='174'>
                  <p>
                    <strong>Saugojimo laikotarpis</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Užsakant pagalbos kelyje paslaugą per platformą (naudotojui suteikiant galimybę
                    pateikti užsakymą dėl vilkiko paslaugos pagal Sąlygas)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Vardas, pavardė, el. pašto adresas, incidento tipas, adresas ir koordinatės,
                    transporto priemonės duomenys, incidento aprašymas, incidento nuotraukos
                  </p>
                </td>
                <td width='133'>
                  <p>Tiesiogiai iš kiekvieno duomenų subjekto (Naudotojo)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Sąlygų galiojimo laikotarpiu ir iki 3 metų po sutarties su duomenų
                    subjektu nutraukimo, remiantis Yaway teisėtu interesu iki teisės aktuose
                    nustatytų apribojimo laikotarpių pabaigos
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Per platformą pranešant apie incidentą (naudotojui suteikiant galimybę pateikti
                    užsakymą dėl žalos tvarkymo paslaugos pagal Sąlygas)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Vardas, pavardė, el. pašto adresas, incidento tipas, transporto priemonės
                    duomenys, incidento aprašymas, incidento nuotraukos
                  </p>
                </td>
                <td width='133'>
                  <p>Tiesiogiai iš kiekvieno duomenų subjekto (Naudotojo)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Sąlygų galiojimo laikotarpiu ir iki 3 metų po sutarties su duomenų
                    subjektu nutraukimo, remiantis Yaway teisėtu interesu iki teisės aktuose
                    nustatytų apribojimo laikotarpių pabaigos
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Užsakymo tvarkymas per skambučių centrą</p>
                </td>
                <td width='132'>
                  <p>
                    Vardas, pavardė, el. pašto adresas, incidento tipas, transporto priemonės
                    duomenys, incidento aprašymas ir kita informacija, kurią naudotojas pateikia
                    skambučio metu
                  </p>
                </td>
                <td width='133'>
                  <p>Tiesiogiai iš kiekvieno duomenų subjekto (Naudotojo)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Sąlygų galiojimo laikotarpiu ir iki 3 metų po sutarties su duomenų
                    subjektu nutraukimo, remiantis Yaway teisėtu interesu iki teisės aktuose
                    nustatytų apribojimo laikotarpių pabaigos
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Atsakymas į duomenų subjekto užklausas</p>
                </td>
                <td width='132'>
                  <p>
                    Vardas, el. pašto adresas ir kiti asmens duomenys, kuriuos jūs pateikiate mums
                    tiesiogiai kreipdamiesi su užklausa ar klausimu, ar per Platformą, ar kitais
                    kanalais (pvz., siunčiant el. laišką)
                  </p>
                </td>
                <td width='133'>
                  <p>Tiesiogiai iš kiekvieno duomenų subjekto</p>
                </td>
                <td width='174'>
                  <p>
                    Iki pateiktos užklausos reikalavimų galiojimo pabaigos. Paprastai šis
                    laikotarpis yra 3 metai
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Vilkiko vairuotojo (Paslaugų teikėjo darbuotojo) paskyros registracija</p>
                </td>
                <td width='132'>
                  <p>
                    Vardas, pavardė, telefono numeris, el. pašto adresas ir slaptažodis, pasirinktas
                    vartotojo paskyrai
                  </p>
                </td>
                <td width='133'>
                  <p>Tiesiogiai iš kiekvieno duomenų subjekto (vilkiko vairuotojo)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Sąlygų galiojimo laikotarpiu ir iki 3 metų po sutarties su duomenų
                    subjektu nutraukimo, remiantis Yaway teisėtu interesu ir pagal teisės aktuose
                    nustatytus apribojimo laikotarpius
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Techniniai duomenys</p>
                </td>
                <td colSpan={3} width='441'>
                  <p>Skaitykite skyrių „Slapukai“</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {NumericSection('6.', <b>KAIP PASLAUGŲ TEIKĖJAI GAUNA PRIEIGĄ PRIE ASMENS DUOMENŲ?</b>)}
        {NumericSection(
          '6.1',
          <>Platforma palengvina užsakymų tarpininkavimą tarp Naudotojų ir Paslaugų teikėjų.</>
        )}
        {NumericSection(
          '6.2',
          <>
            Užsakant paslaugą, Yaway perduoda su Naudotojo Užsakymu susijusius duomenis atitinkamam
            Paslaugų teikėjui.
          </>
        )}
        {NumericSection(
          '6.3',
          <>
            Pateikiant Užsakymą, Paslaugų teikėjas yra atsakingas už Naudotojo asmens duomenų,
            susijusių su Užsakymo vykdymu, tvarkymą. Klausimais, susijusiais su asmens duomenų
            tvarkymu, susijusiu su Paslaugų teikėjo tvarkymu, prašome kreiptis į Paslaugų teikėją.
          </>
        )}

        {NumericSection(
          '7.',
          <b>KADA YAWAY GALI DALINTIS ASMENS DUOMENIMIS SU TREČIOSIOMIS ŠALIMIS?</b>
        )}
        {NumericSection(
          '7.1',
          <>
            Yaway gali dalintis jūsų asmens duomenimis su savo įgaliotais tvarkytojais, pvz., tam
            tikrais trečiųjų šalių paslaugų teikėjais, pvz., IT paslaugų teikėjais ar kitais
            paslaugų teikėjais.
          </>
        )}
        {NumericSection(
          '7.2',
          <>
            Mes taip pat galime dalintis jūsų asmens duomenimis su trečiosiomis šalimis, jei mums
            tai teisiškai privaloma, pvz., jei kuri nors oficiali institucija, turinti kompetenciją
            reikalauti tokių duomenų, juos iš mūsų reikalauja, pvz., kai duomenys yra reikalaujami
            teismo arba teisėsaugos institucijų.
          </>
        )}
        {NumericSection(
          '7.3',
          <>
            Yaway neperduoda asmens duomenų už Europos Sąjungos ar Europos Ekonominės Erdvės ribų,
            išskyrus atvejus, kai turime galiojantį teisinį pagrindą ir dėl šiose privatumo
            principuose paaiškintų tikslų. Perduodant jūsų asmens duomenis trečiosioms šalims, mes
            užtikriname, kad būtų taikomos atitinkamos saugumo priemonės ir jūsų teisės būtų
            apsaugotos, pvz., naudojant Europos Komisijos standartines sutarčių sąlygas dėl asmens
            duomenų perdavimo trečiosioms šalims (t. y., sutartines standartines sąlygas). Galite
            paprašyti saugumo priemonių kopijos, kurias įgyvendinome asmens duomenų perdavimo
            atveju, susisiekdami su mumis, naudodamiesi žemiau pateiktais kontaktais.
          </>
        )}

        {NumericSection('8.', <b>KAIP YAWAY APSAUGO JŪSŲ ASMENS DUOMENIS?</b>)}
        {NumericSection(
          '',
          <>
            Siekdami apsaugoti jūsų asmens duomenis nuo neteisėtos prieigos, neteisėto tvarkymo ar
            atskleidimo, atsitiktinio praradimo, pakeitimo ar sunaikinimo, mes naudojame tinkamas
            technines ir organizacines priemones pagal taikomus teisės aktus. Šios priemonės apima,
            bet neapsiriboja, tinkamų kompiuterinės saugos sistemų diegimu, popierinių ir
            elektroninių bylų apsaugą techninėmis ir fizinėmis priemonėmis, taip pat dokumentų ir
            pastatų prieigos kontrolę ir apribojimą.
          </>
        )}

        {NumericSection('9.', <b>SLAPUKAI</b>)}
        {NumericSection(
          '9.1',
          <>
            Yaway Platforma naudoja slapukus. Šiame skyriuje aprašyti mūsų slapukų naudojimo
            principai (<b>slapukų principai</b>), kurie taikomi naudojantis platforma.
          </>
        )}
        {NumericSection(
          '9.2',
          <>
            Slapukai yra maži tekstiniai failai, kurie išsaugomi jūsų kietajame diske svetainės
            pagalba. Slapukai padeda mums stebėti ir gerinti platformos funkcionalumą ir naudojimą,
            taip pat jūsų patirtį platformoje. Mes galime naudoti slapukus, kad matytume, kurios
            sritys ir funkcijos yra populiarios, ir suskaičiuotume apsilankymus mūsų platformoje,
            identifikuotume jus kaip sugrįžtantį lankytoją ir pritaikytume jūsų patirtį platformoje
            pagal jūsų pageidavimus. Mes taip pat galime naudoti slapukus tikslinių skelbimų ar
            reklaminių pranešimų tikslais.
          </>
        )}
        {NumericSection('9.3', <>Mes naudojame šiuos slapukų tipus mūsų platformoje:</>)}
        {NumericSection(
          '9.3.1',
          <>
            <u>Būtini slapukai</u>, kurie yra būtini naudojant platformą ir jos funkcijas.
          </>,
          2
        )}
        {NumericSection(
          '9.3.2',
          <>
            <u>Funkciniai slapukai</u>, kurie saugo informaciją apie jūsų pasirinkimus ir leidžia
            pritaikyti platformą pagal jūsų poreikius. Funkciniai slapukai saugo jūsų pasirinkimus.
            Funkciniai slapukai, kuriuos naudojame, saugo el. pašto adresą po prisijungimo, todėl,
            jei prisijungiate prie platformos su keliais el. pašto adresais, mes galime pasiūlyti
            jums sujungti skirtingas platformos paskyras, kas turėtų supaprastinti jūsų procesus.
          </>,
          2
        )}
        {NumericSection(
          '9.3.3',
          <>
            <u>Analitiniai slapukai</u>, kurie saugo informaciją apie tai, kaip mūsų platforma yra
            naudojama, kad gautume įžvalgų apie tai, kaip dažnai lankomasi mūsų platformoje, kuriose
            platformos dalyse lankytojai praleidžia daugiausia laiko, kaip dažnai sąveikaujama su
            puslapiu ar puslapio dalimi, kas leidžia mums modifikuoti platformos struktūrą,
            navigaciją ir turinį taip, kad būtų kuo patogesnis vartotojui.
          </>,
          2
        )}
        {NumericSection(
          '9.3.4',
          <>
            <u>Reklaminiai slapukai</u>, kurie leidžia reklamos teikėjams stebėti, kaip jūs
            naudojate portalą, kad būtų galima pateikti tikslinius skelbimus ir turinį, kuris galėtų
            būti jums labiausiai įdomus.
          </>,
          2
        )}
        {NumericSection('9.4', <>Specifiniai platformoje naudojami slapukai yra šie:</>)}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='188'>
                  <p>
                    <strong>Slapukas</strong>
                  </p>
                </td>
                <td width='139'>
                  <p>
                    <strong>Aprašymas</strong>
                  </p>
                </td>
                <td width='135'>
                  <p>
                    <strong>Trukmė</strong>
                  </p>
                </td>
                <td width='138'>
                  <p>
                    <strong>Tipas</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_ga</p>
                </td>
                <td width='139'>
                  <p>
                    Šį slapuką įdiegė „Google Analytics“. Slapukas naudojamas apskaičiuoti
                    lankytojų, sesijų ir kampanijų duomenis bei stebėti svetainės naudojimą,
                    siekiant parengti svetainės analitinę ataskaitą. Slapukai saugo duomenis
                    anonimiškai ir priskiria atsitiktinai sugeneruotą numerį, kad identifikuotų
                    kiekvieną lankytoją.
                  </p>
                </td>
                <td width='135'>
                  <p>2 metai</p>
                </td>
                <td width='138'>
                  <p>Analitiniai</p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_gid</p>
                </td>
                <td width='139'>
                  <p>
                    Šį slapuką įdiegė „Google Analytics“. Slapukas naudojamas saugoti informaciją
                    apie tai, kaip lankytojai naudoja svetainę, ir padeda rengti svetainės našumo
                    analitinę ataskaitą. Surinkti duomenys, be kita ko, apima lankytojų skaičių, jų
                    kilmės šaltinį ir puslapius, kuriuos jie aplanko, anonimiškai.
                  </p>
                </td>
                <td width='135'>
                  <p>1 diena</p>
                </td>
                <td width='138'>
                  <p>Analitiniai</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {NumericSection(
          '9.5',
          <>
            Bet kada galite ištrinti arba blokuoti slapukus savo interneto naršyklės nustatymuose.
            Tačiau kai kurie slapukai gali būti būtini platformos veikimui. Todėl turėtumėte
            suprasti, kad blokuodami arba ištrindami slapukus, kai kurios platformos dalys gali
            neveikti tinkamai.
          </>
        )}
        {NumericSection(
          '9.6',
          <>
            Daugiau bendros informacijos apie slapukus, įskaitant skirtumus tarp sesijos slapukų ir
            nuolatinių slapukų, rasite{' '}
            <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>
          </>
        )}
        {NumericSection(
          '9.7',
          <>
            Jei turite klausimų apie slapukų politiką, galite susisiekti su mumis, naudodamiesi
            žemiau pateiktais kontaktais.
          </>
        )}

        {NumericSection('10.', <b>JŪSŲ TEISĖS</b>)}
        {NumericSection(
          '10.1',
          <>
            Yaway siekia užtikrinti, kad visos duomenų subjektų teisės, kylančios iš taikomų teisės
            aktų, visada būtų garantuotos jums. Visų pirma, kiekvienas duomenų subjektas turi:
          </>,
          2
        )}
        {NumericSection(
          '10.1.1',
          <>teisę susipažinti su asmens duomenimis, kuriuos apie jus tvarko Yaway;</>,
          2
        )}
        {NumericSection(
          '10.1.2',
          <>teisę reikalauti, kad Yaway ištaisytų bet kokius netikslius asmens duomenis;</>,
          2
        )}
        {NumericSection(
          '10.1.3',
          <>
            teisę reikalauti, kad Yaway ištrintų jūsų asmens duomenis ir (arba) apribotų jų
            tvarkymą, jei neturime galiojančio teisinio pagrindo tvarkyti;
          </>,
          2
        )}
        {NumericSection(
          '10.1.4',
          <>
            teisę gauti savo tvarkomus asmens duomenis struktūrizuotu, dažniausiai naudojamu ir
            mašininiu būdu nuskaitomu formatu, taip pat teisę perduoti savo asmens duomenis kitam
            duomenų valdytojui;
          </>,
          2
        )}
        {NumericSection('10.1.5', <>teisę prieštarauti jūsų asmens duomenų tvarkymui;</>, 2)}
        {NumericSection(
          '10.2',
          <>
            Jei manote, kad jūsų teisės buvo pažeistos, galite kreiptis į savo jurisdikcijos
            priežiūros instituciją (Lietuvoje tai yra Valstybinė duomenų apsaugos inspekcija,
            adresas L. Sapiegos g. 1, Vilnius, <a href='mailto:vdai@ada.lt'>vdai@ada.lt</a>).
          </>,
          2
        )}

        {NumericSection('11.', <b>TAIKOMA TEISĖ IR JURISDIKCIJA</b>)}
        {NumericSection(
          '',
          <>
            Privatumo sąlygoms taikoma Lietuvos Respublikos teisė. Bet kokie ginčai, kylantys iš
            privatumo principų, bus sprendžiami Vilniaus miesto apylinkės teisme, nebent turite
            teisinę teisę kreiptis į savo gyvenamosios vietos teismą.
          </>
        )}

        {NumericSection('12.', <b>KONTAKTINIAI DUOMENYS</b>)}
        {NumericSection(
          '',
          <>
            Jei turite klausimų dėl privatumo principų ar slapukų principų, arba rūpesčių dėl to,
            kaip mes naudojame jūsų asmens duomenis, arba jei norite įgyvendinti savo teises
            aukščiau aprašytu būdu, prašome susisiekti su mumis el. paštu arba raštu naudodamiesi
            žemiau pateiktais kontaktais.
          </>
        )}

        <p>
          <b>YAWAY OÜ</b>
        </p>
        <p>
          El. paštas: <a href='mailto:hello@yaway.ai'>hello@yaway.ai</a>
        </p>
        <p>Adresas: Valukoja 8/1, 11415 Talinas</p>
      </div>
    </>
  );
};

export default PrivacyEnglish;
