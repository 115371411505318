import { ApiAssistanceIncidentType, ApiClaimIncidentType } from '../../api';
import { LanguageType } from './LanguageType';

export const et: LanguageType = {
  ok: 'OK',
  close: 'SULGE',
  next: 'Edasi',
  name: 'Nimi',
  submit: 'ESITA',
  refresh: 'Värskenda',
  phoneNumber: 'Telefoninumber',
  enterVehicleNumber: 'Sisestage sõiduki number',
  enterYourName: 'Sisestage oma nimi',
  enterYourPhoneNumber: 'Sisesta oma telefoninumber',
  selectCounty: 'Valige maakond',
  all: 'Kõik',
  refreshPageToSeeUpdates:
    'Oleme lehekülge uuendanud. Värskendage lehte, et näha viimaseid muudatusi.',
  orderHelp: 'TELLI ABI',
  fileAClaimDescription:
    'Kindlustusjuhtumi korral palume esitage avaldus või täitke avaldus BALTA ÄPPis!',
  fileClaim: 'ESITA AVALDUS',
  assistanceIncident: {
    WHAT_IS_THE_ISSUE: 'Mis juhtus?',
    [ApiAssistanceIncidentType.EmptyBattery]: 'Aku tühi',
    [ApiAssistanceIncidentType.RunOutOfFuel]: 'Kütus otsas',
    [ApiAssistanceIncidentType.FlatTires]: 'Rehv tühi',
    [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Kinni liiv/lumi/muda',
    [ApiAssistanceIncidentType.DoorLocked]: 'Uksed lukus',
    [ApiAssistanceIncidentType.UnknownTechnicalIssue]: 'Teadmata tehniline rike',
    [ApiAssistanceIncidentType.TrafficAccident]: 'Liiklusõnnetus',
    [ApiAssistanceIncidentType.Other]: 'Muu',
  },
  claimIncident: {
    WHAT_IS_THE_ISSUE: 'Mis juhtus?',
    [ApiClaimIncidentType.WindshieldDamage]: 'Klaasikahju',
    [ApiClaimIncidentType.TrafficAccident]: 'Liiklusõnnetus',
    [ApiClaimIncidentType.HitWildAnimal]: 'Kokkupõrge loomaga',
    [ApiClaimIncidentType.TechnicalBreakdown]: 'Tehniline rike',
    [ApiClaimIncidentType.Theft]: 'Vargus',
    [ApiClaimIncidentType.VandalismOrFire]: 'Vandalism või tulekahju',
    [ApiClaimIncidentType.Other]: 'Muu',
    invalidClaim: 'Vigane kahjunõue!',
  },
  vehicleDetails: {
    mark: 'Mark',
    model: 'Mudel',
    year: 'Aasta',
    error: 'Abi saamiseks võtke palun ühendust operaatoriga.',
    callOperator: 'Helista',
    missingLicensePlate: 'Sisestage sõiduki number',
    invalidLicensePlate: 'Lubatud on ainult numbrid ja tähed',
    missingName: 'Sisestage oma nimi',
    missingPhoneNumber: 'Sisestage oma telefoninumber',
    requiredField: 'Nõutud väli',
    invalidPhoneNumber: 'Peab olema arv, mis algab + -ga',
  },
  findTowTruck: {
    message1: 'Operaatorite teavitamine',
    message2: 'Puksiiri leidmine võtab maksimaalselt 2 minutit',
    message3: 'Geopiirkonna seadistamine',
    message4: 'Läheduses asuvate puksiiride otsimine',
    message5: 'Leidsime läheduses asuvad puksiirid',
    message6: 'Info edastamine puksiiridele',
    message7: 'Puksiirid hindavad andmeid',
    message8: 'Ootame puksiiride vastuseid',
  },
  waitForTowTruck: {
    title: 'Abi on teel!',
    driverComing: 'tuleb appi',
    callDriver: 'Helista juhile',
    driverShouldBeThere: 'Juht peaks juba seal olema',
    eta: 'Hinnanguline ooteaeg',
  },
  noTowTruckFound: {
    title: 'Vabandust! Puksiiri pole praegu saadaval',
    details: `Üks operaatoritest otsib teile puksiirautot. Teile helistatakse kohe, kui oleme puksiiri leidnud!`,
  },
  towTruckJobFinished: {
    title: 'Töö on lõpetatud',
  },
  map: {
    searchHint: 'Otsi',
    error: 'Andmete saatmine ebaõnnestus. Palun kontrollige oma internetiühendust.',
    pin: 'Asukoha määramiseks lohista kaarti',
    pleaseSetPinToYourLocation: 'Palun kinnita asukoht kaardil',
    unableToRetrieveLocation: 'Asukohta ei õnnestunud leida. Palun leidke asukoht kaardil.',
    confirmLocation: {
      modalTitle: 'Kinnita aadress',
      modalBody: 'Palun kinnitage, et aadress on <b>%{address}</b>.',
      confirm: 'Kinnitage',
      cancel: 'Tühista',
    },
    failedToRetrieveAddress: 'Ebaõnnestus aadressi kättesaamine. Palun proovige uuesti.',
    failedToSaveLocation: 'Asukoha salvestamine ebaõnnestus. Palun proovige uuesti.',
  },
  countdown: {
    minutes: 'Minutid',
    seconds: 'sekundid',
  },
  errors: {
    noVehicleFound: 'Sõidukit ei leitud',
    tryAgain: 'Vabandust, midagi läks valesti. Proovige hiljem uuesti.',
    networkProblem:
      'Tuvastatud võrgukatkestus. Palun veenduge, et ühendus toimib ja proovige uuesti.',
    failedToUpload:
      'Piltide üleslaadimine ebaõnnestus. Palun proovige uuesti või eemaldage pildid.',
    savingAccidentDetailsFailed: 'Juhtumi info salvestamine ebaõnnestus. Palun proovige uuesti.',
    claimNotFound: 'Juhtumit ei leitud.',
    savingPreferencesFailed: 'Suhtluskanali salvestamine ebaõnnestus. Palun proovige uuesti.',
    claimSubmissionFailed: 'Juhtum esitamine ebaõnnestus. Palun proovige uuesti.',
  },
  additionalDetails: {
    addPhotos: 'Vajuta, et lisada pilte',
    requiredField: 'Nõutud väli',
    descriptionLabel: 'Kirjeldus',
    pictures: 'Pildid (valikuline)',
    email: 'E-post (valikuline)',
    invalidEmail: 'Vale e-posti formaat!',
    descriptionPlaceholder: {
      [ApiAssistanceIncidentType.EmptyBattery]:
        'Kui kaua on sõiduk seisnud? Kas auto asub garaažis või maa-aluses parklas?',
      [ApiAssistanceIncidentType.RunOutOfFuel]: 'Palun kirjeldage olukorda.',
      [ApiAssistanceIncidentType.FlatTires]:
        'Palun kirjeldage olukorda. Milline rehv on tühi? Kas rehv on külje pealt kahjustatud?',
      [ApiAssistanceIncidentType.DoorLocked]:
        'Palun kirjeldage olukorda. Kus on auto võtmed? Kas varuvõtmed on olemas?',
      [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Palun kirjeldage olukorda.',
      [ApiAssistanceIncidentType.UnknownTechnicalIssue]:
        'Palun kirjeldage riket. Kas auto käivitub? Kas neutraal läheb sisse? Kas rool keerab? Kas käsipidur tuleb maha?',
      [ApiAssistanceIncidentType.TrafficAccident]:
        'Palun kirjeldage olukorda. Kas sõiduk on liikumisvõimeline? Kas õnnetuses osalesid teised osapooled? Kas olete politseid teavitanud?',
      [ApiAssistanceIncidentType.Other]: 'Palun kirjeldage olukorda.',
    },
  },
  landing: {
    welcome: 'Tere tulemast',
    callYourInsuranceCompanyToGetLink: `Palun helista oma kindlustusele, et saada SMS'i teel link.`,
    openTheLinkAgain: 'Kui sulle saabus SMS, siis ava link uuesti.',
  },
  linkValidation: {
    linkValidationFailed: 'Lingi valideerimine ebaõnnestus',
    linkHasExpired: 'Link on aegunud',
    linkAlreadyProcessed: 'Linki on juba kasutatud',
    invalidLink: 'Vigane link',
    sorrySomethingWentWrong: 'Vabandame, midagi läks valesti',
    callToGetNewLink: `Helista, et saada uus link SMS'iga`,
  },
  trackDriver: {
    warning: 'TÄHELEPANU',
    towtruckDriverChanged: 'Teie juhtumi lahendab teine juht!',
  },
  otherParties: {
    delete: 'Eemalda',
    missingValue: 'Puuduvad väärtused',
    title: 'Teised osapooled',
    otherPartiesInvolved: 'Kas oli teisi osapooli?',
    otherPartiesInfo: 'Kas tead teise poole andmeid?',
    otherPartiesType: 'Teiseks osapooleks oli',
    vehiclePartyType: 'Sõiduk',
    objectPartyType: 'Objekt',
    vehicleParty: {
      number: 'Sõiduki number',
      name: 'Kontakti nimi (valikuline)',
      personalCode: 'Isikukood (valikuline)',
      phoneNumber: 'Telefoninumber (valikuline)',
      email: 'E-post (valikuline)',
    },
    objectParty: {
      description: 'Kirjelda osapoolt',
      name: 'Kontakti nimi (valikuline)',
      personalCode: 'Isikukood (valikuline)',
      phoneNumber: 'Telefoninumber (valikuline)',
      email: 'E-post (valikuline)',
    },
    addAnotherParty: 'Lisa järgmine osapool',
    yes: 'JAH',
    no: 'EI',
    invalidPartyPresentErrorMessage:
      'Mõnel osapoolel puuduvad nõutavad andmed. Palun täitke andmed või kustutage osapool!',
  },
  notifierInfo: {
    title: 'TEAVITAJA INFO',
    driver: 'Juht',
    name: 'Täisnimi',
    personalCode: 'Isikukood (valikuline)',
    telephone: 'Telefoninumber',
    email: 'E-post',
    confirmUserIsDriver: 'Kas olid juhtumi ajal sõiduki juht?',
    notifyUserTofillDriverDetails: '(Kui ei, palun lisa juhi andmed)',
    affirm: 'JAH',
    deny: 'EI',
    errors: {
      name: 'Täisnimi on kohustuslik!',
      telephone: 'Telefoninumber võib sisaldada ainult numbreid ja ei tohi olla tühi!',
      email: 'Sisesta toimiv e-posti aadress!',
      personalCode: 'Isikukood ei ole korrektne!',
    },
  },
  insuranceSelection: {
    title: 'Kindlustus',
    cascoSelect: 'Valige kaskokindlustus',
    subtitle: 'Me saadame nõude kindlustusandjale',
    mtplSelect: 'Valige liikluskindlustus',
    CascoNoneApplicable: 'Ei ole kaskokindlustust',
    MtplNotSure: 'Ma ei tea',
    mandatorySelectionError: 'Valik on kohustuslik!',
    casco: 'Kasko',
    mtpl: 'Liikluskindlustus',
  },
  accidentDetails: {
    title: 'Juhtumi üksikasjad',
    affirmation: 'JAH',
    deny: 'EI',
    dateTime: 'Sisestage õnnetuse kuupäev ja kellaaeg',
    mandatoryLocation: 'Asukoht on kohustuslik',
    location: 'Juhtumi asukoht',
    description: 'Juhtumi kirjeldus',
    culprit: {
      responsibleParty: 'Kes oli juhtumi põhjustaja?',
      notifier: '%{licensePlate} on põhjustaja',
      otherParty: 'Teine osapool oli põhjustaja',
      unknown: 'Teadmata',
    },
    dateError: 'Kuupäev ja kellaaeg on vajalikud!',
    authoritiesNotified: 'Kas politseid teavitati?',
    personalInjury: 'Kas oli isikukahjusid?',
    accidentDateIsMandatory: 'Juhtumi kuupäev on kohustuslik!',
    accidentTimeIsMandatory: 'Juhtumi kellaaeg on kohustuslik!',
    accidentDescriptionIsMandatory: 'Juhtumi kirjeldus on kohustuslik!',
  },
  claimReceived: {
    title: 'JUHTUM REGISTREERITUD',
    body: `Juhtum on edukalt registreeritud. Küsimuste või uuenduste korral võtame
          sinuga otse ühendust. Võid nüüd lehe sulgeda.`,
  },
  communicationPreference: {
    title: 'Eelistatud suhtluskanal',
    email: 'E-POST',
    sms: 'SMS',
    error: 'Vähemal üks eelistatud suhtluskanal on kohustuslik!',
    submit: 'ESITA',
  },
  damageLocation: {
    title: 'Vigastuse asukoht',
  },
  photos: {
    title: 'LISA PILDID',
    skipForNow: 'JÄTAN VAHELE',
    skipStep: 'JÄTA SAMM VAHELE',
    takePhotos: 'TEE PILDID',
    uploadFromGallery: 'LAADI GALERIIST',
    photoUploadLimitReached: 'Lubatud on lisada kokku %{number} pilti, ülejäänud eemaldatakse.',
    skipTitle: `Sa tahad piltide lisamise vahele jätta. Pildid on vajalikud kahju menetlemiseks. Palun lisa need kindlasti hiljem.`,
    turnYourPhoneHorizontally: 'Keera telefon horisontaalasendisse',
    takeAPhotoOfDamage: 'Pildista kahjustust',
    uploadSuggestions: {
      generalViewOfTheVehicle: 'Pilt sõiduki üldvaatest koos registrinumbriga',
      damagePicture: 'Pildid kahjustusest (kaugemalt ja lähemalt)',
      availableDocuments:
        'Teade liiklusõnnetusest või muu kirjalikult vormistatud paber (olemasolul)',
      driversLicense: 'Pilt juhiloast',
      scenePicture: 'Pildid sündmuskohalt (olemasolul)',
    },
  },
  partnerLanding: {
    title: 'Kuidas saame aidata?',
    fileAClaim: 'Juhtumi registreerimine',
    roadsideAssistance: 'Autoabi',
  },
  caseStatus: {
    title: 'Juhtumi staatus',
    registration: 'Sõiduki number',
    steps: {
      caseRegistered: 'Juhtum registreeritud',
      caseRegisteredDone: 'Juhtum registreeritud',
      vehicleDeliveredToWorkshop: 'Sõiduk toimetatud töökotta',
      vehicleDeliveredToWorkshopDone: 'Sõiduk toimetatud töökotta',
      estimatedDiagnostics: 'Diagnostika',
      estimatedDiagnosticsDone: 'Diagnostika lõpetatud',
      preparingRepairEstimate: 'Remondikulude kalkulatsioon ettevalmistamisel',
      preparingRepairEstimateDone: 'Remondikulude kalkulatsioon lõpetatud',
      confirmingRepairEstimate: 'Remondikulude kalkulatsioon kinnitamisel',
      confirmingRepairEstimateDone: 'Remondikulude kalkulatsioon kinnitatud',
      estimatedArrivalOfSpareParts: 'Varuosade saabumine',
      estimatedArrivalOfSparePartsDone: 'Varuosad saabunud',
      repairingStarts: 'Tööde algus',
      repairingStartsDone: 'Töödega alustatud',
      estimatedEndOfRepair: 'Tööde lõpp',
      estimatedEndOfRepairDone: 'Tööde lõpp',
      pickUpVehicle: 'Teie sõiduk on valmis',
      pickUpVehicleDone: 'Sõiduk üle antud',
    },
    nextStep: 'Teie juhtum on käsitluses. Järgmine samm on',
    workshopDetails: 'Töökoja info',
    errors: {
      invalidLink: 'Link on aegunud või kehtetu',
      caseNotFound: 'Juhtumit ei leitud',
    },
  },
  terms: {
    agreement: 'Nõustun tingimustega ja privaatsuspoliitikaga',
    termsAndPrivacy: 'Tingimused ja privaatsuspoliitika',
    error: 'Te peate nõustuma tingimustega ja privaatsuspoliitikaga',
    termsTitle: 'TEENUSE ÜLDTINGIMUSED',
    privacyTitle: 'PRIVAATSUS-TINGIMUSED',
  },
  workshop: {
    choose: 'VALI TÖÖKODA',
    hint: '<h6><b>Vali töökoda vajutades ikoonil <img src = %{img} width="20px" height="20px" /></b></h6>',
  },
  feedback: {
    title: 'Teie tagasiside on meile oluline!',
    subText: 'Andke meile teada, milline oli teie üldine kogemus.',
    emptyRatingText: 'Palun hinnake oma kogemust.',
    commentLabel: 'Muud kommentaarid (vabatahtlik)',
    expired: 'Link on aegunud!',
    notFound: 'Link on kehtetu!',
    alreadyGiven: 'Hinnang on juba antud!',
    success: 'Aitäh! Teie tagasiside on vastu võetud!',
    submissionError: 'Tagasiside esitamine ebaõnnestus! Palun proovige hiljem uuesti.',
  },
  assistanceLanding: {
    title: 'Telli autoabi',
    description:
      '<p>Vajutades nupule ALUSTA tellid autoabi <b>75% kiiremini</b> kui operaatoriga suheldes.<br><br> See on lihtne 4-sammuline protsess, mis võtab kuni 2 minutit. Teie asukoht tuvastatakse GPS-i kaudu.</p>',
    start: 'ALUSTA',
  },
};
