import { Dispatch } from 'redux';
import { assistanceLinkApi } from '../../../apis';
import { ClientError } from '../../../common/exception/ClientError';
import { saveAssistanceRequestDetails } from '../assistanceRequestReducer';
import { AssistanceStorage } from '../../../common/AssistanceStorage';
import { getLanguageTypeFromEnum } from '../../../locale/languageMap';
import { setLocale } from 'react-redux-i18n';
import { linkValidationErrors } from './LinkValidation';
import { clearAssistanceRequestData } from '../assistanceRequestActions';
import { reloadTranslationsFromStore } from '../../commonActions';
import { ServiceStorage } from '../../../common/ServiceStorage';

export const validateLinkAction =
  (code: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    await clearOldAssistanceSmsId(code);
    clearAssistanceRequestData(dispatch);
    reloadTranslationsFromStore(dispatch);
    const response = await assistanceLinkApi.validateAssistanceRequestLink({ code: code });
    if (response.isValid && response.id) {
      dispatch(
        saveAssistanceRequestDetails({
          requestId: response.id,
          submitTime: undefined,
          requestExternalId: response.externalId,
        })
      );
      // @ts-ignore
      dispatch(setLocale(getLanguageTypeFromEnum(response.language)));
      AssistanceStorage.setAssistanceRequestId(response.id);
      AssistanceStorage.setAssistanceRequestSmSID(code);
    } else {
      AssistanceStorage.clearAssistanceRequestId();
      ServiceStorage.clear();
      throw new ClientError(
        response.msg ? response.msg : linkValidationErrors.LINK_VALIDATION_FAILED
      );
    }
  };

const clearOldAssistanceSmsId = async (code: string) => {
  const smsId = AssistanceStorage.getAssistanceRequestSmSID();

  if (smsId && code !== smsId) {
    localStorage.clear();
  }
};
