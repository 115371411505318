import React from 'react';
import BatteryDead from '../../../assets/assistance-requests/images/incidents/BatteryDead';
import CarAccident from '../../../assets/assistance-requests/images/incidents/CarAccident';
import EngineHeat from '../../../assets/assistance-requests/images/incidents/EngineHeat';
import FlatTire from '../../../assets/assistance-requests/images/incidents/FlatTire';
import Locked from '../../../assets/assistance-requests/images/incidents/Locked';
import LowFuel from '../../../assets/assistance-requests/images/incidents/LowFuel';
import Other from '../../../assets/assistance-requests/images/incidents/Other';
import Towing from '../../../assets/assistance-requests/images/incidents/Towing';
import { ApiAssistanceIncidentType } from '../../../api';
import { IncidentProps } from '../../../common/components/incidents/Incident';

export const IncidentList: IncidentProps[] = [
  {
    icon: <EngineHeat />,
    text: ApiAssistanceIncidentType.UnknownTechnicalIssue.toString(),
  },
  {
    icon: <BatteryDead />,
    text: ApiAssistanceIncidentType.EmptyBattery.toString(),
  },
  {
    icon: <FlatTire />,
    text: ApiAssistanceIncidentType.FlatTires.toString(),
  },
  {
    icon: <CarAccident />,
    text: ApiAssistanceIncidentType.TrafficAccident.toString(),
  },
  {
    icon: <Towing />,
    text: ApiAssistanceIncidentType.StuckInSandSnowMud.toString(),
  },
  {
    icon: <LowFuel />,
    text: ApiAssistanceIncidentType.RunOutOfFuel.toString(),
  },
  {
    icon: <Locked />,
    text: ApiAssistanceIncidentType.DoorLocked.toString(),
  },
  {
    icon: <Other />,
    text: ApiAssistanceIncidentType.Other.toString(),
  },
];
