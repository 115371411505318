const SERVICE_IS_TEST_KEY = 'serviceIsTest';

export class ServiceStorage {
  public static setTestService(isTest: boolean): void {
    localStorage.setItem(SERVICE_IS_TEST_KEY, String(isTest));
  }

  public static isTestService(): boolean {
    const isService = localStorage.getItem(SERVICE_IS_TEST_KEY);
    return isService === 'true';
  }

  public static clear(): void {
    localStorage.removeItem(SERVICE_IS_TEST_KEY);
  }
}
