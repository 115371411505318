/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FeedbackLinkCaseType {
    AssistanceRequest = 'ASSISTANCE_REQUEST',
    Claim = 'CLAIM',
    Cases = 'CASES'
}

export function FeedbackLinkCaseTypeFromJSON(json: any): FeedbackLinkCaseType {
    return FeedbackLinkCaseTypeFromJSONTyped(json, false);
}

export function FeedbackLinkCaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackLinkCaseType {
    return json as FeedbackLinkCaseType;
}

export function FeedbackLinkCaseTypeToJSON(value?: FeedbackLinkCaseType | null): any {
    return value as any;
}

