/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCommunicationPreferenceType,
    ApiCommunicationPreferenceTypeFromJSON,
    ApiCommunicationPreferenceTypeFromJSONTyped,
    ApiCommunicationPreferenceTypeToJSON,
} from './';

/**
 * Communication Preference
 * @export
 * @interface ApiCommunicationPreference
 */
export interface ApiCommunicationPreference {
    /**
     * 
     * @type {Array<ApiCommunicationPreferenceType>}
     * @memberof ApiCommunicationPreference
     */
    communicationPreferences: Array<ApiCommunicationPreferenceType>;
}

export function ApiCommunicationPreferenceFromJSON(json: any): ApiCommunicationPreference {
    return ApiCommunicationPreferenceFromJSONTyped(json, false);
}

export function ApiCommunicationPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCommunicationPreference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationPreferences': ((json['communicationPreferences'] as Array<any>).map(ApiCommunicationPreferenceTypeFromJSON)),
    };
}

export function ApiCommunicationPreferenceToJSON(value?: ApiCommunicationPreference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communicationPreferences': ((value.communicationPreferences as Array<any>).map(ApiCommunicationPreferenceTypeToJSON)),
    };
}

